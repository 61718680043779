import { CommonModule } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import {
  RouterModule,
  RouterOutlet,
  Router,
  ActivationEnd,
  NavigationEnd,
  ActivationStart,
} from '@angular/router'
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav'
import { HeaderComponent } from './components/header/header'
import { Loading, NavMenu } from './globals'
import { SpinnerCustomComponent } from './components/spinner/custom-spinner.component'
import { NavMenuComponent } from './components/nav-menu/nav-menu'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MixSnackbar } from './components/snackbar/snackbar'
import { getLocalStorage, setLocalStorage } from './helpers'

const setToken = (value: any) => {
  const access_token = getLocalStorage('access_token')
  if (access_token != value.access_token) {
    setLocalStorage('access_token', value.access_token)
    setLocalStorage('refresh_token', value.refresh_token)
    location.href = '/'
  }
}

window.addEventListener('message', e => {
  // console.log('message on web', e)
  //message from extension
  if (e.data) {
    if (e.data.key == 'token_from_extension' && location.pathname != '/login') {
      setToken(e.data.value)
    } else if (e.data.key == 'login_from_extension') {
      setToken(e.data.value)
    } else if (e.data.key == 'logout_from_extension') {
      localStorage.clear()
      sessionStorage.clear()
      location.href = '/login'
    }
  }
})

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    HeaderComponent,
    SpinnerCustomComponent,
    MatSidenavModule,
    NavMenuComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  @ViewChild('drawer') drawer: MatDrawer | undefined

  isPermission = false

  get isShowHeader() {
    return this.isPermission
  }

  get isShowMenu() {
    return this.isPermission
  }

  constructor(
    _: MixSnackbar,
    public Router: Router,
    public Loading: Loading,
    public NavMenu: NavMenu
  ) {
    this.Router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        this.isPermission = (e.snapshot.data['permissions'] || []).length > 0
      }

      if (e instanceof NavigationEnd) {
        if (this.drawer) {
          this.drawer.close()
        }
      }
    })
  }

  ngOnInit(): void {
    this.NavMenu.open.subscribe(() => {
      if (this.drawer) {
        this.drawer.toggle()
      }
    })
  }
}
