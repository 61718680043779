<div class="login-body-wrapper">
  <div class="login-layout">
    <div class="login-center">
      <div class="login-box">
        <div>
          <div class="login-logo">
            <img alt="Logo" src="/assets/images/logos/logo-login.svg" />
          </div>
          <div class="brand-title">ระบบจัดการหลังร้าน Brandname Hunter</div>
        </div>

        <form autocomplete="off">
          <div class="title mb-2">
            <span class="title">ตั้งรหัสผ่านใหม่</span>
          </div>

          <div class="row gx-0 w-100 mt-3">
            <div class="col-12 mt-1">
              <label
                class="form-label"
                [class.text-danger]="formChangePassword.controls['password'].touched && formChangePassword.controls['password'].invalid">
                รหัสผ่านใหม่
              </label>
              <app-input-password
                [control]="formChangePassword.controls['password']"
                (onInput)="onCheckMatchPassword()">
                @if (formChangePassword.controls['password'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                }@else if (formChangePassword.controls['password'].hasError('pattern')){
                <ng-container error>รหัสผ่านไม่ถูกต้อง</ng-container>
                }
              </app-input-password>
            </div>

            <div class="mb-3 detail">
              <li>ตัวอักษรอย่างน้อย 6 ตัว</li>
              <li>มีตัวเลข 0-9</li>
              <li>มีตัวอักษรภาษาอังกฤษ (A-Z) ตัวพิมพ์ใหญ่ 1 ตัว</li>
              <li>สามารถใช้อักขระพิเศษ เช่น # * $ & ได้</li>
            </div>

            <div class="col-12 mt-1">
              <label
                class="form-label"
                [class.text-danger]="formChangePassword.controls['password_confirm'].touched && formChangePassword.controls['password_confirm'].invalid">
                ยืนยันรหัสผ่านใหม่
              </label>
              <app-input-password
                [control]="formChangePassword.controls['password_confirm']"
                (onInput)="onCheckMatchPassword()">
                @if(formChangePassword.controls['password_confirm'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (formChangePassword.controls['password_confirm'].hasError('match')){
                <ng-container error>รหัสผ่านใหม่ไม่ตรงกัน</ng-container>
                }
              </app-input-password>
            </div>

            <div class="col-12 mt-3">
              <button
                matRipple
                type="button"
                class="btn btn-primary w-100"
                (click)="onResetPassword()">
                ยืนยัน
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
