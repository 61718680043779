import { Component } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { SearchComponent } from '../../../components/search/search'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'
import {
  Pagination,
  formatPrice,
  formatNoDecimal,
  Moment,
  DownloadFileBlob,
} from '../../../helpers'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { FormControl, FormGroup } from '@angular/forms'
import { CheckboxAllTableComponent } from '../../../components/checkbox-table/checkbox-all-table.component'
import { CheckboxTableComponent } from '../../../components/checkbox-table/checkbox-table.component'
import { SearchFilterComponent } from './search-filter/search-filter'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import { SelectComponent } from '../../../components/select/select'
import { ReportService } from '../../../services/report.service'
import { SortByComponent } from '../../../components/sort-by/sort-by'
import { FilterReportProduct } from '../../../globals/search-and-filter/report-product'
import { forkJoin } from 'rxjs'
@Component({
  selector: 'app-report-product',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    SearchComponent,
    MatPaginatorModule,
    MatTooltipModule,
    DatePickerRangeComponent,
    CheckboxAllTableComponent,
    CheckboxTableComponent,
    SearchFilterComponent,
    SelectComponent,
    SortByComponent,
  ],
  templateUrl: './product.html',
  styleUrls: ['./product.scss'],
})
export class ReportProductComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatNoDecimal = formatNoDecimal
  readonly Moment = Moment

  readonly paginationReportProduct = new Pagination({
    sortName: 'id',
  })

  itemChecked = new Set<any>()

  form = new FormGroup({
    report_product: new FormControl(1),
  })

  formProduct = new FormGroup({
    date_str: new FormControl(''),
    date_end: new FormControl(''),
  })

  productList: any = []
  productSum = {
    total: 0,
    stock: 34,
  }

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public reportService: ReportService,
    public filterReportProduct: FilterReportProduct
  ) {}

  async ngOnInit() {
    this.getReportProductList()
  }

  async getReportProductList() {
    const pagination = this.paginationReportProduct.get()
    const dataFilter = this.filterReportProduct.getData()

    const payload = {
      ...pagination,
      ...dataFilter,
    }

    const apis = [
      this.reportService.getReportProductList(payload),
      this.reportService.getReportProductSummaryList(payload),
    ]
    await new Promise(rev => {
      this.loading.start()
      forkJoin(apis).subscribe(([resProduct, resProductSum]: any) => {
        if (resProduct && !resProduct.is_error) {
          this.productList = resProduct.data.records
          this.paginationReportProduct.setFromResponse(resProduct.data)
        } else {
          this.customSnackBar.fail()
        }
        if (resProductSum && !resProductSum.is_error) {
          this.productSum = resProductSum.data
        } else {
          this.customSnackBar.fail()
        }
        rev(null)

        this.loading.stop()
      })
    })
  }

  sumField(field: string) {
    return this.productList.reduce((total: number, product: any) => {
      return total + (product[field] || 0)
    }, 0)
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationReportProduct.get()
    const dataFilter = this.filterReportProduct.getData()
    dataFilter.filter.ids = this.itemChecked.size > 0 ? [...this.itemChecked.values()] : undefined

    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      ...dataFilter,
    }
    this.loading.start()
    this.reportService.exportReportProductList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `report-product.xlsx`)
          this.itemChecked.clear()
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
