import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { RoleService } from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'

@Injectable({ providedIn: 'root' })
export class FilterTableTier extends __searchAndFitler {
  readonly list = {
    actives: <any[]>[
      { value: true, text: 'ใช้งาน' },
      { value: false, text: 'ไม่ใช้งาน' },
    ],
  }

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  constructor(
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {
    const filter = {
      is_actives: [],
    }
    super('/setting/tier', filter)
  }

  initData() {}

  isActiveChecked(bool: any) {
    return this.filter['is_actives'].some((id: any) => id === bool)
  }

  activeChange(bool: any) {
    const is_actives = this.filter['is_actives']
    if (is_actives.includes(bool)) {
      is_actives.splice(is_actives.indexOf(bool), 1)
    } else {
      is_actives.push(bool)
    }
  }

  removeActive(bool: any) {
    this.activeChange(bool)
  }

  getData() {
    const filter: any = {}

    const data: any = {
      filter,
    }

    const is_actives = this.filter['is_actives']
    if (is_actives.length) {
      filter.is_actives = is_actives
    }

    if (Object.keys(filter).length) {
      data.filter = filter
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
