<div class="is-sort {{ divClass }}" (click)="clickSort()">
  <ng-content></ng-content>
  <div class="icon-th-sort">
    @if (isActive('asc')) {
    <img src="/assets/images/icons/icon-sort-up.svg" />
    }
    <!-- @ -->
    @if (isActive('desc')) {
    <img src="/assets/images/icons/icon-sort-down.svg" />
    }
  </div>
</div>
