<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="merge-order-wrapper">
      <div class="neutral-950">กรุณาเลือกออเดอร์หลักที่ต้องการรวม</div>
    </div>

    <mat-radio-group aria-label="Select an option" [formControl]="check">
      <div class="merge-order-item-wrapper">
        @for (item of data.orders; track $index) {
        <div class="merge-order-item">
          <div class="radio-button-wrapper">
            <mat-radio-button [value]="item.id"></mat-radio-button>
          </div>
          <div class="order-info-wrapper">
            <div class="order-info-inner-wrapper">
              <div class="info-text">
                <span class="info-text-bold">{{ item.order_no }}</span> {{item.today ? 'วันนี้' :
                Moment(item.created_at).format('DD/MM/YYYY') }}, {{
                Moment(item.created_at).format('HH:mm') }}
              </div>
              <div class="customer-info">
                <div class="info-text-lg info-text-bold">{{ item.customer_name }}</div>
                <div class="d-flex align-items-center gap-2">
                  @if (channelIcon[item.channel_id]) {
                  <img
                    width="20"
                    height="20"
                    src="/assets/images/icons/icon-social-{{ channelIcon[item.channel_id] }}.svg"
                    [alt]="item.channel.name || '-'" />
                  }
                  <div class="info-text-md">
                    {{ item.channel?.name || 'ไม่ระบุ' }}@if (item.channel_name) {:
                    <span class="name-channel" [innerHTML]="item.channel_name"></span> }
                  </div>
                </div>
              </div>
              <div class="net-total">ยอดรวมสุทธิ์: {{ formatPrice(item.total) }}</div>
            </div>

            <div class="order-info-inner-wrapper">
              @for (delivery_address of item.delivery_addresses; track $index) { @if (!$index ||
              item.showAllAddressInmerge) {
              <div class="customer-info" [class.mt-2]="$index > 0">
                <div class="d-flex align-items-center gap-2">
                  <span class="icon icon-dark-grey material-symbols-rounded fill"
                    >local_shipping</span
                  >
                  <div class="info-text-md info-text-bold">{{ delivery_address.name }}</div>
                </div>
                <div class="separator">|</div>
                <div class="info-text-md">{{ delivery_address.telephone }}</div>
              </div>
              <div class="info-text-md" [class.mb-2]="$index > 0">
                {{ delivery_address.address }}
              </div>
              } } @if (item.delivery_addresses.length > 1 && item.is_multi_delivery) {
              @if(!item.showAllAddressInmerge) {
              <div (click)="item.showAllAddressInmerge = true" class="view-address">
                ดูที่อยู่จัดส่งทั้งหมด
                <span class="icon material-symbols-rounded fill">expand_more</span>
              </div>
              } @else {

              <div (click)="item.showAllAddressInmerge = false" class="view-address">
                ซ่อนที่อยู่จัดส่งทั้งหมด
                <span class="icon material-symbols-rounded fill">expand_less</span>
              </div>
              } }
            </div>
          </div>
        </div>
        }
      </div>
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button
      matRipple
      class="btn btn-primary btn-md"
      (click)="onConfirm()"
      [disabled]="!check.value">
      รวมออเดอร์
    </button>
  </div>
</mat-dialog-actions>
