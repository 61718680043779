<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      ออเดอร์
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขออเดอร์</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">คืนสินค้า</div>
  </div>

  <div class="title-wrapper">คืนสินค้า</div>

  @if (order && payment) {
  <div class="page-content-inner mb-3">
    <div class="title-blue">คืนสินค้า</div>

    <div class="table-responsive-detail mt-3">
      <table class="table table-fixed-column table-detail" [style]="{ width: 'max-content' }">
        <thead>
          <tr>
            <th width="320">สินค้า</th>
            <th width="100" class="text-center">จำนวน</th>
            <th width="150" class="text-end">ราคา (บาท)</th>
            <th width="200">จำนวนที่ต้องการคืน</th>
          </tr>
        </thead>
        <tbody>
          @for (item of products.controls; track $index) {
          <tr>
            <td>
              <div class="d-flex align-items-center">
                <img src="{{ item.controls['img'].value }}" width="60" class="me-2" />
                {{ item.controls['name'].value }}
              </div>
            </td>
            <td class="text-center">
              {{ formatPriceAutoDecimal(item.controls['data'].value.quantity) }}
            </td>
            <td class="text-end">{{ formatPrice(item.controls['data'].value.price) }}</td>
            <td>
              <div class="input-number-fake">
                <app-input-number
                  [control]="item.controls['quantity']"
                  [min]="0"
                  [max]="item.controls['data'].value.quantity"
                  [useZero]="true"
                  [decimal]="0"
                  placeholder="0"
                  [remove_bottom]="true">
                </app-input-number>
                <div class="btn-num-right">
                  <button matRipple (click)="setQuantity(1, item)">
                    <img src="/assets/images/icons/icon-btn-number-up.svg" />
                  </button>
                  <button matRipple (click)="setQuantity(-1, item)">
                    <img src="/assets/images/icons/icon-btn-number-down.svg" />
                  </button>
                </div>
              </div>
            </td>
          </tr>
          }
          <tr class="return">
            <td>รวมยกเลิกสินค้า</td>
            <td></td>
            <td class="text-end">{{ formatPrice(totalProduct.totalPriceReturn) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      @if(!products.controls.length) {
      <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
      }
    </div>

    <div class="line mt-3"></div>

    <div class="title-blue my-3">การชำระเงิน</div>
    <div class="bg-gray">
      <div class="d-flex justify-content-between">
        <span>ราคารวมสินค้าคงเหลือ</span>
        <span> {{ formatPrice(totalProduct.totalPrice) }} บาท</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>ส่วนลดเพิ่มเติม</span>
        <span>{{ formatPrice(discount) }} บาท</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>รหัสส่วนลด</span>
        <span>{{ formatPrice(discountCodePrice) }} บาท</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>ส่วนลดเครดิต</span>
        <span>{{ formatPrice(creditCodePrice) }} บาท</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>ส่วนลดกะรัต</span>
        <span>{{ formatPrice(caratCodePrice) }} บาท</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>ยอดรวมสุทธิ</span>
        <span>{{ formatPrice(sumTotal) }} บาท</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>ยอดชำระก่อนหน้า</span>
        <span>{{ formatPrice(previousPayment) }} บาท</span>
      </div>
      <div class="d-flex justify-content-between">
        <b>{{ calcPayment > 0 ? 'ยอดที่ชำระเกิน (คืนเครดิต)' : 'ยอดที่ต้องชำระเพิ่ม'}}</b>
        <b [class.color--green-600]="calcPayment > 0" [class.text-danger]="calcPayment <= 0"
          >{{ formatPrice(calcPayment) }} บาท</b
        >
      </div>
      <div class="line-sub"></div>
      <div class="d-flex justify-content-between">
        <span>กะรัตที่ได้</span>
        <span>{{ formatPriceAutoDecimal(caratAmount) }} กะรัต</span>
      </div>
    </div>

    <div class="line mt-4"></div>

    <div class="title-blue my-3">รายละเอียดเพิ่มเติม</div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <label
          class="form-label"
          [class.text-danger]="form.controls.remark.touched && form.controls.remark.invalid">
          ระบุเหตุผลที่ต้องการคืน <span class="text-danger">*</span>
        </label>
        <app-text-area [control]="form.controls.remark" maxlength="500" remainingText="500">
          @if (form.controls.remark.hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-text-area>
      </div>
      <div class="col-12 col-sm-4">
        <label class="form-label"> ไฟล์แนบ </label>
        <app-upload-image-profile
          [type]="'edit'"
          [logoDefault]="''"
          (upload)="form.controls.file_id.setValue($event.id)"
          (onDelete)="form.controls.file_id.reset()"></app-upload-image-profile>
      </div>
    </div>

    <div class="line mt-3"></div>
    <div class="d-flex justify-content-end">
      <button
        matRipple
        class="btn btn-outline-secondary min-w-100px me-2"
        [routerLink]="['/order/edit/' + order_id]">
        ยกเลิก
      </button>
      <button matRipple class="btn btn-primary min-w-100px" (click)="onConfirm()">บันทึก</button>
    </div>
  </div>
  }
</div>
