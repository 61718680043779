<span class="snackbar-fail" matSnackBarLabel>
  <span class="d-flex align-items-center">
    <img class="me-2" [src]="mixSnackbar.iconFail" />
    {{ data }}
  </span>
  <span
    class="icon material-symbols-outlined cursor-pointer"
    matSnackBarAction
    (click)="snackBarRef.dismissWithAction()"
  >
    close
  </span>
</span>
