<div class="search-filter-wrapper">
  <app-input-search-scan
    [control]="filterTableOrder.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterTableOrder.confirm(); onConfirm.emit()"></app-input-search-scan>

  <button (click)="openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
</div>

@if (filterTableOrder.isFilter) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterTableOrder.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @for (d of filterTableOrder.list.channel; track $index) { @if
    (filterTableOrder.isChannelChecked(d.id)) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ d.name }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableOrder.removeChannel(d.id); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } } @for (d of filterTableOrder.list.transport; track $index) { @if
    (filterTableOrder.isTransportChecked(d.id)) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ d.name }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableOrder.removeTransport(d.id); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } } @if (filterTableOrder.startEndDateFormat) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTableOrder.startEndDateFormat }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableOrder.removeStartEndDate(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
  </div>
</div>
}
