import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { InputComponent } from '../../../components/input/input'
import { SelectComponent } from '../../../components/select/select'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { imports } from '../../../imports'
import { Loading } from '../../../globals'
import { PublicService } from '../../../services'
import { Moment, formatPrice } from '../../../helpers'

@Component({
  selector: 'app-modal-payment',
  templateUrl: './modal-payment.html',
  styleUrls: ['./modal-payment.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
  ],
})
export class ModalPaymentComponent {
  readonly formatPrice = formatPrice
  readonly Moment = Moment

  payment: any

  constructor(
    public dialogRef: MatDialogRef<ModalPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public publicService: PublicService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    this.loading.start()
    this.publicService.getPaymentByOrderId(this.data.item.id).subscribe((res: any) => {
      if (!res.is_error) {
        this.payment = res.data
      } else {
        this.customSnackBar.fail(res.message)
      }
      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }
}
