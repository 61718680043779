import { Component } from '@angular/core'
import { imports } from '../../imports'

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [...imports],
  templateUrl: './not-found.html',
  styleUrls: ['./not-found.scss'],
})
export class NotFoundComponent {}
