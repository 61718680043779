<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">รายงาน</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">{{ listChoose(report.value)?.name }}</div>
  </div>

  <div class="select-wrapper">
    <app-select [control]="report" [datas]="listReport" fieldValue="value" placeholder="เลือก">
      <ng-template #optionRef let-option> {{ option.name }} </ng-template>
    </app-select>
  </div>

  @if (report.value == 1) {
  <app-report-sales></app-report-sales>
  } @else if (report.value == 2) {
  <app-report-product></app-report-product>
  } @else if (report.value == 3) {
  <app-report-user></app-report-user>
  } @else if (report.value == 4) {
  <app-report-customer-ranking></app-report-customer-ranking>
  }
</div>
