import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatSelect, MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { CommonModule } from '@angular/common'
import { SanitizeHtmlPipe } from '../../pipes/inner-html'

@Component({
  selector: 'app-select-sort[control][datas][fieldValue]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatSelectModule,
    MatInputModule,
    SanitizeHtmlPipe,
  ],
  templateUrl: './select-sort.html',
  styleUrls: ['./select-sort.scss'],
})
export class SelectSortComponent implements OnInit {
  @ContentChild(TemplateRef) triggerRef: any
  @ContentChild(TemplateRef) optionRef: any
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() label = ''
  @Input() datas!: any[]
  @Input() fieldValue!: any
  @Input() disabled = false

  @Output() selectionChange = new EventEmitter()
  // @Input('eng')
  // eng?: TemplateRef<any>;
  @ViewChild('select') select: any

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  get dataSelect() {
    const value = this.control.value
    if (value) {
      return this.datas.find((d: any) => d[this.fieldValue] == value)
    }
    return null
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }
}
