<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text" (click)="toList()">ผู้ติดต่อ</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toList()">
      ข้อมูลลูกค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    @if(isCreate) {
    <div class="breadcrumb-text">เพิ่มลูกค้า</div>
    } @else if(isView) {
    <div class="breadcrumb-text">รายละเอียดลูกค้า</div>
    } @else if(isEdit) {
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="toView()">
      รายละเอียดลูกค้า
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขลูกค้า</div>
    }
  </div>

  <div class="title-wrapper">{{ isCreate ? 'เพิ่มลูกค้าใหม่' : 'รายละเอียดลูกค้า' }}</div>

  @if (!isView) {
  <div class="page-content-inner">
    <!-- edit / create -->

    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="col-12 col-lg-12 mb-3">
          <span class="title-blue">ข้อมูลลูกค้า</span>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['code'].touched && form.controls['code'].invalid">
              รหัสลูกค้า
            </label>

            <app-input [control]="form.controls['code']" [disabled]="true">
              @if (form.controls['code'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['code'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 10 ตัวอักษร</ng-container>
              } @else if (form.controls['code'].hasError('formApi')) {
              <ng-container error>{{ form.controls['code'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
              ชื่อลูกค้า <span class="text-danger">*</span>
            </label>

            <app-input [control]="form.controls['name']">
              @if (form.controls['name'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['name'].hasError('maxlength')) {
              <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
              } @else if (form.controls['name'].hasError('formApi')) {
              <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
              }
            </app-input>
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['birth_date'].touched && form.controls['birth_date'].invalid">
              วันเดือนปีเกิด
            </label>

            <app-date-picker [control]="form.controls['birth_date']" placeholder="วว/ดด/ปปปป">
              @if (form.controls['birth_date'].hasError('formApi')) {
              <ng-container error>
                {{ form.controls['birth_date'].errors?.['formApi']}}
              </ng-container>
              }
            </app-date-picker>
          </div>

          <div class="col-12 col-sm-6"></div>

          <div class="col-12 col-sm-6">
            <div>
              <label class="form-label"> ระดับสมาชิก <span class="text-danger">*</span> </label>
              <img
                src="assets/images/icons/icon-info.svg"
                class="ms-2"
                [matTooltip]="tootipTier"
                matTooltipPosition="above"
                matTooltipClass="tooltip-black tooltip-customer-tier" />
            </div>

            <app-input [control]="tempControl" [value]="tier?.name" [disabled]="true"> </app-input>
          </div>

          <div class="col-12 col-sm-6">
            <label
              class="form-label"
              [class.text-danger]="form.controls['credit'].touched && form.controls['credit'].invalid">
              เครดิตที่ใช้ได้
            </label>

            <app-input-number
              [control]="form.controls['credit']"
              [useZero]="true"
              [autoDecimal]="true"
              [disabled]="true">
            </app-input-number>
          </div>

          <div class="col-12 col-sm-6 d-flex pb-3">
            <div [formGroup]="form">
              <label class="form-label">&nbsp;</label><br />
              <mat-checkbox
                formControlName="is_manage_tier"
                (change)="form.controls['po_baht'].reset()"
                >กำหนดระดับสมาชิกเอง</mat-checkbox
              >
            </div>
          </div>

          <div class="col-12 col-sm-6">
            @if (form.controls['is_manage_tier'].value) {
            <label
              class="form-label"
              [class.text-danger]="form.controls['po_baht'].touched && form.controls['po_baht'].invalid">
              ยอดการสั่งซื้อกำหนดเอง <span class="text-danger">*</span>
            </label>
            <app-input-number
              [control]="form.controls['po_baht']"
              [min]="0"
              unit="บาท"
              (onInput)="setTier()">
              @if (form.controls['po_baht'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['po_baht'].hasError('max')) {
              <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
              } @else if (form.controls['po_baht'].hasError('formApi')) {
              <ng-container error>{{ form.controls['po_baht'].errors?.['formApi']}}</ng-container>
              }
            </app-input-number>
            }
          </div>

          <div class="col-12 col-lg-12">
            <label
              class="form-label"
              [class.text-danger]="form.controls['address'].touched && form.controls['address'].invalid">
              ที่อยู่ปัจจุบัน <span class="text-danger">*</span>
            </label>
            <app-text-area
              [control]="form.controls['address']"
              remainingText="250"
              maxlength="250"
              (onChange)="changeAddress($event)">
              @if (form.controls['address'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              } @else if (form.controls['address'].hasError('formApi')) {
              <ng-container error>{{ form.controls['address'].errors?.['formApi']}}</ng-container>
              }
            </app-text-area>
          </div>

          <div class="col-12 col-lg-12">
            <div class="row">
              <div
                class="form-label d-flex align-items-center col-12 col-lg-6 mb-0"
                [class.text-danger]="form.controls['delivery_address'].touched && form.controls['delivery_address'].invalid">
                ที่อยู่จัดส่ง <span class="text-danger ms-1"> *</span>
              </div>
              <div class="col-12 col-lg-6 d-flex justify-content-sm-end" [formGroup]="form">
                <mat-checkbox
                  formControlName="is_same_address"
                  (change)="setSameAddress(form.controls['delivery_address'])">
                  ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน
                </mat-checkbox>
              </div>
            </div>

            <app-text-area
              [control]="form.controls['delivery_address']"
              remainingText="250"
              maxlength="250"
              [disabled]="!!form.controls.is_same_address.value">
              @if (form.controls['delivery_address'].hasError('required')) {
              <ng-container error>กรุณากรอกข้อมูล</ng-container>
              }
            </app-text-area>
          </div>
        </div>

        <div class="col-12 col-lg-12 mb-3">
          <span class="title-blue">ข้อมูลติดต่อ</span>
        </div>

        <div class="col-12 col-lg-12">
          <div class="row">
            <div class="col-12 col-sm-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['telephone'].touched && form.controls['telephone'].invalid">
                เบอร์โทรศัพท์ <span class="text-danger">*</span>
              </label>

              <app-input [control]="form.controls['telephone']">
                @if (form.controls['telephone'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (form.controls['telephone'].hasError('pattern')) {
                <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
                } @else if (form.controls['telephone'].hasError('formApi')) {
                <ng-container error
                  >{{ form.controls['telephone'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input>
            </div>

            <div class="col-12 col-sm-6">
              <label
                class="form-label"
                [class.text-danger]="form.controls['email'].touched && form.controls['email'].invalid">
                อีเมล
              </label>

              <app-input [control]="form.controls['email']">
                @if (form.controls['email'].hasError('pattern')) {
                <ng-container error>รูปแบบอีเมลไม่ถูกต้อง</ng-container>
                } @else if (form.controls['email'].hasError('maxlength')) {
                <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
                } @else if (form.controls['email'].hasError('formApi')) {
                <ng-container error>{{ form.controls['email'].errors?.['formApi']}}</ng-container>
                }
              </app-input>
            </div>
          </div>
          @for (item of channels.controls; track item; let index = $index;) {
          <div class="row">
            <div class="col-12 col-sm-6">
              <label
                class="form-label"
                [class.text-danger]="item.controls['channel_id'].touched && item.controls['channel_id'].invalid">
                ช่องทางโซเชียล
              </label>

              <app-select
                [control]="item.controls['channel_id']"
                [datas]="listSocialFilter(item.controls['channel_id'])"
                fieldValue="id"
                placeholder="เลือก">
                <ng-template #optionRef let-option>
                  <div class="d-flex align-items-center gap-2">
                    @if (channelIcon[option.id]) {
                    <img
                      [alt]="option.name"
                      src="/assets/images/icons/icon-social-{{ channelIcon[option.id] }}.svg"
                      width="20" />
                    } {{ option.name }}
                  </div></ng-template
                >
                @if (item.controls['channel_id'].hasError('required')) {
                <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                }
              </app-select>
            </div>

            <div class="col-12 col-sm-6">
              <div class="row">
                <div class="col-10 col-lg-10">
                  <label
                    class="form-label"
                    [class.text-danger]="item.controls['name'].touched && item.controls['name'].invalid">
                    ชื่อโซเชียล
                    <span class="text-danger">*</span>
                  </label>

                  <app-input [control]="item.controls['name']">
                    @if (item.controls['name'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    }
                  </app-input>
                </div>
                <div class="col-2 col-lg-2">
                  <div class="h-100 d-flex justify-content-end align-items-center">
                    <button
                      matRipple
                      class="btn btn-outline-danger mt-2 button-del"
                      (click)="delSocial(index)">
                      <span class="icon material-symbols-outlined fill">delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          <!-- @if (channels.value.length < list.social.length) { -->
          <div class="col-12 col-lg-6 d-flex mb-3">
            <button matRipple class="btn btn-outline-secondary" (click)="addSocial()">
              <span class="icon material-symbols-outlined fill">add_circle</span>
              ช่องทางโซเชียล
            </button>
          </div>
          <!-- } -->
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="col-12 col-lg-12 mb-3">
          <span class="title-blue">ใบกำกับภาษี</span>
        </div>
        <div class="mb-3" [formGroup]="form">
          <mat-checkbox formControlName="is_tax" (change)="formTax.markAsUntouched()">
            ออกใบกำกับภาษี
          </mat-checkbox>
        </div>
        @if (form.controls['is_tax'].value) {
        <div>
          <label
            class="form-label"
            [class.text-danger]="formTax.controls['tax_name'].touched && formTax.controls['tax_name'].invalid">
            ชื่อ-นามสกุล / บริษัทผู้เสียภาษี <span class="text-danger">*</span>
          </label>

          <app-input [control]="formTax.controls['tax_name']">
            @if (formTax.controls['tax_name'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_name'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (formTax.controls['tax_name'].hasError('formApi')) {
            <ng-container error>{{ formTax.controls['tax_name'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div>
          <label
            class="form-label"
            [class.text-danger]="formTax.controls['tax_id'].touched && formTax.controls['tax_id'].invalid">
            เลขประจำตัวผู้เสียภาษี <span class="text-danger">*</span>
          </label>

          <app-input [control]="formTax.controls['tax_id']">
            @if (formTax.controls['tax_id'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_id'].hasError('pattern')) {
            <ng-container error>Tax ID ไม่ถูกต้อง</ng-container>
            } @else if (formTax.controls['tax_id'].hasError('formApi')) {
            <ng-container error>{{ formTax.controls['tax_id'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div>
          <div class="row">
            <div
              class="form-label d-flex align-items-center col-12 col-lg-6 mb-0"
              [class.text-danger]="formTax.controls['tax_address'].touched && formTax.controls['tax_address'].invalid">
              ที่อยู่ในการออกใบกำกับภาษี <span class="text-danger ms-1"> *</span>
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-sm-end" [formGroup]="formTax">
              <mat-checkbox
                formControlName="is_tax_same_address"
                (change)="setSameAddress(formTax.controls['tax_address'])">
                ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน
              </mat-checkbox>
            </div>
          </div>

          <app-text-area
            [control]="formTax.controls['tax_address']"
            remainingText="250"
            maxlength="250"
            [disabled]="!!formTax.controls.is_tax_same_address.value">
            @if (formTax.controls['tax_address'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_address'].hasError('formApi')) {
            <ng-container error
              >{{ formTax.controls['tax_address'].errors?.['formApi']}}</ng-container
            >
            }
          </app-text-area>
        </div>

        <div>
          <label
            class="form-label"
            [class.text-danger]="formTax.controls['tax_telephone'].touched && formTax.controls['tax_telephone'].invalid">
            เบอร์โทรศัพท์ <span class="text-danger">*</span>
          </label>

          <app-input [control]="formTax.controls['tax_telephone']">
            @if (formTax.controls['tax_telephone'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (formTax.controls['tax_telephone'].hasError('pattern')) {
            <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
            } @else if (formTax.controls['tax_telephone'].hasError('formApi')) {
            <ng-container error
              >{{ formTax.controls['tax_telephone'].errors?.['formApi']}}</ng-container
            >
            }
          </app-input>
        </div>
        }
      </div>
    </div>

    <div class="line"></div>
    <div class="d-flex justify-content-end">
      <button matRipple class="btn btn-outline-secondary min-w-100px me-2" (click)="toList()">
        ยกเลิก
      </button>
      <button matRipple class="btn btn-primary min-w-100px" (click)="onConfirm()">บันทึก</button>
    </div>
  </div>
  } @else {
  <!-- view -->
  @if (dataManage) {
  <div class="page-content-inner">
    <div class="row">
      <div class="col-12 col-lg-4 px-3 px-sm-4">
        <div class="d-flex align-items-center h-100">
          <div class="title-blue">ข้อมูลลูกค้า</div>
        </div>
      </div>
      <div class="col-12 col-lg-8 px-3 px-sm-4">
        <div class="d-flex flex-wrap justify-content-sm-end w-100">
          @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.DELETE])) {
          <button
            matRipple
            class="btn btn-outline-danger min-w-100px me-2 mt-2"
            (click)="confirmDelete()">
            <span class="icon material-symbols-outlined fill">delete</span>
            ลบข้อมูล
          </button>
          }
          <!-- ~ -->
          @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.UPDATE])) {
          <button
            matRipple
            class="btn btn-outline-secondary min-w-100px me-2 mt-2"
            (click)="openModalCarat('แก้ไขกะรัตสะสม')">
            แก้ไขกะรัตสะสม
          </button>
          <button
            matRipple
            class="btn btn-outline-secondary min-w-100px me-2 mt-2"
            (click)="openModalCredit('แก้ไขเครดิต')">
            แก้ไขเครดิต
          </button>
          <button
            matRipple
            class="btn btn-primary min-w-100px mt-2"
            [routerLink]="['/customer/edit/' + dataManage.id]">
            <span class="icon material-symbols-outlined fill">edit</span>
            แก้ไขข้อมูล
          </button>
          }
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 p-4 line-vertical">
        <div class="img-customer">
          <img src="/assets/images/icons/icon-customer.svg" />
        </div>
        <div class="text-center mt-3">
          <div class="name-customer">{{ dataManage.name }}</div>
          <div class="gray">รหัสลูกค้า : {{ dataManage.code }}</div>
          <div class="gray">ระดับสมาชิก : {{ tier?.name || '-' }}</div>
          <div class="gray">วันเกิด : {{ Moment(dataManage.birth_date).format('DD/MM/YYYY') }}</div>
        </div>

        <div class="row mt-3 text-center">
          <div class="col-6 col-lg-6 col-sm-3 mt-3">
            <div class="gray">ยอดขาย</div>
            <b class="blue my-2">{{ formatPrice(dataManage.total_price) }}</b>
          </div>
          <div class="col-6 col-lg-6 col-sm-3 mt-3">
            <div class="gray">ยกเลิก</div>
            <b class="red my-2">{{ formatPrice(dataManage.cancel_amount) }}</b>
          </div>
          <div class="col-6 col-lg-6 col-sm-3 mt-3">
            <div class="gray">กะรัตสะสม</div>
            <b class="green my-2">{{ formatPrice(dataManage.carat) }}</b>
          </div>
          <div class="col-6 col-lg-6 col-sm-3 mt-3">
            <div class="gray">เครดิตที่ใช้ได้</div>
            <b class="green my-2">{{ formatPrice(dataManage.credit) }}</b>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 px-3 px-sm-4 pb-4">
        <div class="col-12 title-blue">ข้อมูลติดต่อ</div>
        <div class="row">
          <div class="col-12 col-lg-6 col-sm-6">
            <div class="gray mt-3">เบอร์โทรศัพท์</div>
            <span class="d-flex align-items-center">
              <span class="icon material-symbols-outlined fill me-2">call</span>
              {{ dataManage.telephone }}
            </span>
          </div>
          <div class="col-12 col-lg-6 col-sm-6">
            <div class="gray mt-3">อีเมล</div>
            <span class="d-flex align-items-center">
              <span class="icon material-symbols-outlined fill me-2">email</span>
              {{ dataManage.email || '-' }}
            </span>
          </div>

          <div class="col-12 col-lg-12">
            <div class="gray mt-3">โซเชียล</div>
            <div class="d-flex flex-wrap">
              @for (item of dataManage.channels; track $index) {
              <span class="d-flex align-items-center me-3">
                <img
                  src="/assets/images/icons/icon-social-{{ channelIcon[item.channel_id] }}.svg"
                  class="me-1" />
                <!-- {{ item.name }} -->
                <span class="name-channel" [innerHTML]="item.name"></span>
              </span>
              }
            </div>
          </div>

          <div class="col-12 col-lg-12">
            <div class="gray mt-3">ที่อยู่ลูกค้า</div>
            <span class="d-flex align-items-center"> {{ dataManage.address || '-' }} </span>
          </div>
        </div>

        <div class="col-12 title-blue mt-5">ใบกำกับภาษี</div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="gray mt-3">ชื่อ-นามสกุล / บริษัทผู้เสียภาษี</div>
            {{ dataManage.is_tax ? dataManage.tax_name : '' || '-' }}
          </div>
          <div class="col-12 col-lg-6">
            <div class="gray mt-3">เลขประจำตัวผู้เสียภาษี</div>
            {{ dataManage.is_tax ? dataManage.tax_id : '' || '-' }}
          </div>

          <div class="col-12 col-lg-12">
            <div class="gray mt-3">เบอร์โทรศัพท์</div>
            {{ dataManage.is_tax ? dataManage.tax_telephone : '' || '-' }}
          </div>

          <div class="col-12 col-lg-12">
            <div class="gray mt-3">ที่อยู่ในการออกใบกำกับภาษี</div>
            {{ dataManage.is_tax ? dataManage.tax_address : '' || '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-order-list [dataManage]="dataManage"></app-order-list>

  <div class="tabs-wrapper mt-4">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      <mat-tab label="รายการสินค้าที่ซื้อ">
        <app-order-product-list [dataManage]="dataManage"></app-order-product-list>
      </mat-tab>
      <mat-tab label="ยอดการสั่งซื้อกำหนดเอง">
        <app-purchase-list [dataManage]="dataManage"></app-purchase-list>
      </mat-tab>
      <mat-tab label="การใช้กะรัต">
        <app-carat-list [dataManage]="dataManage" #appCaratList></app-carat-list>
      </mat-tab>
      <mat-tab label="การใช้เครดิต">
        <app-credit-list [dataManage]="dataManage" #appCreditList></app-credit-list>
      </mat-tab>
      <mat-tab label="รหัสส่วนลดที่แลก">
        <app-promotion-list [dataManage]="dataManage" #appCaratList></app-promotion-list>
      </mat-tab>
    </mat-tab-group>

    <div class="footer-detail d-flex justify-content-end">
      <button
        matRipple
        class="btn btn-outline-secondary min-w-100px"
        [routerLink]="['/customer']"
        [queryParams]="{ tab: 0 }">
        กลับสู่หน้าข้อมูลลูกค้า
      </button>
    </div>
  </div>
  } }
</div>
