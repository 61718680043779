
<mat-dialog-content>
  <div class="modal-content py-2 ">
    <div class="d-flex justify-content-center">
      <div class="loader mb-3"></div>
    </div>
    <div class="text-center mt-3">
      <b class="font-18 ">กำลังเชื่อมต่อสินค้า...</b> <br />
    </div>
    <div class="text-center mt-2">
      <span class="font-18">กำลังเชื่อมต่อสินค้าระหว่าง Brandname hunter และ {{ data.shop }}</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-center w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ยกเลิก</button>
  </div>
</mat-dialog-actions>
