import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router'
import { Profile } from './globals'
import { CompanyManagementComponent } from './modules/setting/company-manage/company-manage'
import { PaymentChannelComponent } from './modules/setting/payment-channel/payment-channel'
import { UserManagementComponent } from './modules/setting/user-management/user-management'
import { SupplierDetailComponent } from './modules/customer-manage/tabs/tab-supplier/supplier-detail/supplier-detail'
import { CustomerDetailComponent } from './modules/customer-manage/tabs/tab-customer/customer-detail/customer-detail'
import { CaratsComponent } from './modules/setting/carats/carats'
import { ProductDetailComponent } from './modules/product/product-detail/product-detail'
import { OrderDetailComponent } from './modules/order/order-detail/order-detail'

export const authGuardAppPermission: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state
) => {
  const router = inject(Router)
  const profile = inject(Profile)

  await profile.getProfile()

  if (!profile.isLogin) {
    router.navigateByUrl('/login')
    return false
  }

  if (state.url == '/') {
    const defaultPage = profile.permissions.defaultPage()
    // console.log('defaultPage', defaultPage)

    if (defaultPage) {
      router.navigateByUrl(defaultPage.path)
    } else {
      router.navigateByUrl('/denied')
    }
    return false
  }

  if (!profile.permissions.checkAccess(route.data['permissions'] || [])) {
    router.navigateByUrl('/denied')
    return false
  }

  return true
}

export const authGuardSetting: CanActivateFn = async (route: ActivatedRouteSnapshot, state) => {
  if (state.url == '/setting') {
    const router = inject(Router)
    router.navigateByUrl('/')
    return false
  }
  return true
}

export const deactivateCompanyManagementEdit = async (component: CompanyManagementComponent) => {
  let isNext = false

  await new Promise(rev => {
    component.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}

export const deactivatePaymentChannelEdit = async (component: PaymentChannelComponent) => {
  let isNext = false

  await new Promise(rev => {
    component.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}

export const deactivateRoleEdit = async (component: UserManagementComponent) => {
  let isNext = false

  await new Promise(rev => {
    component.roleManageComponent?.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}

export const deactivateCustomerManage = async (component: CustomerDetailComponent) => {
  let isNext = false

  await new Promise(rev => {
    component?.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}

export const deactivateSupplierManage = async (component: SupplierDetailComponent) => {
  let isNext = false

  await new Promise(rev => {
    component?.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}

export const deactivateCaratManage = async (component: CaratsComponent) => {
  let isNext = false

  await new Promise(rev => {
    component?.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}

export const deactivateProductManage = async (component: ProductDetailComponent) => {
  let isNext = false

  await new Promise(rev => {
    component?.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}

export const deactivateOrderManage = async (component: OrderDetailComponent) => {
  let isNext = false

  await new Promise(rev => {
    component?.openModalCancel((bool: boolean) => {
      isNext = bool
      rev(null)
    })
  })

  return isNext
}
