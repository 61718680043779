<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{ data.title || 'ยืนยันการลบ' }}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">{{ data.detail }}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-danger btn-md" matRipple (click)="onClose()">ยกเลิก</button>
    <button class="btn btn-danger btn-md" matRipple (click)="onConfirm()">
      {{ data.confirm || 'ลบ' }}
    </button>
  </div>
</mat-dialog-actions>
