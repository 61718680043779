<div>
  <mat-form-field appearance="outline" class="textarea">
    @if (value !== undefined) {
    <textarea
      matInput
      [value]="value"
      placeholder="{{placeholder || ''}}"
      rows="{{ rows || 4 }}"
      [disabled]="true"
    ></textarea>
    } @else {
    <textarea
      matInput
      [formControl]="formControl"
      placeholder="{{ placeholder || '' }}"
      (keyup.enter)="onEnter.emit()"
      rows="{{ rows || 4 }}"
      maxlength="{{ maxlength }}"
      minlength="{{ minlength }}"
      #input
      (change)="onChange.emit(input.value)"
    ></textarea>
    } @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    }
  </mat-form-field>

  @if (remainingText) {
  <div class="remaining-text">{{ isRemainingText(formControl.value) }}</div>
  }
</div>
