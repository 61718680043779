import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ExpenseService {
  constructor(private service: Service) {}
  private pathExpenseCategory = '/v1/expense-categories'
  getExpenseCategoryList(payload: any): Observable<any> {
    return this.service.post(`${this.pathExpenseCategory}/query`, payload)
  }
  getExpenseCategory(id: number): Observable<any> {
    return this.service.get(`${this.pathExpenseCategory}/${id}`)
  }

  private pathExpense = '/v1/expenses'
  getExpenseList(payload: any): Observable<any> {
    return this.service.post(`${this.pathExpense}/query`, payload)
  }
  getExpense(id: any): Observable<any> {
    return this.service.get(`${this.pathExpense}/${id}`)
  }
  addExpense(payload: any): Observable<any> {
    return this.service.post(`${this.pathExpense}`, payload)
  }
  updateExpense(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathExpense}/${id}`, payload)
  }
  deleteExpense(id: any, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathExpense}/${id}`, payload)
  }
  exportExpenseList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathExpense}/export`, payload)
  }
}
