import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Moment,
  convertDateToApi,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'

@Component({
  selector: 'app-carats',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    DatePickerComponent,
    CdkDrag,
    CdkDropList,
  ],
  templateUrl: './carats.html',
  styleUrls: ['./carats.scss'],
})
export class CaratsComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  // mobile = window.matchMedia('(max-width: 1000px)')

  isEdit: boolean = false
  isView: boolean = false
  dataManage: any
  id: any = 1

  discounts = new FormArray<FormGroup>([
    new FormGroup({
      carat: new FormControl('', [Validators.required]),
      discount_price: new FormControl('', [Validators.required]),
      start_date: new FormControl(null, [Validators.required]),
      end_date: new FormControl(null),
    }),
  ])
  form = new FormGroup({
    carat: new FormControl(1),
    total_order_amount: new FormControl('', [Validators.required]),
    order_start_date: new FormControl(null, [Validators.required]),
    order_end_date: new FormControl(null),
    value_amount: new FormControl('', [Validators.required]),
    // value_start_date: new FormControl(null, [Validators.required]),
    // value_end_date: new FormControl(null),
    discounts: this.discounts,
  })

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public companyProfileService: CompanyProfileService,
    public caratService: CaratService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    this.initData()
  }

  initData() {
    const apis = [this.caratService.getCarat(this.id)]

    this.loading.start()
    forkJoin(apis).subscribe(([resCarat]: any) => {
      if (resCarat) {
        if (!resCarat.is_error) {
          this.dataManage = resCarat.data

          this.form.reset({
            carat: resCarat.data.carat || 1,
            total_order_amount: resCarat.data.total_order_amount,
            order_start_date: resCarat.data.order_start_date,
            order_end_date: resCarat.data.order_end_date,
            value_amount: resCarat.data.value_amount,
            // value_start_date: resCarat.data.value_start_date,
            // value_end_date: resCarat.data.value_end_date,
          })
          this.discounts.clear()
          const discounts = (this.dataManage.discounts || []).sort((a: any, b: any) =>
            a.sort > b.sort ? 1 : -1
          )
          for (const discount of discounts) {
            this.addCaratDiscount(discount)
          }
        } else {
          this.customSnackBar.fail(resCarat.message)
        }
      }

      this.loading.stop()
    })
  }

  addCaratDiscount(data: any) {
    let newG = new FormGroup({
      data: new FormControl(data),
      carat: new FormControl(data.carat || null, [Validators.required, Validators.min(1)]),
      discount_price: new FormControl(data.discount_price || null, [
        Validators.required,
        Validators.min(1),
      ]),
      // start_date: new FormControl(data.start_date || null, [Validators.required]),
      // end_date: new FormControl(data.end_date || null),
    })
    this.discounts.push(newG)
  }

  delCaratDiscount(index: number) {
    this.discounts.removeAt(index)
    this.checkValidate()
  }

  checkValidate() {
    let valid = true
    // const values = this.discounts.getRawValue()
    let idx = 0
    for (const form of this.discounts.controls) {
      const value = form.getRawValue()
      if (value.carat) {
        const isMatch = this.discounts.controls.some(
          (f: FormGroup) =>
            f.controls['carat'] !== form.controls['carat'] &&
            f.controls['carat'].value == value.carat
        )

        if (isMatch) {
          valid = false

          form.controls['carat'].setErrors({
            unique: true,
          })
        } else {
          form.controls['carat'].updateValueAndValidity()
        }
      }

      if (value.discount_price) {
        const isMatch = this.discounts.controls.some(
          (f: FormGroup) =>
            f.controls['discount_price'] !== form.controls['discount_price'] &&
            f.controls['discount_price'].value == value.discount_price
        )

        if (isMatch) {
          valid = false

          form.controls['discount_price'].setErrors({
            unique: true,
          })
        } else {
          form.controls['discount_price'].updateValueAndValidity()
        }
      }

      // if (
      //   value.start_date &&
      //   value.end_date &&
      //   Moment(value.start_date).unix() > Moment(value.end_date).unix()
      // ) {
      //   form.controls['end_date'].setErrors({
      //     min: true,
      //   })
      // }
      idx++
    }

    this.discounts.markAllAsTouched()

    return valid
  }

  confirmSave(): void {
    ValidateForm(this.form)
    const valid = this.checkValidate()
    if (!this.form.valid || !valid) return

    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })
  }

  onSave(): void {
    const value = this.form.getRawValue()
    const discountValue = this.discounts.getRawValue()
    const payload = {
      carat: value.carat,
      value_amount: value.value_amount,
      order_start_date: convertDateToApi(value.order_start_date),
      order_end_date: convertDateToApi(value.order_end_date, 'day'),
      total_order_amount: value.total_order_amount,
      // value_start_date: value.value_start_date,
      // value_end_date: value.value_end_date,
      discounts: discountValue.map((val, i) => {
        return {
          id: val['data']?.id,
          carat: val['carat'],
          discount_price: val['discount_price'],
          // start_date: val['start_date'],
          // end_date: val['end_date'],
          sort: i + 1,
        }
      }),
    }

    // return console.log(payload)

    this.loading.start()
    this.caratService.updateCarat(this.id, payload).subscribe((res: any) => {
      // console.log("res update", res)
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.isSaved = true
          this.toView()
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  toView() {
    this.router.navigate(['/setting/carats/view'])
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && this.form.dirty && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )
    }
    this.checkValidate()
    this.form.markAllAsTouched()
  }
}
