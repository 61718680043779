import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class Loading {
  #count: number = 0

  constructor() {}

  get isLoading() {
    return !!this.#count
  }

  start(num = 1) {
    setTimeout(() => {
      // console.log('start', num)
      this.#count += num
      // console.log('count', this.#count)
    })
  }

  stop(num = 1) {
    setTimeout(() => {
      // console.log('stop', num)
      let count = this.#count - num
      if (count < 0) {
        count = 0
      }
      this.#count = count
      // console.log('count', this.#count)
    }, 500)
  }
}
