import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { imports } from '../../../../imports'
import { FilterTableHistoryExpense } from '../../../../globals/search-and-filter/history-expense'

@Component({
  selector: 'app-modal-filter-expense-item',
  templateUrl: './modal-filter.html',
  styleUrls: ['./modal-filter.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
  ],
})
export class ModalFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTableHistoryExpense: FilterTableHistoryExpense
  ) {}

  ngOnInit(): void {
    this.filterTableHistoryExpense.initData()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTableHistoryExpense.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTableHistoryExpense.confirm()
    this.dialogRef.close(true)
  }
}
