import { Component } from '@angular/core'
import { imports } from '../../../imports'
import { MatDialog } from '@angular/material/dialog'
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { SelectComponent } from '../../../components/select/select'
import { SearchComponent } from '../../../components/search/search'
import { Loading, Profile } from '../../../globals'
import { Router, ActivatedRoute } from '@angular/router'
import { CaratService, MasterService, PromotionService, PublicService } from '../../../services'
import { OrderService } from '../../../services/order.service'
import { MatMenuModule } from '@angular/material/menu'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { forkJoin } from 'rxjs'
import {
  Moment,
  ValidateForm,
  checkUseCarat,
  formatPrice,
  formatPriceAutoDecimal,
  imgProductDefault,
  productImage,
  setErrorForm,
} from '../../../helpers'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import { checkPomotionExpired } from '../../../helpers/promotion'

@Component({
  selector: 'app-order-return',
  standalone: true,
  imports: [
    ...imports,
    SearchComponent,
    SelectComponent,
    MatMenuModule,
    InputNumberComponent,
    UploadImageProfileComponent,
    TextareaComponent,
  ],
  templateUrl: './order-return.html',
  styleUrls: ['./order-return.scss'],
})
export class OrderReturnComponent {
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  products = new FormArray<FormGroup>([])

  form = new FormGroup({
    remark: new FormControl('', [Validators.required]),
    file_id: new FormControl(null),
  })

  order_id = null
  order: any
  payment: any
  #promotion: any

  list = {
    customer: <any[]>[],
    channel: <any[]>[],
    transport: <any[]>[],
  }

  carat = {
    total_order_amount: 0,
  }

  get promotion() {
    return checkPomotionExpired(this.#promotion)
  }

  get totalProduct() {
    return this.products.controls.reduce(
      (total, form: FormGroup) => {
        const product = form.controls['data'].value
        const totalPriceReturn =
          form.controls['data'].value.price * (form.controls['quantity'].value || 0)
        const totalPrice = product.total_price - totalPriceReturn
        total.totalPriceReturn += totalPriceReturn
        total.totalPrice += totalPrice
        if (product.is_carat) {
          total.totalPriceOnlyCarat += totalPrice
        }

        let totalPriceRemain = totalPrice
        let discountCodePrice = 0
        if (this.promotion) {
          if (this.promotion.is_percent) {
            if (
              this.promotion.products.some(
                (p: any) =>
                  p.product_id == product.product_id &&
                  p.product_detail_id == product.product_detail_id
              )
            ) {
              discountCodePrice = (this.promotion.value * totalPriceRemain) / 100
            }
          } else {
            discountCodePrice = this.promotion.value
          }
        }
        totalPriceRemain -= discountCodePrice

        total.totalPriceRemain += totalPriceRemain
        total.discountCodePrice += discountCodePrice

        return total
      },
      {
        totalPriceReturn: 0, //ราคาสินค้าที่คืน
        totalPrice: 0, //ไม่รวมส่วนลด
        totalPriceOnlyCarat: 0, //ไม่รวมส่วนลดเฉพาะสินค้าที่เข้าร่วมกะรัต
        totalPriceRemain: 0, //รวมส่วนลด
        discountCodePrice: 0, //ส่วนลด
      }
    )
  }

  get shippingCost() {
    return this.order.shipping_cost || 0
  }

  get discount() {
    return this.order.discount || 0
  }

  get discountCodePrice() {
    return this.order.discount_code_price || 0
  }

  get creditCodePrice() {
    return this.order.credit_code_price || 0
  }

  get caratCodePrice() {
    return this.order.carat_code_price || 0
  }

  get sumTotal() {
    return (
      this.payment.previous_payment +
      this.discount +
      this.discountCodePrice +
      this.creditCodePrice +
      this.caratCodePrice -
      this.totalProduct.totalPriceReturn
    )
  }

  get caratAmount() {
    const sumTotalOnlyIsCarat = this.totalProduct.totalPriceOnlyCarat

    const totalCrat =
      this.carat.total_order_amount && sumTotalOnlyIsCarat
        ? Math.floor(sumTotalOnlyIsCarat / this.carat.total_order_amount)
        : 0
    return totalCrat > 0 ? totalCrat : 0
  }

  get previousPayment() {
    return this.payment?.previous_payment || 0
  }

  get calcPayment() {
    let previousPayment = this.previousPayment
    return previousPayment - this.sumTotal
  }

  get additionPayment() {
    //ยอดที่ต้องชำระเพิ่ม
    return this.calcPayment
  }

  get returnCredit() {
    //ยอดที่ชำระเกิน
    return this.calcPayment > 0 ? this.calcPayment : 0
  }

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public masterService: MasterService,
    public orderService: OrderService,
    public caratService: CaratService,
    public publicService: PublicService,
    public promotionService: PromotionService
  ) {}

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.initData(params['id'])
      this.order_id = params['id']
    })
  }

  initData(id: any) {
    const apis = [this.caratService.getCarat(1), this.orderService.getOrder(id)]

    this.loading.start()
    forkJoin(apis).subscribe(([resCarat, resOrder]: any) => {
      if (!resCarat.is_error) {
        if (checkUseCarat(resCarat.data)) {
          this.carat = resCarat.data
        }
      } else {
        this.customSnackBar.fail(resCarat.message)
      }

      if (!resOrder.is_error) {
        this.publicService.getPaymentByOrderId(resOrder.data.id).subscribe((resPayment: any) => {
          if (!resPayment.is_error) {
            this.payment = resPayment.data
            this.order = resOrder.data

            for (const product of this.order.products) {
              if (product.quantity > 0) {
                const form = new FormGroup(
                  {
                    data: new FormControl(product),
                    name: new FormControl(product.product_name),
                    img: new FormControl(productImage(product)?.file?.url || imgProductDefault),
                    quantity: new FormControl(null, [Validators.required]),
                  },
                  {}
                )
                this.products.push(form)
              }
            }

            if (this.payment.promotion_id) {
              this.promotionService
                .getPromotion(this.payment.promotion_id)
                .subscribe(resPomotion => {
                  if (!resPomotion.is_error) {
                    this.#promotion = resPomotion.data
                  }
                })
            }
          } else {
            this.customSnackBar.fail(resPayment.message)
          }
        })
      } else {
        this.customSnackBar.fail(resOrder.message)
      }

      this.loading.stop()
    })
  }

  setQuantity(num: 1 | -1, item: FormGroup) {
    const quantity = item.controls['quantity'].value
    const max = item.controls['data'].value.quantity || 0
    if (num == 1 ? quantity < max : quantity > 0) {
      item.controls['quantity'].setValue(quantity + num)
    }
  }

  toList() {
    this.router.navigate(['/order'])
  }

  onConfirm() {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const details = this.products.controls.reduce((details: any[], form: FormGroup) => {
      const quantity = form.controls['quantity'].value
      if (quantity) {
        // รายการคืนสินค้า
        details.push({
          order_product_id: form.controls['data'].value.id, // ไอดีของ order.procusts.id
          quantity, // จำนวนที่จะคืน
        })
      }
      return details
    }, <any[]>[])

    if (!details.length) {
      this.customSnackBar.fail('ไม่พบจำนวนที่ต้องการคืน')
      return
    }

    const value = this.form.getRawValue()
    const payload = {
      remark: value.remark,
      file_id: value.file_id, // ไฟล์แนบ
      total_price: this.totalProduct.totalPriceRemain, // ราคารวมสินค้าคงเหลือ
      discount_code_price: this.totalProduct.discountCodePrice, // ส่วนลดจากโปรโมชั่น
      promotion_id: this.promotion?.id || null, // ถ้าโปรโมชั่นเดิมที่เคยใช้ยังใช้ได้ ก็ส่งไอดีโปรโมชั่น
      remain_total: this.sumTotal, // ยอดรวมสุทธิ หลังหักส่วนลด ถ้ามี
      previous_payment: this.previousPayment, // เอาจาก get payment by order ฟิว `previous_payment`
      addition_payment: this.additionPayment, // ยอดที่ต้องชำระเพิ่ม
      carat: this.caratAmount, // คำนวณกะรัตที่จะได้ใหม่
      return_credit: this.returnCredit, // ยอดคืนเครดิต ถ้ายอดเกินที่จ่ายไว้ก็คืนเป็นเครดิต
      return_discount_code_price: this.discountCodePrice,
      return_credit_code_price: this.creditCodePrice,
      return_carat_code_price: this.caratCodePrice,
      details,
    }

    // return console.log(payload)

    this.loading.start()
    this.orderService.returnOrder(this.order_id, payload).subscribe((res: any) => {
      if (!res.is_error) {
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        this.toList()
      } else {
        if (res.errors[0]?.field) {
          setErrorForm(this.form, res.errors)
        } else {
          this.customSnackBar.failSave(res.message)
        }
      }
      this.loading.stop()
    })
  }
}
