<div class="search-filter-wrapper">
  <app-input-search
    [control]="filterTableHistoryOrder.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterTableHistoryOrder.confirm(); onConfirm.emit()"></app-input-search>

  <button (click)="openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
</div>

@if (filterTableHistoryOrder.isFilter) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterTableHistoryOrder.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @for (active of filterTableHistoryOrder.list.status_ids; track $index) { @if
    (filterTableHistoryOrder.isStatusIdChecked(active.id)) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ active.text }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableHistoryOrder.removeStatusId(active.id); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } } @if (filterTableHistoryOrder.startEndDateFormat) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTableHistoryOrder.startEndDateFormat }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableHistoryOrder.removeStartEndDate(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
  </div>
</div>
}
