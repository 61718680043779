import { Moment } from './moment'

export const checkPomotionExpired = (promotion: any) => {
  if (promotion) {
    const now = Moment().unix()

    if (Moment(promotion.effective_date_from).unix() > now) {
      return null
    }

    if (promotion.effective_date_to && Moment(promotion.effective_date_to).unix() < now) {
      return null
    }
  }

  return promotion
}
