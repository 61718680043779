import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { imports } from '../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { OrderService } from '../../../services'
import { forkJoin } from 'rxjs'
import { formatPrice, formatPriceAutoDecimal, productImage } from '../../../helpers'

@Component({
  selector: 'app-modal-view-product',
  templateUrl: './modal-view-product.html',
  styleUrls: ['./modal-view-product.scss'],
  standalone: true,
  imports: [...imports, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose],
})
export class ModalViewProductComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  dataManage: any
  products: any[] = []

  imgDefault = '/assets/images/default-product.svg'

  constructor(
    public profile: Profile,
    public dialogRef: MatDialogRef<ModalViewProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    this.loading.start()
    this.orderService.getOrder(this.data.item.id).subscribe((res: any) => {
      if (!res.is_error) {
        this.dataManage = res.data
        this.products = res.data.products
          .filter((p: any) => p.quantity > 0)
          .map((product: any) => {
            return {
              ...product,
              image: productImage(product),
              delivery_address: res.data.delivery_addresses.find(
                (da: any) => da.uuid == product.delivery_address_uuid
              ),
            }
          })
      } else {
        this.customSnackBar.fail(res.message)
      }

      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }
}
