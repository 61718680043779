import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private service: Service) {}
  private pathUser = '/v1/users'

  getUserList(payload: any): Observable<any> {
    return this.service.post(`${this.pathUser}/query`, payload)
  }
  getUser(id: any): Observable<any> {
    return this.service.get(`${this.pathUser}/${id}`)
  }
  addUser(payload: any): Observable<any> {
    return this.service.post(`${this.pathUser}`, payload)
  }
  updateUser(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathUser}/${id}`, payload)
  }
  deleteUser(payload: any): Observable<any> {
    return this.service.delete(`${this.pathUser}/${payload.id}`)
  }
  checkImportUser(payload: any): Observable<any> {
    return this.service.post(`${this.pathUser}/pre-import`, payload)
  }
  importUser(payload: any): Observable<any> {
    return this.service.post(`${this.pathUser}/import`, payload)
  }
  exportUser(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathUser}/export`, payload)
  }
  templateUser(payload: any): Observable<any> {
    return this.service.getBlob(`${this.pathUser}/template`, payload)
  }
}
