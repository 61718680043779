import {
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl } from '@angular/forms'
import { SelectComponent } from '../select/select'
import { distinctUntilChanged, pairwise, startWith } from 'rxjs'

declare const Cleave: any

@Component({
  selector: 'app-input-number[control]',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './input-number.html',
  styleUrls: ['./input-number.scss'],
})
export class InputNumberComponent {
  @Input() value: any
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() unit = ''
  @Input() hintLabel = ''
  @Input() disabled = false
  @Input() delimiter = ','
  @Input() useZero = false
  @Input() autoDecimal: boolean = false
  @Input() min?: number
  @Input() max?: number
  @Input() decimal: number = 2
  @Input() remove_bottom = false

  @Output() onInput = new EventEmitter()
  @Output() onChange = new EventEmitter<any>()
  @Output() onEnter = new EventEmitter()
  @Output() onblur = new EventEmitter()

  @ViewChild('inputElement') inputElement: ElementRef | null = null

  cleave$: any

  ready = false

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.inputElement) {
      const input = this.inputElement.nativeElement
      if (input) {
        input.setAttribute('type', 'text')

        this.cleave$ = new Cleave(input, {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: this.decimal,
          delimiter: this.delimiter,
          onValueChanged: (e: any) => {
            const value = parseFloat(e.target.rawValue)
            if (!isNaN(value)) {
              if (this.min !== undefined && value < this.min) {
                this.cleave$.setRawValue(this.min)
              }
              if (this.max !== undefined && value > this.max) {
                this.cleave$.setRawValue(this.max)
              }
            }
          },
        })
        const defaultValue = this.control.value
        this.cleave$.setRawValue(this.setAutoDecimal(this.#setValueInput(defaultValue)))

        this.control.valueChanges
          .pipe(startWith(defaultValue), distinctUntilChanged(), pairwise())
          .subscribe(([prev, next]: [any, any]) => {
            if (next == '-') return

            if (prev !== next) {
              let value = this.#setValueInput(next)
              if (this.cleave$.element !== document.activeElement) {
                //change value without focus
                value = this.setAutoDecimal(value)
              }
              this.cleave$.setRawValue(value)
            }
          })

        input.onfocus = () => {
          if (!this.disabled) {
            // setTimeout(() => {
            this.cleave$.setRawValue(parseFloat(this.getValue()))
            // }, 10)
          }
        }

        input.onblur = () => {
          this.cleave$.setRawValue(this.setAutoDecimal(this.getValue()))
          this.onblur.emit()
          setTimeout(() => {
            this.control?.markAsTouched()
          }, 100)
        }

        input.oninput = () => {
          if (!this.disabled) {
            this.inputValue()
            setTimeout(() => {
              this.control?.markAsDirty()
              this.control?.markAsTouched()
            }, 100)
          }
        }

        input.onchange = () => {
          if (!this.disabled) {
            this.change()
          }
        }
      }
    }

    setTimeout(() => {
      this.ready = true
    })
  }

  ngOnDestroy() {
    if (this.cleave$) {
      this.cleave$.destroy()
    }
  }

  #setValueInput(value: any) {
    const checkZero = this.useZero && value != 0
    if ((!value && checkZero) || isNaN(value)) {
      return ''
    }
    return value
  }

  getValue(isParse = false) {
    let value = this.cleave$.getRawValue()
    return value ? (value == '-' ? value : isParse ? parseFloat(value) : value) : null
  }

  inputValue() {
    setTimeout(() => {
      let value = this.getValue(true)
      this.control?.setValue(value, {
        emitEvent: false,
      })
      this.onInput.emit(value)
    }, 10)
  }

  setAutoDecimal(value: string) {
    const checkZero = this.useZero && value == '0'
    if (value || checkZero) {
      let val = parseFloat(value)
      return this.autoDecimal ? val.toFixed(this.decimal) : val
    }
    return ''
  }

  change() {
    setTimeout(() => {
      let value = this.getValue(true)
      this.onChange.emit(value)
    }, 100)
  }
}
