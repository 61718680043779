import { Injectable } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor() {
    super()
    this.nextPageLabel = 'หน้าถัดไป'
    this.previousPageLabel = 'หน้าก่อนหน้า'
    this.itemsPerPageLabel = 'แสดงหน้าละ'
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return '0 จาก ' + length
      }

      length = Math.max(length, 0)
      const startIndex = page * pageSize
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
      return startIndex + 1 + ' - ' + endIndex + ' จาก ' + length
    }
  }
}
