import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl } from '@angular/forms'
import { SelectComponent } from '../../../components/select/select'

import { MatDialog } from '@angular/material/dialog'
import { ModalScanComponent } from '../modal-scan/modal-scan'

@Component({
  selector: 'app-input-search-scan[control]',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './input-search-scan.html',
  styleUrls: ['./input-search-scan.scss'],
})
export class InputSearchScanComponent implements OnInit {
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() disabled = false

  @Output() onInput = new EventEmitter()
  @Output() onEnter = new EventEmitter()

  showPassword: boolean = false

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  openModalScan(title: string): void {
    const dialogRef = this.dialog.open(ModalScanComponent, {
      data: {
        title,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.control.setValue(result)
        this.onEnter.emit()
      }
    })
  }

  close() {
    this.control.reset()
    this.onEnter.emit()
  }
}
