import { ApplicationConfig } from '@angular/core'
import { provideRouter } from '@angular/router'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { provideHttpClient, withFetch } from '@angular/common/http'

import { routes } from './app.routes'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { CustomPaginator } from './components/paginator/paginaator'
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog'

const dialogConfig: MatDialogConfig = {
  maxHeight: '90vh',
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    provideAnimationsAsync(),
    provideRouter(routes),
    [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],
    [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: dialogConfig }],
  ],
}
