export const formatPrice = (num: any) => {
  num = num || 0
  return !isNaN(num)
    ? (Math.round(num * 100) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : num
}

export const formatPriceAutoDecimal = (num: any) =>
  num && !isNaN(num)
    ? parseFloat(parseFloat(num.toString()).toFixed(2))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : num

export const formatNumber = (num: any) =>
  num && typeof num == 'string' ? parseFloat(num.replace(/\,/g, '')) : num

export const formatNoDecimal = (num: any) => {
  num = num || 0
  return !isNaN(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : num
}
 

//display number with 2 decimal
export const formatTwoDecimal = (num: any) =>
  num && !isNaN(num) ? (Math.round(num * 100) / 100).toFixed(2) : num

export const formatQty = (num: any) =>
  num && typeof num == 'string' ? parseInt(num.split(',').join('')) : num
