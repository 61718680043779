import { Injectable } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

interface ValueInf {
  [key: string]: any
}

interface DataInf {
  key: string
  value: ValueInf
}

const KEY = {
  A8CM: 'address - 8cm',
} as const

type EnumKEY = (typeof KEY)[keyof typeof KEY]

@Injectable({ providedIn: 'root' })
export class PrintHtml {
  readonly data = new BehaviorSubject<DataInf | null>(null)

  readonly KEY = KEY

  constructor(private Router: Router) {}

  print(key: EnumKEY, value: ValueInf) {
    this.data.next({
      key,
      value,
    })
  }

  clear() {
    this.data.next(null)
  }
}
