import { Component, EventEmitter, Injectable, Input, OnInit, Output, inject } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { MatRipple } from '@angular/material/core'
import { FileService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { Loading } from '../../../../globals'

@Component({
  selector: 'app-upload-image-payment-cancel',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    MatRipple,
  ],
  templateUrl: './upload-image.html',
  styleUrls: ['./upload-image.scss'],
})
export class UploadImageComponent implements OnInit {
  @Input() type: string = 'view'
  @Input() imageDefault = ''
  @Output() showChange = new EventEmitter<boolean>()
  @Output() upload = new EventEmitter<any>()
  @Output() onDelete = new EventEmitter()
  @Output() onOpen = new EventEmitter()
  @Output() onClose = new EventEmitter()

  @Input() preview: string = 'square'
  @Input() isRemark: boolean = false

  readonly acceptFileTypes = ['image/png', 'image/jpeg', 'image/jpg']

  imageSrc: any

  get imgUrl() {
    return this.imageSrc || this.imageDefault
  }

  constructor(
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public fileService: FileService
  ) {}

  ngOnInit(): void {}

  // ------------upload file-------------------

  onChangeUpload(fileUpload: any) {
    const file = fileUpload.files[0]
    fileUpload.value = ''
    if (file) {
      //check file
      if (!this.isValidType(file.type)) {
        this.customSnackBar.fail('ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg')
        return
      }
      if (!this.isValidSize(file.size)) {
        this.customSnackBar.fail('ไฟล์ต้องไม่เกิน 30mb')
        return
      }

      this.loading.start()
      this.fileService.upload(file).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.upload.emit(res.data[0].id)
            this.imageSrc = res.data[0].url
          } else {
            this.customSnackBar.fail()
          }
        }
        this.loading.stop()
      })
    }
  }

  isValidType(fileType: any) {
    return this.acceptFileTypes.some((type: any) => {
      return type === fileType
    })
  }

  isValidSize(fileSize: number) {
    return fileSize < 30 * Math.pow(1024, 2)
  }

  isDelete() {
    this.upload.emit(null)
    if (this.imageSrc) {
      this.imageSrc = ''
    } else {
      this.imageDefault = ''
    }
    this.onDelete.emit()
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }
}
