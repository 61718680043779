import { Component, Input, Output } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { APP_PERMISSION, NavMenu, Profile } from '../../globals'
import { MatRipple } from '@angular/material/core'
import { Router, RouterModule } from '@angular/router'
import { MatMenuModule } from '@angular/material/menu'
import { MatDialog } from '@angular/material/dialog'
import { ModalLogoutComponent } from '../modal-logout/modal-logout'
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    MatRipple,
    RouterModule,
    MatMenuModule,
    ModalLogoutComponent,
  ],
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
})
export class HeaderComponent {
  readonly APP_PERMISSION = APP_PERMISSION

  pathDefaultSetting: any

  constructor(
    public NavMenu: NavMenu,
    public router: Router,
    public profile: Profile,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.pathDefaultSetting = this.profile.permissions.defaultSetting()
  }

  checkActive(menu: any) {
    return this.router.url.indexOf(menu) === 0
  }

  openLogout() {
    const dialogRefConfirm = this.dialog.open(ModalLogoutComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.profile.logout()
      }
    })
  }
}
