import { Component, EventEmitter, Injectable, Input, Output, SimpleChanges } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl } from '@angular/forms'
import { SelectComponent } from '../select/select'

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './input.html',
  styleUrls: ['./input.scss'],
})
export class InputComponent {
  @Input() value: any
  @Input() control: FormControl | AbstractControl = new FormControl('')
  @Input() placeholder = ''
  @Input() unit = ''
  @Input() maxlength = ''
  @Input() minlength = ''
  @Input() max = ''
  @Input() min = ''
  @Input() hintLabel = ''
  @Input() disabled = false
  @Input() type = 'text'
  @Input() remove_bottom = false

  @Output() onInput = new EventEmitter()
  @Output() onChange = new EventEmitter()
  @Output() onEnter = new EventEmitter()

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }
}
