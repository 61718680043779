export const thaiBath = (num: any) => {
  if (isNaN(num)) return 'ข้อมูลนำเข้าไม่ถูกต้อง'
  num = parseFloat(num)
  if (num == 0) return 'ศูนย์บาท'
  num = num.toFixed(2).toString()
  const [number, digit] = num.split('.')
  const numbers = number.split('').reverse()
  const digits = digit.split('').reverse()
  const arrayText = []
  if (parseInt(digit)) {
    arrayText.push('สตางค์')
    setTextPrice(digits, arrayText)
  } else {
    arrayText.push('ถ้วน')
  }
  arrayText.push('บาท')
  if (parseFloat(number)) {
    setTextPrice(numbers, arrayText)
  } else {
    arrayText.push('ศูนย์')
  }
  return arrayText.reverse().join('')
}

const numberArray = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า']

const ditArray = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']

function setTextPrice(nums: string[], arrayText: string[]) {
  let i = 0
  for (let nn in nums) {
    const n = parseInt(nn)
    const num = parseInt(nums[n])
    let ditText = ditArray[i]
    if (i == 6) {
      const mils = arrayText.filter(v => v.search('ล้าน') > -1)
      for (const mil of mils) {
        ditText += 'ล้าน'
      }
    }
    if (num || ditText == 'ล้าน') {
      arrayText.push(ditText)
    }
    let text = numberArray[num]
    if (num == 1 && (i == 0 || i == 6) && n != nums.length - 1) {
      text = 'เอ็ด'
    }
    if (i == 6) {
      i = 0
    }
    if (i == 1 && num == 2) {
      text = 'ยี่'
    }
    if (num != 1 || (num == 1 && i != 1)) {
      arrayText.push(text)
    }
    i++
  }
}
