<mat-dialog-content>
  <div class="modal-content">
    <div class="content-info-wrapper">
      <span class="icon-70 icon-orange material-symbols-rounded fill">error</span>
      <div class="content-title mb-1">{{ data.title }}</div>
      <div class="content-text">{{ data.detail }}</div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-center w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">ปิด</button>
  </div>
</mat-dialog-actions>
