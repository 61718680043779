@if (type == 'edit') {
<input
  type="file"
  id="uploadMore"
  [accept]="acceptFileTypes.toString()"
  class="input-upload"
  multiple
  #fileUpload
  (change)="onChangeUpload(fileUpload)" />

@if (images.length > 0) {
<div class="d-flex flex-wrap">
  @for (item of images; track item; let index = $index) { @if (!item.is_cover){
  <div class="upload-image-box-inner d-flex justify-content-start me-3">
    @if (item.url) {
    <button class="btn-close-img" matRipple (click)="isDelete(index)">
      <span class="font-14 icon-white material-symbols-outlined"> close </span>
    </button>
    }

    <label class="upload-image-inner {{preview == 'square' && 'square'}}">
      <img alt="Uploaded Image" class="upload-image" [src]="item.url" />
    </label>
  </div>
  } }
  <!-- ~ -->
  @if (imageNotCover.length < 9) {
  <div class="drop-wrapper d-flex justify-content-start align-items-center">
    <label
      class="upload-file-wrapper d-flex flex-column justify-content-center align-items-center py-4 my-sm-3 mb-2"
      for="uploadMore"
      (dragover)="dragOver($event)"
      (drop)="drop($event)">
      <span class="icon-16 icon-blue material-symbols-outlined fill"> add_circle </span>
      <div class="font-14 blue">เพิ่มรูปภาพ</div>
    </label>
  </div>
  }
</div>
} @else {
<!-- ~ -->
<div class="drop-wrapper d-flex justify-content-start align-items-center">
  <label
    class="upload-file-wrapper d-flex flex-column justify-content-center align-items-center py-4 my-sm-3 mb-2"
    for="uploadMore"
    (dragover)="dragOver($event)"
    (drop)="drop($event)">
    <span class="icon-16 icon-blue material-symbols-outlined fill"> add_circle </span>
    <div class="font-14 blue">เพิ่มรูปภาพ</div>
  </label>
</div>
<!-- ~ -->
} } @else {
<div class="d-flex flex-wrap">
  @for (item of imgUrl; track $index) { @if (!item.is_cover){
  <!-- Uploaded Image -->
  <div class="upload-image-box-inner d-flex justify-content-start me-3">
    <div class="upload-image-inner {{preview == 'square' && 'square'}}">
      <img alt="Uploaded Image" class="upload-image" [src]="item.url" />
    </div>
  </div>
  } }
</div>
}
