import { Component, Inject, ViewChild } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { InputComponent } from '../../../components/input/input'
import { SelectComponent } from '../../../components/select/select'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { imports } from '../../../imports'
import QrScanner from 'qr-scanner'

@Component({
  selector: 'app-modal-scan',
  templateUrl: './modal-scan.html',
  styleUrls: ['./modal-scan.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
  ],
})
export class ModalScanComponent {
  @ViewChild('elVideo') elVideo: any

  qrScanner!: QrScanner

  constructor(
    public dialogRef: MatDialogRef<ModalScanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar
  ) {}

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.qrScanner) {
        this.qrScanner.stop()
      }
    })
  }

  ngAfterViewInit() {
    this.qrScanner = new QrScanner(
      this.elVideo.nativeElement,
      result => {
        this.dialogRef.close(result.data)
      },
      {
        highlightCodeOutline: true,
        highlightScanRegion: true,
      }
    )
    this.qrScanner.start()
  }

  onClose(): void {
    this.dialogRef.close()
  }
}
