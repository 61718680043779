import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup } from '@angular/forms'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { FilterTablePromotion, FilterTableUser } from '../../../../globals'
import { imports } from '../../../../imports'

@Component({
  selector: 'app-modal-filter-promotion',
  templateUrl: './modal-filter.html',
  styleUrls: ['./modal-filter.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
  ],
})
export class ModalFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTablePromotion: FilterTablePromotion
  ) {}

  ngOnInit(): void {
    this.filterTablePromotion.initData()
  }

  form = new FormGroup({
    date_str: new FormControl(''),
    date_end: new FormControl(''),
  })

  status = [{ text: 'ใช้งาน' }, { text: 'ไม่ใช้งาน' }, { text: 'หมดอายุ' }]

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTablePromotion.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTablePromotion.confirm()
    this.dialogRef.close(true)
  }
}
