import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, ORDER_ID, convertDateToApi } from '../../helpers'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'

@Injectable({ providedIn: 'root' })
export class FilterTableHistoryPayment extends __searchAndFitler {
  readonly list = {
    is_qr_codes: <any[]>[
      { id: true, text: 'โอนเงินผ่านธนาคาร' },
      { id: false, text: 'Credit / Debit Card' },
    ],
    is_successes: <any[]>[
      { id: true, text: 'สำเร็จ' },
      { id: false, text: 'ไม่สำเร็จ' },
    ],
  }

  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  constructor(public loading: Loading, public customSnackBar: CustomSnackBar) {
    const filter = {
      is_qr_codes: [],
      is_successes: [],
    }
    super('/history/payment', filter)

    this._initForm(this.form)
  }

  initData() {}

  isQrCodeChecked(id: any) {
    return this.filter['is_qr_codes'].some((val: any) => val === id)
  }

  qrCodeChange(id: any) {
    const is_qr_codes = this.filter['is_qr_codes']
    if (is_qr_codes.includes(id)) {
      is_qr_codes.splice(is_qr_codes.indexOf(id), 1)
    } else {
      is_qr_codes.push(id)
    }
  }

  removeQrCode(id: any) {
    this.qrCodeChange(id)
  }

  isSuccessesChecked(id: any) {
    return this.filter['is_successes'].some((val: any) => val === id)
  }

  successesChange(id: any) {
    const is_successes = this.filter['is_successes']
    if (is_successes.includes(id)) {
      is_successes.splice(is_successes.indexOf(id), 1)
    } else {
      is_successes.push(id)
    }
  }

  removeSuccesses(id: any) {
    this.successesChange(id)
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const is_qr_codes = this.filter['is_qr_codes']
    if (is_qr_codes.length) {
      filter.is_qr_codes = is_qr_codes
    }

    const is_successes = this.filter['is_successes']
    if (is_successes.length) {
      filter.is_successes = is_successes
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
