import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl } from '@angular/forms'
import { SelectComponent } from '../select/select'

@Component({
  selector: 'app-input-password[control]',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './input-password.html',
  styleUrls: ['./input-password.scss'],
})
export class InputPasswordComponent implements OnInit {
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() disabled = false

  @Output() onInput = new EventEmitter()
  @Output() onEnter = new EventEmitter()

  showPassword: boolean = false

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }
}
