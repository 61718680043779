import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor'
import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  inject,
  SimpleChanges,
} from '@angular/core'
import { MatError, MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../select/select'
import { MatRipple } from '@angular/material/core'
import { distinctUntilChanged, pairwise, startWith } from 'rxjs'

@Component({
  selector: 'app-text-editor',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    MatRipple,
    NgxEditorModule,
    MatError,
  ],
  templateUrl: './text-editor.html',
  styleUrls: ['./text-editor.scss'],
})
export class TextEditorComponent implements OnInit {
  @Input() control: FormControl | AbstractControl = new FormControl('')
  @Input() placeholder = ''

  editor: any = Editor
  html = ''
  toolbar: Toolbar = [
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['bold', 'italic', 'underline'],
    ['bullet_list', 'ordered_list'],
    ['align_left', 'align_center', 'align_right'],
  ]

  objDiv: any = HTMLElement

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    const scrollHeight = window.scrollY || document.documentElement.scrollTop
  }

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.editor = new Editor()
    this.objDiv = document.getElementById('text-editor') as HTMLElement
    this.html = this.control.value || ''

    this.formControl.valueChanges
      .pipe(startWith(this.html), distinctUntilChanged(), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev != next) {
          this.html = next
        }
      })
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy()
    }
  }

  log(log: any) {
    console.log('log', log)
  }
}
