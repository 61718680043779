import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private service: Service) {}
  // Roles
  private pathRole = '/v1/roles'
  // Roles service
  getRoleList(payload: any): Observable<any> {
    return this.service.get(`${this.pathRole}`, payload)
  }
  getApplications(payload: any): Observable<any> {
    return this.service.get(`${this.pathRole}/applications`, payload)
  }
  getRole(id: any, payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathRole}/${id}`)
  }
  addRole(payload: any): Observable<any> {
    return this.service.post(`${this.pathRole}`, payload)
  }
  updateRole(id: any, payload: any = {}): Observable<any> {
    return this.service.put(`${this.pathRole}/${id}`, payload)
  }
  deleteRole(id: any, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathRole}/${id}`)
  }
}
