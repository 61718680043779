import { Component } from '@angular/core'
import { imports } from '../../../imports'
import { CoverSheetPrivateComponent } from '../cover-sheet-private/cover-sheet-private'

@Component({
  selector: 'app-cover-sheet-log',
  standalone: true,
  imports: [...imports, CoverSheetPrivateComponent],
  templateUrl: './cover-sheet-log.html',
  styleUrls: ['./cover-sheet-log.scss'],
})
export class CoverSheetLogComponent {
  constructor() {}

  ngOnInit(): void {}
}
