import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { RoleService } from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { forkJoin } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FilterTableUser extends __searchAndFitler {
  readonly list = {
    actives: <any[]>[
      { value: true, text: 'ใช้งาน' },
      { value: false, text: 'ไม่ใช้งาน' },
    ],
    role: <any[]>[],
  }

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  constructor(
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {
    const filter = {
      is_actives: [],
      role_ids: [],
    }
    super('/setting/user-management', filter)
  }

  initData() {
    const apis = [this.roleService.getRoleList({})]

    this.loading.start()
    forkJoin(apis).subscribe(([resRole]: any) => {
      if (resRole) {
        if (!resRole.is_error) {
          this.list.role = resRole.data.map((d: any) => {
            return {
              ...d,
              checked: false,
            }
          })
        } else {
          this.customSnackBar.fail(resRole.message)
        }
      }

      this.loading.stop()
    })
  }

  isActiveChecked(bool: any) {
    return this.filter['is_actives'].some((id: any) => id === bool)
  }

  activeChange(bool: any) {
    const is_actives = this.filter['is_actives']
    if (is_actives.includes(bool)) {
      is_actives.splice(is_actives.indexOf(bool), 1)
    } else {
      is_actives.push(bool)
    }
  }

  removeActive(bool: any) {
    this.activeChange(bool)
  }

  isRoleChecked(roleId: any) {
    return this.filter['role_ids'].some((id: any) => id == roleId)
  }

  roleChange(roleId: any) {
    const role_ids = this.filter['role_ids']
    if (role_ids.includes(roleId)) {
      role_ids.splice(role_ids.indexOf(roleId), 1)
    } else {
      role_ids.push(roleId)
    }
  }

  removeRole(bool: any) {
    this.roleChange(bool)
  }

  getData() {
    const filter: any = {}

    const data: any = {
      filter,
    }

    const is_actives = this.filter['is_actives']
    if (is_actives.length) {
      filter.is_actives = is_actives
    }

    const role_ids = this.filter['role_ids']
    if (role_ids.length) {
      filter.role_ids = role_ids
    }

    if (Object.keys(filter).length) {
      data.filter = filter
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
