import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'

@Component({
  selector: 'app-checkbox-table[checkedValue][value]',
  standalone: true,
  imports: [MatCheckboxModule],
  templateUrl: './checkbox-table.component.html',
  styleUrls: ['./checkbox-table.component.scss'],
})
export class CheckboxTableComponent implements OnInit {
  @Input() checkedValue!: Set<any>
  @Input() value: any
  @Input() disabled = false
  @Input() max = 0
  @Output() onChange = new EventEmitter()

  get isChecked() {
    return this.checkedValue.has(this.value)
  }

  get isDisabled() {
    return this.disabled || (this.isMaximum && !this.isChecked)
  }

  get isMaximum() {
    return this.max > 0 && this.checkedValue.size >= this.max
  }

  ngOnInit(): void {}

  handleClick() {
    if (this.isDisabled) return

    if (this.isChecked) {
      this.checkedValue.delete(this.value)
    } else {
      if (!this.isMaximum) {
        this.checkedValue.add(this.value)
      }
    }
    this.onChange.emit(this.isChecked)
  }
}
