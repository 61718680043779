import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class TransportService {
  constructor(private service: Service) {}
  private pathTransport = '/v1/transports'

  getTransportList(payload: any): Observable<any> {
    return this.service.post(`${this.pathTransport}/query`, payload)
  }
  getTransport(): Observable<any> {
    return this.service.get(`${this.pathTransport}/banks`)
  }
}
