<div class="top-content-wrapper">
  <div class="top-content-left mb-3">
    <app-search
      [control]="filterTableSupplier.search"
      placeholder="ค้นหา"
      (onEnter)="paginationSupplier.reset();getSupplierList()"></app-search>
  </div>
  <div class="top-content-right mb-3">
    @if (profile.permissions.checkAccess([APP_PERMISSION.SUPPLIER], [ACTION_ACCESS.CREATE])) {
    <button matRipple class="btn btn-primary" [routerLink]="['/supplier/create']">
      <span class="icon material-symbols-outlined fill">add_circle</span>
      เพิ่มผู้ขาย
    </button>
    }
  </div>
</div>

<div class="table-responsive table-main-tabs-wrapper">
  <table class="table table-fixed-column">
    <thead>
      <tr>
        <th width="120">รหัสผู้ขาย</th>
        <th width="280">ชื่อผู้ขาย</th>
        <th width="150">เบอร์โทรศัพท์</th>
        <th width="250">อีเมล</th>
        <th width="220">อัปเดตโดย</th>
        <th width="290">อัปเดตล่าสุด</th>
        <th width="120" class="fixed box-shadow-left text-center">จัดการ</th>
      </tr>
    </thead>

    <tbody>
      @for (item of supplierList; track $index) {
      <tr>
        <td>{{item.code}}</td>
        <td>{{item.name}}</td>
        <td>{{item.telephone}}</td>
        <td>{{item.email}}</td>
        <td>{{item.updated_by ? item.updated_by.full_name : ''}}</td>
        <td>{{Moment(item.updated_at).format('DD-MM-YYYY, HH:mm')}}</td>
        <td class="fixed box-shadow-left">
          <div class="action-button-wrapper justify-content-center">
            @if (item.action.view) {
            <button
              matRipple
              matTooltip="ดู"
              matTooltipPosition="above"
              class="btn btn-outline-secondary btn-icon"
              [routerLink]="['/supplier/view/' + item.id]">
              <span class="icon material-symbols-outlined fill">visibility</span>
            </button>
            } @if (item.action.edit) {
            <button
              matRipple
              matTooltip="แก้ไข"
              matTooltipPosition="above"
              class="btn btn-outline-secondary btn-icon"
              [routerLink]="['/supplier/edit/' + item.id]">
              <span class="icon material-symbols-outlined fill">edit</span>
            </button>
            }
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>

  @if(!supplierList.length) {
  <div class="data-not-found-wrapper">ไม่พบข้อมูลผู้ขาย กรุณาเพิ่มผู้ขาย</div>
  }
</div>

<!-- Pagination -->
@if (supplierList.length) {
<div class="pagination-wrapper">
  <mat-paginator
    [pageIndex]="paginationSupplier.data.pageIndex"
    [length]="paginationSupplier.data.length"
    [pageSize]="paginationSupplier.data.pageSize"
    [pageSizeOptions]="paginationSupplier.data.pageSizeOptions"
    aria-label="Select page"
    (page)="paginationSupplier.setFromPaginator($event);getSupplierList()">
  </mat-paginator>
</div>
}
