import { Component, Inject, OnInit } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { InputComponent } from '../../../../components/input/input'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { ProductBrandService } from '../../../../services'
import { Loading } from '../../../../globals'
import { forkJoin } from 'rxjs'
import { ValidateForm, setErrorForm } from '../../../../helpers'
import { imports } from '../../../../imports'

export const regexName = /[^A-Z0-9-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\ ]/

@Component({
  selector: 'app-modal-product-brand',
  templateUrl: './modal-product-brand.html',
  styleUrls: ['./modal-product-brand.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ModalConfirmComponent,
    ModalLeaveComponent,
    InputComponent,
    MatSlideToggleModule,
  ],
})
export class ModalProductBrandComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
      (control: AbstractControl) => {
        if (regexName.test(control.value)) {
          return {
            pattern: true,
          }
        }
        return null
      },
    ]),
    is_active: new FormControl(true),
  })

  dataManage: any

  constructor(
    public dialogRef: MatDialogRef<ModalProductBrandComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productBrandService: ProductBrandService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    const apis = []

    if (this.data.type == 'edit') {
      apis.push(this.productBrandService.getProductBrand(this.data.dataManage.id))
    } else {
      this.form.reset({
        is_active: true,
      })
    }

    if (apis.length) {
      this.loading.start()
      forkJoin(apis).subscribe(([resProductBrand]: any) => {
        if (resProductBrand) {
          if (!resProductBrand.is_error) {
            this.dataManage = resProductBrand.data

            this.form.reset({
              name: this.dataManage.name,
              is_active: this.dataManage.is_active,
            })
          } else {
            this.customSnackBar.fail(resProductBrand.message)
          }
        }

        this.loading.stop()
      })
    }
  }

  onClose() {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    if (this.data.type == 'edit') {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const isEdit = this.data.type == 'edit'

    const value = this.form.getRawValue()
    const payload = {
      name: value.name,
      is_active: value.is_active,
    }

    // return console.log(payload)

    const api = isEdit
      ? this.productBrandService.updateProductBrand(this.data.dataManage.id, payload)
      : this.productBrandService.addProductBrand(payload)
    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.dialogRef.close(true)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }
}
