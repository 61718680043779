import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-status',
  standalone: true,
  templateUrl: './status.html',
  styleUrls: ['./status.scss'],
})
export class StatusComponent implements OnInit {
  @Input() status = -1

  ngOnInit(): void {}

  customColorStatus: any = {
    1: 'red',
    2: 'orange',
    3: 'green',
  }
}
