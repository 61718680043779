<div class="tabs-content-wrapper-detail">
  <div class="d-flex justify-content-between">
    <!--  (onEnter)="getProductBrandList()" -->
    <app-search
      [control]="search"
      placeholder="ค้นหา"
      (onEnter)="paginationCarat.reset();getCustomerCaratList()"></app-search>

    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.EXPORT])) {
    <button
      matRipple
      class="btn btn-outline-secondary min-w-100px"
      (click)="openModalDownloadFile('ดาวน์โหลดไฟล์การใช้กะรัต', { message: 'คุณต้องการดาวน์โหลดไฟล์การใช้กะรัตใช่หรือไม่' })">
      <span class="icon material-symbols-outlined fill">download_2</span>
      ส่งออกไฟล์
    </button>
    }
  </div>

  <div class="table-responsive-detail mt-3">
    <div [style]="{ 'min-width': '1000px' }">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th width="260">กะรัตที่เปลี่ยน</th>
            <th>หมายเหตุ</th>
            <th width="200">โดย</th>
            <th width="200">วันที่</th>
          </tr>
        </thead>

        <tbody>
          @for (item of caratList; track $index) {
          <tr>
            <td>
              {{ item.carat_type_name }}
              <span [class]="item.carat_type == 2 ? 'color--red-600' : 'color--green-600'">
                {{ formatPrice(item.carat) }}
              </span>
            </td>
            <td>{{ item.remark }}</td>
            <td>{{ item.updated_by ? item.updated_by.full_name : '' }}</td>
            <td>{{ Moment(item.updated_at).format('DD-MM-YYYY, HH:mm') }}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>

    @if(!caratList.length) {
    <div class="data-not-found-wrapper">ไม่พบรายการกะรัต</div>
    }
  </div>

  <!-- Pagination -->
  @if (caratList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationCarat.data.pageIndex"
      [length]="paginationCarat.data.length"
      [pageSize]="paginationCarat.data.pageSize"
      [pageSizeOptions]="paginationCarat.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationCarat.setFromPaginator($event);getCustomerCaratList()">
    </mat-paginator>
  </div>
  }
</div>
