<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="container gx-0">
      <div class="row">
        <div class="col-12 col-sm-6">
          <label class="form-label"> แสดงตาม </label>

          <app-select
            [control]="filterReportUser.form.controls['period']"
            [datas]="filterReportUser.list.period"
            fieldValue="value"
            placeholder="เลือก"
          >
            <ng-template #optionRef let-option> {{ option.text }} </ng-template>
          </app-select>
        </div>

        <div class="col-12 col-sm-6">
          <label class="form-label"> วันที่ </label>

          <app-date-picker-range
            [controlStr]="filterReportUser.form.controls['start_date']"
            [controlEnd]="filterReportUser.form.controls['end_date']"
          ></app-date-picker-range>
        </div>
      </div>
    </div>
    <div class="filter-item-wrapper">
      <div class="filter-title">สถานะออเดอร์</div>

      <div class="filter-checkbox-wrapper checkbox-two-column">
        @for (item of filterReportUser.list.status_ids; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterReportUser.isStatusIdChecked(item.id)"
          (change)="filterReportUser.statusIdChange(item.id)"
        >
          {{ item.text }}
        </mat-checkbox>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onReset()">ล้างค่า</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">นำไปใช้</button>
  </div>
</mat-dialog-actions>
