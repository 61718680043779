<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{ data.isPaid ? 'ข้อมูลการโอนเงิน' : 'หลักฐานการโอนเงิน' }}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="payment-detail-wrapper">
      <div class="d-flex flex-column align-items-center gap-2">
        <img
          src="/assets/images/icons/img-payment-success.svg"
          width="128"
          height="100"
          alt="Payment Success" />
        <div class="text-bold color--green-600">ชำระเงินเรียบร้อยแล้ว</div>
      </div>

      <div class="d-flex flex-column gap-1">
        <div class="text-bold color--neutral-950">เลขที่ออเดอร์ {{ data.item.order_no }}</div>
        <div class="d-flex align-items-center gap-2">
          <div class="color--neutral-950">ชื่อลูกค้า:</div>
          <div class="color--neutral-950">{{ data.item.customer_name }}</div>
        </div>
      </div>

      <div class="line"></div>

      @if (payment) {
      <div class="d-flex flex-column gap-1">
        @if (payment.payment_amount) {
        <!-- ~ -->
        @if (payment.is_qrcode) {
        <div class="box-qr-img">
          <img [src]="payment.file.url" />
        </div>
        }

        <!-- ~ -->
        @if (payment.is_qrcode) {
        <div class="payment-info-wrapper mt-3">
          <div class="payment-info-title color--neutral-950">บัญชีธนาคาร</div>
          <div class="payment-info d-flex align-items-center gap-2">
            @if (payment) {
            <img
              src="/assets/images/logos/banks/{{ payment.bank_account.bank.code }}.png"
              width="20"
              height="20"
              alt="Bank" />
            }
            <div class="color--neutral-950">
              {{ payment.bank_account.account_name }}
              <span class="color--neutral-500">
                (เลขที่บัญชี: {{ payment.bank_account.account_number }})
              </span>
            </div>
          </div>
        </div>
        }

        <div class="payment-info-wrapper">
          <div class="payment-info-title color--neutral-950">ช่องทางการชำระเงิน</div>
          <div class="payment-info color--neutral-950">
            {{payment.is_qrcode ? 'โอนเงินผ่านธนาคาร' : 'Credit / Debit Card'}}
          </div>
        </div>

        <div class="payment-info-wrapper">
          <div class="payment-info-title color--neutral-950">ยอดชำระ</div>
          <div class="payment-info color--neutral-950">
            {{ formatPrice(payment.payment_amount) }} บาท
          </div>
        </div>

        <div class="payment-info-wrapper">
          <div class="payment-info-title color--neutral-950">วันเวลาที่ชำระ</div>
          <div class="payment-info color--neutral-950">
            {{ payment.payment_date ? Moment(payment.payment_date).format('DD-MM-YYYY, HH:mm') : ''
            }}
          </div>
        </div>

        @if (payment.is_qrcode) {
        <!-- ~ -->
        @for (item of payment.files; track $index) {
        <div class="box-qr-img mt-3">
          <img [src]="item.file.url" />
        </div>
        }
        <!-- ~ -->
        }
        <!-- ~ -->
        } @else {
        <div class="d-flex gap-2">
          <img src="assets/images/icons/icon-payment-check.svg" />
          <div>
            ชำระเงินด้วยส่วนลดรวม {{ formatPrice(payment.credit_code_price +
            payment.carat_code_price + payment.discount_code_price) }} บาท
          </div>
        </div>
        <div class="d-flex gap-2 mt-1">
          <div>วันเวลาที่ชำระ:</div>
          <div>
            {{ payment.created_at ? Moment(payment.created_at).format('DD-MM-YYYY, HH:mm') : '' }}
          </div>
        </div>
        }
      </div>
      }
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
  </div>
</mat-dialog-actions>
