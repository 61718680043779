import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private service: Service) {}

  private pathReportSaleYear = '/v1/report/sales/yearly'
  getReportSaleYear(payload: any): Observable<any> {
    return this.service.post(`${this.pathReportSaleYear}`, payload)
  }
  exportReportSaleYear(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathReportSaleYear}/export`, payload)
  }

  private pathReportSaleMonth = '/v1/report/sales/monthly'
  getReportSaleMonth(payload: any): Observable<any> {
    return this.service.post(`${this.pathReportSaleMonth}`, payload)
  }
  exportReportSaleMonth(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathReportSaleMonth}/export`, payload)
  }

  private pathReportProduct = '/v1/report/order-products'
  getReportProductList(payload: any): Observable<any> {
    return this.service.post(`${this.pathReportProduct}/query`, payload)
  }
  exportReportProductList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathReportProduct}/export`, payload)
  }
  getReportProductSummaryList(payload: any): Observable<any> {
    return this.service.get(`${this.pathReportProduct}/summary`, payload)
  }

  private pathReportUsers = '/v1/report/users'
  getReportUsersList(payload: any): Observable<any> {
    return this.service.post(`${this.pathReportUsers}/query`, payload)
  }
  exportReportUsersList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathReportUsers}/export`, payload)
  }

  private pathReportCustomers = '/v1/report/customers'
  getReportCustomersList(payload: any): Observable<any> {
    return this.service.post(`${this.pathReportCustomers}/query`, payload)
  }
  exportReportCustomersList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathReportCustomers}/export`, payload)
  }
}
