import { Component, Inject, Injectable, OnInit } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InputComponent } from '../../../../../../components/input/input'
import { ValidateForm } from '../../../../../../helpers'
import { ModalConfirmComponent } from '../../../../../../components/modal-confirm/modal-confirm'
import { RoleService } from '../../../../../../services'
import { Loading } from '../../../../../../globals'
import { CustomSnackBar } from '../../../../../../components/snackbar/snackbar'
import { ModalLeaveComponent } from '../../../../../../components/modal-leave/modal-leave'
import { imports } from '../../../../../../imports'

@Component({
  selector: 'app-modal-role',
  templateUrl: './modal-role.html',
  styleUrls: ['./modal-role.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    ModalConfirmComponent,
  ],
})
export class ModalRoleComponent implements OnInit {
  tab = 0

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
  })

  dataManage: any

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalRoleComponent>,
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.initData(this.data)
  }

  initData(data: any) {
    if (data.type == 'edit') {
      this.roleService.getRole(this.data.dataManage.id).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.dataManage = res.data
          } else {
            this.customSnackBar.fail()
          }
        }
        this.loading.stop()
      })
      this.form.reset({
        name: data.dataManage.name,
      })
    } else {
      this.form.reset()
    }
  }

  onClose(): void {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onSuccess(data: any): void {
    this.dialogRef.close(data)
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    if (this.data.type == 'edit') {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm(): void {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })
  }

  onSave() {
    const isEdit = this.data.type == 'edit'
    const application_permission_ids = isEdit
      ? this.dataManage.applications.reduce((ids: any[], app: any) => {
          for (const application_permission of app.application_permissions) {
            if (application_permission.permission.is_selected) {
              ids.push(application_permission.id)
            }
          }
          return ids
        }, [])
      : []

    const value = this.form.getRawValue()
    const payload = {
      name: value.name,
      application_permission_ids,
    }

    const api = isEdit
      ? this.roleService.updateRole(this.data.dataManage.id, payload)
      : this.roleService.addRole(payload)
    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.onSuccess({
            id: isEdit ? this.dataManage.id : res.data,
            name: payload.name,
          })
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
        } else {
          const message = res.errors?.[0]?.message || res.message
          if (message) {
            this.form.controls['name'].setErrors({
              formApi: res.errors?.[0]?.message || res.message,
            })
          } else {
            this.customSnackBar.failSave()
          }
        }
      }
      this.loading.stop()
    })
  }
}
