<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content-table">
    @if (data.type == 'add') {
    <app-search
      [control]="search"
      placeholder="ค้นหา"
      (onEnter)="paginationProduct.reset();getProductList()"></app-search>
    } @else {
    <app-search [control]="search" placeholder="ค้นหา"></app-search>
    }

    <table class="table table-fixed-column table-detail mt-3">
      <thead>
        <tr>
          <th width="80"></th>
          <th width="250" class="text-center">รหัสสินค้า</th>
          <th width="280">ชื่อสินค้า</th>
          <th width="160" class="text-end">ราคา (บาท)</th>
          <th width="230">จำนวน</th>
        </tr>
      </thead>

      <tbody>
        @for (item of productList; track $index) {
        <tr>
          @if (data.type == 'add') {
          <td>
            <mat-checkbox
              class="checkbox-table"
              [checked]="productSelect.get(item.key)?.checked || false"
              (click)="productChecked(item, !item.checked)">
            </mat-checkbox>
          </td>
          }
          <td>
            <div class="d-flex align-items-center">
              <img class="img-table me-3" src="{{ item.image?.file?.url || imgDefault }}" />
              <div class="d-flex flex-column w-75">
                <div class="mb-2">{{ item.product.product_code}}</div>
              </div>
            </div>
          </td>
          <td>
            <div>{{ item.product.product_name }}</div>
            <div class="gray font-14">{{ item.product.color }}, {{ item.product.size }}</div>
          </td>
          <td class="text-end">{{ formatPrice(item.priceControl.value) }}</td>
          <td>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <button
                  class="btn btn-outline-secondary"
                  matRipple
                  [disabled]="item.quantity < 2"
                  (click)="reduceQuantity(item)">
                  <span class="icon-16 material-symbols-outlined fill"> remove </span>
                </button>
                <div class="px-3">{{ item.quantity }}</div>
                <button
                  class="btn btn-outline-secondary"
                  matRipple
                  [disabled]="item.quantity == item.stock"
                  (click)="addQuantity(item)">
                  <span class="icon-16 material-symbols-outlined fill"> add </span>
                </button>

                <div class="ms-2">/ {{ item.stock }} ชิ้น</div>
              </div>
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>

    @if(!productList.length) {
    <div class="data-not-found-wrapper">ไม่พบสินค้า</div>
    } @if (productList.length && data.type == 'add') {
    <div class="pagination-wrapper d-flex flex-wrap gap-2">
      <mat-paginator
        [pageIndex]="paginationProduct.data.pageIndex"
        [length]="paginationProduct.data.length"
        [pageSize]="paginationProduct.data.pageSize"
        [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
        (page)="paginationProduct.setFromPaginator($event);pageNumber.setValue(paginationProduct.data.pageIndex + 1);getProductList()"
        aria-label="Select page">
      </mat-paginator>
      <div class="d-flex align-items-center gap-2 mat-mdc-paginator">
        <span>ไปที่หน้า</span>
        <div class="font-12" [style]="{ 'min-width': '70px', 'max-width': '70px' }">
          @if (!rerender) {
          <app-input-number
            [control]="pageNumber"
            placeholder="0"
            [min]="1"
            [max]="paginationProduct.totalPage || 1"
            [remove_bottom]="true"
            (onEnter)="onChangePage()"
            (onblur)="onChangePage(true)">
          </app-input-number>
          }
        </div>
        / {{ formatPriceAutoDecimal(paginationProduct.totalPage) }}
      </div>
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex flex-wrap justify-content-between w-100">
    <div class="d-flex align-items-center">
      <span class="font-14 gray-600"
        >ทั้งหมด {{ total.amount }} รายการ ({{ total.quantity }} ชิ้น)</span
      >
      <b class="ms-5">{{ formatPrice(total.price) }} บาท</b>
    </div>
    <div class="d-flex align-items-center">
      <button matRipple class="btn btn-outline-secondary btn-md me-2" (click)="onClose()">
        ยกเลิก
      </button>
      <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">
        {{ data.type == 'add' ? 'เพิ่มสินค้า' : 'บันทึก' }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
