<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text">ค่าใช้จ่าย</div>
  </div>

  <div class="title-wrapper">ค่าใช้จ่าย</div>

  <div class="page-content-inner">
    <div class="detail-content-wrapper container-fluid gx-0">
      <div class="row">
        <div class="col-12 mb-3">
          <span class="title-blue">รายละเอียดค่าใช้จ่าย</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-sm-11">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-3">
                  <label
                    class="form-label"
                    [class.text-danger]="recordExpense.touched && recordExpense.invalid">
                    หมวดหมู่ <span class="text-danger">*</span>
                  </label>

                  <app-select
                    [control]="recordExpense"
                    [datas]="list.recordExpenses"
                    fieldValue="id"
                    placeholder="ทั้งหมด"
                    (selectionChange)="getExpense()">
                    <ng-template #optionRef let-option> {{ option.name }} </ng-template>
                  </app-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      @if (recordExpense.value != 3) {
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-sm-11">
              @for (item of details.controls; track $index) {
              <div class="row" [style]="{ minHeight: '100px' }">
                <div class="col-12 col-md-6 col-lg-3">
                  <label
                    class="form-label"
                    [class.text-danger]="item.controls['code'].touched && item.controls['code'].invalid">
                    รหัสค่าใช้จ่าย <span class="text-danger">*</span>
                  </label>

                  <app-input [control]="item.controls['code']">
                    @if (item.controls['code'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    } @else if (item.controls['code'].hasError('maxlength')) {
                    <ng-container error>กรุณากรอกข้อมูลไม่เกิน 50 ตัวอักษร</ng-container>
                    } @else if (item.controls['code'].hasError('formApi')) {
                    <ng-container error>
                      {{ item.controls['code'].errors?.['formApi']}}
                    </ng-container>
                    }
                  </app-input>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                  <label
                    class="form-label"
                    [class.text-danger]="item.controls['name'].touched && item.controls['name'].invalid">
                    ชื่อค่าใช้จ่าย <span class="text-danger">*</span>
                  </label>

                  <app-input [control]="item.controls['name']">
                    @if (item.controls['name'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    } @else if (item.controls['name'].hasError('maxlength')) {
                    <ng-container error>กรุณากรอกข้อมูลไม่เกิน 250 ตัวอักษร</ng-container>
                    } @else if (item.controls['name'].hasError('formApi')) {
                    <ng-container error>
                      {{ item.controls['name'].errors?.['formApi']}}
                    </ng-container>
                    }
                  </app-input>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                  <label
                    class="form-label"
                    [class.text-danger]="item.controls['price'].touched && item.controls['price'].invalid">
                    ค่าใช้จ่าย <span class="text-danger">*</span>
                  </label>

                  <app-input-number [control]="item.controls['price']" [useZero]="true" unit="บาท">
                    @if (item.controls['price'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    }
                  </app-input-number>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                  <label class="form-label"> วันที่ </label>

                  <app-date-picker [control]="form.controls['date']" placeholder="วว/ดด/ปปปป">
                  </app-date-picker>
                </div>
              </div>
              }
              <!-- ~ -->
              @if (!details.length) {
              <div class="row" [style]="{ minHeight: '100px' }">
                <div class="col-12">&nbsp;</div>
              </div>
              }

              <div class="row">
                <div class="col-12 col-sm-6">
                  <label class="form-label"> หมายเหตุ </label>

                  <app-text-area
                    [control]="form.controls['remark']"
                    remainingText="100"
                    maxlength="100">
                  </app-text-area>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3">
                  <span class="title-blue">เอกสารที่เกี่ยวข้อง (เช่น ใบเสร็จ)</span>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <label class="form-label"> ไฟล์แนบ </label>

                  <div class="upload-button-wrapper d-flex align-items-center">
                    <button
                      matRipple
                      class="btn btn-outline-secondary"
                      (click)="inputUpload.click()">
                      <span class="icon material-symbols-outlined fill">add_circle</span>
                      อัปโหลด
                    </button>

                    <input
                      type="file"
                      #inputUpload
                      multiple
                      class="d-none"
                      (change)="uploadFile($event)" />

                    <div class="upload-info-text">สูงสุด 3 ไฟล์ ขนาดไฟล์ไม่เกิน 10 Mb</div>
                  </div>

                  <div class="upload-file-name-wrapper">
                    @for (f of files; track $index) {
                    <div class="upload-file-item-wrapper">
                      <div class="upload-file-wrapper">
                        <span class="icon-16 icon-grey material-symbols-outlined fill"
                          >attach_file</span
                        >
                        <div class="upload-file-name">{{ f.name }}</div>
                      </div>

                      <button matRipple class="btn btn-icon" (click)="delFile($index)">
                        <span class="icon material-symbols-outlined fill">delete</span>
                      </button>
                    </div>
                    }

                    <!-- <div class="upload-file-error">เกิดข้อผิดพลาด</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      } @else {
      <div class="row" [style]="{ minHeight: '100px' }">
        <div class="col-12">
          @for (item of details.controls; track $index) {
          <div class="row">
            <div class="col-12 col-sm-11">
              <div class="row">
                <div class="col-12 col-sm-3">
                  <label
                    class="form-label"
                    [class.text-danger]="item.controls['code'].touched && item.controls['code'].invalid">
                    รหัสสินค้า <span class="text-danger">*</span>
                  </label>

                  <app-input-product
                    [control]="item.controls['code']"
                    placeholder="เลือก"
                    (onSelectionChange)="productSelect($event, item)">
                    @if (item.controls['code'].hasError('required')) {
                    <ng-container error> กรุณาเลือกข้อมูล </ng-container>
                    }
                  </app-input-product>
                </div>

                <div class="col-12 col-sm-3">
                  <label class="form-label"> ชื่อสินค้า </label>

                  <app-input [control]="item.controls['name']" [disabled]="true"> </app-input>
                </div>

                <div class="col-12 col-sm-3">
                  <label class="form-label">
                    ราคา
                    <span class="text-danger">*</span>
                  </label>

                  <app-input-number [control]="item.controls['price']" [useZero]="true" unit="บาท">
                    @if (item.controls['price'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    }
                  </app-input-number>
                </div>

                <div class="col-12 col-sm-3">
                  <label
                    class="form-label"
                    [class.text-danger]="item.controls['qty'].touched && item.controls['qty'].invalid">
                    จำนวนที่ชำรุด <span class="text-danger">*</span>
                  </label>

                  <div class="input-number-fake">
                    <app-input-number
                      [control]="item.controls['qty']"
                      [min]="0"
                      [useZero]="true"
                      [decimal]="0"
                      placeholder="0"
                      [disabled]="!item.controls['product_id'].value">
                      @if (item.controls['qty'].hasError('required')) {
                      <ng-container error>กรุณากรอกข้อมูล</ng-container>
                      } @else if (item.controls['qty'].hasError('formApi')) {
                      <ng-container error>
                        {{ item.controls['qty'].errors?.['formApi']}}
                      </ng-container>
                      }
                    </app-input-number>
                    <div class="btn-num-right">
                      <button matRipple (click)="setQty(item.controls['qty'], 1)">
                        <img src="/assets/images/icons/icon-btn-number-up.svg" />
                      </button>
                      <button matRipple (click)="setQty(item.controls['qty'], -1)">
                        <img src="/assets/images/icons/icon-btn-number-down.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-1">
              @if ($index > 0) {
              <div class="delete-button-wrapper">
                <button
                  matRipple
                  class="btn btn-icon btn-outline-danger"
                  (click)="details.removeAt($index)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
              </div>
              }
            </div>
          </div>
          }
          <!-- ~ -->
          @if (!details.length) {
          <div class="row" [style]="{ minHeight: '100px' }">
            <div class="col-12">&nbsp;</div>
          </div>
          }

          <div class="row mb-3">
            <div class="col-12 col-sm-11">
              <div class="row">
                <div class="col-12">
                  <button matRipple class="btn btn-outline-secondary" (click)="addDetail()">
                    <span class="icon material-symbols-outlined fill">add_circle</span>
                    เพิ่มสินค้า
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-11">
              <div class="row">
                <div class="col-12 col-sm-3">
                  <label class="form-label"> วันที่ </label>

                  <app-date-picker [control]="form.controls['date']" placeholder="วว/ดด/ปปปป">
                  </app-date-picker>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-11">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <label class="form-label"> หมายเหตุ </label>

                  <app-text-area
                    [control]="form.controls['remark']"
                    remainingText="100"
                    maxlength="100">
                  </app-text-area>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>

    <div class="footer-button-wrapper d-flex justify-content-end">
      @if (profile.permissions.checkAccess([APP_PERMISSION.EXPENSE], [ACTION_ACCESS.CREATE])) {
      <button matRipple class="btn btn-outline-secondary btn-md" (click)="getExpense()">
        ยกเลิก
      </button>
      <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
      }
    </div>
  </div>
</div>
