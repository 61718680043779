import { Component, EventEmitter, Injectable, Input, OnInit, Output, inject } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../select/select'
import { MatRipple } from '@angular/material/core'
import { CustomSnackBar } from '../snackbar/snackbar'
import { Loading } from '../../globals'
import { FileService, PublicService } from '../../services'

@Component({
  selector: 'app-upload-image-profile',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
    MatRipple,
  ],
  templateUrl: './upload-image-profile.html',
  styleUrls: ['./upload-image-profile.scss'],
})
export class UploadImageProfileComponent implements OnInit {
  @Input() isPublic = false
  @Input() type: string = 'view'
  @Input() logoDefault = ''
  @Input() imageDefault = '/assets/images/logos/none-logos.svg'
  @Output() showChange = new EventEmitter<boolean>()
  @Output() upload = new EventEmitter<any>()
  @Output() onDelete = new EventEmitter()
  @Output() onOpen = new EventEmitter()
  @Output() onClose = new EventEmitter()

  @Input() preview: string = 'square'
  @Input() isRemark: boolean = false

  uuid = crypto.randomUUID()

  readonly acceptFileTypes = ['image/png', 'image/jpeg', 'image/jpg']

  imageSrc: any

  get logoUrl() {
    return this.imageSrc || this.logoDefault
  }

  get imgUrl() {
    return this.logoUrl || this.imageDefault
  }

  constructor(
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public fileService: FileService,
    public publicService: PublicService
  ) {}

  ngOnInit(): void {}

  // ------------upload file-------------------

  onChangeUpload(fileUpload: any) {
    const file = fileUpload.files[0]
    fileUpload.value = ''
    if (file) {
      //check file
      if (!this.isValidType(file.type)) {
        this.customSnackBar.fail('ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg')
        return
      }
      if (!this.isValidSize(file.size)) {
        this.customSnackBar.fail('ไฟล์ต้องไม่เกิน 30mb')
        return
      }

      this.loading.start()
      const api = this.isPublic ? this.publicService.upload(file) : this.fileService.upload(file)
      api.subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.upload.emit(res.data[0])
            this.imageSrc = res.data[0].url
          } else {
            this.customSnackBar.fail()
          }
        }
        this.loading.stop()
      })
    }
  }

  isValidType(fileType: any) {
    return this.acceptFileTypes.some((type: any) => {
      return type === fileType
    })
  }

  isValidSize(fileSize: number) {
    return fileSize < 30 * Math.pow(1024, 2)
  }

  isDelete() {
    this.upload.emit(null)
    if (this.imageSrc) {
      this.imageSrc = ''
    } else {
      this.logoDefault = ''
    }
    this.onDelete.emit()
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }
}
