import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(private service: Service) {}
  private pathPromotion = '/v1/promotions'

  getPromotionList(payload: any): Observable<any> {
    return this.service.post(`${this.pathPromotion}/query`, payload)
  }
  getPromotion(id: number): Observable<any> {
    return this.service.get(`${this.pathPromotion}/${id}`)
  }
  addPromotion(payload: any): Observable<any> {
    return this.service.post(`${this.pathPromotion}`, payload)
  }
  updatePromotion(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathPromotion}/${id}`, payload)
  }
  deletePromotion(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathPromotion}/${id}`, payload)
  }
  exportPromotion(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathPromotion}/export`, payload)
  }
}
