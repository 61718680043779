import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryService {
  constructor(private service: Service) {}
  private pathProductCate = '/v1/product-categories'

  getProductCategoryList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductCate}/query`, payload)
  }
  getProductCatetgory(id: number): Observable<any> {
    return this.service.get(`${this.pathProductCate}/${id}`)
  }
  addProductCategory(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductCate}`, payload)
  }
  updateProductCategory(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathProductCate}/${id}`, payload)
  }
  deleteProductCategory(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathProductCate}/${id}`, payload)
  }
}
