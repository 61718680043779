import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InputComponent } from '../../../components/input/input'
import { Moment, ValidateForm } from '../../../helpers'
import { MatRipple } from '@angular/material/core'

@Component({
  selector: 'app-modal-discount-code',
  templateUrl: './modal-discount-code.html',
  styleUrls: ['./modal-discount-code.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    MatRipple,
  ],
})
export class ModalDiscountCodeComponent {
  readonly Moment = Moment

  promotionCode: any

  constructor(
    public dialogRef: MatDialogRef<ModalDiscountCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.promotionCode = this.data.promotionCode
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const data = this.data.discountCodeList.find((d: any) => d.code == this.promotionCode)
    this.dialogRef.close(data?.code)
  }

  endFormat(item: any) {
    return item.effective_date_to
      ? Moment(item.effective_date_to).formatThaiYear('DD MMM YYYY HH:mm')
      : '-'
  }
}
