import { Component, Inject } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { CustomSnackBar } from '../snackbar/snackbar'

@Component({
  selector: 'app-modal-download-file',
  templateUrl: './modal-download-file.html',
  styleUrls: ['./modal-download-file.scss'],
  standalone: true,
  imports: [MatButtonModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose],
})
export class ModalDownloadFileComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalDownloadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public CustomSnackBar: CustomSnackBar
  ) {}

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(this.data)
  }
}
