<div class="page-main-content">
  <div class="stick-left">
    <div class="top-content-wrapper align-items-start flex-wrap mb-3">
      <div class="top-content-left align-items-start gap-4">
        <div class="title-wrapper mt-1 mb-0">ออเดอร์</div>
        <div class="order-search-filter-wrapper">
          <app-search-filter-order
            placeholder="ค้นหาออเดอร์"
            (onConfirm)="paginationOrder.reset();getOrderList();orderSelect.clear()"></app-search-filter-order>
        </div>
      </div>
      <div class="top-content-right">
        @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER], [ACTION_ACCESS.EXPORT])) {
        <button
          matRipple
          (click)="openModalDownloadFile('ดาวน์โหลดไฟล์ออเดอร์', { message: 'คุณต้องการดาวน์โหลดไฟล์ออเดอร์ที่เลือกใช่หรือไม่' })"
          class="btn btn-outline-secondary">
          <span class="icon material-symbols-outlined fill">download_2</span>
          ส่งออกไฟล์
        </button>
        }
        <!-- ~ -->
        @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER], [ACTION_ACCESS.IMPORT])) {
        <button
          matRipple
          (click)="openModalUploadFile('นำเข้าออเดอร์')"
          class="btn btn-outline-secondary">
          <span class="icon material-symbols-outlined fill">add_circle</span>
          นำเข้าออเดอร์
        </button>
        }
        <!-- ~ -->
        @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER], [ACTION_ACCESS.CREATE])) {
        <button matRipple [routerLink]="['/order/create']" class="btn btn-primary">
          <span class="icon material-symbols-outlined fill">add_circle</span>
          สร้างออเดอร์
        </button>
        }
      </div>
    </div>

    <div class="order-status-section page-content-inner">
      <div class="order-status-dropdown">
        <label class="form-label"> สถานะออเดอร์ </label>
        <app-select
          [control]="status_id"
          [datas]="list.status"
          fieldValue="id"
          placeholder="เลือก"
          (selectionChange)="afterFilter()">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
        </app-select>
      </div>

      <div class="order-status-wrapper">
        <div class="order-status-item-wrapper">
          @for (item of consts; track $index) {
          <div
            class="order-status-item cursor-pointer"
            [class.active]="item.status_id == status_id.value"
            (click)="onFilterStatus(item)">
            <div class="order-status-text">{{ item.description }}</div>
            <div class="order-status-number">{{ item.count }}</div>
          </div>
          }
        </div>
      </div>
    </div>

    <div class="order-quantity-wrapper">
      <div class="neutral-950">จำนวนออเดอร์: {{ paginationOrder.data.length }}</div>
      <div class="order-selected-wrapper">
        @if (orderList.length) {
        <div class="neutral-950">เลือก: {{ orderSelect.size }}</div>
        @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER], [ACTION_ACCESS.UPDATE])) {
        <button
          matRipple
          (click)="openModalMergeOrder('รวมออเดอร์')"
          class="btn btn-primary btn-md"
          [disabled]="orderSelect.size < 2">
          รวมออเดอร์
        </button>
        }

        <button
          matRipple
          [matMenuTriggerFor]="printAllSelect"
          class="btn btn-orange btn-md"
          [disabled]="!orderForPrint.length">
          พิมพ์ทุกรายการที่เลือก
          <span class="icon material-symbols-rounded fill">arrow_drop_down</span>
        </button>

        <mat-menu #printAllSelect="matMenu" class="print-menu-wrapper">
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('address')">
            <span>ที่อยู่</span>
          </div>
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('receipt')">
            <span>ใบเสร็จรับเงิน</span>
          </div>
          @if (isPrintTax) {
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('tax')">
            <span>ใบกำกับภาษี (ต้นฉบับ)</span>
          </div>
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('tax', true)">
            <span>ใบกำกับภาษี (สำเนา)</span>
          </div>
          }
        </mat-menu>

        @if(status_id.value == ORDER_ID.PAID) {
        <button matRipple [matMenuTriggerFor]="printAll" class="btn btn-orange btn-md">
          พิมพ์ทุกรายการ
          <span class="icon material-symbols-rounded fill">arrow_drop_down</span>
        </button>

        <mat-menu #printAll="matMenu" class="print-menu-wrapper">
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('address', false, filterAllWaitPrint())">
            <span>ที่อยู่</span>
          </div>
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('receipt', false, filterAllWaitPrint())">
            <span>ใบเสร็จรับเงิน</span>
          </div>
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('tax', false, filterAllWaitPrint())">
            <span>ใบกำกับภาษี (ต้นฉบับ)</span>
          </div>
          <div
            matRipple
            class="mat-menu-item d-flex align-items-center"
            (click)="printAllChecked('tax', true, filterAllWaitPrint())">
            <span>ใบกำกับภาษี (สำเนา)</span>
          </div>
        </mat-menu>
        }
        <!-- ~ -->
        }
      </div>
    </div>
  </div>

  @if(orderList.length) {
  <div class="order-list-wrapper">
    <app-order-log
      [orderList]="orderList"
      [orderSelect]="orderSelect"
      (reset)="getOrderList()"
      (printSngle)="printSngle($event.view, $event.id, $event.isCopy)"></app-order-log>
  </div>
  <div class="pagination-wrapper stick-left">
    <mat-paginator
      [pageIndex]="paginationOrder.data.pageIndex"
      [length]="paginationOrder.data.length"
      [pageSize]="paginationOrder.data.pageSize"
      [pageSizeOptions]="paginationOrder.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationOrder.setFromPaginator($event);getOrderList()">
    </mat-paginator>
  </div>
  } @else {
  <div class="order-not-found-wrapper">
    <img
      src="/assets/images/icons/img-order-not-found.svg"
      alt="Order Not Found"
      class="img-order-not-found" />

    <div class="order-not-found-text">ไม่พบออเดอร์ @if (totalOrder == 0) { กรุณาสร้างออเดอร์ }</div>
    @if (totalOrder == 0) {
    <button matRipple [routerLink]="['/order/create']" class="btn btn-outline-secondary">
      <span class="icon material-symbols-outlined fill">add_circle</span>
      สร้างออเดอร์
    </button>
    }
  </div>
  }
</div>
