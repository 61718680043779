import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class TierService {
  constructor(private service: Service) {}
  private pathTier = '/v1/tiers'

  getTierList(payload: any): Observable<any> {
    return this.service.post(`${this.pathTier}/query`, payload)
  }
  getTier(id: number): Observable<any> {
    return this.service.get(`${this.pathTier}/${id}`)
  }
  addTier(payload: any): Observable<any> {
    return this.service.post(`${this.pathTier}`, payload)
  }
  updateTier(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathTier}/${id}`, payload)
  }
  deleteTier(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathTier}/${id}`, payload)
  }
}
