<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ระดับสมาชิก</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="tabs-modal-tier-wrapper">
      <mat-tab-group
        animationDuration="0ms"
        [disableRipple]="true"
        [selectedIndex]="tab"
        (selectedIndexChange)="changeTab($event)"
      >
        @for (item of tabList; track item; let i = $index) {
        <mat-tab label="{{item.name}}">
          <div class="tabs-content-wrapper">
            <div class="mt-2">สิทธิพิเศษ {{item.name}}</div>
            <div
              class="font-14"
              [innerHtml]="sanitizer.bypassSecurityTrustHtml(item.description || '')"
            ></div>
          </div>
        </mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">ปิด</button>
  </div>
</mat-dialog-actions>
