<div class="search-filter-wrapper">
  <app-input-search
    [control]="filterTableCustomerOrder.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterTableCustomerOrder.confirm(); onConfirm.emit()"></app-input-search>

  <button (click)="openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
</div>

@if (filterTableCustomerOrder.isFilter) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterTableCustomerOrder.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @if (filterTableCustomerOrder.startEndDateFormatPayment) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTableCustomerOrder.startEndDateFormatPayment }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableCustomerOrder.removeStartEndDatePayment(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } @if (filterTableCustomerOrder.banksText) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTableCustomerOrder.banksText }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableCustomerOrder.removeBankId(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } @if (filterTableCustomerOrder.startEndDateFormatClosed) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTableCustomerOrder.startEndDateFormatClosed }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableCustomerOrder.removeStartEndDateClosed(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } @if (filterTableCustomerOrder.trackingsText) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTableCustomerOrder.trackingsText }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableCustomerOrder.removeTrackingId(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } @if (filterTableCustomerOrder.statussText) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTableCustomerOrder.statussText }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableCustomerOrder.removeStatusId(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
  </div>
</div>
}
