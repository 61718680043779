import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../../components/date-picker-range/date-picker-range'
import { SelectComponent } from '../../../../../components/select/select'
import { imports } from '../../../../../imports'
import { ProductStockService, SupplierService } from '../../../../../services'
import { Loading } from '../../../../../globals'
import { forkJoin } from 'rxjs'
import { InputNumberComponent } from '../../../../../components/input-number/input-number'
import { Ascending, ValidateForm, setErrorForm } from '../../../../../helpers'

@Component({
  selector: 'app-modal-add-stock',
  templateUrl: './modal-add-stock.html',
  styleUrls: ['./modal-add-stock.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputNumberComponent,
    SelectComponent,
  ],
})
export class ModalAddStockComponent {
  product_list = new FormArray<FormGroup>([])
  form = new FormGroup({
    supplier_id: new FormControl(null),
    product_list: this.product_list,
  })

  list = {
    supplier: <any[]>[],
  }

  get products() {
    return this.data.product.details.length ? this.data.product.details : [this.data.product]
  }

  constructor(
    public dialogRef: MatDialogRef<ModalAddStockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productStockService: ProductStockService,
    public supplierService: SupplierService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    const apis = [this.supplierService.getSupplierList({})]

    this.loading.start()
    forkJoin(apis).subscribe(([resSupplier]: any) => {
      if (resSupplier) {
        if (!resSupplier.is_error) {
          this.list.supplier = Ascending(resSupplier.data, 'name')
        } else {
          this.customSnackBar.fail(resSupplier.message)
        }
      }

      for (const product of this.products) {
        const newForm = new FormGroup({
          product: new FormControl(product),
          stock: new FormControl(0, [Validators.required, Validators.max(1000000)]),
          cost_price: new FormControl(null, [Validators.max(1000000000)]),
        })
        this.product_list.push(newForm)
      }

      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onSave(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const value = this.form.getRawValue()
    const product_list = this.product_list.getRawValue()
    const payload = {
      supplier_id: value.supplier_id,
      details: product_list.map((value: any) => {
        return {
          product_id: value.product.product_id || value.product.id,
          product_detail_id: value.product.details ? null : value.product.id,
          cost_price: value.cost_price || 0,
          stock: value.stock,
          total_cost_price: value.cost_price || 0,
          is_up: true,
        }
      }),
    }

    // return console.log(payload)

    this.loading.start()
    this.productStockService.addProductStock(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.dialogRef.close(true)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }
}
