<mat-form-field appearance="outline" class="input">
  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [min]="min"
    [max]="max" />
  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <img
      src="/assets/images/icons/icon-date-picker.svg"
      matDatepickerToggleIcon
      class="iconSuffix" />
  </mat-datepicker-toggle>
  <mat-datepicker #picker>
    @if (showClear) {
    <mat-date-range-picker-actions
      [spellcheck]="false"
      [nonce]="true"
      [inert]="true"
      [contentEditable]="true"
      [autocapitalize]="true">
      <div class="d-flex justify-content-between w-100">
        <button
          matRipple
          [disabled]="disabled"
          class="btn btn-outline-secondary"
          (click)="formControl.reset();picker.close();onChange.emit()">
          ล้าง
        </button>
        <button
          matRipple
          [disabled]="disabled"
          class="btn btn-primary"
          matDateRangePickerApply
          (click)="onChange.emit()">
          ตกลง
        </button>
      </div>
    </mat-date-range-picker-actions>
    }
  </mat-datepicker>

  @if (control.invalid) {
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  }
</mat-form-field>
