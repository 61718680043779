<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ระดับส่วนลดกะรัต</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <b class="color--brand-blue font-18"> สะสมกะรัตวันนี้แลกรับส่วนลดสุดคุ้ม </b>
    <div class="color--neutral-500 font-14 mb-3">
      รับ 1 กะรัตเมื่อมียอดสั่งซื้อทุกๆ {{ formatNoDecimal(data.carat.total_order_amount) }} บาท {{
      dateText }}
    </div>
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr>
          <th width="266" class="text-center">กะรัต</th>
          <th width="266" class="text-center">ส่วนลด (บาท)</th>
        </tr>
      </thead>

      <tbody>
        @for (item of data.carat.discounts; track $index) {
        <tr>
          <td class="d-flex align-items-center justify-content-center">
            <span class="icon material-symbols-outlined fill color--brand-blue me-1">diamond</span>
            {{ item.carat }}
          </td>
          <td class="text-center">{{ formatNoDecimal(item.discount_price) }}</td>
        </tr>
        }
      </tbody>
    </table>

    @if(!data.carat.discounts.length) {
    <div class="data-not-found-wrapper">ไม่พบรายการกะรัต</div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onClose()">ปิด</button>
  </div>
</mat-dialog-actions>
