import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router'
import { SettingMenuComponent } from '../../components/setting-menu/setting-menu'
import { imports } from '../../imports'
import { SelectComponent } from '../../components/select/select'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Profile } from '../../globals'
import { ReportProductComponent } from './product/product'
import { ReportUserComponent } from './user/user'
import { ReportSalesComponent } from './sales/sales'
import { ReportCustomerRankingComponent } from './customer-ranking/customer-ranking'

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [
    ...imports,
    RouterOutlet,
    SettingMenuComponent,
    SelectComponent,
    ReportProductComponent,
    ReportUserComponent,
    ReportSalesComponent,
    ReportCustomerRankingComponent,
  ],
  templateUrl: './report.html',
  styleUrls: ['./report.scss'],
})
export class ReportComponent implements OnInit {
  report = new FormControl(1)
  listReport = [
    {
      value: 1,
      name: 'รายงานยอดขาย',
    },
    {
      value: 2,
      name: 'รายการสินค้า',
    },
    {
      value: 3,
      name: 'รายงานผู้ใช้งาน',
    },
    {
      value: 4,
      name: 'รายงานอันดับลูกค้า',
    },
  ]

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile
  ) {}

  ngOnInit(): void {}

  listChoose(id: any) {
    return this.listReport.find((item: any) => item.value == id)
  }

  log(log: any) {
    console.log(log)
  }
}
