import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { imports } from '../../../../../../imports'

@Component({
  selector: 'app-checkbox-access-role',
  standalone: true,
  imports: [...imports, MatTabsModule, MatCheckboxModule],
  templateUrl: './checkbox-access-role.html',
  styleUrls: ['./checkbox-access-role.scss'],
})
export class RoleComponent implements OnInit {
  @Input() isEditAccess = false
  @Input() dataCheckbox: any = []

  @Output() onChange = new EventEmitter()

  ngOnInit(): void {}
}
