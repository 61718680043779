<div class="alert-message alert-{{ type }} w-100">
  <img src="/assets/images/icons/icon-alert-{{ type }}.svg" />
  <div class="body-message">
    {{ message }}
    @if (subMessage) {
    <ng-container>
      <br />
      <span class="body-message-sub"> {{ subMessage }} </span>
    </ng-container>
    }
  </div>
  <div class="cross" (click)="onClose.emit(false)">
    <img src="/assets/images/icons/icon-error-cross.svg" />
  </div>
</div>
