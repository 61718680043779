<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="container gx-0">
      <div class="row gy-2">
        <div class="col-12">
          <label
            class="label"
            [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid"
          >
            ชื่อหมวดหมู่สินค้า
            <span class="text-danger">*</span>
          </label>
          <app-input [control]="form.controls['name']">
            @if (form.controls['name'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (form.controls['name'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            } @else if (form.controls['name'].hasError('formApi')) {
            <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
            }
          </app-input>
        </div>

        <div class="col-12">
          <label class="label"> สถานะการใช้งาน </label>
          <div class="mt-2">
            <mat-slide-toggle
              hideIcon="true"
              color="primary"
              class="slide-toggle"
              [formControl]="form.controls.is_active"
            >
              ใช้งาน
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
  </div>
</mat-dialog-actions>
