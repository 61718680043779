<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="filter-item-wrapper">
      <div class="filter-title">ระดับสมาชิก</div>

      <div class="filter-checkbox-wrapper">
        @for (active of filterTableCustomer.list.tier; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableCustomer.isTierChecked(active.id)"
          (change)="filterTableCustomer.tierChange(active.id)"
        >
          {{ active.name }}
        </mat-checkbox>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onReset()">ล้างค่า</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">นำไปใช้</button>
  </div>
</mat-dialog-actions>
