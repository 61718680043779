<div>
  <mat-form-field
    appearance="outline"
    class="input {{ remove_bottom && 'remove-bottom'}}"
    hintLabel="{{hintLabel}}">
    @if (value !== undefined) {
    <input matInput [value]="value" placeholder="{{placeholder || ''}}" [disabled]="true" />
    } @else {
    <input matInput [formControl]="formControl" class="d-none" />
    }
    <input
      matInput
      placeholder="{{placeholder || ''}}"
      (keyup.enter)="onEnter.emit()"
      #inputElement
      [disabled]="control.disabled" />

    @if (ready && control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    } @if (unit) {
    <div matSuffix class="unit">{{unit}}</div>
    }
  </mat-form-field>
</div>
