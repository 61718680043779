<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">ยกเลิกปิดรายการ</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">คุณต้องการยกเลิกปิดรายการใช่หรือไม่</div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ไม่ทำต่อ</button>
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">ยกเลิกปิดรายการ</button>
  </div>
</mat-dialog-actions>
