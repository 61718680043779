import { Component, Input } from '@angular/core'
import { imports } from '../../../imports'

@Component({
  selector: 'app-payment-status-log[stepCompleted]',
  standalone: true,
  imports: [...imports],
  templateUrl: './payment-status-log.html',
  styleUrls: ['./payment-status-log.scss'],
})
export class PaymentStatuslogComponent {
  @Input() stepCompleted!: {
    isCreate: boolean
    isWaitPaid: boolean
    isVerify: boolean
    isPreparing: boolean
    isShipped: boolean
  }
}
