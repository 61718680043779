import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker'
import { provideMomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core'
import 'moment/locale/th'
import 'moment/locale/en-gb'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
}

@Component({
  selector: 'app-date-picker[control]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatInputModule,
    MatDatepickerModule,
  ],
  providers: [provideMomentDateAdapter(MY_FORMATS)],
  templateUrl: './date-picker.html',
  styleUrls: ['./date-picker.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = 'DD/MM/YYYY'
  @Input() label = ''
  @Input() disabled = false
  @Input() min: any
  @Input() max: any
  @Input() showClear = false

  @Output() onChange = new EventEmitter()

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  constructor(
    private _adapter: DateAdapter<any>,
    private _intl: MatDatepickerIntl,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {}

  ngOnInit() {
    this._locale = 'th'
    this._adapter.setLocale(this._locale)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  // french() {
  //   this._locale = 'th';
  //   this._adapter.setLocale(this._locale);
  //   this.updateCloseButtonLabel('Fermer le calendrier');
  // }

  // updateCloseButtonLabel(label: string) {
  //   this._intl.closeCalendarLabel = label;
  //   this._intl.changes.next();
  // }
}
