import { Component, Inject, Injectable, OnInit, inject } from '@angular/core'
import {
  MAT_SNACK_BAR_DATA,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarHorizontalPosition,
  MatSnackBarLabel,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'
import { MatButtonModule } from '@angular/material/button'

const horizontalPosition: MatSnackBarHorizontalPosition = 'end'
const verticalPosition: MatSnackBarVerticalPosition = 'top'
const duration = 5

export const SnackBarOptionSuccess = {
  horizontalPosition,
  verticalPosition,
  duration: duration * 1000,
  panelClass: ['snack-bar-success-wrapper'],
}

export const SnackBarOptionFail = {
  horizontalPosition,
  verticalPosition,
  duration: duration * 1000,
  panelClass: ['snack-bar-fail-wrapper'],
}

@Injectable({ providedIn: 'root' })
export class MixSnackbar {
  iconFail = '/assets/images/icons/icon-fail.svg'

  constructor() {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result && typeof reader.result == 'string') {
          this.iconFail = reader.result
        }
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', this.iconFail)
    xhr.responseType = 'blob'
    xhr.send()
  }
}

@Component({
  selector: 'app-snack-bar-success',
  templateUrl: 'snackbar-success.html',
  styleUrl: 'snackbar.scss',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction],
})
export class SnackBarSuccessComponent {
  snackBarRef = inject(MatSnackBarRef)

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: any,
    public mixSnackbar: MixSnackbar
  ) {}
}

@Component({
  selector: 'app-snack-bar-fail',
  templateUrl: 'snackbar-fail.html',
  styleUrl: 'snackbar.scss',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction],
})
export class SnackBarFailComponent {
  snackBarRef = inject(MatSnackBarRef)

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: any,
    public mixSnackbar: MixSnackbar
  ) {}
}

@Injectable({ providedIn: 'root' })
export class CustomSnackBar {
  constructor(private _snackBar: MatSnackBar) {}

  success(message: string, options = {}) {
    if (!this._snackBar) return
    this._snackBar.openFromComponent(SnackBarSuccessComponent, {
      data: message,
      ...SnackBarOptionSuccess,
      ...options,
    })
  }

  fail(message: string = '', options = {}) {
    if (!this._snackBar) return
    this._snackBar.openFromComponent(SnackBarFailComponent, {
      data: message || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งภายหลัง',
      ...SnackBarOptionFail,
      ...options,
    })
  }

  failSave(message: string = '', options = {}) {
    if (!this._snackBar) return
    this.fail(message || 'บันทึกข้อมูลไม่สำเร็จ', options)
  }
}
