import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private service: Service) {}
  private pathProduct = '/v1/products'

  getProductList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/query`, payload)
  }
  getProduct(id: number): Observable<any> {
    return this.service.get(`${this.pathProduct}/${id}`)
  }
  addProduct(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}`, payload)
  }
  updateProduct(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathProduct}/${id}`, payload)
  }
  deleteProduct(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathProduct}/${id}`, payload)
  }
  deleteProductBulk(payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathProduct}/bulk-delete`, payload)
  }
  checkImportProduct(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/pre-import`, payload)
  }
  importProduct(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/import`, payload)
  }
  exportProduct(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathProduct}/export`, payload)
  }
  templateProduct(payload: any): Observable<any> {
    return this.service.getBlob(`${this.pathProduct}/template`, payload)
  }

  getProductOrderList(id: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/${id}/orders`, payload)
  }
  exportProductOrder(id: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathProduct}/${id}/orders/export`, payload)
  }
  getProductForOrderList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/order/query`, payload)
  }
  exportProductOrderReturn(id: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathProduct}/${id}/order-return-histories/export`, payload)
  }

  getProductPromotionList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProduct}/promotion/query`, payload)
  }
}
