<div id="text-editor" class="text-editor">
  <div class="editor">
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" [customMenuRef]="customMenu">
    </ngx-editor-menu>
    <ng-template #customMenu>
      <div class="NgxEditor__Seperator"></div>
    </ng-template>
    <ngx-editor
      [editor]="editor"
      [(ngModel)]="html"
      (ngModelChange)="formControl.setValue($event)"
      [placeholder]="placeholder">
    </ngx-editor>
  </div>

  <!-- @if (control.invalid) {
  <div class="editor" [class.is-error]="control.invalid">
    <ngx-editor [editor]="editor" [(ngModel)]="html" [placeholder]="placeholder"> </ngx-editor>
  </div>

  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  } -->
</div>
