import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ProductStockService {
  constructor(private service: Service) {}
  private pathProductStock = '/v1/product-stocks'

  getProductStockList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductStock}/query`, payload)
  }
  getProductStock(id: number): Observable<any> {
    return this.service.get(`${this.pathProductStock}/${id}`)
  }
  addProductStock(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductStock}`, payload)
  }
  updateProductStock(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathProductStock}/${id}`, payload)
  }
  deleteProductStock(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathProductStock}/${id}`, payload)
  }

  private pathProductStockLog = '/v1/product-stock-logs'

  getProductStockLogList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductStockLog}/query`, payload)
  }
  getProductStockLog(payload: any): Observable<any> {
    return this.service.get(`${this.pathProductStockLog}`, payload)
  }
  exportProductStockLog(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathProductStockLog}/export`, payload)
  }
}
