import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { imports } from '../../../../../imports'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { Loading } from '../../../../../globals'
import { FileService, PublicService } from '../../../../../services'

@Component({
  selector: 'app-modal-slip',
  templateUrl: './modal-slip.html',
  styleUrls: ['./modal-slip.scss'],
  standalone: true,
  imports: [...imports, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose],
})
export class ModalSlipComponent {
  url = ''

  constructor(
    public dialogRef: MatDialogRef<ModalSlipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public fileService: FileService
  ) {
    this.getPayment()
  }

  getPayment() {
    this.loading.start()
    this.fileService.signUrl(this.data.item.file_id).subscribe((res: any) => {
      if (!res.is_error) {
        this.url = res.data
      } else {
        this.customSnackBar.fail(res.message)
      }
      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }
}
