<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="order-detail-wrapper">
      <div class="order-number">เลขที่ใบสั่งซื้อ: {{ data.item.order_no }}</div>
      <div class="detail-text">
        รายละเอียดการโอนอยู่ในลิงก์ ลูกค้าสามารถติดตามออเดอร์<br />และชำระเงินได้ทางลิงก์นี้
        <a class="detail-link" target="_blank" [href]="linkUrl">{{ linkUrl }}</a>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button
      matRipple
      class="btn btn-primary btn-md"
      [cdkCopyToClipboard]="textCopy"
      (click)="onConfirm()">
      คัดลอก
    </button>
  </div>
</mat-dialog-actions>
