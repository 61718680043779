<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content py-2">
    <label
      class="label"
      [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid"
    >
      ชื่อสิทธิ์ผู้ใช้งาน
      <span class="text-danger">*</span>
    </label>
    <app-input [control]="form.controls['name']">
      @if (form.controls['name'].hasError('required')) {
      <ng-container error>กรุณากรอกข้อมูล</ng-container>
      }@else if (form.controls['name'].hasError('formApi')) {
      <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
      }
    </app-input>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button class="btn btn-outline-secondary btn-md" matRipple (click)="onClose()">ยกเลิก</button>
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">{{data.title}}</button>
  </div>
</mat-dialog-actions>
