import { Component, ViewChild } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'
import { TabUserManageComponent } from './tabs/tab-user-manage/tab-user-manage'
import { TabRoleManageComponent } from './tabs/tab-role-manage/tab-role-manage'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { ACTION_ACCESS, APP_PERMISSION, Profile } from '../../../globals'
import { imports } from '../../../imports'
@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [
    ...imports,
    TabUserManageComponent,
    TabRoleManageComponent,
    MatTabsModule,
    ModalLeaveComponent,
  ],
  templateUrl: './user-management.html',
  styleUrls: ['./user-management.scss'],
})
export class UserManagementComponent {
  @ViewChild('roleManageComponent') roleManageComponent?: TabRoleManageComponent

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  tab = 0
  isEditAccess = false

  get isPermissionUser() {
    return this.profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT])
  }

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile
  ) {}

  ngOnInit() {
    if (this.isPermissionUser) {
      this.route.queryParams.subscribe((params: any) => {
        if (params.tab) {
          this.tab = params.tab
        }
      })
    }
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)

    // this.openModalLeave()
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  openModalLeave(): void {
    const dialogRef = this.dialog.open(ModalLeaveComponent, {})

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('done', result)
      } else {
        console.log('close')
      }
    })
  }
}
