import { Component, OnInit, SimpleChanges } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { imports } from '../../../imports'
import { InputComponent } from '../../../components/input/input'
import { TextareaComponent } from '../../../components/text-area/text-area'
import {
  EmailReg,
  ValidateForm,
  setErrorForm,
  ValidateArrayForm,
  Pagination,
  productImage,
  formatPrice,
  formatPriceAutoDecimal,
  EnumMarketplaceShop,
  MARKETPLACE_SHOP,
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  MARKETPLACE_SHOP_STORAGE,
  Moment,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { ModalLeaveComponent } from '../../../components/modal-leave/modal-leave'
import { MatDialog } from '@angular/material/dialog'
import { CompanyProfileService, CaratService, MaketplaceService } from '../../../services'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { forkJoin } from 'rxjs'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop'
import { MatTabsModule } from '@angular/material/tabs'
import { StatusUserComponent } from '../../../components/status-user/status-user'
import { flush } from '@angular/core/testing'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import { ModalAdjustStockComponent } from './modal-adjust-stock/modal-adjust-stock'
import { ModalConnectComponent } from './modal-connect/modal-connect'
import { ModalUnconnectComponent } from './modal-unconnect/modal-unconnect'
import { ModalProductComponent } from './modal-product/modal-product'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatPaginatorModule } from '@angular/material/paginator'
import { ModalConnectProductComponent } from './modal-connect-product/modal-connect-product'

@Component({
  selector: 'app-carats',
  standalone: true,
  imports: [
    ...imports,
    InputComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    ModalLeaveComponent,
    InputNumberComponent,
    CdkDrag,
    CdkDropList,
    MatTabsModule,
    StatusUserComponent,
    MatSlideToggle,
    ModalAdjustStockComponent,
    ModalConnectComponent,
    ModalUnconnectComponent,
    ModalProductComponent,
    MatTooltipModule,
    MatPaginatorModule,
  ],
  templateUrl: './marketplace.html',
  styleUrls: ['./marketplace.scss'],
})
export class MarketplaceComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly productImage = productImage
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly MARKETPLACE_SHOP = MARKETPLACE_SHOP
  readonly Moment = Moment

  tab = 0

  maketplace = {
    base_action: {
      view: false,
      add: false,
      edit: false,
      delete: false,
      import: false,
      export: false,
    },
    id: 1,
    shopee_name: '',
    shopee_is_connected: false,
    shopee_is_stock_connected: false,
    shopee_stock_updated_at: null,
    shopee_is_order_connected: false,
    shopee_order_updated_at: null,
    lazada_name: '',
    lazada_is_connected: false,
    lazada_is_stock_connected: false,
    lazada_stock_updated_at: null,
    lazada_is_order_connected: false,
    lazada_order_updated_at: null,
  }

  // lazada_code = ''
  shopee_is_connected: boolean = false
  shopee_is_stock_connected = new FormControl(false)
  shopee_is_order_connected = new FormControl(false)

  lazada_is_connected: boolean = false
  lazada_is_stock_connected = new FormControl(false)
  lazada_is_order_connected = new FormControl(false)

  readonly paginationShopee = new Pagination({
    sortName: 'id',
    sortBy: 'desc',
  })
  shopeeList: any[] = []

  readonly paginationLazada = new Pagination({
    sortName: 'id',
    sortBy: 'desc',
  })
  lazadaList: any[] = []

  get productList() {
    if (this.tab === 0) {
      return this.shopeeList
    } else {
      return this.lazadaList
    }
  }

  get lazadaConnected() {
    return this.lazada_is_connected
  }

  get shopeeConnected() {
    return this.shopee_is_connected
  }

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public route: ActivatedRoute,
    public Router: Router,
    public maketplaceService: MaketplaceService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }

      if (params.code) {
        setLocalStorage(MARKETPLACE_SHOP_STORAGE.LAZADA, params.code)
        this.getMarketplace(MARKETPLACE_SHOP.LAZADA)
      } else {
        this.getMarketplace()
      }
    })
    this.getList()
  }

  getMarketplace(connect?: EnumMarketplaceShop) {
    this.loading.start()
    this.maketplaceService.getMaketplace().subscribe(async res => {
      if (!res.is_error) {
        this.maketplace = res.data

        this.shopee_is_connected = this.maketplace.shopee_is_connected
        this.shopee_is_stock_connected.setValue(this.maketplace.shopee_is_stock_connected)
        this.shopee_is_order_connected.setValue(this.maketplace.shopee_is_order_connected)

        const lazada_code = res.data.lazada_code || getLocalStorage(MARKETPLACE_SHOP_STORAGE.LAZADA)
        if (lazada_code) {
          setLocalStorage('lazada_code', lazada_code)
        }
        this.lazada_is_connected = this.maketplace.lazada_is_connected && !!lazada_code
        this.lazada_is_stock_connected.setValue(this.maketplace.lazada_is_stock_connected)
        this.lazada_is_order_connected.setValue(this.maketplace.lazada_is_order_connected)

        if (connect) {
          const payload = this.setPayloadMarketplace({
            shopee_is_connected: this.shopee_is_connected,
            lazada_is_connected:
              connect == MARKETPLACE_SHOP.LAZADA ? true : this.lazada_is_connected,
          })

          this.updateMarketplace(payload)
          this.navigateToTab(this.tab)
        }
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  formatUpdate(dateText: string) {
    return Moment(dateText).format('DD-MM-YYYY, HH:mm')
  }

  getList() {
    if (this.tab === 0) {
      this.getShopeeList()
    } else {
      this.getLazadaList()
    }
  }

  getShopeeList() {
    const pagination = this.paginationLazada.get()
    const payload = {
      ...pagination,
    }

    this.loading.start()
    // const res = resShopee
    this.maketplaceService.getShopeeList(payload).subscribe(res => {
      if (!res.is_error) {
        this.shopeeList = res.data.records
        this.paginationShopee.setFromResponse(res.data)
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  getLazadaList() {
    const pagination = this.paginationLazada.get()
    const payload = {
      ...pagination,
    }

    this.loading.start()
    this.maketplaceService.getLazadaList(payload).subscribe(res => {
      if (!res.is_error) {
        this.lazadaList = res.data.records
        this.paginationLazada.setFromResponse(res.data)
      } else {
        this.customSnackBar.fail()
      }

      this.loading.stop()
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)

    this.getList()
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }

  toView() {
    this.router.navigate(['/setting/carats/view'])
  }

  connectShopee(): void {
    this.shopee_is_connected = true
  }

  connectLazada(): void {
    this.loading.start()
    this.maketplaceService.connectLazada().subscribe(res => {
      if (!res.is_error) {
        location.href = res.data
      } else {
        this.customSnackBar.fail()
      }
      this.loading.stop()
    })
  }

  openModalAdjustStock(title: string, item: any): void {
    const dialogRef = this.dialog.open(ModalAdjustStockComponent, {
      data: {
        title,
        shop: this.tab === 0 ? MARKETPLACE_SHOP.SHOPEE : MARKETPLACE_SHOP.LAZADA,
        item,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.tab === 0) {
          this.getShopeeList()
        } else {
          this.getLazadaList()
        }
      }
    })
  }

  openModalAddProduct(title: any, type: any) {
    const dialogRef = this.dialog.open(ModalProductComponent, {
      disableClose: true,
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        title,
        type,
        shop: this.tab === 0 ? MARKETPLACE_SHOP.SHOPEE : MARKETPLACE_SHOP.LAZADA,
        products: this.productList,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.tab === 0) {
          this.getShopeeList()
        } else {
          this.getLazadaList()
        }
      }
    })
  }

  setPayloadMarketplace(
    addPayload = {
      shopee_is_connected: this.shopee_is_connected,
      lazada_is_connected: this.lazada_is_connected,
    }
  ) {
    return {
      shopee_name: this.maketplace.shopee_name,
      shopee_is_connected: addPayload.shopee_is_connected,
      shopee_is_stock_connected:
        this.shopee_is_stock_connected.value && !!addPayload.shopee_is_connected,
      shopee_is_order_connected:
        this.shopee_is_order_connected.value && !!addPayload.shopee_is_connected,
      lazada_name: this.maketplace.lazada_name,
      lazada_is_connected: addPayload.lazada_is_connected,
      lazada_is_stock_connected: this.lazada_is_stock_connected.value && !!this.lazada_is_connected,
      lazada_is_order_connected: this.lazada_is_order_connected.value && !!this.lazada_is_connected,
    }
  }

  openModalConnect(shop: EnumMarketplaceShop): void {
    const dialogRef = this.dialog.open(ModalConnectComponent, {
      data: {
        shop,
        payload: this.setPayloadMarketplace(),
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getMarketplace()
      }
    })
  }

  openModalUnconnectAccount(title: any, shop: EnumMarketplaceShop): void {
    const dialogRef = this.dialog.open(ModalUnconnectComponent, {
      data: {
        title,
        detail: `หากคุณยกเลิกการเชื่อมต่อ สต๊อกและออเดอร์จะไม่เชื่อมต่อกับ ${shop} อีก`,
      },
    })

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const payload = this.setPayloadMarketplace({
          shopee_is_connected: shop == MARKETPLACE_SHOP.SHOPEE ? false : this.shopee_is_connected,
          lazada_is_connected: shop == MARKETPLACE_SHOP.LAZADA ? false : this.lazada_is_connected,
        })

        const updateSuccess = await this.updateMarketplace(payload)
        console.log('updateSuccess', updateSuccess)

        if (updateSuccess) {
          if (shop == MARKETPLACE_SHOP.LAZADA) {
            removeLocalStorage(MARKETPLACE_SHOP_STORAGE.LAZADA)
          }
        }
      }
    })
  }

  async updateMarketplace(
    payload = {
      shopee_is_connected: this.shopee_is_connected,
      lazada_is_connected: this.lazada_is_connected,
    }
  ) {
    return await new Promise(rev => {
      this.loading.start()
      this.maketplaceService.updateMaketplace(payload).subscribe(res => {
        if (!res.is_error) {
          this.getMarketplace()
          rev(true)
        } else {
          this.customSnackBar.fail()
          rev(false)
        }
        this.loading.stop()
      })
    })
  }

  openModalConnectProduct(item: any): void {
    const dialogRef = this.dialog.open(ModalConnectProductComponent, {
      data: {
        shop: this.tab === 0 ? MARKETPLACE_SHOP.SHOPEE : MARKETPLACE_SHOP.LAZADA,
        item,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.tab === 0) {
          this.getShopeeList()
        } else {
          this.getLazadaList()
        }
      }
    })
  }

  openModalUnconnectProduct(title: any, item: any): void {
    const dialogRef = this.dialog.open(ModalUnconnectComponent, {
      data: {
        title,
        detail: `หากคุณยกเลิกการเชื่อมต่อสินค้า สต๊อกจะไม่อัปเดตไป ${
          this.tab === 0 ? MARKETPLACE_SHOP.SHOPEE : MARKETPLACE_SHOP.LAZADA
        } อีก`,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const api =
          this.tab === 0
            ? this.maketplaceService.disconnectShopeeProduct(item.id)
            : this.maketplaceService.disconnectLazadaProduct(item.id)

        this.loading.start()
        api.subscribe(res => {
          if (!res.is_error) {
            if (this.tab === 0) {
              this.getShopeeList()
            } else {
              this.getLazadaList()
            }
          } else {
            this.customSnackBar.fail()
          }
          this.loading.stop()
        })
      }
    })
  }
}
