@if (type == 'edit') {
<input
  type="file"
  [id]="uuid"
  [accept]="acceptFileTypes.toString()"
  class="input-upload"
  #fileUpload
  (change)="onChangeUpload(fileUpload)"
/>

@if (logoUrl) {
<div class="upload-image-box-inner d-flex justify-content-start align-items-center">
  @if (logoUrl) {
  <button class="btn-close-img" matRipple (click)="isDelete()">
    <span class="font-14 icon-white material-symbols-outlined"> close </span>
  </button>
  }

  <label
    class="upload-image-inner {{preview == 'square' && 'square'}}"
    [for]="uuid"
    (dragover)="dragOver($event)"
    (drop)="drop($event)"
  >
    <img alt="Uploaded Image" class="upload-image" [src]="logoUrl" />
  </label>
</div>

} @else {
<div class="drop-wrapper d-flex justify-content-start align-items-center">
  <label
    class="upload-file-wrapper d-flex flex-column justify-content-center align-items-center py-4 my-sm-3 mb-2"
    [for]="uuid"
    (dragover)="dragOver($event)"
    (drop)="drop($event)"
  >
    <span class="icon-16 icon-blue material-symbols-outlined fill"> add_circle </span>
  </label>
</div>
} } @else {
<ng-container>
  <!-- Uploaded Image -->
  <div class="upload-image-box-inner d-flex justify-content-start align-items-center">
    <div class="upload-image-inner {{preview == 'square' && 'square'}}">
      <img alt="Uploaded Image" class="upload-image" [src]="imgUrl" />
    </div>
  </div>
</ng-container>
}
