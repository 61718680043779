import JsBarcode from 'jsbarcode'

export const getBarcodeBase64 = (text: string) => {
  var canvas = document.createElement('canvas')
  JsBarcode(canvas, text, { format: 'CODE128', displayValue: false })
  return {
    width: canvas.width,
    height: canvas.height,
    base64: canvas.toDataURL('image/png'),
    text,
  }
}
