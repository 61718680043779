import { Component, EventEmitter, Input, Output } from '@angular/core'
import { imports } from '../../../imports'
import { MatDialog } from '@angular/material/dialog'
import { ModalDeleteComponent } from '../../../components/modal-delete/modal-delete'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ModalPaymentComponent } from '../modal-payment/modal-payment'
import { ModalOrderComponent } from '../modal-order/modal-order'
import { ModalViewProductComponent } from '../modal-view-product/modal-view-product'
import { ModalCancelCloseComponent } from '../modal-cancel-close/modal-cancel-close'
import { ModalCloseComponent } from '../modal-close/modal-close'
import { MatMenuModule } from '@angular/material/menu'
import {
  BANK_NONE,
  Moment,
  ORDER_ID,
  channelIcon,
  channelId,
  channelName,
  formatPrice,
  isPayment,
  isPrinted,
  isShipment,
  resetOrderList,
} from '../../../helpers'
import { OrderService } from '../../../services'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { ModalSlipComponent } from '../modal-slip/modal-slip'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-order-log[orderList][orderSelect]',
  standalone: true,
  imports: [imports, MatMenuModule],
  templateUrl: './order-log.html',
  styleUrls: ['./order-log.scss'],
})
export class OrderLogComponent {
  @Input() orderList: any[] = []
  @Input() orderSelect!: Map<any, any>

  @Output() reset = new EventEmitter()
  @Output() printSngle = new EventEmitter<{
    view: 'address' | 'receipt' | 'tax'
    id: any
    isCopy: boolean
  }>()

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly ORDER_ID = ORDER_ID
  readonly channelName = channelName as any
  readonly formatPrice = formatPrice
  readonly channelIcon = channelIcon as any
  readonly channelId = channelId
  readonly isPayment = isPayment
  readonly isPrinted = isPrinted
  readonly isShipment = isShipment
  readonly BANK_NONE = BANK_NONE

  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService,
    public sanitizer: DomSanitizer
  ) {}

  isShowOverdue(item: any) {
    return item.status_id != ORDER_ID.CREATE
  }

  isShowPayment(item: any) {
    return [ORDER_ID.VERIFY].includes(item.status_id)
    // &&
    // item.payment_amount != item.total
  }

  calcDiff(payment_diff_amount: number) {
    return Math.abs(payment_diff_amount)
  }

  confirmAccept(item: any) {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {
        title: 'ยอมรับการแจ้งโอนเงิน',
        detail: `คุณต้องการยอมรับการแจ้งโอนเงินใช่หรือไม่ หากยอมรับสถานะออเดอร์จะเปลี่ยนเป็น
        ชำระเงินแล้ว`,
        confirm: 'ยอมรับ',
      },
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.accept(item.id).subscribe((res: any) => {
          if (!res.is_error) {
            this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
            this.reset.emit()
          } else {
            this.customSnackBar.fail('บันทึกข้อมูลไม่สำเร็จ')
          }
          this.loading.stop()
        })
      }
    })
  }

  confirmReject(item: any) {
    const dialogRefConfirm = this.dialog.open(ModalDeleteComponent, {
      data: {
        title: 'ปฏิเสธการแจ้งโอนเงิน',
        detail: `คุณต้องการปฏิเสธการแจ้งโอนเงินใช่หรือไม่ หากปฏิเสธสถานะออเดอร์จะเปลี่ยนเป็น
        ยังไม่ชำระเงิน`,
        confirm: 'ปฏิเสธ',
      },
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.reject(item.id).subscribe((res: any) => {
          if (!res.is_error) {
            this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
            this.reset.emit()
          } else {
            this.customSnackBar.fail('บันทึกข้อมูลไม่สำเร็จ')
          }
          this.loading.stop()
        })
      }
    })
  }

  confirmDelete(item: any) {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบรายการออเดอร์เลขที่ “${item.order_no}” ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.deleteOrder(item.id).subscribe((res: any) => {
          if (!res.is_error) {
            this.customSnackBar.success('ลบข้อมูลสำเร็จ')
            this.reset.emit()
          } else {
            this.customSnackBar.fail('ลบข้อมูลไม่สำเร็จ')
          }
          this.loading.stop()
        })
      }
    })

    return dialogRefDelete
  }

  openModalSlip(view: 'create' | 'verify' | 'paid', order: any): void {
    const dialogRef = this.dialog.open(ModalSlipComponent, {
      disableClose: true,
      data: {
        view,
        order,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      this.reset.emit()
    })
  }

  openModalPayment(item: any): void {
    this.dialog.open(ModalPaymentComponent, {
      data: {
        item,
      },
    })
  }

  openModalOrder(title: string, item: any): void {
    this.dialog.open(ModalOrderComponent, {
      data: {
        title,
        item,
      },
    })
  }

  openModalViewProduct(title: string, item: any): void {
    this.dialog.open(ModalViewProductComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        title,
        item,
      },
    })
  }

  openModalCancelClose(item: any): void {
    const dialogRef = this.dialog.open(ModalCancelCloseComponent, {
      data: {
        item,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.cancelClosedOrder(item.id).subscribe((res: any) => {
          if (!res.is_error) {
            this.customSnackBar.success('ยกเลิกปิดรายการสำเร็จ')
            this.reset.emit()
          } else {
            this.customSnackBar.fail('ยกเลิกปิดรายการไม่สำเร็จ')
          }
          this.loading.stop()
        })
      }
    })
  }

  openModalClose(title: string, item: any): void {
    const dialogRef = this.dialog.open(ModalCloseComponent, {
      data: { title, item },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customSnackBar.success('ปิดรายการสำเร็จ')
        this.reset.emit()
      }
    })
  }
}
