import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { InputComponent } from '../../../components/input/input'
import { SelectComponent } from '../../../components/select/select'
import { TextareaComponent } from '../../../components/text-area/text-area'
import { imports } from '../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import { FileService, OrderService, PublicService } from '../../../services'
import {
  Ascending,
  BANK_NONE,
  Moment,
  ORDER_ID,
  PAYMENT_TYPE,
  ValidateArrayForm,
  checkUseCarat,
  convertDateToApi,
  formatPrice,
  mergeDateAndTime,
} from '../../../helpers'
import { UploadImageProfileComponent } from '../../../components/upload-image-profile/upload-image-profile'
import { InputNumberComponent } from '../../../components/input-number/input-number'
import { TimePickerComponent } from '../../../components/time-picker/time-picker'
import { MatRadioModule } from '@angular/material/radio'
import { forkJoin } from 'rxjs'

@Component({
  selector: 'app-modal-slip',
  templateUrl: './modal-slip.html',
  styleUrls: ['./modal-slip.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    UploadImageProfileComponent,
    InputNumberComponent,
    TimePickerComponent,
    MatRadioModule,
  ],
})
export class ModalSlipComponent {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly Moment = Moment
  readonly BANK_NONE = BANK_NONE
  readonly ORDER_ID = ORDER_ID

  changed = false
  order: any
  carat = {
    carat: 0,
    total_order_amount: 0,
    value_amount: 0,
    discounts: [],
    isUseCarat: false,
  }
  payment: any
  bank_account_main_id: any
  arrayPayments = new FormArray<FormGroup<{ [k: string]: FormControl }>>([])

  readonly acceptFileTypes = ['image/png', 'image/jpeg', 'image/jpg']

  list = {
    bank_account: <any[]>[],
    bank: <any[]>[],
  }

  get totalPaymentAmount() {
    return this.arrayPayments.controls.reduce((total, form: FormGroup) => {
      return total + form.controls['payment_amount'].value || 0
    }, this.payment?.payment_amount || 0)
  }

  get isAddPayment() {
    return (
      [ORDER_ID.CREATE, ORDER_ID.VERIFY, ORDER_ID.PAID, ORDER_ID.PRINTED].includes(
        this.data.order.status_id
      ) && this.profile.permissions.checkAccess([APP_PERMISSION.ORDER], [ACTION_ACCESS.UPDATE])
    )
  }

  totalProductPrice(onlyIsCarat: boolean): number {
    return this.order.products.reduce((total: number, d: any) => {
      if (onlyIsCarat && !d.is_carat) {
        return total
      }
      return (total += d.total_price || 0)
    }, 0)
  }

  get caratTotal() {
    if (this.carat.isUseCarat) {
      const sumTotalOnlyIsCarat = this.totalProductPrice(true)

      let total =
        this.carat.total_order_amount && sumTotalOnlyIsCarat
          ? Math.floor(sumTotalOnlyIsCarat / this.carat.total_order_amount)
          : 0
      if (total < 1) {
        total = 0
      }

      return {
        total,
      }
    } else {
      return {
        total: 0,
      }
    }
  }

  constructor(
    public profile: Profile,
    public dialogRef: MatDialogRef<ModalSlipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public publicService: PublicService,
    public fileService: FileService,
    public orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.getData()
    this.getPayment()
  }

  getData() {
    const apis = [
      this.orderService.getOrder(this.data.order.id),
      this.publicService.getCarats(),
      this.publicService.getBankAccounts(),
      this.publicService.getBanks(),
    ]

    this.loading.start()
    forkJoin(apis).subscribe(([resOrder, resCarat, resBankAccount, resBank]: any) => {
      this.order = resOrder.data

      if (!resCarat.is_error) {
        this.carat = {
          ...resCarat.data,
          isUseCarat: checkUseCarat(resCarat.data),
        }
      } else {
        this.customSnackBar.fail()
      }

      if (!resBankAccount.is_error) {
        this.list.bank_account = resBankAccount.data

        this.bank_account_main_id = null
        const banckAccountMain = this.list.bank_account.find((d: any) => d.is_primary)
        if (banckAccountMain) {
          this.bank_account_main_id = banckAccountMain.id
        } else if (this.list.bank_account[0]) {
          this.bank_account_main_id = this.list.bank_account[0].id
        }
      } else {
        this.customSnackBar.fail()
      }

      if (!resBank.is_error) {
        this.list.bank = [
          resBank.data.find((b: any) => b.code == BANK_NONE.CODE),
          ...resBank.data.filter((b: any) => b.code != BANK_NONE.CODE),
        ]
      } else {
        this.customSnackBar.fail()
      }

      if (this.data.view == 'create') {
        this.addPayment()
      }

      this.loading.stop()
    })
  }

  getPayment() {
    this.loading.start()
    this.publicService.getPaymentByOrderId(this.data.order.id).subscribe((res: any) => {
      if (!res.is_error) {
        this.payment = res.data
      } else {
        if (this.data.view != 'create') {
          this.customSnackBar.fail(res.message)
        }
      }
      this.loading.stop()
    })
  }

  onDelete(item: any) {
    this.orderService.deleteFilePayment(item.id).subscribe(res => {
      if (!res.is_error) {
        this.getPayment()
      } else {
        this.customSnackBar.failSave('')
      }
      this.loading.stop()
    })
  }

  isValidType(fileType: any) {
    return this.acceptFileTypes.some((type: any) => {
      return type === fileType
    })
  }

  isValidSize(fileSize: number) {
    return fileSize < 30 * Math.pow(1024, 2)
  }

  onClose(): void {
    this.dialogRef.close(this.changed)
  }

  addPayment() {
    const now = Moment()
    let newData = new FormGroup<{ [k: string]: FormControl }>({
      payment: new FormControl(1),
      bank_account_id: new FormControl(this.bank_account_main_id || null, [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      file_id: new FormControl(null, [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      bank_id: new FormControl(null, [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      payment_amount: new FormControl(null, [Validators.required]),
      date: new FormControl(now.toISOString(), [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
      time: new FormControl<string>(now.format('HH:mm:ss'), [
        (control: AbstractControl) => {
          const parent = control.parent as FormGroup
          if (parent && parent.controls['payment'].value == PAYMENT_TYPE.SLIP && !control.value) {
            return {
              required: true,
            }
          }
          return null
        },
      ]),
    })

    this.arrayPayments.push(newData)
  }

  delPayment(index: number) {
    this.arrayPayments.removeAt(index)
  }

  resetPayment(paymentGroup: FormGroup) {
    const value = paymentGroup.getRawValue()
    const now = Moment()
    paymentGroup.reset({
      payment: value.payment,
      bank_account_id: this.bank_account_main_id || null,
      date: now.toISOString(),
      time: now.format('HH:mm:ss'),
    })
  }

  onSave() {
    ValidateArrayForm(this.arrayPayments)
    if (!this.arrayPayments.valid) return

    const valuePayments = this.arrayPayments.getRawValue()

    const payment_date = convertDateToApi(Moment())

    let payment = {}
    if (this.data.view == 'create') {
      payment = {
        order_id: this.data.order.id,
        is_used_promotion: false,
        promotion_id: null,
        used_credit: 0, // จำนวนเครดิตที่จะใช้
        used_carat: 0, // จำนวนกะรัตที่จะใช้
        is_qrcode: true,
        file_id: null, // แนบสลิป
        bank_account_id: null,
        bank_id: null,
        payment_date,
        discount_code_price: this.order.discount_code_price,
        credit_code_price: this.order.credit_code_price,
        carat_code_price: this.order.carat_code_price,
        grand_total_price: this.order.total_price,
        received_carat: this.caratTotal.total, // ยอดกะรัตที่ได้จากการเปิดออเดอร์
        is_consent: true,
        is_tax: this.order.is_tax,
        tax_name: this.order.tax_name,
        tax_id: this.order.tax_id,
        tax_address: this.order.tax_address,
        tax_telephone: this.order.tax_telephone,
        is_tax_same_address: this.order.is_tax_same_address,
      }
    } else {
      payment = this.payment
    }

    const payload: any = {
      ...payment,
      payment_amount: this.totalPaymentAmount,
      payment_date,
      details: [
        ...(this.payment ? this.payment.details : []),
        ...valuePayments.map(value => {
          const detail: any = {
            id: null,
            payment_type: value['payment'], // 1:QrCode 2:เงินสด 3:Credit
            bank_account_id: null,
            bank_id: null,
            payment_date: null,
            payment_amount: 0,
            fee_amount: 0,
            file_id: null,
          }

          const payment_amount = value['payment_amount'] || 0

          if (value['payment'] == 1) {
            detail.file_id = value['file_id'] || null
            detail.bank_id = value['bank_id'] || null
            detail.payment_amount = payment_amount
            detail.bank_account_id = value['bank_account_id'] || null
            if (value['date']) {
              detail.payment_date = convertDateToApi(mergeDateAndTime(value['date'], value['time']))
            }
          } else {
            detail.payment_amount = payment_amount
            detail.payment_date = payment_date
          }
          return detail
        }),
      ],
    }

    // return console.log(payload)

    this.loading.start()
    this.publicService.savePayment(payload).subscribe((res: any) => {
      if (!res.is_error) {
        this.arrayPayments.clear()
        this.getPayment()
        this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
      } else {
        this.customSnackBar.failSave(res.message)
      }
      this.loading.stop()
    })
  }
}
