import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'
import { SettingMenuComponent } from '../setting-menu/setting-menu'

@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [SettingMenuComponent],
  templateUrl: './nav-menu.html',
  styleUrls: ['./nav-menu.scss'],
})
export class NavMenuComponent implements OnInit {
  ngOnInit(): void {}
}
