<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">สินค้าย่อย</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <table class="table table-fixed-column table-detail" [style]="{ 'min-width': '700px' }">
    <thead>
      <tr>
        <th width="100" class="text-center"></th>
        <th width="250">รหัสสินค้า</th>
        <th>ชื่อสินค้า</th>
      </tr>
    </thead>

    <tbody>
      @for (item of productList; track $index) {
      <tr>
        <td class="text-center">
          {{ formatPriceAutoDecimal(paginationProduct.indexData.min + $index + 1) }}
        </td>
        <td>{{ item.code }}</td>
        <td>
          <div>{{ item.name }}</div>
          <div class="gray font-14">{{ item.color }}, {{ item.size }}</div>
        </td>
      </tr>
      }
    </tbody>
  </table>

  @if(!productList.length) {
  <div class="data-not-found-wrapper">ไม่พบสินค้า</div>
  } @else {
  <div class="pagination-wrapper d-flex flex-wrap gap-2">
    <mat-paginator
      [pageIndex]="paginationProduct.data.pageIndex"
      [length]="paginationProduct.data.length"
      [pageSize]="paginationProduct.data.pageSize"
      [pageSizeOptions]="paginationProduct.data.pageSizeOptions"
      (page)="paginationProduct.setFromPaginator($event);pageNumber.setValue(paginationProduct.data.pageIndex + 1);getProductList()"
      aria-label="Select page">
    </mat-paginator>
    <div class="d-flex align-items-center gap-2 mat-mdc-paginator">
      <span>ไปที่หน้า</span>
      <div class="font-12" [style]="{ 'min-width': '70px', 'max-width': '70px' }">
        @if (!rerender) {
        <app-input-number
          [control]="pageNumber"
          placeholder="0"
          [min]="1"
          [max]="paginationProduct.totalPage || 1"
          [remove_bottom]="true"
          (onEnter)="onChangePage()"
          (onblur)="onChangePage(true)">
        </app-input-number>
        }
      </div>
      / {{ formatPriceAutoDecimal(paginationProduct.totalPage) }}
    </div>
  </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex flex-wrap justify-content-between w-100">
    <div class="d-flex align-items-center">
      <span class="font-14 gray-600">
        ทั้งหมด {{ formatPriceAutoDecimal(paginationProduct.data.length) }} รายการ
      </span>
    </div>
    <div class="d-flex align-items-center">
      <button matRipple class="btn btn-primary btn-md" (click)="onClose()">ปิด</button>
    </div>
  </div>
</mat-dialog-actions>
