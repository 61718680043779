import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DatePickerComponent } from '../date-picker/date-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from '../select/select';

@Component({
  selector: 'app-alert-message[type][message]',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent implements OnInit {
  @Input() type: 'success' | 'error' = 'success'
  @Input() message = ''
  @Input() subMessage = ''
  @Output() onClose = new EventEmitter()

  constructor() { }

  ngOnInit(): void { }
}
