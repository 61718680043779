import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../select/select'

@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './text-area.html',
  styleUrls: ['./text-area.scss'],
})
export class TextareaComponent {
  @Input() value: any
  @Input() control: FormControl | AbstractControl = new FormControl('')
  @Input() placeholder = ''
  @Input() rows = ''
  @Input() remainingText = ''
  @Input() maxlength = ''
  @Input() minlength = ''
  @Input() disabled = false

  @Output() onChange = new EventEmitter()
  @Output() onEnter = new EventEmitter()

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  isRemainingText(value: any) {
    return value
      ? value.length + `/${this.remainingText || 100}`
      : 0 + `/${this.remainingText || 100}`
  }
}
