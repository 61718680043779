import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../../components/date-picker-range/date-picker-range'
import { Loading } from '../../../../../globals'
import { imports } from '../../../../../imports'
import { CustomerService } from '../../../../../services'
import { DownloadFileBlob } from '../../../../../helpers'

@Component({
  selector: 'app-modal-upload-file',
  templateUrl: './modal-upload-file.html',
  styleUrls: ['./modal-upload-file.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
  ],
})
export class ModalUploadFileComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalUploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public customerService: CustomerService
  ) {
    // this.filterTableUser.initData()
  }

  file: null | File = null
  isCheckImport = false
  uploadError: any
  fileErrorMessage = ''
  acceptFileTypes: any = ['xlsx']

  members = [{ text: 'Member' }, { text: 'Silver' }, { text: 'Gold' }, { text: 'Platinum' }]

  onClose(): void {
    this.dialogRef.close()
  }

  downloadTemplate() {
    const payload = {}

    this.loading.start()
    this.customerService.templateCustomer(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `template-import-customers.xlsx`)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }

  onChangeUpload(fileUplaod: any) {
    this.isCheckImport = false
    const file = fileUplaod.files[0]

    fileUplaod.value = ''
    this.file = null
    this.uploadError = null
    this.fileErrorMessage = ''
    if (file) {
      const typeName = file.name.split('.')[1]
      if (!this.acceptFileTypes.some((d: any) => d.includes(typeName))) {
        this.fileErrorMessage = 'ไฟล์ต้องเป็นนามสกุล .xlsx กรุณาอัปโหลดไฟล์ใหม่'
        return
      }

      // 1MB = 1048576
      if (file.size > 10485760) {
        this.fileErrorMessage = 'ไฟล์ต้องไม่เกิน 10 MB กรุณาอัปโหลดไฟล์ใหม่'
        return
      }

      this.file = file
    }
  }

  checkImport() {
    if (this.file) {
      const payload = new FormData()
      payload.append('file', this.file)

      this.loading.start()
      this.customerService.checkImportCustomer(payload).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.isCheckImport = true
          } else {
            this.uploadError = res.errors
          }
        }
        this.loading.stop()
      })
    }
  }

  resetUpload() {
    this.file = null
    this.uploadError = null
  }

  onSaveUpload(): void {
    if (this.file) {
      const payload = new FormData()
      payload.append('file', this.file)

      this.loading.start()
      this.customerService.importCustomer(payload).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
            this.dialogRef.close(true)
          } else {
            this.uploadError = res.errors
            if (res.status === 0) {
              this.customSnackBar.failSave()
            }
          }
        }
        this.loading.stop()
      })
    }
  }
}
