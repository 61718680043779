import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FilterReportUser, FilterTablePromotion } from '../../../../globals'
import { imports } from '../../../../imports'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { FormGroup, FormControl } from '@angular/forms'
import { SelectComponent } from '../../../../components/select/select'

@Component({
  selector: 'app-modal-filter-report-user',
  templateUrl: './modal-filter.html',
  styleUrls: ['./modal-filter.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerRangeComponent,
    SelectComponent,
  ],
})
export class ModalFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterReportUser: FilterReportUser
  ) {}

  ngOnInit(): void {
    this.filterReportUser.initData()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterReportUser.resetModalFilter()
  }

  onConfirm(): void {
    this.filterReportUser.confirm()
    this.dialogRef.close(true)
  }
}
