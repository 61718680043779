import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class ProductBrandService {
  constructor(private service: Service) {}
  private pathProductBrand = '/v1/product-brands'

  getProductBrandList(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductBrand}/query`, payload)
  }
  getProductBrand(id: any): Observable<any> {
    return this.service.get(`${this.pathProductBrand}/${id}`)
  }
  addProductBrand(payload: any): Observable<any> {
    return this.service.post(`${this.pathProductBrand}`, payload)
  }
  updateProductBrand(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathProductBrand}/${id}`, payload)
  }
  deleteProductBrand(id: any, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathProductBrand}/${id}`, payload)
  }
}
