<div class="search-filter-wrapper">
  <app-input-search
    [control]="filterTablePromotion.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterTablePromotion.confirm(); onConfirm.emit()"></app-input-search>

  <button (click)="openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
</div>

@if (filterTablePromotion.isFilter) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterTablePromotion.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @for (item of filterTablePromotion.list.promotion_status_ids; track $index) { @if
    (filterTablePromotion.isStatusIdChecked(item.id)) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ item.text }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTablePromotion.removeStatusId(item.id); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } } @if (filterTablePromotion.startEndDateFormat) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ filterTablePromotion.startEndDateFormat }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTablePromotion.removeStartEndDate(); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    }
  </div>
</div>
}
