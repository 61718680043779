import { Component, Inject, OnInit } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatTabsModule } from '@angular/material/tabs'
import { CustomSnackBar } from '../../../../../../components/snackbar/snackbar'
import { StatusUserComponent } from '../../../../../../components/status-user/status-user'
import { MatPaginatorModule } from '@angular/material/paginator'
import { ModalConfirmComponent } from '../../../../../../components/modal-confirm/modal-confirm'
import { ModalLeaveComponent } from '../../../../../../components/modal-leave/modal-leave'
import { InputComponent } from '../../../../../../components/input/input'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../../../../../../components/select/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { RoleService, UserService } from '../../../../../../services'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../../globals'
import {
  DownloadFileBlob,
  EmailReg,
  Pagination,
  ValidateForm,
  setErrorForm,
} from '../../../../../../helpers'
import { forkJoin } from 'rxjs'
import { imports } from '../../../../../../imports'

export const regexName = /^[a-zA-Zก-๚]*$/

@Component({
  selector: 'app-modal-user',
  templateUrl: './modal-user.html',
  styleUrls: ['./modal-user.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatPaginatorModule,
    ModalConfirmComponent,
    ModalLeaveComponent,
    StatusUserComponent,
    InputComponent,
    SelectComponent,
    MatSlideToggleModule,
  ],
})
export class ModalUserComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  tab = 0
  isTabUser: boolean = true

  file: null | File = null
  fileErrorMessage = ''
  uploadError: any
  acceptFileTypes: any = ['xlsx']
  userList: any[] = []
  readonly paginationUser = new Pagination({
    sortName: 'code',
  })

  list = {
    position: [
      { value: 'Admin', text: 'Admin' },
      { value: 'Stock', text: 'Stock' },
      { value: 'Owner', text: 'Owner' },
      { value: 'Account Supervisor', text: 'Account Supervisor' },
      { value: 'Supervisor Stock', text: 'Supervisor Stock' },
    ],
    role: [],
  }

  form = new FormGroup({
    code: new FormControl('', [Validators.required]),
    first_name: new FormControl('', [Validators.required, Validators.pattern(regexName)]),
    last_name: new FormControl('', [Validators.required, Validators.pattern(regexName)]),
    position: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(EmailReg)]),
    tel: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10,10}$/)]),
    role_id: new FormControl<any>(null, [Validators.required]),
    is_active: new FormControl(true),
  })

  dataManage: any

  get userPageList() {
    return this.userList.filter((d: any, i: number) => {
      return i >= this.paginationUser.indexData.min && i <= this.paginationUser.indexData.max
    })
  }

  constructor(
    public dialogRef: MatDialogRef<ModalUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public profile: Profile,
    public roleService: RoleService,
    public userService: UserService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {}

  ngOnInit(): void {
    this.initData(this.data)
  }

  initData(data: any) {
    const apis = [this.roleService.getRoleList({})]

    if (data.type == 'edit') {
      apis.push(this.userService.getUser(this.data.dataManage.id))
    } else {
      this.form.reset({
        is_active: true,
      })
    }

    this.loading.start()
    forkJoin(apis).subscribe(([resRole, resUser]: any) => {
      if (resRole) {
        if (!resRole.is_error) {
          this.list.role = resRole.data
        } else {
          this.customSnackBar.fail(resRole.message)
        }
      }

      if (resUser) {
        if (!resUser.is_error) {
          this.dataManage = resUser.data

          this.form.reset({
            code: this.dataManage.code,
            first_name: this.dataManage.first_name,
            last_name: this.dataManage.last_name,
            position: this.dataManage.position,
            email: this.dataManage.email,
            tel: this.dataManage.tel,
            role_id: this.dataManage.role_id,
            is_active: this.dataManage.is_active,
          })
        } else {
          this.customSnackBar.fail(resUser.message)
        }
      }

      this.loading.stop()
    })
  }

  onClose() {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    if (this.data.type == 'edit') {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
  }

  onSave(): void {
    const isEdit = this.data.type == 'edit'

    const value = this.form.getRawValue()
    const payload = {
      code: value.code,
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      role_id: value.role_id,
      position: value.position,
      tel: value.tel,
      is_active: value.is_active,
    }

    // return console.log(payload)

    const api = isEdit
      ? this.userService.updateUser(this.data.dataManage.id, payload)
      : this.userService.addUser(payload)
    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.dialogRef.close(true)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }

  downloadTemplate() {
    const payload = {}

    this.loading.start()
    this.userService.templateUser(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `template-import-users.xlsx`)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }

  onChangeUpload(fileUplaod: any) {
    const file = fileUplaod.files[0]

    fileUplaod.value = ''
    this.file = null
    this.uploadError = null
    this.fileErrorMessage = ''
    if (file) {
      const typeName = file.name.split('.')[1]
      if (!this.acceptFileTypes.some((d: any) => d.includes(typeName))) {
        this.fileErrorMessage = 'ไฟล์ต้องเป็นนามสกุล .xlsx กรุณาอัปโหลดไฟล์ใหม่'
        return
      }

      // 1MB = 1048576
      if (file.size > 10485760) {
        this.fileErrorMessage = 'ไฟล์ต้องไม่เกิน 10 MB กรุณาอัปโหลดไฟล์ใหม่'
        return
      }

      this.file = file
    }
  }

  checkImport() {
    if (this.file) {
      const payload = new FormData()
      payload.append('file', this.file)

      this.loading.start()
      this.userService.checkImportUser(payload).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.userList = res.data
            this.paginationUser.setFromResponse({
              page: 1,
              total_record: this.userList.length,
            })
            this.isTabUser = false
          } else {
            this.uploadError = res.errors
          }
        }
        this.loading.stop()
      })
    }
  }

  resetUpload() {
    this.file = null
    this.uploadError = null
  }

  onConfirmTabUpload(): void {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSaveUpload()
      }
    })
  }

  onSaveUpload(): void {
    if (this.file) {
      const payload = new FormData()
      payload.append('file', this.file)

      this.loading.start()
      this.userService.importUser(payload).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
            this.dialogRef.close(true)
          } else {
            this.uploadError = res.errors
            if (res.status === 0) {
              this.customSnackBar.failSave()
            }
          }
        }
        this.loading.stop()
      })
    }
  }
}
