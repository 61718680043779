import { Component, EventEmitter, Injectable, Input, OnInit, Output, inject } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { DatePickerComponent } from '../date-picker/date-picker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { SelectComponent } from '../select/select'
import { MatRipple } from '@angular/material/core'
import { CustomSnackBar } from '../snackbar/snackbar'
import { Loading } from '../../globals'
import { FileService } from '../../services'

@Component({
  selector: 'app-upload-image-more[control]',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    DatePickerComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
    MatRipple,
  ],
  templateUrl: './upload-image-more.html',
  styleUrls: ['./upload-image-more.scss'],
})
export class UploadImageMoreComponent implements OnInit {
  @Input() type: string = 'view'
  @Input() control!: AbstractControl
  @Output() showChange = new EventEmitter<boolean>()
  @Output() upload = new EventEmitter<any>()
  @Output() onOpen = new EventEmitter()
  @Output() onClose = new EventEmitter()
  @Input() imageDefault = '/assets/images/default-image-product-more.png'

  @Input() preview: string = 'square'

  readonly acceptFileTypes = ['image/png', 'image/jpeg', 'image/jpg']

  get images() {
    return this.control.value || []
  }

  get imgUrl() {
    return this.images.length > 0 ? this.images : [{ url: this.imageDefault }]
  }

  get imageNotCover() {
    return this.control.value.filter((im: any) => !im.is_cover)
  }

  constructor(
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public fileService: FileService
  ) {}

  ngOnInit(): void {
    // console.log('logoUrl', this.imageDefault)
  }

  // ------------upload file-------------------

  onChangeUpload(fileUpload: any) {
    const files = fileUpload.files
    if (files.length) {
      for (const file of files) {
        //check file
        if (!this.isValidType(file.type)) {
          this.customSnackBar.fail('ไฟล์ต้องเป็นนามสกุล png, jpeg, jpg')
          return
        }
        if (!this.isValidSize(file.size)) {
          this.customSnackBar.fail('ไฟล์ต้องไม่เกิน 30mb')
          return
        }
      }

      this.loading.start()
      this.fileService.uploads(files).subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            let values: any[] = [...res.data, ...this.control.value.filter((d: any) => !d.is_cover)]
            const cover = this.control.value.find((d: any) => d.is_cover)
            values = values.slice(0, 9) as never[]
            if (cover) {
              values.unshift(cover)
            }

            this.control.setValue(values)
          } else {
            this.customSnackBar.fail()
          }
        }
        this.loading.stop()
      })
    }
    fileUpload.value = ''
  }

  isValidType(fileType: any) {
    return this.acceptFileTypes.some((type: any) => {
      return type === fileType
    })
  }

  isValidSize(fileSize: number) {
    return fileSize < 30 * Math.pow(1024, 2)
  }

  isDelete(index: number) {
    // if (this.images && this.images.length > 0) {
    //   this.images.splice(index, 1)
    // } else {
    this.control.value.splice(index, 1)
    this.control.updateValueAndValidity()
    // }
    // this.upload.emit(this.imageSrc)
  }

  dragOver(event: Event) {
    event.preventDefault()
  }

  drop(event: any) {
    event.preventDefault()
    this.onChangeUpload(event.dataTransfer)
  }
}
