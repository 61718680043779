<div class="status mt-3">
  <div class="status-detail font-14">
    <div class="stepper-wrapper">
      <div
        class="stepper-item"
        [class.completed]="stepCompleted.isCreate"
        [class.active]="!stepCompleted.isWaitPaid"
      >
        <div class="step-counter">
          <span class="icon material-symbols-outlined fill">description</span>
        </div>
        <div class="step-name">รอยืนยันที่อยู่จัดส่ง</div>
      </div>
      <div
        class="stepper-item"
        [class.completed]="stepCompleted.isWaitPaid"
        [class.active]="!stepCompleted.isVerify"
      >
        <div class="step-counter">
          <span class="icon material-symbols-outlined fill">payments</span>
        </div>
        <div class="step-name">รอชำระเงิน</div>
      </div>
      <div
        class="stepper-item"
        [class.completed]="stepCompleted.isVerify"
        [class.active]="!stepCompleted.isPreparing"
      >
        <div class="step-counter">
          <span class="icon material-symbols-outlined fill"> assured_workload</span>
        </div>
        <div class="step-name">กำลังตรวจสอบการชำระเงิน</div>
      </div>
      <div
        class="stepper-item"
        [class.completed]="stepCompleted.isPreparing"
        [class.active]="!stepCompleted.isShipped"
      >
        <div class="step-counter">
          <span class="icon material-symbols-outlined fill"> inventory_2</span>
        </div>
        <div class="step-name">กำลังจัดเตรียมสินค้า</div>
      </div>
      <div class="stepper-item" [class.completed]="stepCompleted.isShipped">
        <div class="step-counter">
          <span class="icon material-symbols-outlined fill"> local_shipping</span>
        </div>
        <div class="step-name">จัดส่งสินค้าแล้ว</div>
      </div>
    </div>
  </div>
</div>
