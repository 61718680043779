import { Component, EventEmitter, Injectable, Input, OnInit, Output, inject } from '@angular/core'
import { MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatDialog } from '@angular/material/dialog'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

import { FilterTableCustomerOrder, Loading } from '../../../../../../app/globals'
import { CustomerService, RoleService } from '../../../../../../app/services'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { ModalFilterComponent } from '../modal-filter/modal-filter'
import { InputSearchComponent } from '../../../../../components/input-search/input-search'
import { MatPaginator } from '@angular/material/paginator'
import { imports } from '../../../../../imports'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
} from '../../../../../helpers'
import { ModalDownloadFileComponent } from '../../../../../components/modal-download-file/modal-download-file'
import { SearchFilterComponent } from './search-filter/search-filter'

@Component({
  selector: 'app-order-list[dataManage]',
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    ModalFilterComponent,
    InputSearchComponent,
    MatPaginator,
    SearchFilterComponent,
  ],
  templateUrl: './order-list.html',
  styleUrls: ['./order-list.scss'],
})
export class OrderListComponent {
  @Input() dataManage!: any

  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  readonly paginationOrder = new Pagination({
    sortBy: 'desc',
  })
  orderList: any[] = []

  constructor(
    public dialog: MatDialog,
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public customerService: CustomerService,
    public filterTableCustomerOrder: FilterTableCustomerOrder
  ) {}

  ngOnDestroy(): void {
    this.filterTableCustomerOrder.reset()
  }

  ngOnInit(): void {
    this.filterTableCustomerOrder.initData(this.dataManage.id)
    this.getCustomerOrderList()
  }

  getCustomerOrderList() {
    const pagination = this.paginationOrder.get()
    const dataFilter = this.filterTableCustomerOrder.getData()
    const payload = {
      ...pagination,
      ...dataFilter,
      search: this.filterTableCustomerOrder.search.value || undefined,
    }

    this.loading.start()
    this.customerService.getCustomerOrderList(this.dataManage.id, payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.orderList = res.data.records.map((d: any) => {
            return {
              ...d,
              trackingText: d.trackings.join(',\n'),
            }
          })
          this.paginationOrder.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }

      this.loading.stop()
    })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationOrder.get()
    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
    }

    this.loading.start()
    this.customerService
      .exportCustomerOrderProduct(this.dataManage.id, payload)
      .subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            DownloadFileBlob(res.body, `${this.dataManage.name}-orders.xlsx`)
            this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
          } else {
            this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
          }
        }
        this.loading.stop()
      })
  }
}
