<div>
  <mat-form-field appearance="outline" class="input" hintLabel="{{hintLabel}}">
    <input
      matInput
      [formControl]="formControl"
      placeholder="{{placeholder || ''}}"
      (input)="onInput.emit($event);optionSelected = null"
      (change)="onChange.emit($event)"
      (keyup.enter)="onEnter.emit($event)"
      maxlength="{{ maxlength }}"
      minlength="{{ minlength }}"
      [matAutocomplete]="auto" />

    <mat-autocomplete
      #auto="matAutocomplete"
      [hideSingleSelectionIndicator]="true"
      (closed)="handleClosed()">
      @for (option of filteredOptions | async; track option) {
      <mat-option
        [value]="option.name"
        (onSelectionChange)="optionSelected = option"
        [id]="option.id"
        class="option-customer-item">
        <span class="d-flex w-100">
          <span class="name"> {{option.name}} </span>
          @if (option.tier) {
          <app-label-tier-color [color]="option.tier.color" class="ms-2">
            {{ option.tier.name }}
          </app-label-tier-color>
          }
        </span>
        <span class="address">{{ option.delivery_address }}</span>
      </mat-option>
      }
    </mat-autocomplete>

    @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    } @if (unit) {
    <div matSuffix class="unit">{{unit}}</div>
    }
  </mat-form-field>
</div>
