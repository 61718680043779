import { Component, Inject } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { imports } from '../../../../../../imports'
import { DatePickerComponent } from '../../../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../../../components/date-picker-range/date-picker-range'
import { CustomSnackBar } from '../../../../../../components/snackbar/snackbar'
import { FilterTableCustomer, FilterTableCustomerOrder } from '../../../../../../globals'
import { SelectComponent } from '../../../../../../components/select/select'

@Component({
  selector: 'app-modal-filter-customer',
  templateUrl: './modal-filter.html',
  styleUrls: ['./modal-filter.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    SelectComponent,
  ],
})
export class ModalFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTableCustomerOrder: FilterTableCustomerOrder
  ) {}

  ngOnInit(): void {
    this.filterTableCustomerOrder.initData(this.data.customer.id)
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTableCustomerOrder.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTableCustomerOrder.confirm()
    this.dialogRef.close(true)
  }
}
