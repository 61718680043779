import * as CryptoJS from 'crypto-js'
import { isNumber, isUndefined, isNull } from '../helpers'
import { environment } from '../../environments/environment'

export const encrypt = (value: any) => {
  if (!isUndefined(value) && !isNull(value)) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), environment.secretKey).toString()
  }
  return ''
}

export const decrypt = (value: any, key?: string) => {
  try {
    if (!isUndefined(value) && !isNull(value)) {
      let bytes = CryptoJS.AES.decrypt(value, key || environment.secretKey)
      let result = bytes.toString(CryptoJS.enc.Utf8)
      if (result && !isNumber(result)) {
        return JSON.parse(result)
      }
      return ''
    }
  } catch (err) {
    console.log('err ', err)
    return ''
  }
  return ''
}
