<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="row">
      <div class="col-12 col-sm-6">
        <label
          class="form-label"
          [class.text-danger]="form.controls['carat_type'].touched && form.controls['carat_type'].invalid"
        >
          เพิ่มกะรัต / ใช้กะรัต <span class="text-danger">*</span>
        </label>

        <app-select
          [control]="form.controls['carat_type']"
          [datas]="list.carat_type"
          fieldValue="value"
          placeholder="เลือก"
        >
          <ng-template #optionRef let-option> {{ option.text }} </ng-template>
          @if (form.controls['carat_type'].hasError('required')) {
          <ng-container error>กรุณาเลือกข้อมูล</ng-container>
          }
        </app-select>
      </div>
      <div class="col-12 col-sm-6">
        <label
          class="form-label"
          [class.text-danger]="form.controls['carat'].touched && form.controls['carat'].invalid"
        >
          จำนวนกะรัต <span class="text-danger">*</span>
        </label>

        <app-input-number [control]="form.controls['carat']" [min]="0">
          @if (form.controls['carat'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }@else if (form.controls['carat'].hasError('formApi')) {
          <ng-container error>{{ form.controls['carat'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>

      <div class="col-12 col-sm-12">
        <label class="form-label"> หมายเหตุ </label>
        <app-text-area [control]="form.controls['remark']" remainingText="200" maxlength="200">
        </app-text-area>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">ยืนยัน</button>
  </div>
</mat-dialog-actions>
