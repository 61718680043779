<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="modal-title">ข้อมูลผู้ขาย</div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label
          class="form-label"
          [class.text-danger]="form.controls['supplier_id'].touched && form.controls['supplier_id'].invalid">
          ผู้ขาย
        </label>

        <app-select
          [control]="form.controls['supplier_id']"
          [datas]="list.supplier"
          fieldValue="id"
          placeholder="เลือก">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
        </app-select>
      </div>
    </div>

    <div class="line"></div>

    <div class="modal-title">ข้อมูลสต๊อกสินค้า</div>

    <div class="table-responsive-detail">
      <table class="table table-detail">
        <thead>
          <tr>
            <th width="140">รหัสสินค้า</th>
            <th width="260">ชื่อสินค้า</th>
            <th width="160">สต๊อก <span class="text-danger">*</span></th>
            <th width="170">ต้นทุนต่อหน่วย (บาท)</th>
          </tr>
        </thead>

        <tbody>
          @for (item of product_list.controls; track $index) {
          <tr>
            <td>{{ item.value.product.code }}</td>
            <td>{{ item.value.product.name }}</td>
            <td>
              <app-input-number [control]="item.controls['stock']" [min]="0" placeholder="0">
                @if (item.controls['stock'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['stock'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000</ng-container>
                } @else if (item.controls['stock'].hasError('formApi')) {
                <ng-container error>{{ item.controls['stock'].errors?.['formApi']}}</ng-container>
                }
              </app-input-number>
            </td>
            <td>
              <app-input-number
                [control]="item.controls['cost_price']"
                [min]="0"
                placeholder="0.00">
                @if (item.controls['cost_price'].hasError('required')) {
                <ng-container error>กรุณากรอกข้อมูล</ng-container>
                } @else if (item.controls['cost_price'].hasError('max')) {
                <ng-container error>กรุณากรอกไม่เกิน 1,000,000,000</ng-container>
                } @else if (item.controls['cost_price'].hasError('formApi')) {
                <ng-container error
                  >{{ item.controls['cost_price'].errors?.['formApi']}}</ng-container
                >
                }
              </app-input-number>
            </td>
          </tr>
          }
        </tbody>
      </table>

      <!-- @if(false) {
      <div class="data-not-found-wrapper">ไม่พบข้อมูลผู้ขาย กรุณาเพิ่มผู้ขาย</div>
      } -->
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onSave()">บันทึก</button>
  </div>
</mat-dialog-actions>
