<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">ตั้งค่าผู้ใช้งาน</div>
  </div>

  <div class="title-wrapper">ตั้งค่าผู้ใช้งาน</div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      @if (isPermissionUser) {
      <mat-tab label="ผู้ใช้งาน" [disabled]="isEditAccess">
        <div class="tabs-content-wrapper">
          <app-tab-user-manage></app-tab-user-manage>
        </div>
      </mat-tab>
      }
      <!-- ~ -->
      @if ( profile.permissions.checkAccess([APP_PERMISSION.ROLE_AND_PERMISSION])) {
      <mat-tab label="สิทธิ์ผู้ใช้งาน">
        <div class="tabs-content-wrapper">
          <app-tab-role-manage
            #roleManageComponent
            [(isEditAccess)]="isEditAccess"></app-tab-role-manage>
        </div>
      </mat-tab>
      }
    </mat-tab-group>
  </div>
</div>
