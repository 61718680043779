<div class="search-filter-wrapper">
  <app-input-search
    [control]="filterTableUser.search"
    placeholder="{{placeholder || ''}}"
    (onEnter)="filterTableUser.confirm(); onConfirm.emit()"></app-input-search>

  <button (click)="openModalFilter()" matRipple class="btn btn-outline-dark">
    <span class="icon material-symbols-outlined fill">filter_list</span>ตัวกรอง
  </button>
</div>

@if (filterTableUser.isFilter) {
<div class="filter-wrapper mb-3">
  <div class="filter-title-wrapper">
    <div class="filter-title">ตัวกรอง</div>
    <div class="filter-separator">|</div>
    <button
      matRipple
      class="btn btn-transparent btn-clear"
      (click)="filterTableUser.reset(); afterFilter()">
      ล้างค่า
    </button>
  </div>

  <div class="filter-tag-wrapper">
    @for (active of filterTableUser.list.actives; track $index) { @if
    (filterTableUser.isActiveChecked(active.value)) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ active.text }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableUser.removeActive(active.value); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } } @for (role of filterTableUser.list.role; track $index) { @if
    (filterTableUser.isRoleChecked(role.id)) {
    <div class="filter-tag-item">
      <div class="filter-tag">{{ role.name }}</div>

      <button
        class="btn btn-transparent btn-icon-mn"
        (click)="filterTableUser.removeRole(role.id); afterFilter()">
        <span class="icon material-symbols-outlined fill">close</span>
      </button>
    </div>
    } }
  </div>
</div>
}
