import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { TierService } from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { forkJoin } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FilterTableCustomer extends __searchAndFitler {
  readonly list = {
    tier: <any[]>[],
  }

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  constructor(
    public tierService: TierService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {
    const filter = {
      tier_ids: [],
    }
    super('/customer?tab=0', filter)
  }

  initData() {
    const apis = [
      this.tierService.getTierList({
        filter: {
          is_actives: [true],
        },
      }),
    ]

    this.loading.start()
    forkJoin(apis).subscribe(([resTier]: any) => {
      if (resTier) {
        if (!resTier.is_error) {
          this.list.tier = resTier.data.filter((d: any) => d.is_active)
        } else {
          this.customSnackBar.fail(resTier.message)
        }
      }

      this.loading.stop()
    })
  }

  isTierChecked(value: any) {
    return this.filter['tier_ids'].some((id: any) => id == value)
  }

  tierChange(value: any) {
    const tier_ids = this.filter['tier_ids']
    if (tier_ids.includes(value)) {
      tier_ids.splice(tier_ids.indexOf(value), 1)
    } else {
      tier_ids.push(value)
    }
  }

  removeTier(bool: any) {
    this.tierChange(bool)
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const tier_ids = this.filter['tier_ids']
    if (tier_ids.length) {
      filter.tier_ids = tier_ids
    }

    if (Object.keys(filter).length) {
      data.filter = filter
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
