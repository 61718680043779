<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="filter-item-wrapper">
      <div class="filter-title">สถานะการใช้งาน</div>

      <div class="filter-checkbox-wrapper">
        @for (active of filterTableUser.list.actives; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableUser.isActiveChecked(active.value)"
          (change)="filterTableUser.activeChange(active.value)"
        >
          {{ active.text }}
        </mat-checkbox>
        }
      </div>
    </div>

    <div class="filter-item-wrapper">
      <div class="filter-title">สิทธิ์ผู้ใช้งาน</div>

      <div class="filter-checkbox-wrapper checkbox-two-column">
        @for (role of filterTableUser.list.role; track $index) {
        <mat-checkbox
          class="filter-checkbox"
          [checked]="filterTableUser.isRoleChecked(role.id)"
          (change)="filterTableUser.roleChange(role.id)"
        >
          {{ role.name }}
        </mat-checkbox>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onReset()">ล้างค่า</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">นำไปใช้</button>
  </div>
</mat-dialog-actions>
