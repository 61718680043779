<div>
  <mat-form-field appearance="outline" class="input" hintLabel="{{hintLabel}}">
    <input
      matInput
      [formControl]="formControl"
      placeholder="{{placeholder || ''}}"
      (input)="onInput.emit($event);findProduct();"
      (change)="onChange.emit($event)"
      (keyup.enter)="onEnter.emit($event)"
      maxlength="{{ maxlength }}"
      minlength="{{ minlength }}"
      [matAutocomplete]="auto" />

    <mat-autocomplete
      #auto="matAutocomplete"
      [hideSingleSelectionIndicator]="true"
      (closed)="onClose()">
      @if (datas.length) {
      <!-- ~ -->
      @for (option of datas; track option) {
      <mat-option
        [value]="option.code"
        (onSelectionChange)="optionSelected = option"
        class="option-customer-item">
        {{ option.code }}
      </mat-option>
      }
      <!-- ~ -->
      } @else {
      <mat-option [disabled]="true" class="option-customer-item text-center text-not-found">
        ไม่พบสินค้า
      </mat-option>
      }
    </mat-autocomplete>

    @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    }
    <div matSuffix class="select-suffix d-flex align-items-center">
      <img src="/assets/images/icons/icon-select.svg" />
    </div>
  </mat-form-field>
</div>
