<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content class="p-0">
  <div class="modal-content" [ngClass]="{'table-content-wrapper': !isTabUser }">
    @if(isTabUser) {
    <div class="tabs-modal-wrapper">
      <mat-tab-group
        animationDuration="0ms"
        [disableRipple]="true"
        [selectedIndex]="tab"
        (selectedIndexChange)="changeTab($event)">
        @if (profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT],
        [ACTION_ACCESS.CREATE, ACTION_ACCESS.UPDATE])) {
        <mat-tab label="เพิ่มผู้ใช้ทีละรายการ" [disabled]="data.type == 'edit'">
          <div class="tabs-content-wrapper">
            <div class="container gx-0">
              <div class="row gy-2">
                <div class="col-12 form-title mb-2">ข้อมูลผู้ใช้</div>
                <div class="col-12">
                  <label
                    class="label"
                    [class.text-danger]="form.controls['code'].touched && form.controls['code'].invalid">
                    รหัสผู้ใช้งาน
                    <span class="text-danger">*</span>
                  </label>
                  <app-input [control]="form.controls['code']" [disabled]="data.type == 'edit'">
                    @if (form.controls['code'].hasError('required')) {
                    <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                    }@else if (form.controls['code'].hasError('formApi')) {
                    <ng-container error
                      >{{ form.controls['code'].errors?.['formApi']}}</ng-container
                    >
                    }
                  </app-input>
                </div>

                <div class="col-12 col-sm-6">
                  <label
                    class="label"
                    [class.text-danger]="form.controls['first_name'].touched && form.controls['first_name'].invalid">
                    ชื่อ
                    <span class="text-danger">*</span>
                  </label>
                  <app-input [control]="form.controls['first_name']">
                    @if (form.controls['first_name'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    }@else if (form.controls['first_name'].hasError('pattern')) {
                    <ng-container error>กรุณากรอกเป็นตัวอักษร</ng-container>
                    }@else if (form.controls['first_name'].hasError('formApi')) {
                    <ng-container error
                      >{{ form.controls['first_name'].errors?.['formApi']}}</ng-container
                    >
                    }
                  </app-input>
                </div>

                <div class="col-12 col-sm-6">
                  <label
                    class="label"
                    [class.text-danger]="form.controls['last_name'].touched && form.controls['last_name'].invalid">
                    นามสกุล
                    <span class="text-danger">*</span>
                  </label>
                  <app-input [control]="form.controls['last_name']">
                    @if (form.controls['last_name'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    }@else if (form.controls['last_name'].hasError('pattern')) {
                    <ng-container error>กรุณากรอกเป็นตัวอักษร</ng-container>
                    }@else if (form.controls['last_name'].hasError('formApi')) {
                    <ng-container error
                      >{{ form.controls['last_name'].errors?.['formApi']}}</ng-container
                    >
                    }
                  </app-input>
                </div>

                <div class="col-12">
                  <label
                    class="label"
                    [class.text-danger]="form.controls['position'].touched && form.controls['position'].invalid">
                    ตำแหน่ง
                    <span class="text-danger">*</span>
                  </label>
                  <app-select
                    [control]="form.controls['position']"
                    [datas]="list.position"
                    fieldValue="value"
                    placeholder="เลือก">
                    <ng-template #optionRef let-option> {{ option.text }} </ng-template>
                    @if (form.controls['position'].hasError('required')) {
                    <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                    }
                  </app-select>
                </div>

                <div class="col-12 col-sm-6">
                  <label
                    class="label"
                    [class.text-danger]="form.controls['email'].touched && form.controls['email'].invalid">
                    อีเมล
                    <span class="text-danger">*</span>
                    <span class="label-detail">&nbsp;(สำหรับใช้งานระบบ)</span>
                  </label>
                  <app-input [control]="form.controls['email']">
                    @if (form.controls['email'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    } @else if (form.controls['email'].hasError('pattern')) {
                    <ng-container error>รูปแบบอีเมลไม่ถูกต้อง</ng-container>
                    } @else if (form.controls['email'].hasError('formApi')) {
                    <ng-container error
                      >{{ form.controls['email'].errors?.['formApi']}}</ng-container
                    >
                    }
                  </app-input>
                </div>

                <div class="col-12 col-sm-6">
                  <label
                    class="label"
                    [class.text-danger]="form.controls['tel'].touched && form.controls['tel'].invalid">
                    เบอร์โทรศัพท์
                    <span class="text-danger">*</span>
                  </label>
                  <app-input [control]="form.controls['tel']">
                    @if (form.controls['tel'].hasError('required')) {
                    <ng-container error>กรุณากรอกข้อมูล</ng-container>
                    }@else if (form.controls['tel'].hasError('pattern')) {
                    <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
                    }@else if (form.controls['tel'].hasError('formApi')) {
                    <ng-container error>{{ form.controls['tel'].errors?.['formApi']}}</ng-container>
                    }
                  </app-input>
                </div>
              </div>
            </div>

            <div class="line"></div>

            <div class="container gx-0">
              <div class="row gy-2">
                <div class="col-12 form-title mb-2">การใช้งาน</div>
                <div class="col-12 col-sm-6">
                  <label
                    class="label"
                    [class.text-danger]="form.controls['role_id'].touched && form.controls['role_id'].invalid">
                    สิทธิ์การใช้งาน
                    <span class="text-danger">*</span>
                  </label>
                  <app-select
                    [control]="form.controls['role_id']"
                    [datas]="list.role"
                    fieldValue="id"
                    placeholder="เลือก">
                    <ng-template #optionRef let-option> {{ option.name }} </ng-template>
                    @if (form.controls['role_id'].hasError('required')) {
                    <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                    }
                  </app-select>
                </div>

                <div class="col-12 col-sm-6">
                  <label class="label"> สถานะการใช้งาน </label>
                  <div class="mt-2">
                    <mat-slide-toggle
                      hideIcon="true"
                      color="primary"
                      class="slide-toggle"
                      [formControl]="form.controls.is_active">
                      ใช้งาน
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        } @if (profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT],
        [ACTION_ACCESS.IMPORT])) {
        <mat-tab label="เพิ่มผู้ใช้หลายรายการ" [disabled]="data.type == 'edit'">
          <div class="tabs-content-wrapper">
            <div class="detail-list-wrapper">
              <ul class="detail-list">
                <li>
                  ดาวน์โหลดตัวอย่างการกรอกข้อมูลผู้ใช้งานได้ที่นี่
                  <span class="template-link" (click)="downloadTemplate()">template.xls</span>
                </li>
                <li>ระบบยอมรับเฉพาะไฟล์ Excel (.xlsx) เท่านั้น</li>
                <li>จำกัดขนาดไฟล์ไม่เกิน 10 MB</li>
                <li>ห้ามกรอกรหัสผู้ใช้งาน และอีเมลซ้ำกับข้อมูลในระบบ</li>
                <li>ตรวจสอบข้อมูลที่เว้นว่าง หรือไม่มีข้อมูลให้ระบุเป็น “ - ”</li>
              </ul>
            </div>

            <input
              id="upload"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              class="d-none"
              #fileUpload
              (change)="onChangeUpload(fileUpload)" />

            @if(!file) {
            <label
              for="upload"
              class="upload-file-wrapper"
              (dragover)="dragOver($event)"
              (drop)="drop($event)"
              [class.border-danger]="fileErrorMessage">
              <div class="upload-file-text-wrapper h-100">
                <span class="icon material-symbols-outlined icon-upload">drive_folder_upload</span>
                <div class="upload-file-text">ลากวาง หรือ เลือกไฟล์อัปโหลด</div>
                <div class="file-type">.xlsx</div>
              </div>
            </label>
            @if (fileErrorMessage) {
            <div class="mt-1 text-danger text-error">{{ fileErrorMessage }}</div>
            } } @else { @if(uploadError) {
            <div class="upload-file-wrapper upload-error-section">
              <div class="upload-file-text-wrapper">
                <div class="xlsx-icon">
                  <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
                </div>
                <div class="file-name-wrapper">
                  <div class="file-name">{{ file.name }}</div>
                  <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
                    <img alt="icon" src="/assets/images/icons/icon-close-circle-red.svg" />
                  </button>
                </div>

                <div class="upload-error-text-wrapper">
                  <div class="upload-error-text">
                    รูปแบบการอัปโหลดไม่ถูกต้อง ตรวจสอบข้อมูลและอัปโหลดใหม่อีกครั้ง
                  </div>
                  <div class="upload-error-text">Format Error:</div>
                  <div class="error-text-row-wrapper">
                    @for (err of uploadError; track $index) {
                    <div class="error-text-row">
                      <!-- <div class="error-row">แถว: 33 คอลัมน์:</div> -->
                      <div class="error-info">{{ err.message }}</div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-upload-wrapper">
              <button class="btn btn-outline-secondary btn-upload" (click)="fileUpload.click()">
                อัปโหลดไฟล์ใหม่
              </button>
            </div>
            } @else {
            <div class="upload-file-wrapper file-name-section">
              <div class="upload-file-text-wrapper h-100">
                <div class="xlsx-icon">
                  <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
                </div>
                <div class="file-name-wrapper">
                  <div class="file-name">{{ file.name }}</div>
                  <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
                    <span class="icon material-symbols-outlined fill icon-cancel">cancel</span>
                  </button>
                </div>
              </div>
            </div>
            } }
          </div>
        </mat-tab>
        }
      </mat-tab-group>
    </div>
    } @if(!isTabUser) {
    <div class="table-section-wrapper">
      <div class="table-responsive">
        <table class="table table-fixed-column">
          <colgroup>
            <col width="120" />
            <col width="140" />
            <col width="140" />
            <col width="145" />
            <col width="175" />
            <col width="150" />
            <col width="120" />
            <col [ngStyle]="{ minWidth: '112px' }" />
          </colgroup>

          <thead>
            <tr class="role">
              <th>รหัสผู้ใช้</th>
              <th>ชื่อ</th>
              <th>นามสกุล</th>
              <th>ตำแหน่ง</th>
              <th>อีเมล</th>
              <th>เบอร์โทรศัพท์</th>
              <th>สิทธิ์ผู้ใช้งาน</th>
              <th>สถานะ</th>
            </tr>
          </thead>

          <tbody>
            @for (user of userPageList; track $index) {
            <tr>
              <td>{{ user.code }}</td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.position }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.tel || '-' }}</td>
              <td>{{ user.role.name }}</td>
              <td>
                <app-status-user [status]="user.is_active ? 'active' : 'inactive'">
                  {{ user.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
                </app-status-user>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      @if (userPageList.length) {
      <div class="pagination-wrapper">
        <mat-paginator
          [pageIndex]="paginationUser.data.pageIndex"
          [length]="paginationUser.data.length"
          [pageSize]="paginationUser.data.pageSize"
          [pageSizeOptions]="paginationUser.data.pageSizeOptions"
          (page)="paginationUser.setFromPaginator($event)"
          aria-label="Select page">
        </mat-paginator>
      </div>
      }
    </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    @if(tab == 0) {
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
    } @else { @if (userList.length > 0) {
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirmTabUpload()">บันทึก</button>
    } @else {
    <button matRipple class="btn btn-primary btn-md" [disabled]="!file" (click)="checkImport()">
      ตรวจสอบ
    </button>
    } }
  </div>
</mat-dialog-actions>
