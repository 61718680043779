export const pageSize = {
  '10cm': {
    width: 378,
    height: 534,
  },
  A4: {
    width: 794,
    height: 1122,
  },
} as const

export type EnumPageSizeKey = keyof typeof pageSize
export type EnumPageSizeValue = (typeof pageSize)[keyof typeof pageSize]

export const printType = {
  address: 'address',
  receipt: 'receipt',
  tax: 'tax',
} as const

export type EnumPrintTypeValue = (typeof printType)[keyof typeof printType]

export const printGroup = {
  order_no: 'order_no',
  order_no_uuid: 'order_no_uuid',
} as const

export type EnumPrintGroupValue = 'order_no' | 'order_no_uuid'

export const splitPage = (
  boxPage: any,
  size: EnumPageSizeValue,
  type: EnumPrintTypeValue,
  call: Function
) => {
  const group = new Map<any, number>()
  const pages: any[] = []

  let pageHeight = size.height
  if (size == pageSize.A4) {
    pageHeight -= 30
  }

  const paddingPage = size == pageSize.A4 ? 48 : 0

  const footer = document.getElementById('footer')
  if (footer) {
    pageHeight -= footer.offsetHeight
  }

  for (let i = 0; i < boxPage.children.length; i++) {
    let selfPageHeight = pageHeight
    const elPage = boxPage.children[i]
    if (elPage.id == 'footer') {
      selfPageHeight -= elPage.offsetHeight
    } else {
      let beForeOffsetTopMove = 0
      for (let i = 0; i < elPage.children.length; i++) {
        const elItem = elPage.children[i]
        if (elItem.classList.contains('auto-move')) {
          const offsetTop = elItem.offsetTop - beForeOffsetTopMove
          const heightOffsetTop = elItem.offsetHeight + offsetTop
          selfPageHeight -= heightOffsetTop

          beForeOffsetTopMove = heightOffsetTop
        }
      }

      let page = createPage(group, pages, 1, selfPageHeight, elPage)
      page.remainOffset -= paddingPage

      let beForeOffsetTop = 0
      // console.log('page.remainOffset', page.remainOffset)
      for (let i = 0; i < elPage.children.length; i++) {
        const elItem = elPage.children[i]

        const offsetTop = elItem.offsetTop - beForeOffsetTop

        let elementIndex = createElementIndex(i)

        const heightOffsetTop = elItem.offsetHeight + offsetTop
        // const dd = document.createElement('div')
        // dd.getElementsByClassName
        if (elItem.classList.contains('table-auto-cut')) {
          const thead = elItem.getElementsByTagName('thead')[0]
          const heightOffsetTop = thead.offsetHeight + offsetTop
          page.remainOffset -= heightOffsetTop
          // console.log({
          //   'thead.offsetHeight': thead.offsetHeight,
          //   'elItem.offsetTop': elItem.offsetTop,
          //   beForeOffsetTop,
          //   offsetTop,
          // })
          // console.log('page.remainOffset', heightOffsetTop, page.remainOffset, thead)
          if (page.remainOffset < 0) {
            page = createPage(group, pages, page.number + 1, selfPageHeight, elPage)
            page.remainOffset -= heightOffsetTop + paddingPage

            elementIndex = createElementIndex(i)
          }
          page.elementIndexs.push(elementIndex)

          const tbody = elItem.getElementsByTagName('tbody')[0]

          for (let ti = 0; ti < tbody.children.length; ti++) {
            const tr = tbody.children[ti]
            page.remainOffset -= tr.offsetHeight
            // console.log('tr', tr.offsetHeight, page.remainOffset, tr)

            if (page.remainOffset < 0) {
              page = createPage(group, pages, page.number + 1, selfPageHeight, elPage)
              page.remainOffset -= heightOffsetTop + tr.offsetHeight + paddingPage

              elementIndex = createElementIndex(i)
              page.elementIndexs.push(elementIndex)
            }

            elementIndex.trIndexs.push(ti)
          }
        } else if (elItem.getAttribute('auto-cut') == 'recript-address') {
          const thead = elItem.getElementsByTagName('thead')[0]
          const heightOffsetTop = thead.offsetHeight + offsetTop
          page.remainOffset -= heightOffsetTop
          // console.log({
          //   'thead.offsetHeight': thead.offsetHeight,
          //   'elItem.offsetTop': elItem.offsetTop,
          //   beForeOffsetTop,
          //   offsetTop,
          // })
          // console.log('page.remainOffset', heightOffsetTop, page.remainOffset, thead)
          if (page.remainOffset < 0) {
            page = createPage(group, pages, page.number + 1, selfPageHeight, elPage)
            page.remainOffset -= heightOffsetTop + paddingPage

            elementIndex = createElementIndex(i)
          }
          page.elementIndexs.push(elementIndex)

          const items = elItem.getElementsByClassName('tag-for-cut-item')

          for (let ti = 0; ti < items.length; ti++) {
            const tagForCutItem = items[ti]
            page.remainOffset -= tagForCutItem.offsetHeight
            // console.log('tagForCutItem', tagForCutItem.offsetHeight, page.remainOffset, tagForCutItem)

            if (page.remainOffset < 0) {
              page = createPage(group, pages, page.number + 1, selfPageHeight, elPage)
              page.remainOffset -= heightOffsetTop + tagForCutItem.offsetHeight + paddingPage

              elementIndex = createElementIndex(i)
              page.elementIndexs.push(elementIndex)
            }

            elementIndex.cutIndexs.push(ti)
          }
        } else if (!elItem.classList.contains('auto-move')) {
          // console.log({
          //   'elItem.offsetHeight': elItem.offsetHeight,
          //   'elItem.offsetTop': elItem.offsetTop,
          //   beForeOffsetTop,
          //   offsetTop,
          // })
          page.remainOffset -= heightOffsetTop
          // console.log('page.remainOffset', heightOffsetTop, page.remainOffset, elItem)
          if (page.remainOffset < 0) {
            page = createPage(group, pages, page.number + 1, selfPageHeight, elPage)
            page.remainOffset -= heightOffsetTop + paddingPage
            // console.log('page.remainOffset', heightOffsetTop, page.remainOffset)
          }

          page.elementIndexs.push(elementIndex)
        }

        beForeOffsetTop += heightOffsetTop
      }
    }
  }

  const newPages = pages.map((page, i) => {
    page.element.style.width = size.width + 'px'
    page.element.style.height = size.height + 'px'
    if (i > 0) {
      page.element.classList.add('page-break')
    }

    const elItems = [...page.element.children]
    for (const index in elItems) {
      const elItem = elItems[index]
      const elementIndex = page.elementIndexs.find((d: any) => d.i == index)
      if (elementIndex) {
        if (elementIndex.cutIndexs.length) {
          setTagForCut(elItem, elementIndex.cutIndexs)
        }
        if (elementIndex.trIndexs.length) {
          setTrToBody(elItem, elementIndex.trIndexs)
        }
      } else {
        if (!elItem.classList.contains('auto-move')) {
          elItem.remove()
        }
      }
    }

    call({ group, page, footer, i, length: pages.length })

    return page
  })

  // console.log('newPages', newPages)

  return newPages
}

const createPage = (
  group: Map<any, number>,
  pages: any[],
  number: number,
  selfPageHeight: number,
  elPage: Element
) => {
  const order_no = elPage.getAttribute(printGroup.order_no)
  if (order_no) {
    group.set(order_no, (group.get(order_no) || 0) + 1)
  }
  const order_no_uuid = elPage.getAttribute(printGroup.order_no_uuid)
  if (order_no_uuid) {
    group.set(order_no_uuid, (group.get(order_no_uuid) || 0) + 1)
  }

  // console.log('page', number)

  const page = {
    number,
    remainOffset: selfPageHeight,
    element: document.createElement('div'),
    elementIndexs: <any[]>[],
    order_no,
    order_no_uuid,
  }
  page.element.className = elPage.className
  page.element.innerHTML = elPage.innerHTML
  pages.push(page)

  return page
}

const createElementIndex = (i: number) => {
  return {
    i,
    cutIndexs: <number[]>[],
    trIndexs: <number[]>[],
  }
}

const setTagForCut = (elTable: any, cutIndexs: number[]) => {
  const tagForCut = elTable.getElementsByClassName('tag-for-cut')[0]
  let innerHTML = ''
  for (let ti = 0; ti < tagForCut.children.length; ti++) {
    if (cutIndexs.includes(ti)) {
      innerHTML += tagForCut.children[ti].outerHTML
    }
  }
  tagForCut.innerHTML = innerHTML
}

const setTrToBody = (elTable: any, trIndexs: number[]) => {
  const tbody = elTable.getElementsByTagName('tbody')[0]
  let innerHTML = ''
  for (let ti = 0; ti < tbody.children.length; ti++) {
    if (trIndexs.includes(ti)) {
      innerHTML += tbody.children[ti].outerHTML
    }
  }
  tbody.innerHTML = innerHTML
}
