<div class="tabs-content-wrapper-detail">
  <div class="d-flex justify-content-between">
    <!--  (onEnter)="getProductBrandList()" -->
    <app-search
      [control]="search"
      placeholder="ค้นหา"
      (onEnter)="paginationPromotion.reset();getCustomerPromotionList()"></app-search>

    @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER], [ACTION_ACCESS.EXPORT])) {
    <button
      matRipple
      class="btn btn-outline-secondary min-w-100px"
      (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รหัสส่วนลดที่แลก', { message: 'คุณต้องการดาวน์โหลดไฟล์รหัสส่วนลดที่แลกใช่หรือไม่' })">
      <span class="icon material-symbols-outlined fill">download_2</span>
      ส่งออกไฟล์
    </button>
    }
  </div>

  <div class="table-responsive-detail mt-3">
    <div [style]="{ 'min-width': '600px' }">
      <table class="table table-fixed-column table-detail">
        <thead>
          <tr>
            <th>รหัสส่วนลด</th>
            <th width="200" class="text-end">มูลค่าส่วนลด</th>
            <th width="200">วันที่</th>
          </tr>
        </thead>

        <tbody>
          @for (item of promotionList; track $index) {
          <tr>
            <td>{{ item.promotion_code }}</td>
            <td class="text-end">{{ formatPrice(item.discount_price) }}</td>
            <td>{{ Moment(item.created_at).format('DD-MM-YYYY, HH:mm') }}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>

    @if(!promotionList.length) {
    <div class="data-not-found-wrapper">ไม่พบรายการสั่งซื้อ</div>
    }
  </div>

  <!-- Pagination -->
  @if (promotionList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationPromotion.data.pageIndex"
      [length]="paginationPromotion.data.length"
      [pageSize]="paginationPromotion.data.pageSize"
      [pageSizeOptions]="paginationPromotion.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationPromotion.setFromPaginator($event);getCustomerPromotionList()">
    </mat-paginator>
  </div>
  }
</div>
