import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import {
  EnumPageSizeValue,
  EnumPrintGroupValue,
  EnumPrintTypeValue,
  Moment,
  splitPage,
} from '../../../../helpers'

@Component({
  selector: 'app-print-html-render[group][type][size][test]',
  standalone: true,
  templateUrl: './print-html-render.html',
  styleUrls: ['./print-html-render.scss'],
})
export class PrintHtmlRenderComponent {
  @ViewChild('boxPage') boxPage!: ElementRef

  @Input() group!: EnumPrintGroupValue
  @Input() type!: EnumPrintTypeValue
  @Input() size!: EnumPageSizeValue
  @Input() test = false
  @Input() css = ''
  @Output() done = new EventEmitter()

  readonly printId = 'scope-print'

  constructor() {}

  ngOnDestroy(): void {
    const elPrint = document.getElementById(this.printId)
    if (elPrint) {
      elPrint.remove()
    }
  }

  ngAfterViewInit(): void {
    let textPageHTML = ''

    let numberPage = 1
    let beforeGroup = ''
    splitPage(this.boxPage.nativeElement, this.size, this.type, (data: any) => {
      if (data.footer) {
        const footer = document.createElement('div')

        footer.innerHTML = data.footer.innerHTML
        footer.className = data.footer.className

        // if (this.type == 'receipt') {
        //   footer.innerHTML = footer.innerHTML
        // }

        data.page.element.appendChild(footer)
      }

      // console.log('group', data.group)
      let numberPageSelf = numberPage
      const nowGroup = data.page[this.group]
      const totalPage = data.group.get(nowGroup)
      if (nowGroup != beforeGroup) {
        numberPage = numberPageSelf = 1
      }

      let outerHTML = data.page.element.outerHTML
        .replaceAll('[[order_no]]', data.page.order_no)
        .replaceAll('[[page/pages]]', `${numberPageSelf}/${totalPage}`)
      textPageHTML += outerHTML

      beforeGroup = nowGroup
      numberPage++
    })

    this.boxPage.nativeElement.innerHTML = textPageHTML

    const div = document.createElement('div')
    div.classList.add('scope-print')
    div.id = this.printId

    div.innerHTML = textPageHTML
    document.body.appendChild(div)
  }
}
