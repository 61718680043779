import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, ORDER_ID, convertDateToApi } from '../../helpers'
import {
  MasterService,
  ProductBrandService,
  ProductCategoryService,
  TransportService,
} from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'

@Injectable({ providedIn: 'root' })
export class FilterReportUser extends __searchAndFitler {
  readonly list = {
    status_ids: <any[]>[
      { id: ORDER_ID.PRINTED, text: 'พิมพ์' },
      { id: ORDER_ID.SHIPMENT, text: 'ปิดรายการ' },
    ],
    period: [
      { value: 'all', text: 'ทั้งหมด' },
      { value: 'year', text: 'ปีนี้' },
      { value: 'month', text: 'เดือนนี้' },
      { value: 'week', text: 'อาทิตย์นี้' },
      { value: 'day', text: 'วันนี้' },
    ],
  }

  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    period: new FormControl(null),
  })

  get isFilter() {
    const data = this.getData(true)
    return data.filter && Object.keys(data.filter).length
  }

  get period() {
    return this.list.period.find((d: any) => d.value === this.form.value.period)
  }

  get startEndDateFormat() {
    return [this.form.controls['start_date'].value, this.form.controls['end_date'].value]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  constructor(
    public masterService: MasterService,
    public productCategoryService: ProductCategoryService,
    public productBrandService: ProductBrandService,
    public transportService: TransportService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {
    const filter = {
      status_ids: [],
    }
    super('/report/users', filter)

    this._initForm(this.form)
  }

  async initData() {}

  isStatusIdChecked(id: any) {
    return this.filter['status_ids'].some((val: any) => val === id)
  }

  statusIdChange(id: any) {
    const status_ids = this.filter['status_ids']
    if (status_ids.includes(id)) {
      status_ids.splice(status_ids.indexOf(id), 1)
    } else {
      status_ids.push(id)
    }
  }

  removeStatusId(id: any) {
    this.statusIdChange(id)
  }

  removeStartEndDate() {
    this.form.controls['start_date'].reset()
    this.form.controls['end_date'].reset()
  }

  getData(isAll = false) {
    const filter: any = {}

    const data: any = {
      filter,
    }

    const status_ids = this.filter['status_ids']
    if (status_ids.length) {
      filter.status_ids = status_ids
    }

    const value = this.form.getRawValue()

    if (value.start_date) {
      filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    if (value.period && (value.period != 'all' || isAll)) {
      filter.period = value.period
    }

    data.filter = filter

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
