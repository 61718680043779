import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class MaketplaceService {
  constructor(private service: Service) {}
  private pathMaketplace = '/v1/marketplace'

  getMaketplace(): Observable<any> {
    return this.service.get(`${this.pathMaketplace}`)
  }
  updateMaketplace(payload: any): Observable<any> {
    return this.service.put(`${this.pathMaketplace}`, payload)
  }
  connectLazada(payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathMaketplace}/lazada/code`, payload)
  }

  private pathShopee = '/v1/shopee-products'
  getShopeeList(payload: any): Observable<any> {
    return this.service.post(`${this.pathShopee}/query`, payload)
  }
  getShopee(id: number): Observable<any> {
    return this.service.get(`${this.pathShopee}/${id}`)
  }
  addShopee(payload: any): Observable<any> {
    return this.service.post(`${this.pathShopee}`, payload)
  }
  updateShopee(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathShopee}/${id}`, payload)
  }
  connectShopeeProduct(id: number, payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathShopee}/${id}/connected`, payload)
  }
  disconnectShopeeProduct(id: number, payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathShopee}/${id}/disconnected`, payload)
  }

  private pathLazada = '/v1/lazada-products'
  getLazadaList(payload: any): Observable<any> {
    return this.service.post(`${this.pathLazada}/query`, payload)
  }
  getLazada(id: number): Observable<any> {
    return this.service.get(`${this.pathLazada}/${id}`)
  }
  addLazada(payload: any): Observable<any> {
    return this.service.post(`${this.pathLazada}`, payload)
  }
  updateLazada(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathLazada}/${id}`, payload)
  }
  connectLazadaProduct(id: number, payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathLazada}/${id}/connected`, payload)
  }
  disconnectLazadaProduct(id: number, payload: any = {}): Observable<any> {
    return this.service.post(`${this.pathLazada}/${id}/disconnected`, payload)
  }
}
