<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text breadcrumb-link">ข้อมูลสินค้า</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แบรนด์สินค้า</div>
  </div>

  <div class="title-wrapper">แบรนด์สินค้า</div>

  <div class="page-content-inner">
    <div class="top-content-wrapper mb-3">
      <app-search
        [control]="filterTableProductBrand.search"
        placeholder="ค้นหา"
        (onEnter)="paginationProductBrand.reset();getProductBrandList()"></app-search>
      @if (profile.permissions.checkAccess([APP_PERMISSION.BRAND_PRODUCT], [ACTION_ACCESS.CREATE]))
      {
      <button
        matRipple
        class="btn btn-primary"
        (click)="openModalProductBrand('เพิ่มแบรนด์สินค้า', 'add')">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มแบรนด์สินค้า
      </button>
      }
    </div>

    <!-- Table -->
    <div class="table-responsive table-main-wrapper">
      <table class="table table-fixed-column">
        <thead>
          <tr>
            <th width="280">ชื่อแบรนด์สินค้า</th>
            <th width="150">สถานะ</th>
            <th width="220">อัปเดตโดย</th>
            <th width="175">อัปเดตล่าสุด</th>
            <th width="120" class="fixed box-shadow-left">จัดการ</th>
          </tr>
        </thead>

        <tbody>
          @for (productBrand of productBrandList; track $index) {
          <tr>
            <td>{{ productBrand.name }}</td>
            <td>
              <app-status-user [status]="productBrand.is_active ? 'active' : 'inactive'">
                {{ productBrand.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
              </app-status-user>
            </td>
            <td>{{ productBrand.updated_by ? productBrand.updated_by.full_name : '' }}</td>
            <td>{{ Moment(productBrand.updated_at).format('DD-MM-YYYY, HH:mm') }}</td>
            <td class="fixed box-shadow-left">
              <div class="action-button-wrapper">
                @if (productBrand.action.edit) {
                <button
                  matRipple
                  matTooltip="แก้ไข"
                  matTooltipPosition="above"
                  class="btn btn-outline-secondary btn-icon"
                  (click)="openModalProductBrand('แก้ไขแบรนด์สินค้า', 'edit', productBrand)">
                  <span class="icon material-symbols-outlined fill">edit</span>
                </button>
                } @if (productBrand.action.delete) {
                <button
                  matRipple
                  matTooltip="ลบ"
                  matTooltipPosition="above"
                  class="btn btn-outline-danger btn-icon"
                  (click)="confirmDelete(productBrand)">
                  <span class="icon material-symbols-outlined fill">delete</span>
                </button>
                }
              </div>
            </td>
          </tr>
          }
        </tbody>
      </table>

      @if(!productBrandList.length) {
      <div class="data-not-found-wrapper">ไม่พบแบรนด์สินค้า กรุณาเพิ่มแบรนด์สินค้า</div>
      }
    </div>

    <!-- Pagination -->
    @if (productBrandList.length) {
    <div class="pagination-wrapper">
      <mat-paginator
        [pageIndex]="paginationProductBrand.data.pageIndex"
        [length]="paginationProductBrand.data.length"
        [pageSize]="paginationProductBrand.data.pageSize"
        [pageSizeOptions]="paginationProductBrand.data.pageSizeOptions"
        aria-label="Select page"
        (page)="paginationProductBrand.setFromPaginator($event);getProductBrandList()">
      </mat-paginator>
    </div>
    }
  </div>
</div>
