import { Component, OnInit, Input } from '@angular/core'
import { imports } from '../../imports'

@Component({
  selector: 'app-label-tier-color',
  standalone: true,
  imports: [...imports],
  templateUrl: './label-tier-color.html',
  styleUrls: ['./label-tier-color.scss'],
})
export class LabelTierColorComponent implements OnInit {
  @Input() color = '#ffffff'
  @Input() class = ''

  ngOnInit(): void {}
}
