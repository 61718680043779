<mat-dialog-content>
  <div class="modal-content py-2 text-center">
    <span class="icon-70 material-symbols-outlined fill me-1 color--orange-600 mb-2">
      error_circle_rounded
    </span>
    <b> คุณมีรายการสินค้าที่ยังไม่ระบุที่อยู่ </b>
    รายการสินค้าที่คุณสั่งซื้อบางรายการยังไม่ระบุที่อยู่จัดส่ง
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-center w-100">
    <button class="btn btn-primary btn-md" matRipple (click)="onConfirm()">ปิด</button>
  </div>
</mat-dialog-actions>
