<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="detail-list-wrapper">
      <ul class="detail-list">
        <li>
          ดาวน์โหลดตัวอย่างการกรอกข้อมูลลูกค้าได้ที่นี่
          <span class="template-link" (click)="downloadTemplate()">template.xls</span>
        </li>
        <li>ระบบยอมรับเฉพาะไฟล์ Excel (.xlsx) เท่านั้น</li>
        <li>จำกัดขนาดไฟล์ไม่เกิน 10 MB</li>
        <li>ห้ามกรอกรหัสผู้ใช้งาน และอีเมลซ้ำกับข้อมูลในระบบ</li>
        <li>ตรวจสอบข้อมูลที่เว้นว่าง หรือไม่มีข้อมูลให้ระบุเป็น “ - ”</li>
      </ul>
    </div>

    <input
      id="upload"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      class="d-none"
      #fileUpload
      (change)="onChangeUpload(fileUpload)"
    />
    @if(!file) {
    <label
      for="upload"
      class="upload-file-wrapper"
      (dragover)="dragOver($event)"
      (drop)="drop($event)"
      [class.border-danger]="fileErrorMessage"
    >
      <div class="upload-file-text-wrapper h-100">
        <span class="icon material-symbols-outlined icon-upload">drive_folder_upload</span>
        <div class="upload-file-text">ลากวาง หรือ เลือกไฟล์อัปโหลด</div>
        <div class="file-type">.xlsx</div>
      </div>
    </label>
    @if (fileErrorMessage) {
    <div class="mt-1 text-danger text-error">{{ fileErrorMessage }}</div>
    } } @else { @if(uploadError) {
    <div class="upload-file-wrapper upload-error-section">
      <div class="upload-file-text-wrapper">
        <div class="xlsx-icon">
          <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
        </div>
        <div class="file-name-wrapper">
          <div class="file-name">{{ file.name }}</div>
          <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
            <img alt="icon" src="/assets/images/icons/icon-close-circle-red.svg" />
          </button>
        </div>

        <div class="upload-error-text-wrapper">
          <div class="upload-error-text">
            รูปแบบการอัปโหลดไม่ถูกต้อง ตรวจสอบข้อมูลและอัปโหลดใหม่อีกครั้ง
          </div>
          <div class="upload-error-text">Format Error:</div>
          <div class="error-text-row-wrapper">
            @for (err of uploadError; track $index) {
            <div class="error-text-row">
              <!-- <div class="error-row">แถว: 33 คอลัมน์:</div> -->
              <div class="error-info">{{ err.message }}</div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="btn-upload-wrapper">
      <button class="btn btn-outline-secondary btn-upload" (click)="fileUpload.click()">
        อัปโหลดไฟล์ใหม่
      </button>
    </div>
    } @else {
    <div class="upload-file-wrapper file-name-section">
      <div class="upload-file-text-wrapper h-100">
        <div class="xlsx-icon">
          <img alt="icon" src="/assets/images/icons/icon-excel.svg" />
        </div>
        <div class="file-name-wrapper">
          <div class="file-name">{{ file.name }}</div>
          <button class="btn btn-transparent btn-icon" (click)="resetUpload()">
            <span class="icon material-symbols-outlined fill icon-cancel">cancel</span>
          </button>
        </div>
      </div>
    </div>
    } }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    @if (!isCheckImport) {
    <button
      matRipple
      class="btn btn-primary btn-md"
      [disabled]="!file || uploadError"
      (click)="checkImport()"
    >
      ตรวจสอบ
    </button>
    } @else {
    <button matRipple class="btn btn-primary btn-md" (click)="onSaveUpload()">บันทึก</button>
    }
  </div>
</mat-dialog-actions>
