import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { imports } from '../../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
} from '../../../../helpers'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatPaginator } from '@angular/material/paginator'
import { ModalDownloadFileComponent } from '../../../../components/modal-download-file/modal-download-file'
import { HistoryService } from '../../../../services/history.service'
import { ProductService } from '../../../../services'

@Component({
  selector: 'app-product-return-log',
  standalone: true,
  imports: [...imports, MatPaginator],
  templateUrl: './product-return-log.html',
  styleUrls: ['./product-return-log.scss'],
})
export class ProductReturnLogComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment

  @Input() product: any

  readonly paginationProductReturnLog = new Pagination({
    sortBy: 'desc',
  })
  productReturnLogList: any[] = []

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public historyService: HistoryService,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getProductReturnLogList()
  }

  getProductReturnLogList() {
    const pagination = this.paginationProductReturnLog.get()
    const payload = {
      ...pagination,
      filter: {
        product_ids: [this.product.id],
      },
    }

    this.loading.start()
    this.historyService.getHistoryOrderReturnList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productReturnLogList = res.data.records

          this.paginationProductReturnLog.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalExportProductReturnLog(title: string, detail: any): void {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exportProductReturnLog()
      }
    })
  }

  exportProductReturnLog() {
    const pagination = this.paginationProductReturnLog.get()
    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      filter: {
        product_ids: [this.product.id],
      },
    }
    this.loading.start()
    this.productService.exportProductOrderReturn(this.product.id, payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `customer-product-return.xlsx`)
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
