<mat-form-field appearance="outline" class="input" [class.hide-error]="hideError">
  <mat-date-range-input [rangePicker]="picker" [min]="min" [max]="max">
    <input
      matStartDate
      readonly
      placeholder="{{ placeholderStr }}"
      [formControl]="formControlStr" />
    <input matEndDate readonly placeholder="{{ placeholderEnd }}" [formControl]="formControlEnd" />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker">
    <img
      src="/assets/images/icons/icon-date-picker.svg"
      matDatepickerToggleIcon
      class="iconSuffix" />
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker>
    <mat-date-range-picker-actions
      [spellcheck]="false"
      [nonce]="true"
      [inert]="true"
      [contentEditable]="true"
      [autocapitalize]="true">
      <div class="d-flex justify-content-between w-100">
        <button
          matRipple
          [disabled]="disabled"
          class="btn btn-outline-secondary"
          (click)="formControlStr.reset();formControlEnd.reset();picker.close();onChange.emit()">
          ล้าง
        </button>
        <button
          matRipple
          [disabled]="disabled"
          class="btn btn-primary"
          matDateRangePickerApply
          (click)="checkValueEnd();onChange.emit()">
          ตกลง
        </button>
      </div>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>

  @if (formControlStr.invalid || formControlEnd.invalid) {
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  }
</mat-form-field>
