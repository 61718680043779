import { Injectable } from '@angular/core'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { Loading } from '../loading'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { convertDateToApi } from '../../helpers'

@Injectable({ providedIn: 'root' })
export class FilterTableHistoryExpense extends __searchAndFitler {
  readonly list = {
    status_ids: <any[]>[
      { id: 1, text: 'ค่าใช้จ่ายในการขาย' },
      { id: 2, text: 'ค่าใช้จ่ายในการบริหาร' },
      { id: 4, text: 'ค่าใช้จ่ายอื่นๆ' },
    ],
  }

  form = new FormGroup({
    search: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  constructor(public loading: Loading, public customSnackBar: CustomSnackBar) {
    const filter = {
      status_ids: [],
    }
    super('/history/expense', filter)
  }

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  initData() {}

  isStatusIdChecked(id: any) {
    return this.filter['status_ids'].some((val: any) => val === id)
  }

  statusIdChange(id: any) {
    const status_ids = this.filter['status_ids']
    if (status_ids.includes(id)) {
      status_ids.splice(status_ids.indexOf(id), 1)
    } else {
      status_ids.push(id)
    }
  }

  removeStatusId(id: any) {
    this.statusIdChange(id)
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const status_ids = this.filter['status_ids']
    if (status_ids.length) {
      filter.expense_category_ids = status_ids
    }

    data.filter = filter

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
