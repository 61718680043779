import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private service: Service) {}
  private pathTag = '/v1/tags'

  getTagList(payload: any): Observable<any> {
    return this.service.post(`${this.pathTag}/query`, payload)
  }
  getTag(id: number): Observable<any> {
    return this.service.get(`${this.pathTag}/${id}`)
  }
  addTag(payload: any): Observable<any> {
    return this.service.post(`${this.pathTag}`, payload)
  }
  updateTag(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathTag}/${id}`, payload)
  }
  deleteTag(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathTag}/${id}`, payload)
  }
}
