import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private service: Service) {}

  private pathHistoryOrder = '/v1/order-histories'
  getHistoryOrderList(payload: any): Observable<any> {
    return this.service.post(`${this.pathHistoryOrder}/orders/query`, payload)
  }
  exportHistoryOrderList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathHistoryOrder}/orders/export`, payload)
  }
  getHistoryOrderInvoiceList(payload: any): Observable<any> {
    return this.service.post(`${this.pathHistoryOrder}/invoices/query`, payload)
  }
  exportHistoryOrderInvoiceList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathHistoryOrder}/invoices/export`, payload)
  }
  getHistoryOrderDeletedList(payload: any): Observable<any> {
    return this.service.post(`${this.pathHistoryOrder}/deleted/query`, payload)
  }
  exportHistoryOrderDeletedList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathHistoryOrder}/deleted/export`, payload)
  }

  private pathHistoryProductInOrder = '/v1/order-product-histories'
  getHistoryProductInOrderList(payload: any): Observable<any> {
    return this.service.post(`${this.pathHistoryProductInOrder}/query`, payload)
  }
  exportHistoryProductInOrderList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathHistoryProductInOrder}/export`, payload)
  }

  private pathHistoryOrderReturn = '/v1/order-return-histories'
  getHistoryOrderReturnList(payload: any): Observable<any> {
    return this.service.post(`${this.pathHistoryOrderReturn}/query`, payload)
  }
  exportHistoryOrderReturnList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathHistoryOrderReturn}/export`, payload)
  }

  private pathHistoryPayment = '/v1/payment-histories'
  getHistoryPaymentList(payload: any): Observable<any> {
    return this.service.post(`${this.pathHistoryPayment}/query`, payload)
  }
  exportHistoryPaymentList(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathHistoryPayment}/export`, payload)
  }
}
