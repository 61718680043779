<div class="page-content-inner">
  <div class="title-black">รายงานผู้ใช้งาน</div>

  <div class="top-content-wrapper">
    <div class="top-content-left">
      <app-search-filter-report-user
        placeholder="ค้นหา"
        (onConfirm)="paginationReportUser.reset();getReportUserList();itemChecked.clear()"></app-search-filter-report-user>
    </div>
    <div class="top-content-right mb-3">
      @if (profile.permissions.checkAccess([APP_PERMISSION.REPORT], [ACTION_ACCESS.EXPORT])) {
      <button
        matRipple
        class="btn btn-outline-secondary"
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายงานผู้ใช้งาน', { message: 'คุณต้องการดาวน์โหลดไฟล์รายงานผู้ใช้งานที่เลือกใช่หรือไม่' })">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive table-main-tabs-wrapper">
    <table class="table table-fixed-column">
      <thead>
        <tr class="tr-vertical-top">
          <th width="70">
            <app-checkbox-all-table
              [checkedValue]="itemChecked"
              [datas]="reportList"
              fieldValue="id"></app-checkbox-all-table>
          </th>
          <th width="230">ผู้ใช้งาน</th>
          <th width="120" class="text-end">สร้างออเดอร์</th>
          <th width="120" class="text-end">พิมพ์</th>
          <th width="120" class="text-end">ปิดรายการ</th>
          <th width="120" class="text-end">ลบออเดอร์</th>
          <th width="140" class="text-end">ยอดขาย</th>
          <th width="160" class="text-end">ยอดขาย (ไม่รวมค่าส่ง)</th>
          <th width="140" class="text-end">กำไร</th>
        </tr>
      </thead>

      <tbody>
        @for (item of reportList; track item) {
        <tr class="tr-vertical-top">
          <td class="td-checkbox">
            <app-checkbox-table [checkedValue]="itemChecked" [value]="item.id"></app-checkbox-table>
          </td>
          <td>
            <div class="d-flex flex-column">
              <div>{{ item.user_name }}</div>
              <div class="color--neutral-500">{{ item.user_email }}</div>
            </div>
          </td>
          <td class="text-end">{{ formatPriceAutoDecimal(item.count_created) }}</td>
          <td class="text-end">{{ formatPriceAutoDecimal(item.count_printed) }}</td>
          <td class="text-end">{{ formatPriceAutoDecimal(item.count_closed) }}</td>
          <td class="text-end">{{ formatPriceAutoDecimal(item.count_deleted) }}</td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td class="text-end">{{ formatPrice(item.total_price_with_out_shipping_cost) }}</td>
          <td class="text-end">{{ formatPrice(item.total_margin) }}</td>
        </tr>
        }
      </tbody>
    </table>

    @if(!reportList.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูลรายงานผู้ใช้งาน</div>
    }
  </div>

  <!-- Pagination -->
  @if (reportList.length) {
  <div class="pagination-wrapper">
    <mat-paginator
      [pageIndex]="paginationReportUser.data.pageIndex"
      [length]="paginationReportUser.data.length"
      [pageSize]="paginationReportUser.data.pageSize"
      [pageSizeOptions]="paginationReportUser.data.pageSizeOptions"
      aria-label="Select page"
      (page)="paginationReportUser.setFromPaginator($event);getReportUserList()">
    </mat-paginator>
  </div>
  }
</div>
