import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatRipple } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AbstractControl, FormControl } from '@angular/forms'

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRipple,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './search.html',
  styleUrls: ['./search.scss'],
})
export class SearchComponent {
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''

  @Output() onEnter = new EventEmitter()

  showModal: boolean = false

  constructor(public dialog: MatDialog) {}

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  close() {
    this.control.reset()
    this.onEnter.emit()
  }
}
