<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ประวัติ</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">
      @if(form.value.history == 1) { ออเดอร์
      <!-- ~ -->
      } @else if (form.value.history == 2) { สินค้าในออเดอร์
      <!-- ~ -->
      } @else if (form.value.history == 3) { รายการคืนสินค้า
      <!-- ~ -->
      } @else if (form.value.history == 4) { รายการค่าใช้จ่าย
      <!-- ~ -->
      } @else if (form.value.history == 5) { การเพิ่มสต๊อกสินค้า
      <!-- ~ -->
      } @else if (form.value.history == 6) { การชำระเงิน
      <!-- ~ -->
      }
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">
      {{ subTitle[$any(form.value.history)]?.[tab] || 'ข้อมูลทั่วไป'}}
    </div>
  </div>

  <div class="history-select-wrapper">
    <app-select
      [control]="form.controls['history']"
      [datas]="list.history"
      fieldValue="value"
      placeholder="เลือก"
    >
      <ng-template #optionRef let-option> {{ option.text }} </ng-template>

      @if (form.controls['history'].hasError('required')) {
      <ng-container error>error</ng-container>
      }
    </app-select>
  </div>

  @if (form.value.history == 1) {
  <app-history-order></app-history-order>
  <!-- ~ -->
  } @else if (form.value.history == 2) {
  <app-product-in-order></app-product-in-order>
  <!-- ~ -->
  } @else if (form.value.history == 3) {
  <app-product-return></app-product-return>
  <!-- ~ -->
  } @else if (form.value.history == 4) {
  <app-expense-item></app-expense-item>
  <!-- ~ -->
  } @else if (form.value.history == 5) {
  <app-restocking></app-restocking>
  <!-- ~ -->
  } @else if (form.value.history == 6) {
  <app-history-payment></app-history-payment>
  <!-- ~ -->
  }
</div>
