export const GENDER_ID = {
  MALE: 0,
  FEMALE: 1,
  OTHER: 3,
} as const

export const genders = [
  { id: 1, name: 'ไม่ระบุ' },
  { id: 2, name: 'ชาย' },
  { id: 3, name: 'หญิง' },
  { id: 4, name: 'อื่นๆ' },
]

export const genderNameFromId = (gender_id: any) =>
  genders.find((g: any) => g.id === gender_id)?.name || ''

export const productImage = (product: any, product_detail?: any) => {
  const cover = product.files.find((f: any) => f.is_cover)
  if (product_detail) {
    return product_detail?.files?.[0] || cover
  } else {
    return cover || product.files[0]
  }
}

export const imgProductDefault = '/assets/images/icons/img-default-table.svg'

export const productKey = (product_id: any, product_detail_id: any) => {
  return `${product_id}-${product_detail_id || ''}`
}
