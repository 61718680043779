import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  computed,
  model,
} from '@angular/core'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatSelect, MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { CommonModule } from '@angular/common'
import { SanitizeHtmlPipe } from '../../pipes/inner-html'

@Component({
  selector: 'app-select[control][datas][fieldValue]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatSuffix,
    MatSelectModule,
    MatInputModule,
    SanitizeHtmlPipe,
  ],
  templateUrl: './select.html',
  styleUrls: ['./select.scss'],
})
export class SelectComponent implements OnInit {
  @ContentChild(TemplateRef) optionRef: any
  @Input() control!: FormControl | AbstractControl
  @Input() placeholder = ''
  @Input() label = ''
  @Input() datas!: any[]
  @Input() fieldValue!: any
  @Input() disabled = false
  @Input() multiple = false
  @Input() type = ''
  @Input() remove_bottom = false
  readonly searchText = model('')
  @Input() search: string[] = []
  @Input() empty = 'ไม่พบข้อมูล'
  @Input() disabledNotAllOption = false

  @Output() selectionChange = new EventEmitter()
  @Output() onSelectionChange = new EventEmitter()
  // @Input('eng')
  // eng?: TemplateRef<any>;
  @ViewChild('select') select: any

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  get tiggerInnerHtml() {
    const htmls: string[] = []

    if (this.select) {
      const selected = this.select.selected
      if (selected) {
        if (Array.isArray(selected)) {
          for (const option of selected) {
            htmls.push(this.#getTextHtml(option))
          }
        } else {
          htmls.push(this.#getTextHtml(selected))
        }
      }
    }

    return htmls
  }

  get dataSelect() {
    const value = this.control.value
    if (value) {
      const values = Array.isArray(value) ? value : [value]
      return this.datas.filter((d: any) => values.includes(d[this.fieldValue]))
    }
    return []
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
    if (changes['datas']) {
    }
  }

  #getTextHtml(option: any) {
    return (
      option._text.nativeElement.firstChild.outerHTML ||
      option._text.nativeElement.firstChild.textContent
    )
  }

  isNoSearch(option: any) {
    if (!this.search.length) return false
    if (!this.searchText) return false
    const searchText = this.searchText().toLowerCase()
    return !this.search.some(
      (field: string) =>
        typeof option[field] == 'string' && option[field].toLowerCase().includes(searchText)
    )
  }
}
