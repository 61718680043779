import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(private service: Service) {}
  private pathSupplier = '/v1/suppliers'

  getSupplierList(payload: any): Observable<any> {
    return this.service.post(`${this.pathSupplier}/query`, payload)
  }
  getSupplier(id: number): Observable<any> {
    return this.service.get(`${this.pathSupplier}/${id}`)
  }
  addSupplier(payload: any): Observable<any> {
    return this.service.post(`${this.pathSupplier}`, payload)
  }
  updateSupplier(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathSupplier}/${id}`, payload)
  }
  deleteSupplier(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathSupplier}/${id}`, payload)
  }
}
