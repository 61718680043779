<mat-form-field appearance="outline" class="select">
  <mat-select
    [formControl]="formControl"
    [placeholder]="placeholder"
    [hideSingleSelectionIndicator]="true"
    panelClass="select-sort"
    #select
    (selectionChange)="selectionChange.emit()"
  >
    <mat-select-trigger>
      <div class="d-flex align-items-center gap-2" [ngStyle]="{ 'margin-top': '1px' }">
        <span class="icon icon-dark material-symbols-outlined fill">sort</span>
        <ng-container
          *ngTemplateOutlet="triggerRef; context: { $implicit: dataSelect }"
        ></ng-container>
      </div>
    </mat-select-trigger>

    @for (option of datas; track option) {
    <mat-option [value]="option[fieldValue]">
      <ng-container *ngTemplateOutlet="optionRef; context: { $implicit: option }"></ng-container>
    </mat-option>
    }
  </mat-select>

  @if (control.invalid) {
  <mat-error>
    <ng-content select="[error]"></ng-content>
  </mat-error>
  }
</mat-form-field>
