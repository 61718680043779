import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, convertDateToApi } from '../../helpers'
import { RoleService } from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'

@Injectable({ providedIn: 'root' })
export class FilterTablePromotion extends __searchAndFitler {
  readonly list = {
    promotion_status_ids: <any[]>[
      { id: 1, text: 'ใช้งาน' },
      { id: 2, text: 'ไม่ใช้งาน' },
      { id: 3, text: 'หมดอายุ' },
      { id: 4, text: 'ไม่หมดอายุ' },
    ],
  }

  form = new FormGroup({
    start_date: new FormControl(''),
    end_date: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  get startEndDateFormat() {
    return [this.form.controls['start_date'].value, this.form.controls['end_date'].value]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  constructor(
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar
  ) {
    const filter = {
      promotion_status_ids: [],
    }
    super('/promotion', filter)

    this._initForm(this.form)
  }

  initData() {}

  isStatusIdChecked(id: any) {
    return this.filter['promotion_status_ids'].some((val: any) => val === id)
  }

  statusIdChange(id: any) {
    const promotion_status_ids = this.filter['promotion_status_ids']
    if (promotion_status_ids.includes(id)) {
      promotion_status_ids.splice(promotion_status_ids.indexOf(id), 1)
    } else {
      promotion_status_ids.push(id)
    }
  }

  removeStatusId(id: any) {
    this.statusIdChange(id)
  }

  removeStartEndDate() {
    this.form.controls['start_date'].reset()
    this.form.controls['end_date'].reset()
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const promotion_status_ids = this.filter['promotion_status_ids']
    if (promotion_status_ids.length) {
      filter.promotion_status_ids = promotion_status_ids
    }

    const value = this.form.getRawValue()
    if (value.start_date) {
      filter.start_date = convertDateToApi(value.start_date)
    }
    if (value.end_date) {
      filter.end_date = convertDateToApi(value.end_date, 'day')
    }

    if (Object.keys(filter).length) {
      data.filter = filter
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
