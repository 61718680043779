import { Component, EventEmitter, Injectable, Input, OnInit, Output, inject } from '@angular/core'
import { MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatDialog } from '@angular/material/dialog'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../globals'
import { CustomerService, RoleService } from '../../../../../services'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { ModalFilterComponent } from '../modal-filter/modal-filter'
import { InputSearchComponent } from '../../../../../components/input-search/input-search'
import { MatPaginator } from '@angular/material/paginator'
import { imports } from '../../../../../imports'
import { SearchComponent } from '../../../../../components/search/search'
import { DownloadFileBlob, Moment, Pagination, formatPrice } from '../../../../../helpers'
import { ModalDownloadFileComponent } from '../../../../../components/modal-download-file/modal-download-file'

@Component({
  selector: 'app-promotion-list[dataManage]',
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    ModalFilterComponent,
    InputSearchComponent,
    MatPaginator,
    SearchComponent,
  ],
  templateUrl: './promotion-list.html',
  styleUrls: ['./promotion-list.scss'],
})
export class PromotionListComponent {
  @Input() dataManage!: any

  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  search = new FormControl('')
  readonly paginationPromotion = new Pagination({
    sortBy: 'desc',
  })
  promotionList: any[] = []

  constructor(
    public profile: Profile,
    public dialog: MatDialog,
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.getCustomerPromotionList()
  }

  getCustomerPromotionList() {
    const pagination = this.paginationPromotion.get()
    const payload = {
      ...pagination,
      search: this.search.value || null,
    }

    this.loading.start()
    this.customerService
      .getCustomerPromotionList(this.dataManage.id, payload)
      .subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            this.promotionList = res.data.records
            this.paginationPromotion.setFromResponse(res.data)
          } else {
            this.customSnackBar.fail()
          }
        }

        this.loading.stop()
      })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const pagination = this.paginationPromotion.get()
    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
    }

    this.loading.start()
    this.customerService
      .exportCustomerPromotion(this.dataManage.id, payload)
      .subscribe((res: any) => {
        if (res) {
          if (!res.is_error) {
            DownloadFileBlob(res.body, `${this.dataManage.name}-promotions.xlsx`)
            this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
          } else {
            this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
          }
        }
        this.loading.stop()
      })
  }
}
