import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { imports } from '../../../../imports'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../globals'
import {
  DownloadFileBlob,
  Moment,
  Pagination,
  formatPrice,
  formatPriceAutoDecimal,
} from '../../../../helpers'
import { MatDialog } from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ProductStockService } from '../../../../services'
import { MatPaginator } from '@angular/material/paginator'
import { ModalDownloadFileComponent } from '../../../../components/modal-download-file/modal-download-file'

@Component({
  selector: 'app-product-stock-log',
  standalone: true,
  imports: [...imports, MatPaginator],
  templateUrl: './product-stock-log.html',
  styleUrls: ['./product-stock-log.scss'],
})
export class ProductStockLogComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment

  @Input() product: any

  readonly paginationStockLog = new Pagination({
    sortBy: 'desc',
  })
  productStockLogList: any[] = []

  constructor(
    public profile: Profile,
    public router: Router,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public productStockService: ProductStockService
  ) {}

  ngOnInit(): void {
    this.getProductStockLogList()
  }

  getProductStockLogList() {
    const pagination = this.paginationStockLog.get()
    const payload = {
      ...pagination,
      filter: {
        product_id: this.product.id,
      },
    }

    this.loading.start()
    this.productStockService.getProductStockLogList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productStockLogList = res.data.records
          this.paginationStockLog.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalExportStrockLog(title: string, detail: any): void {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exportStrockLog()
      }
    })
  }

  exportStrockLog() {
    const pagination = this.paginationStockLog.get()
    const payload = {
      sort_by: pagination.sort_by,
      sort_name: pagination.sort_name,
      filter: {
        product_id: this.product.id,
      },
    }

    this.loading.start()
    this.productStockService.exportProductStockLog(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `product-stock-logs.xlsx`)
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
