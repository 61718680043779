import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CompanyProfileService {
  constructor(private service: Service) {}
  private pathCompanyProfile = '/v1/company-profile'

  getCompanyProfile(): Observable<any> {
    return this.service.get(`${this.pathCompanyProfile}`)
  }
  updateCompanyProfile(payload: any): Observable<any> {
    return this.service.put(`${this.pathCompanyProfile}`, payload)
  }
}
