import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'

@Component({
  selector: 'app-checkbox-all-table[checkedValue][datas]',
  standalone: true,
  imports: [MatCheckboxModule],
  templateUrl: './checkbox-all-table.component.html',
  styleUrls: ['./checkbox-all-table.component.scss'],
})
export class CheckboxAllTableComponent implements OnInit {
  @Input() checkedValue!: Set<any>
  @Input() datas: any[] = []
  @Input() fieldValue = ''
  @Input() disabled = false
  @Output() onChange = new EventEmitter()

  ngOnInit(): void {}

  get isChecked() {
    return !!(
      this.datas.length &&
      !this.datas.some((data: any) => !this.checkedValue.has(this.getValue(data)))
    )
  }

  get isDisabled() {
    return this.disabled
  }

  handleClick() {
    if (this.isDisabled) return
    if (this.isChecked) {
      for (const data of this.datas) {
        this.checkedValue.delete(this.getValue(data))
      }
    } else {
      for (const data of this.datas) {
        this.checkedValue.add(this.getValue(data))
      }
    }
    this.onChange.emit(this.isChecked)
  }

  getValue(data: any) {
    return this.fieldValue ? data[this.fieldValue] : data
  }
}
