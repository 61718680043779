import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { imports } from '../../../imports'
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu'
import { TagService } from '../../../services'

@Component({
  selector: 'app-input-tag[control]',
  standalone: true,
  imports: [...imports, MatInputModule, MatSuffix, MatMenuModule],
  templateUrl: './input-tag.html',
  styleUrls: ['./input-tag.scss'],
})
export class InputTagComponent {
  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger
  @ViewChild('input') input: any

  @Input() control!: FormControl | AbstractControl
  @Input() disabled = false

  @Output() onInput = new EventEmitter()
  @Output() onChange = new EventEmitter()
  @Output() onEnter = new EventEmitter()

  get formControl() {
    if (this.control instanceof AbstractControl) {
      return this.control as FormControl
    }
    return this.control
  }

  get values() {
    return this.control.value || []
  }

  get isOpen() {
    return this.trigger?.menuOpen
  }

  constructor(public tagService: TagService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.control.disable()
      } else {
        this.control.enable()
      }
    }
  }

  enter(e: any) {
    const name = e.target.value
    const payload = {
      name,
      is_active: true,
    }
    this.tagService.addTag(payload).subscribe(res => {
      if (!res.is_error) {
        this.control.setValue([
          {
            id: res.data,
            name,
          },
          ...this.values,
        ])
      }
    })
    e.target.value = ''
  }

  delItem(item: any) {
    this.values.splice(this.values.indexOf(item), 1)
    this.control.updateValueAndValidity()
    setTimeout(() => {
      this.input.nativeElement.focus()
    })
  }
}
