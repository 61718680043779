import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { SettingMenuComponent } from '../../components/setting-menu/setting-menu'
import { imports } from '../../imports'

@Component({
  selector: 'app-setting',
  standalone: true,
  imports: [...imports, RouterOutlet, SettingMenuComponent],
  templateUrl: './setting.html',
  styleUrls: ['./setting.scss'],
})
export class SettingComponent {}
