import { Component } from '@angular/core'
import { imports } from '../../imports'

@Component({
  selector: 'app-permission-denied',
  standalone: true,
  imports: [...imports],
  templateUrl: './permission-denied.html',
  styleUrls: ['./permission-denied.scss'],
})
export class PermissionDeniedComponent {}
