export const MARKETPLACE_SHOP = {
  SHOPEE: 'Shopee',
  LAZADA: 'Lazada',
} as const

export type EnumMarketplaceShop = (typeof MARKETPLACE_SHOP)[keyof typeof MARKETPLACE_SHOP]

export const MARKETPLACE_SHOP_STORAGE = {
  LAZADA: 'lazada_code',
} as const
