import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private service: Service) {}
  private pathCustomer = '/v1/customers'

  getCustomerList(payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomer}/query`, payload)
  }
  getCustomer(id: number): Observable<any> {
    return this.service.get(`${this.pathCustomer}/${id}`)
  }
  addCustomer(payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomer}`, payload)
  }
  updateCustomer(id: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathCustomer}/${id}`, payload)
  }
  deleteCustomer(id: number, payload: any = {}): Observable<any> {
    return this.service.delete(`${this.pathCustomer}/${id}`, payload)
  }
  exportCustomer(payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathCustomer}/export`, payload)
  }
  checkImportCustomer(payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomer}/pre-import`, payload)
  }
  importCustomer(payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomer}/import`, payload)
  }
  templateCustomer(payload: any): Observable<any> {
    return this.service.getBlob(`${this.pathCustomer}/template`, payload)
  }

  private pathCustomerCarat = '/v1/customer-carats'
  getCustomerCaratList(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerCarat}/${customerId}/query`, payload)
  }
  addCustomerCarat(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerCarat}/${customerId}`, payload)
  }
  exportCustomerCarat(customerId: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathCustomerCarat}/${customerId}/export`, payload)
  }

  private pathCustomerCredit = '/v1/customer-credits'
  getCustomerCreditList(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerCredit}/${customerId}/query`, payload)
  }
  addCustomerCredit(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerCredit}/${customerId}`, payload)
  }
  exportCustomerCredit(customerId: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathCustomerCredit}/${customerId}/export`, payload)
  }

  private pathCustomerOrder = '/v1/customer-orders'
  getCustomerOrderList(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerOrder}/${customerId}/orders/query`, payload)
  }
  getCustomerOrderFilters(customerId: any, payload: any): Observable<any> {
    return this.service.get(`${this.pathCustomerOrder}/${customerId}/orders/filters`, payload)
  }
  getCustomerOrderProductList(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerOrder}/${customerId}/products/query`, payload)
  }
  exportCustomerOrderProduct(customerId: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathCustomerOrder}/${customerId}/products/export`, payload)
  }

  private pathCustomerPromotion = '/v1/customer-promotions'
  getCustomerPromotionList(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerPromotion}/${customerId}/query`, payload)
  }
  exportCustomerPromotion(customerId: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathCustomerPromotion}/${customerId}/export`, payload)
  }

  private pathCustomerPurchase = '/v1/customer-purchases'
  getCustomerPurchaseList(customerId: any, payload: any): Observable<any> {
    return this.service.post(`${this.pathCustomerPurchase}/${customerId}/query`, payload)
  }
  exportCustomerPurchase(customerId: any, payload: any): Observable<any> {
    return this.service.postBlob(`${this.pathCustomerPurchase}/${customerId}/export`, payload)
  }
}
