import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Service } from './service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private service: Service) {}
  // Auths
  private pathAuths = '/v1';
  // Auths Service
  getInfo(payload: any): Observable<any> {
    return this.service.get(`${this.pathAuths}/AuthInfo`, payload);
  }
  login(payload: any): Observable<any> {
    return this.service.post(`${this.pathAuths}/login`, payload);
  }
  logout(payload: any): Observable<any> {
    return this.service.post(`${this.pathAuths}/logout`, payload);
  }
  refreshToken(payload: any): Observable<any> {
    return this.service.post(`${this.pathAuths}/refresh-token`, payload);
  }
  forgotPassword(payload: any): Observable<any> {
    return this.service.post(`${this.pathAuths}/forgot-password`, payload);
  }
  changePassword(payload: any): Observable<any> {
    return this.service.post(`${this.pathAuths}/change-password`, payload);
  }
  getProfile(): Observable<any> {
    return this.service.get(`${this.pathAuths}/profile`);
  }
}
