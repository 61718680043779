import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { SelectComponent } from '../../../../components/select/select'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { imports } from '../../../../imports'
import { Loading } from '../../../../globals'
import { MaketplaceService, ProductStockService } from '../../../../services'
import { MARKETPLACE_SHOP, ValidateForm, setErrorForm } from '../../../../helpers'
import { InputNumberComponent } from '../../../../components/input-number/input-number'

@Component({
  selector: 'app-modal-adjust-stock-marketplace',
  templateUrl: './modal-adjust-stock.html',
  styleUrls: ['./modal-adjust-stock.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputNumberComponent,
    SelectComponent,
    TextareaComponent,
  ],
})
export class ModalAdjustStockComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalAdjustStockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public maketplaceService: MaketplaceService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    this.form.reset({
      stock: this.data.item.stock,
    })
    this.form.controls.stock.addValidators((control: AbstractControl) => {
      if (control.value && control.value > this.data.item.stock) {
        return {
          over: true,
        }
      }
      return null
    })
  }

  form = new FormGroup({
    stock: new FormControl<number | null>(null, [Validators.required, Validators.max(1000000)]),
  })

  onClose(): void {
    this.dialogRef.close()
  }

  onSave(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    const item = this.data.item
    const value = this.form.getRawValue()
    const stock = value.stock || 0
    const payload = {
      product_id: item.product_id,
      product_detail_id: item.product_detail_id,
      cost_price: item.cost_price,
      stock,
    }

    // return console.log(payload)

    const api =
      this.data.shop == MARKETPLACE_SHOP.SHOPEE
        ? this.maketplaceService.updateShopee(item.id, payload)
        : this.maketplaceService.updateLazada(item.id, payload)

    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.dialogRef.close(true)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }
}
