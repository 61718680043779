import { Routes } from '@angular/router'
import { NotFoundComponent } from './modules/not-found/not-found'
import { LoginComponent } from './modules/login/login'
import { SettingComponent } from './modules/setting/setting'
import { UserManagementComponent } from './modules/setting/user-management/user-management'
import {
  authGuardAppPermission,
  authGuardSetting,
  deactivateCaratManage,
  deactivateCompanyManagementEdit,
  deactivateCustomerManage,
  deactivateOrderManage,
  deactivatePaymentChannelEdit,
  deactivateProductManage,
  deactivateRoleEdit,
  deactivateSupplierManage,
} from './app.guard'
import { APP_PERMISSION } from './globals'
import { PermissionDeniedComponent } from './modules/permission-denied/permission-denied'
import { ResetPasswordComponent } from './modules/reset-pass/reset-pass'
import { OrderComponent } from './modules/order/order'
import { CompanyManagementComponent } from './modules/setting/company-manage/company-manage'
import { ProductCategoryComponent } from './modules/setting/product-category/product-category'
import { ProductBrandComponent } from './modules/setting/product-brand/product-brand'
import { PaymentChannelComponent } from './modules/setting/payment-channel/payment-channel'
import { PromotionComponent } from './modules/setting/promotion/promotion'
import { CustomerManageComponent } from './modules/customer-manage/customer-manage'
import { TierComponent } from './modules/setting/tier/tier'
import { CustomerDetailComponent } from './modules/customer-manage/tabs/tab-customer/customer-detail/customer-detail'
import { SupplierDetailComponent } from './modules/customer-manage/tabs/tab-supplier/supplier-detail/supplier-detail'
import { ProductComponent } from './modules/product/product'
import { ProductDetailComponent } from './modules/product/product-detail/product-detail'
import { CaratsComponent } from './modules/setting/carats/carats'
import { OrderDetailComponent } from './modules/order/order-detail/order-detail'
import { OrderPaymentComponent } from './modules/order-payment/order-payment'
import { HistoryComponent } from './modules/history/history'
import { OrderReturnComponent } from './modules/order/order-return/order-return'
import { HistoryOrderLogsComponent } from './modules/history/order/history-order-logs/history-order-logs'
import { MarketplaceComponent } from './modules/setting/marketplace/marketplace'
import { ReportComponent } from './modules/report/report'
import { RecordExpensesComponent } from './modules/record-expenses/record-expenses'
import { CoverSheetPublicComponent } from './modules/order/cover-sheet-public/cover-sheet-public'
import { CoverSheetPrivateComponent } from './modules/order/cover-sheet-private/cover-sheet-private'
import { CoverSheetLogComponent } from './modules/order/cover-sheet-log/cover-sheet-log'
import { HomeComponent } from './modules/home/home'
import { TempComponent } from './modules/temp/temp'

export const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [authGuardAppPermission] },
  {
    path: 'order',
    component: OrderComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.ORDER],
    },
  },
  {
    path: 'order/create',
    component: OrderDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.ORDER],
    },
  },
  {
    path: 'order/cover-sheet/:view',
    component: CoverSheetPrivateComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.ORDER],
    },
  },
  {
    path: 'order/cover-sheet-public/:view',
    component: CoverSheetPublicComponent,
  },
  {
    path: 'order/edit/:id',
    component: OrderDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.ORDER],
    },
    canDeactivate: [deactivateOrderManage],
  },
  {
    path: 'order/return/:id',
    component: OrderReturnComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.ORDER],
    },
  },
  {
    path: 'order-payment/:link_code',
    component: OrderPaymentComponent,
  },
  {
    path: 'product',
    component: ProductComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.PRODUCT],
    },
  },
  {
    path: 'product/create',
    component: ProductDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.PRODUCT],
    },
    canDeactivate: [deactivateProductManage],
  },
  {
    path: 'product/edit/:id',
    component: ProductDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.PRODUCT],
    },
    canDeactivate: [deactivateProductManage],
  },
  {
    path: 'product/view/:id',
    component: ProductDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.PRODUCT],
    },
  },
  {
    path: 'customer',
    component: CustomerManageComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.CUSTOMER, APP_PERMISSION.SUPPLIER],
    },
  },
  {
    path: 'customer/create',
    component: CustomerDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.CUSTOMER],
    },
    canDeactivate: [deactivateCustomerManage],
  },
  {
    path: 'customer/edit/:id',
    component: CustomerDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.CUSTOMER],
    },
    canDeactivate: [deactivateCustomerManage],
  },
  {
    path: 'customer/view/:id',
    component: CustomerDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.CUSTOMER],
    },
  },
  {
    path: 'supplier/create',
    component: SupplierDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.CUSTOMER],
    },
    canDeactivate: [deactivateSupplierManage],
  },
  {
    path: 'supplier/edit/:id',
    component: SupplierDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.CUSTOMER],
    },
    canDeactivate: [deactivateSupplierManage],
  },
  {
    path: 'supplier/view/:id',
    component: SupplierDetailComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.CUSTOMER],
    },
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.HISTORY],
    },
  },
  {
    path: 'history/logs/:id',
    component: HistoryOrderLogsComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.HISTORY],
    },
  },
  {
    path: 'history/sheet-log/:view',
    component: CoverSheetLogComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.HISTORY],
    },
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.REPORT],
    },
  },
  {
    path: 'record-expenses',
    component: RecordExpensesComponent,
    canActivate: [authGuardAppPermission],
    data: {
      permissions: [APP_PERMISSION.EXPENSE],
    },
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [authGuardSetting],
    children: [
      {
        path: 'user-management',
        component: UserManagementComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.USER_MANAGEMENT, APP_PERMISSION.ROLE_AND_PERMISSION],
        },
        canDeactivate: [deactivateRoleEdit],
      },
      {
        path: 'company-manage/view',
        component: CompanyManagementComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.COMPANY],
        },
      },
      {
        path: 'company-manage/edit',
        component: CompanyManagementComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.COMPANY],
        },
        canDeactivate: [deactivateCompanyManagementEdit],
      },
      {
        path: 'product-category',
        component: ProductCategoryComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.PRODUCT_CATEGORY],
        },
      },
      {
        path: 'product-brand',
        component: ProductBrandComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.BRAND_PRODUCT],
        },
      },
      {
        path: 'payment-channel',
        component: PaymentChannelComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.BANK_COMPANY],
        },
        canDeactivate: [deactivatePaymentChannelEdit],
      },
      {
        path: 'promotion',
        component: PromotionComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.PROMOTION],
        },
      },
      {
        path: 'carats/view',
        component: CaratsComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.CARATS],
        },
      },
      {
        path: 'carats/edit',
        component: CaratsComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.CARATS],
        },
        canDeactivate: [deactivateCaratManage],
      },
      {
        path: 'tier',
        component: TierComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.TIER],
        },
      },
      {
        path: 'marketplace',
        component: MarketplaceComponent,
        canActivate: [authGuardAppPermission],
        data: {
          permissions: [APP_PERMISSION.MARKETPLACE],
        },
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'temp', component: TempComponent },
  { path: 'denied', component: PermissionDeniedComponent },
  { path: '**', component: NotFoundComponent },
]
