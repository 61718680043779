import { Component, ViewChild } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormArray,
  AbstractControl,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatPaginator } from '@angular/material/paginator'
import { forkJoin } from 'rxjs'
import { OrderListComponent } from '../order-list/order-list'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../../../globals'
import { ModalLeaveComponent } from '../../../../../components/modal-leave/modal-leave'
import {
  EmailReg,
  Moment,
  ValidateForm,
  convertDateToApi,
  formatPrice,
  channelId,
  channelIcon,
  setErrorForm,
  formatPriceAutoDecimal,
  currenTier,
  minTier,
  checkUseCarat,
} from '../../../../../helpers'
import { InputComponent } from '../../../../../components/input/input'
import { SelectComponent } from '../../../../../components/select/select'
import { TextareaComponent } from '../../../../../components/text-area/text-area'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { InputNumberComponent } from '../../../../../components/input-number/input-number'
import { SearchComponent } from '../../../../../components/search/search'
import { ModalCreditComponent } from '../modal-credit/modal-credit'
import { ModalDeleteComponent } from '../../../../../components/modal-delete/modal-delete'
import { ModalCaratComponent } from '../modal-carat/modal-carat'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { CaratService, CustomerService, MasterService, TierService } from '../../../../../services'
import { imports } from '../../../../../imports'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm'
import { CaratListComponent } from '../carat-list/carat-list'
import { CreditListComponent } from '../credit-list/credit-list'
import { OrderProductListComponent } from '../order-product-list/order-product-list'
import { PromotionListComponent } from '../promotion-list/promotion-list'
import { PurchaseListComponent } from '../purchase-list/purchase-list'

@Component({
  selector: 'app-customer-detail',
  standalone: true,
  imports: [
    ...imports,
    MatTooltipModule,
    MatTabsModule,
    MatPaginator,
    ModalLeaveComponent,
    InputComponent,
    SelectComponent,
    ReactiveFormsModule,
    MatCheckboxModule,
    TextareaComponent,
    DatePickerComponent,
    InputNumberComponent,
    SelectComponent,
    SearchComponent,
    OrderListComponent,
    OrderProductListComponent,
    PurchaseListComponent,
    CaratListComponent,
    CreditListComponent,
    PromotionListComponent,
  ],
  templateUrl: './customer-detail.html',
  styleUrls: ['./customer-detail.scss'],
})
export class CustomerDetailComponent {
  @ViewChild('appCaratList') appCaratList?: CaratListComponent
  @ViewChild('appCreditList') appCreditList?: CreditListComponent

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly Moment = Moment
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly channelIcon = channelIcon as any

  tab = 0
  id: number = 1

  isEdit: boolean = false
  isCreate: boolean = false
  isView: boolean = false

  tempControl = new FormControl('')
  is_manage_tier = new FormControl(false)
  channels = new FormArray<FormGroup<any>>([])
  form = new FormGroup({
    code: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    is_tax: new FormControl(false),
    birth_date: new FormControl(''),
    credit: new FormControl(0),
    is_manage_tier: this.is_manage_tier,
    is_manage_carat: new FormControl(false),
    po_baht: new FormControl(null, [
      Validators.max(1000000000),
      (control: AbstractControl) => {
        if (this.is_manage_tier.value && !control.value) {
          return {
            required: true,
          }
        }

        return null
      },
    ]),
    address: new FormControl('', [Validators.required]),
    is_same_address: new FormControl(false),
    delivery_address: new FormControl('', [Validators.required]),
    telephone: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10,10}$/)]),
    email: new FormControl('', [Validators.pattern(EmailReg), Validators.maxLength(100)]),
    channels: this.channels,
  })

  formTax = new FormGroup({
    tax_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    tax_id: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{13,13}$/)]),
    tax_address: new FormControl('', [Validators.required]),
    tax_telephone: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10,10}$/)]),
    is_tax_same_address: new FormControl(false),
  })

  dataManage: any
  carat = {
    total_order_amount: 0,
  }

  list = {
    tier: <any[]>[],
    channel: <any[]>[],
  }

  get tierList() {
    return this.list.tier.filter((t: any) => t.is_active)
  }

  get tootipTier() {
    return (
      `เกณฑ์ระดับสมาชิก\n` +
      this.tierList.map((t: any) => `${t.name} : ${t.min_amount} - ${t.max_amount}`).join(`\n`)
    )
  }

  tier: any

  get minTier() {
    return minTier(this.tierList)
  }

  // get receiveCarat() {
  //   const po_baht = this.form.controls['po_baht'].value || 0
  //   if (!po_baht || !this.carat.total_order_amount) return 0
  //   const total = Math.floor(po_baht / this.carat.total_order_amount)
  //   return total > 0 ? total : 0
  // }

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public router: Router,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public customerService: CustomerService,
    public tierService: TierService,
    public caratService: CaratService,
    public masterService: MasterService
  ) {}

  ngOnInit() {
    if (this.router.url.includes('view')) {
      this.isView = true
    }
    if (this.router.url.includes('edit')) {
      this.isEdit = true
    }
    if (this.router.url.includes('create')) {
      this.isCreate = true
    }
    this.route.params.subscribe(params => {
      this.initData(params['id'])
    })

    this.form.controls['po_baht'].valueChanges.subscribe(value => {
      this.setTier()
    })
  }

  initData(id: any = this.dataManage?.id) {
    const apis = [
      this.tierService.getTierList({}),
      this.masterService.getChannel(),
      this.caratService.getCarat(1),
    ]

    if (this.isEdit || this.isView) {
      apis.push(this.customerService.getCustomer(id))
    }

    if (apis.length) {
      this.loading.start()
      forkJoin(apis).subscribe(([resTier, resChannel, resCarat, resCustomer]: any) => {
        if (resTier) {
          if (!resTier.is_error) {
            this.list.tier = resTier.data
          } else {
            this.customSnackBar.fail(resCustomer.message)
          }
        }

        if (resChannel) {
          if (!resChannel.is_error) {
            this.list.channel = resChannel.data.filter((c: any) => c.id != channelId.none)
          } else {
            this.customSnackBar.fail(resChannel.message)
          }
        }
        if (resCarat) {
          if (!resCarat.is_error) {
            if (checkUseCarat(resCarat.data)) {
              this.carat = resCarat.data
            }
          } else {
            this.customSnackBar.fail(resCarat.message)
          }
        }

        if (resCustomer) {
          if (!resCustomer.is_error) {
            this.dataManage = resCustomer.data

            this.form.reset({
              code: this.dataManage.code,
              name: this.dataManage.name,
              birth_date: this.dataManage.birth_date,
              // is_manage_tier: this.dataManage.is_manage_tier,
              // is_manage_carat: this.dataManage.is_manage_carat,
              // po_baht: this.dataManage.po_baht,
              address: this.dataManage.address,
              is_same_address: this.dataManage.is_same_address,
              delivery_address: this.dataManage.delivery_address,
              telephone: this.dataManage.telephone,
              email: this.dataManage.email,
              is_tax: this.dataManage.is_tax,
            })

            this.channels.clear()
            const channels = this.dataManage.channels || []
            for (const channel of channels) {
              this.addSocial({
                channel_id: channel.channel_id,
                name: channel.name,
              })
            }

            this.formTax.reset({
              tax_id: this.dataManage.tax_id,
              tax_name: this.dataManage.tax_name,
              is_tax_same_address: this.dataManage.is_tax_same_address,
              tax_address: this.dataManage.tax_address,
              tax_telephone: this.dataManage.tax_telephone,
            })
          } else {
            this.customSnackBar.fail(resCustomer.message)
          }
        }

        this.setTier()

        this.loading.stop()
      })
    }
  }

  setTier() {
    const po_baht = (this.form.controls['po_baht'].value || 0) + (this.dataManage?.total_price || 0)
    this.tier = currenTier(this.tierList, po_baht)
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
  }

  changeAddress(value: any) {
    if (this.form.controls.is_same_address.value) {
      this.form.controls['delivery_address'].setValue(value)
    }
    if (this.formTax.controls.is_tax_same_address.value) {
      this.formTax.controls['tax_address'].setValue(value)
    }
  }

  setSameAddress(control: AbstractControl) {
    control.setValue(this.form.controls['address'].value)
  }

  addSocial(value = { channel_id: '', name: '' }) {
    let newData = new FormGroup({
      channel_id: new FormControl(value.channel_id, [Validators.required]),
      name: new FormControl(value.name, [Validators.required]),
    })

    this.channels.push(newData)
  }

  delSocial(index: number) {
    this.channels.removeAt(index)
  }

  listSocialFilter(control: AbstractControl) {
    return this.list.channel.filter(
      (c: any) =>
        !this.channels.value.some((d: any) => d.channel_id == c.id && d.channel_id != control.value)
    )
  }

  openModalCarat(title: string): void {
    const dialogRef = this.dialog.open(ModalCaratComponent, {
      disableClose: true,
      data: {
        title,
        dataManage: this.dataManage,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.initData()
        this.appCaratList?.getCustomerCaratList()
      }
    })
  }

  openModalCredit(title: string): void {
    const dialogRef = this.dialog.open(ModalCreditComponent, {
      disableClose: true,
      data: {
        title,
        dataManage: this.dataManage,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.initData()
        this.appCreditList?.getCustomerCreditList()
      }
    })
  }

  confirmDelete() {
    const dialogRefDelete = this.dialog.open(ModalDeleteComponent, {
      data: {
        detail: `คุณต้องการลบข้อมูลลูกค้านี้ใช่หรือไม่`,
      },
    })

    dialogRefDelete.afterClosed().subscribe(result => {
      if (result) {
        this.deleteCustomer()
      }
    })

    return dialogRefDelete
  }

  deleteCustomer() {
    this.loading.start()
    this.customerService.deleteCustomer(this.dataManage.id).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('ลบข้อมูลสำเร็จ')
          this.toList()
        } else {
          this.customSnackBar.fail(res.message || 'ลบข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }

  onConfirm(): void {
    ValidateForm(this.form)

    let is_vaild_tax = true
    if (this.form.controls['is_tax'].value) {
      ValidateForm(this.formTax)
      is_vaild_tax = this.formTax.valid
    }

    if (!this.form.valid || !is_vaild_tax) return

    if (this.isEdit) {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const value = this.form.getRawValue()
    const valueTax = this.formTax.getRawValue()
    const valueChannel = this.channels.getRawValue()

    const payload = {
      code: value.code || this.dataManage?.code || 'C' + Moment().format('YYYYMMDDHHmmss'),
      name: value.name,
      birth_date: convertDateToApi(value.birth_date),
      tier_id: this.tier?.id || null,
      is_manage_tier: value.is_manage_tier,
      is_manage_carat: value.is_manage_carat,
      po_baht: value.po_baht || null,
      carat: this.dataManage?.carat,
      address: value.address,
      is_same_address: value.is_same_address,
      delivery_address: value.delivery_address,
      telephone: value.telephone,
      email: value.email,
      is_tax: value.is_tax,
      tax_id: valueTax.tax_id,
      tax_name: valueTax.tax_name,
      is_tax_same_address: valueTax.is_tax_same_address,
      tax_address: valueTax.tax_address,
      tax_telephone: valueTax.tax_telephone,
      channels: valueChannel.map((c: any) => {
        return {
          channel_id: c.channel_id,
          name: c.name,
        }
      }),
    }

    // return console.log(payload)

    const api = this.isEdit
      ? this.customerService.updateCustomer(this.dataManage.id, payload)
      : this.customerService.addCustomer(payload)

    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.isSaved = true
          this.toView(this.isCreate ? res.data : this.dataManage.id)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            if (res.errors[0]?.field) {
              setErrorForm(this.form, res.errors)
            } else {
              this.customSnackBar.failSave(res.message)
            }
          }
        }
      }
      this.loading.stop()
    })
  }

  toList() {
    this.router.navigate(['/customer'], {
      queryParams: {
        tab: 0,
      },
    })
  }

  toView(id = this.dataManage.id) {
    this.router.navigate(['/customer/view/' + id])
  }

  isSaved = false
  openModalCancel(resolveFromRouter: (value: boolean) => void): void {
    if (this.isEdit && this.form.dirty && !this.isSaved) {
      const dialogCancel = this.dialog.open(ModalLeaveComponent, {
        data: {},
      })

      dialogCancel.afterClosed().subscribe(result => {
        resolveFromRouter(!!result)
      })
    } else {
      resolveFromRouter(true)
    }
  }
}
