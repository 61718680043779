import { Component, ViewChild } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ModalLeaveComponent } from '../../components/modal-leave/modal-leave'
import { ACTION_ACCESS, APP_PERMISSION, Profile } from '../../globals'
import { TabCustomerComponent } from './tabs/tab-customer/tab-customer'
import { TabSupplierComponent } from './tabs/tab-supplier/tab-supplier'
import { imports } from '../../imports'
@Component({
  selector: 'app-customer-manage',
  standalone: true,
  imports: [
    ...imports,
    MatTabsModule,
    ModalLeaveComponent,
    TabCustomerComponent,
    TabSupplierComponent,
  ],
  templateUrl: './customer-manage.html',
  styleUrls: ['./customer-manage.scss'],
})
export class CustomerManageComponent {
  @ViewChild('tabCustomer') tabCustomer?: TabCustomerComponent
  @ViewChild('tabSupplier') tabSupplier?: TabSupplierComponent

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS

  tab = 0

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.tab = params.tab
      }
    })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
    this.navigateToTab(this.tab)

    if ($tabIndex == 0 && this.profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER])) {
      this.tabCustomer?.getCustomerList()
      return
    }
    this.tabSupplier?.getSupplierList()
  }

  navigateToTab(tab: number) {
    this.Router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      skipLocationChange: false,
    })
  }
}
