<div class="page-main-content view-print">
  <div class="top-content-wrapper">
    <div class="top-content-left">
      @if (showPrint) {
      <button matRipple class="btn btn-primary btn-md" (click)="onPrint()">พิมพ์</button>
      }
      <!-- ~ -->
      @if(isButtonConfirm && profile.permissions.checkAccess([APP_PERMISSION.ORDER],
      [ACTION_ACCESS.UPDATE])) {
      <button matRipple (click)="openModalStatusConfirm()" class="btn btn-primary">
        ทำเครื่องหมายว่าพิมพ์แล้ว
      </button>
      }
    </div>
  </div>

  @if (companyProfile) {
  <!-- ~ -->
  @if(options.address.show) {
  <!-- ~ -->
  @if (addresss.length) {
  <app-print-html-render
    group="order_no_uuid"
    type="address"
    [size]="options.address['10cm']"
    [test]="false">
    <div id="footer" class="address-page-footer page-footer">
      <div class="color--neutral-600">[[order_no]]</div>
      <div class="d-flex gap-2">
        <div class="color--neutral-600">{{ Moment().format('DD/MM/YYYY, h:mm A') }}</div>
        <div class="color--neutral-600">[[page/pages]]</div>
      </div>
    </div>

    @for (item of addresss; track $index) {
    <div
      class="address-page-item"
      [style]="{ width: options.address['10cm'].width + 'px'}"
      [attr.order_no]="item.order_no"
      [attr.order_no_uuid]="item.order_no + '-' + item.address.uuid">
      <div class="d-flex flex-column align-items-center mb-2">
        <div class="color--neutral-950">{{ item.transport_name }}</div>
        <div class="color--neutral-950">{{ item.order_no }}</div>
        <img width="104" height="40" alt="Barcode" [src]="item.barcode_order_no.base64" />
      </div>

      <div class="mb-2">
        <div class="d-flex column-gap-1 mb-1">
          <div class="cover-sheet-label">ผู้ส่ง:</div>
          <div>{{ companyProfile.sender_name || '-' }}</div>
          <div>{{ companyProfile.sender_telephone }}</div>
        </div>

        <div class="d-flex column-gap-1">
          <div class="cover-sheet-label">ที่อยู่:</div>
          <div class="address-text">{{ companyProfile.sender_address || '-' }}</div>
        </div>
      </div>
      <div class="line"></div>

      <div class="my-2">
        <div class="d-flex column-gap-1 mb-1">
          <div class="cover-sheet-label">ผู้รับ:</div>
          <div>{{ item.address.name }}</div>
          <div>{{ item.address.telephone }}</div>
        </div>

        <div class="d-flex column-gap-1">
          <div class="cover-sheet-label">ที่อยู่:</div>
          <div class="address-text">{{ item.address.address }}</div>
        </div>
      </div>
      <div class="line"></div>

      <div class="customer-info-wrapper mt-2 mb-3">
        <div>
          <span class="name-channel" [innerHTML]="item.channel_name"></span>
        </div>
        <div>{{ item.address.name }}</div>
        <div>{{ Moment(item.created_at).format('DD/MM/YYYY') }}</div>
      </div>

      <div class="table-address-page-wrapper table-auto-cut">
        <table class="table-address-page table-address">
          <thead>
            <tr>
              <th width="72">รหัสสินค้า</th>
              <th width="146">ชื่อสินค้า</th>
              <th width="40" class="text-center">จำนวน</th>
              <th width="64" class="text-end">รวม (บาท)</th>
            </tr>
          </thead>

          <tbody>
            @for (product of item.products ; track $index) {
            <tr>
              <td>{{ product.product_code || product.product.code }}</td>
              <td>{{ product.product_name }}</td>
              <td class="text-center">{{ formatPriceAutoDecimal(product.quantity) }}</td>
              <td class="text-end">{{ formatPrice(product.total_price) }}</td>
            </tr>
            }
            <!-- @for (item of temps; track $index) {
            <tr>
              <td>K120424021 _M</td>
              <td>MLB_Clothing_Black_Size(M)</td>
              <td class="text-center">1</td>
              <td class="text-end">19,500.00</td>
            </tr>
            } -->
          </tbody>
        </table>
      </div>
    </div>
    }
  </app-print-html-render>
  }
  <!-- ~ -->
  } @else if(options.receipt.show) {
  <!-- ~ -->
  @if (orders.length) {
  <app-print-html-render group="order_no" type="receipt" [size]="options.receipt.A4" [test]="false">
    <div id="footer" class="a4-page-footer page-footer">
      <div class="color--neutral-600">[[order_no]]</div>
      <div class="color--neutral-600">{{ Moment().format('DD/MM/YYYY, h:mm A') }}</div>
      <div class="color--neutral-600">[[page/pages]]</div>
    </div>

    @for (item of orders; track $index) {
    <div
      class="a4-page-item"
      [style]="{ width: options.receipt.A4.width + 'px'}"
      [attr.order_no]="item.order_no">
      <div class="table-border-wrapper" auto-cut="recript-address">
        <table class="table table-bordered table-top-wrapper">
          <thead>
            <tr>
              <th class="text-center">ผู้ส่ง</th>
              <th class="text-center">ผู้รับ</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td [style]="{ 'vertical-align': 'baseline' }">
                <div class="d-flex flex-column gap-2">
                  <div class="d-flex gap-1">
                    <div>ชื่อ:</div>
                    <div>{{ companyProfile.sender_name || '-' }}</div>
                  </div>
                  <div class="d-flex gap-1">
                    <div>เบอร์ติดต่อ:</div>
                    <div>{{ companyProfile.sender_telephone || '-' }}</div>
                  </div>
                  <div class="d-flex flex-column gap-1">
                    <div>ที่อยู่:</div>
                    <div class="address-text">{{ companyProfile.sender_address || '-' }}</div>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex gap-4">
                  <div class="d-flex flex-column align-items-center">
                    <div class="color--neutral-950 mb-1">{{ item.transport.name }}</div>
                    <div class="color--neutral-950 mb-2">{{ item.order_no }}</div>
                    <img
                      width="104"
                      height="40"
                      alt="Barcode"
                      [src]="item.barcode_order_no.base64" />
                  </div>

                  <div class="d-flex flex-column gap-2 tag-for-cut">
                    @for (ads of item.use_address; track $index) {
                    <div class="d-flex flex-column gap-2 tag-for-cut-item">
                      <div class="d-flex gap-1">
                        <div>ชื่อ:</div>
                        <div>{{ ads.name }}</div>
                      </div>
                      <div class="d-flex gap-1">
                        <div>เบอร์โทรศัพท์:</div>
                        <div>{{ ads.telephone }}</div>
                      </div>
                      <div class="d-flex flex-column gap-1">
                        <div>ที่อยู่:</div>
                        <div class="address-text">{{ ads.address }}</div>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="header-section">
        <div class="header-title text-center">ใบเสร็จรับเงิน</div>

        <div class="d-flex flex-column gap-1 mb-4">
          <div class="company-name">{{ companyProfile.name || '-' }}</div>
          <div>ที่อยู่: {{ companyProfile.address || '-' }}</div>
          <div>เบอร์ติดต่อ: {{ companyProfile.telephone || '-' }}</div>
          <div>อีเมล: {{ companyProfile.email || '-' }}</div>
          <div>หมายเลขประจำตัวผู้เสียภาษี: {{ companyProfile.tax_id }}</div>
        </div>

        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column gap-1">
            <div>ถึง</div>
            <div class="company-name">คุณ: {{ item.customer.name }}</div>
            <div>เบอร์โทรศัพท์: {{ item.customer.telephone }}</div>
            <div>ที่อยู่:{{ item.customer.address }}</div>
          </div>

          <div class="right-side-info d-flex flex-column gap-1">
            <div>เลขที่เอกสาร: {{ item.order_no }}</div>
            <div>ช่องการชำระเงิน: {{ item.payment ? paymentText(item) : '-' }}</div>
            <div>การจัดส่ง: {{ item.transport.name }}</div>
          </div>
        </div>
      </div>

      <div class="table-border-wrapper table-auto-cut">
        <table class="table table-bordered table-receipt">
          <thead>
            <tr>
              <th width="42" class="text-center">#</th>
              <th width="141">รหัสสินค้า (ID NO.)</th>
              <th width="210">ชื่อสินค้า</th>
              <th width="71" class="text-center">จำนวน</th>
              <th width="96" class="text-end">ราคา/หน่วย</th>
              <th width="138" class="text-end">จำนวนเงิน (รวมภาษี)</th>
            </tr>
          </thead>

          <tbody>
            @for (product of item.products ; track $index) {
            <tr>
              <td class="text-center">{{ $index + 1 }}</td>
              <td>{{ product.product_code }}</td>
              <td>{{ product.product_name }}</td>
              <td class="text-center">{{ formatPriceAutoDecimal(product.quantity) }}</td>
              <td class="text-end">{{ formatPrice(product.price) }}</td>
              <td class="text-end">{{ formatPrice(product.total_price) }}</td>
            </tr>
            }
            <!-- @for (item of temps; track $index) {
            <tr>
              <td class="text-center">{{ $index + 1 }}</td>
              <td>PNL220823002</td>
              <td>PANALOPE_RENE' SIGNATURE T-SHIRT WHITE(S)</td>
              <td class="text-center">1</td>
              <td class="text-end">990.00</td>
              <td class="text-end">990.00</td>
            </tr>
            } -->
          </tbody>
        </table>
      </div>

      <div class="summary-info-wrapper">
        <div class="note-wrapper"></div>
        <div class="total-wrapper receipt">
          <div class="total-item">
            <div>ส่วนลดรวมทั้งสิ้น:</div>
            <div>
              {{ formatPrice(item.discount + item.discount_code_price + item.credit_code_price +
              item.carat_code_price) }}
            </div>
          </div>
          <div class="total-item">
            <div>ค่าส่ง:</div>
            <div>{{ formatPrice(item.shipping_cost) }}</div>
          </div>
          <div class="total-item">
            <div>ราคาสุทธิ:</div>
            <div>{{ formatPrice(item.total) }}</div>
          </div>
        </div>
      </div>
    </div>
    }
  </app-print-html-render>
  }
  <!-- ~ -->
  } @else if(options.tax.show) {
  <!-- ~ -->
  @if (orders.length) {
  <app-print-html-render group="order_no" type="tax" [size]="options.tax.A4" [test]="false">
    @for (item of orders; track $index) {
    <!-- ~ -->
    @if (item.is_tax) {
    <div
      class="a4-page-item"
      [style]="{ width: options.tax.A4.width + 'px'}"
      [attr.order_no]="item.order_no">
      <div class="header-section tax-invoice-header auto-move">
        <div class="header-title text-center">
          ใบเสร็จรับเงิน / ใบกำกับภาษี {{ isCopy ? '(สำเนา / Copy)' : '(ต้นฉบับ / Original)' }}
        </div>

        <div class="header-info-wrapper">
          <div class="header-info-left">
            <div class="header-info">
              <div class="header-label">ลูกค้า / Customer</div>
              <div class="header-text">{{ item.tax_name }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">ที่อยู่ / Address</div>
              <div class="header-text">{{ item.tax_address }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">เลขผู้เสียภาษี / Tax ID</div>
              <div class="header-text">{{ item.tax_id }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">เบอร์โทรศัพท์ / Telephone Number</div>
              <div class="header-text">{{ item.tax_telephone }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">อีเมล / E-mail</div>
              <div class="header-text">{{ item.customer.email }}</div>
            </div>
          </div>

          <div class="header-info-right">
            <div class="header-info">
              <div class="header-label">เลขที่ / NO.</div>
              <div class="header-text">{{ item.invoice_no }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">วันที่ / Issue</div>
              <div class="header-text">
                {{ item.payment_date ? Moment(item.payment_date).format('DD/MM/YYYY') : '-' }}
              </div>
            </div>

            <div class="header-info">
              <div class="header-label">อ้างอิง / Ref</div>
              <div class="header-text">{{ item.order_no }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">หน้า / Page</div>
              <div class="header-text">[[page/pages]]</div>
            </div>
          </div>
        </div>

        <div class="line-grey"></div>

        <div class="header-info-wrapper">
          <div class="header-info-left">
            <div class="header-info">
              <div class="header-label">ผู้ออก / Issuer</div>
              <div class="header-text">{{ companyProfile.name || '-' }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">ที่อยู่ / Address</div>
              <div class="header-text">{{ companyProfile.address || '-' }}</div>
            </div>

            <div class="header-info">
              <div class="header-label">เลขผู้เสียภาษี / Tax ID</div>
              <div class="header-text">{{ companyProfile.tax_id }}</div>
            </div>
          </div>

          <div class="header-info-right">
            <div class="header-info">
              <div class="header-label">จัดเตรียมโดย</div>
              <div class="header-text">{{ profile.fullName || adminName }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-border-wrapper table-auto-cut">
        <table class="table table-bordered table-tax">
          <thead>
            <tr>
              <th width="42" class="text-center">#</th>
              <th width="141">รหัสสินค้า (ID NO.)</th>
              <th width="210">ชื่อสินค้า</th>
              <th width="71" class="text-center">จำนวน</th>
              <th width="96" class="text-end">ราคา/หน่วย</th>
              <th width="138" class="text-end">จำนวนเงิน (รวมภาษี)</th>
            </tr>
          </thead>

          <tbody>
            @for (product of item.products ; track $index) {
            <tr>
              <td class="text-center">{{ $index + 1 }}</td>
              <td>{{ product.product_code || product.product.code }}</td>
              <td>{{ product.product_name }}</td>
              <td class="text-center">{{ formatPriceAutoDecimal(product.quantity) }}</td>
              <td class="text-end">{{ formatPrice(product.price) }}</td>
              <td class="text-end">{{ formatPrice(product.total_price) }}</td>
            </tr>
            }
            <!-- @for (item of temps; track $index) {
            <tr>
              <td class="text-center">{{ $index + 1 }}</td>
              <td>PNL220823002</td>
              <td>PANALOPE_RENE' SIGNATURE T-SHIRT WHITE(S)</td>
              <td class="text-center">1</td>
              <td class="text-end">990.00</td>
              <td class="text-end">990.00</td>
            </tr>
            } -->
          </tbody>
        </table>
      </div>

      <div class="summary-info-wrapper">
        <div class="note-wrapper">
          <div class="note">หมายเหตุ:</div>
          <div class="total-text">({{ thaiBath(item.total) }})</div>
        </div>
        <div class="total-wrapper">
          <div class="total-item">
            <div>ส่วนลดรวมทั้งสิ้น:</div>
            <div>
              {{ formatPrice(item.discount + item.discount_code_price + item.credit_code_price +
              item.carat_code_price) }}
            </div>
          </div>
          <div class="total-item">
            <div>ค่าส่ง:</div>
            <div>{{ formatPrice(item.shipping_cost) }}</div>
          </div>
          <div class="total-item">
            <div>ยอดขายสุทธิก่อนภาษีมูลค่าเพิ่ม:</div>
            <div>{{ formatPrice(item.before_vat) }}</div>
          </div>
          <div class="total-item">
            <div>ภาษีมูลค่าเพิ่ม 7%:</div>
            <div>{{ formatPrice(item.vat_amount) }}</div>
          </div>
          <div class="total-item">
            <div>ราคาสุทธิ:</div>
            <div>{{ formatPrice(item.total) }}</div>
          </div>
        </div>
      </div>

      <div class="signature-wrapper">
        <div class="signature-item">
          <div class="line"></div>
          <div class="">ผู้รับสินค้า / Received by</div>
        </div>
        <div class="signature-item">
          <div class="line"></div>
          <div class="">ผู้ส่ง / Delivery by</div>
        </div>
        <div class="signature-item">
          <div class="line"></div>
          <div class="">ผู้รับเงิน / Cachier</div>
        </div>
        <div class="signature-item">
          <div class="line"></div>
          <div class="">ผู้อนุมัติ / Authorized</div>
        </div>
      </div>
    </div>
    }
    <!-- ~ -->
    }
  </app-print-html-render>
  }
  <!-- ~ -->
  }
  <!-- ~ -->
  }
</div>
