import { Component, Inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { AbstractControl, FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { ModalLeaveComponent } from '../../../../components/modal-leave/modal-leave'
import { InputComponent } from '../../../../components/input/input'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { Loading } from '../../../../globals'
import { ValidateForm, setErrorForm } from '../../../../helpers'
import { SelectComponent } from '../../../../components/select/select'
import { InputNumberComponent } from '../../../../components/input-number/input-number'
import { TierService } from '../../../../services'
import { forkJoin } from 'rxjs'
import { imports } from '../../../../imports'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { TextEditorComponent } from '../../../../components/text-editor/text-editor'

@Component({
  selector: 'app-modal-tier',
  templateUrl: './modal-tier.html',
  styleUrls: ['./modal-tier.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ModalConfirmComponent,
    ModalLeaveComponent,
    InputComponent,
    InputNumberComponent,
    MatSlideToggleModule,
    SelectComponent,
    TextareaComponent,
    TextEditorComponent,
  ],
})
export class ModalTierComponent implements OnInit {
  min_amount = new FormControl('', [Validators.required])

  color = '#ffffff'
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    min_amount: this.min_amount,
    max_amount: new FormControl('', [
      Validators.required,
      (control: AbstractControl) => {
        const minValue = this.min_amount.value || 0
        if (control.value && control.value < minValue) {
          return {
            min: true,
          }
        }

        return null
      },
    ]),
    description: new FormControl(''),
    is_active: new FormControl(true),
  })

  dataManage: any

  constructor(
    public dialogRef: MatDialogRef<ModalTierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public tierService: TierService
  ) {}

  ngOnInit(): void {
    this.initData()
  }

  initData() {
    const apis = []

    if (this.data.type == 'edit') {
      apis.push(this.tierService.getTier(this.data.dataManage.id))
    } else {
      this.form.reset({
        is_active: true,
      })
    }

    if (apis.length) {
      this.loading.start()
      forkJoin(apis).subscribe(([resTier]: any) => {
        if (resTier) {
          if (!resTier.is_error) {
            this.dataManage = resTier.data

            this.color = this.dataManage.color || '#ffffff'
            this.form.reset({
              name: this.dataManage.name,
              min_amount: this.dataManage.min_amount,
              max_amount: this.dataManage.max_amount,
              description: this.dataManage.description,
              is_active: this.dataManage.is_active,
            })
          } else {
            this.customSnackBar.fail(resTier.message)
          }
        }

        this.loading.stop()
      })
    }
  }

  onClose() {
    if (this.data.type == 'edit' && this.form.dirty) {
      const dialogRef = this.dialog.open(ModalLeaveComponent, {
        data: {
          data: true,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close()
        }
      })
    } else {
      this.dialogRef.close()
    }
  }

  onConfirm(): void {
    ValidateForm(this.form)
    if (!this.form.valid) return

    if (this.data.type == 'edit') {
      this.openModalConfirm()
    } else {
      this.onSave()
    }
  }

  openModalConfirm() {
    const dialogRefConfirm = this.dialog.open(ModalConfirmComponent, {
      data: {},
    })

    dialogRefConfirm.afterClosed().subscribe(result => {
      if (result) {
        this.onSave()
      }
    })

    return dialogRefConfirm
  }

  onSave(): void {
    const isEdit = this.data.type == 'edit'

    const value = this.form.getRawValue()
    const payload = {
      name: value.name,
      color: this.color,
      min_amount: value.min_amount,
      max_amount: value.max_amount,
      description: value.description,
      is_active: value.is_active,
    }

    // return console.log(payload)

    const api = isEdit
      ? this.tierService.updateTier(this.data.dataManage.id, payload)
      : this.tierService.addTier(payload)
    this.loading.start()
    api.subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.customSnackBar.success('บันทึกข้อมูลสำเร็จ')
          this.dialogRef.close(true)
        } else {
          if (res.errors[0]?.field) {
            setErrorForm(this.form, res.errors)
          } else {
            this.customSnackBar.failSave(res.message)
          }
        }
      }
      this.loading.stop()
    })
  }
}
