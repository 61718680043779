<div class="page-content-inner">
  <b class="mb-1">อันดับลูกค้า</b>

  <div class="d-flex justify-content-between flex-wrap mt-4">
    <div class="d-flex flex-wrap gap-3 mb-3">
      <div>
        <app-search
          [control]="search"
          placeholder="ค้นหา"
          (onEnter)="getCustomerRanking()"></app-search>
      </div>

      <div class="select-top">
        <app-select
          [control]="top"
          [datas]="topList"
          fieldValue="value"
          placeholder="เลือก"
          [remove_bottom]="true"
          (selectionChange)="getCustomerRanking()">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
        </app-select>
      </div>

      <app-date-picker-range
        [controlStr]="dateStr"
        [controlEnd]="dateEnd"
        [hideError]="true"
        (onChange)="getCustomerRanking()"></app-date-picker-range>
    </div>
    <div>
      @if (profile.permissions.checkAccess([APP_PERMISSION.REPORT], [ACTION_ACCESS.EXPORT])) {
      <button
        matRipple
        class="btn btn-outline-secondary"
        (click)="openModalDownloadFile('ดาวน์โหลดไฟล์อันดับลูกค้า', { message: 'คุณต้องการดาวน์โหลดไฟล์อันดับลูกค้าใช่หรือไม่' })">
        <span class="icon material-symbols-outlined fill">download_2</span>
        ส่งออกไฟล์
      </button>
      }
    </div>
  </div>

  <div class="table-responsive-no-height mt-3">
    <table class="table table-fixed-column table-detail">
      <thead>
        <tr>
          <th width="100" class="text-center">อันดับ</th>
          <th width="200">ชื่อลูกค้า</th>
          <th width="200">เบอร์โทรศัพท์</th>
          <th width="160">ช่องทางโซเชียล</th>
          <th width="250">ชื่อโซเชียล</th>
          <th width="140" class="text-end">รวมยอดขาย</th>
          <th width="140" class="text-end">รวมกำไรสุทธิ</th>
        </tr>
      </thead>

      <tbody>
        @for (item of list; track item; let i = $index) {
        <tr>
          <td class="text-center">{{ i+1 }}</td>
          <td>{{ item.customer_name || '-' }}</td>
          <td>{{ item.telephone || '-' }}</td>
          <td>{{ item.channel || '-' }}</td>
          <td>
            <span class="name-channel" [innerHTML]="item.channel_name || '-'"></span>
          </td>
          <td class="text-end">{{ formatPrice(item.total_price) }}</td>
          <td class="text-end">{{ formatPrice(item.total_margin) }}</td>
        </tr>
        }
      </tbody>
    </table>

    @if(!list.length) {
    <div class="data-not-found-wrapper">ไม่พบข้อมูล</div>
    }
  </div>
</div>
