<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="row">
      <div class="col-12">
        <div class="remain-stock-text">
          สต๊อกคงเหลือของล็อตนี้ : <span class="remain-stock-number"> {{ data.item.stock }}</span>
        </div>
      </div>
      <div class="col-12">
        <label
          class="form-label"
          [class.text-danger]="form.controls['stock'].touched && form.controls['stock'].invalid">
          สต๊อกคงเหลือใหม่ (ต้องมากกว่าหรือเท่ากับ 0 และน้อยกว่า {{ data.item.stock }})
          <span class="text-danger">*</span>
        </label>

        <app-input-number [control]="form.controls['stock']" [min]="0">
          @if (form.controls['stock'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['stock'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 1,000,000</ng-container>
          } @else if (form.controls['stock'].hasError('over')) {
          <ng-container error>กรุณากรอกน้อยกว่าสต๊อกคงเหลือเดิม</ng-container>
          } @else if (form.controls['stock'].hasError('formApi')) {
          <ng-container error>{{ form.controls['stock'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onSave()">บันทึก</button>
  </div>
</mat-dialog-actions>
