<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="modal-title mb-3">ข้อมูลโปรโมชั่น</div>
    <div class="row">
      <div class="col-12">
        <label
          class="label"
          [class.text-danger]="form.controls['code'].touched && form.controls['code'].invalid">
          รหัสโปรโมชั่น
          <span class="text-danger">*</span>
        </label>

        <app-input [control]="form.controls['code']" [disabled]="data.type == 'edit'">
          @if (form.controls['code'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['code'].hasError('maxlength')) {
          <ng-container error>กรุณากรอกข้อมูลไม่เกิน 10 ตัวอักษร</ng-container>
          }@else if (form.controls['code'].hasError('formApi')) {
          <ng-container error>{{ form.controls['code'].errors?.['formApi']}}</ng-container>
          }
        </app-input>
      </div>
      <div class="col-12">
        <label
          class="label"
          [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
          ชื่อโปรโมชั่น
          <span class="text-danger">*</span>
        </label>

        <app-input [control]="form.controls['name']">
          @if (form.controls['name'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (form.controls['name'].hasError('maxlength')) {
          <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
          }@else if (form.controls['name'].hasError('formApi')) {
          <ng-container error>{{ form.controls['name'].errors?.['formApi']}}</ng-container>
          }
        </app-input>
      </div>
      <div class="col-12 col-sm-6">
        <label
          class="label"
          [class.text-danger]="form.controls['is_percent'].touched && form.controls['is_percent'].invalid">
          มูลค่าหรือเปอร์เซ็นต์ของส่วนลด
          <span class="text-danger">*</span>
        </label>

        <app-select
          [control]="form.controls['is_percent']"
          [datas]="list.persent"
          fieldValue="value"
          placeholder="เลือก"
          (selectionChange)="form.controls['value'].reset()">
          <ng-template #optionRef let-option> {{ option.text }} </ng-template>
          @if (form.controls['is_percent'].hasError('required')) {
          <ng-container error>กรุณาเลือกข้อมูล</ng-container>
          }@else if (form.controls['is_percent'].hasError('formApi')) {
          <ng-container error>{{ form.controls['is_percent'].errors?.['formApi']}}</ng-container>
          }
        </app-select>
      </div>
      <div class="col-12 col-sm-6">
        <label
          class="label"
          [class.text-danger]="form.controls['value'].touched && form.controls['value'].invalid">
          มูลค่าส่วนลด
          <span class="text-danger">*</span>
        </label>

        <app-input-number
          [control]="form.controls['value']"
          [min]="0"
          [unit]="unitValue"
          [disabled]="disableValue">
          @if (form.controls['value'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }@else if (form.controls['value'].hasError('max')) {
          <ng-container error>กรุณากรอกไม่เกิน 100</ng-container>
          }@else if (form.controls['value'].hasError('formApi')) {
          <ng-container error>{{ form.controls['value'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
      <div class="col-12 col-sm-6">
        <label class="label"> กลุ่มลูกค้าที่สามารถใช้โปรโมชั่นได้ </label>

        <app-select
          [control]="form.controls['tiers']"
          [datas]="list.tier"
          fieldValue="id"
          [search]="['name']"
          placeholder="เลือก"
          [multiple]="true"
          [disabledNotAllOption]="isAllOption(form.controls['tiers'].value)"
          (onSelectionChange)="changeTier($event)">
          <ng-template #optionRef let-option> {{ option.name }} </ng-template>
          @if (form.controls['tiers'].hasError('formApi')) {
          <ng-container error>{{ form.controls['tiers'].errors?.['formApi']}}</ng-container>
          }
        </app-select>
      </div>
      <div class="col-12 col-sm-6">
        <label
          class="label"
          [class.text-danger]="form.controls['products'].touched && form.controls['products'].invalid">
          สินค้าย่อย
          <span class="text-danger">*</span>
        </label>

        <app-select
          [control]="form.controls['products']"
          [datas]="list.product_detail"
          fieldValue="key"
          [search]="['full_name']"
          placeholder="เลือก"
          [multiple]="true"
          [disabledNotAllOption]="isAllOption(form.controls['products'].value)"
          (onSelectionChange)="changeProduct($event)">
          <ng-template #optionRef let-option> {{ option.full_name }} </ng-template>
          @if (form.controls['products'].hasError('required')) {
          <ng-container error>กรุณาเลือกข้อมูล</ng-container>
          }@else if (form.controls['products'].hasError('formApi')) {
          <ng-container error>{{ form.controls['products'].errors?.['formApi']}}</ng-container>
          }
        </app-select>
      </div>
      <div class="col-12 col-sm-6">
        <label class="label"> มูลค่าการสั่งซื้อขั้นต่ำ </label>
        <app-input-number [control]="form.controls['min_amount']" [min]="0" unit="บาท">
          @if (form.controls['min_amount'].hasError('formApi')) {
          <ng-container error>{{ form.controls['min_amount'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
      <div class="col-12">
        <label class="label"> คำอธิบาย </label>
        <app-text-area
          [control]="form.controls['description']"
          remainingText="200"
          maxlength="200"></app-text-area>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox
          [formControl]="is_limit"
          (change)="form.controls['total_code'].reset();form.controls['code_per_person'].reset()">
          จำกัดจำนวน
        </mat-checkbox>
      </div>
      @if (is_limit.value) {
      <div class="col-12 col-sm-6">
        <label
          class="label"
          [class.text-danger]="form.controls['total_code'].touched && form.controls['total_code'].invalid">
          จำนวนโค้ดส่วนลด
          <span class="text-danger">*</span>
        </label>

        <app-input-number [control]="form.controls['total_code']" [min]="0">
          @if (form.controls['total_code'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }@else if (form.controls['total_code'].hasError('formApi')) {
          <ng-container error>{{ form.controls['total_code'].errors?.['formApi']}}</ng-container>
          }
        </app-input-number>
      </div>
      <div class="col-12 col-sm-6">
        <label
          class="label"
          [class.text-danger]="form.controls['code_per_person'].touched && form.controls['code_per_person'].invalid">
          จำนวนโค้ดส่วนลดต่อคน
          <span class="text-danger">*</span>
        </label>

        <app-input-number [control]="form.controls['code_per_person']" [min]="0">
          @if (form.controls['code_per_person'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }@else if (form.controls['code_per_person'].hasError('formApi')) {
          <ng-container error
            >{{ form.controls['code_per_person'].errors?.['formApi']}}</ng-container
          >
          }
        </app-input-number>
      </div>
      }
    </div>
    <div class="line"></div>
    <div class="modal-title mb-3">การใช้งาน</div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label
          class="label"
          [class.text-danger]="form.controls['effective_date_from'].touched && form.controls['effective_date_from'].invalid">
          วันที่เริ่มต้น
          <span class="text-danger">*</span>
        </label>
        <app-date-picker [control]="form.controls['effective_date_from']" placeholder="วว/ดด/ปปปป">
          @if (form.controls['effective_date_from'].hasError('required')) {
          <ng-container error>กรุณาเลือกข้อมูล</ng-container>
          } @else if (form.controls['effective_date_from'].hasError('formApi')) {
          <ng-container error
            >{{ form.controls['effective_date_from'].errors?.['formApi']}}</ng-container
          >
          }
        </app-date-picker>
      </div>

      <div class="col-12 col-sm-6">
        <label class="label"> วันที่สิ้นสุด </label>
        <app-date-picker [control]="form.controls['effective_date_to']" placeholder="วว/ดด/ปปปป">
          @if (form.controls['effective_date_to'].hasError('minMax')) {
          <ng-container error> วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น </ng-container>
          } @else if (form.controls['effective_date_to'].hasError('formApi')) {
          <ng-container error
            >{{ form.controls['effective_date_to'].errors?.['formApi']}}</ng-container
          >
          }
        </app-date-picker>
      </div>

      <div class="col-12">
        <label class="label"> สถานะการใช้งาน </label>
        <div class="mt-2">
          <mat-slide-toggle
            hideIcon="true"
            color="primary"
            class="slide-toggle"
            [formControl]="form.controls.is_active">
            ใช้งาน
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">ยกเลิก</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
  </div>
</mat-dialog-actions>
