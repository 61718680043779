import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { InputComponent } from '../../../components/input/input'
import { formatNoDecimal, formatPrice, Moment } from '../../../helpers'
import { MatRipple } from '@angular/material/core'

@Component({
  selector: 'app-modal-discount-carat',
  templateUrl: './modal-discount-carat.html',
  styleUrls: ['./modal-discount-carat.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    MatRipple,
  ],
})
export class ModalDiscountCaratComponent {
  readonly formatNoDecimal = formatNoDecimal
  readonly formatPrice = formatPrice
  readonly Moment = Moment

  get dateText() {
    if (this.data.carat.order_start_date) {
      const startText = `เริ่มตั้งแต่วันที่ ${Moment(this.data.carat.order_start_date).format(
        'DD-MM-YYYY'
      )}`
      if (this.data.carat.order_end_date) {
        return `${startText} ถึง ${Moment(this.data.carat.order_end_date).format('DD-MM-YYYY')}`
      }
      return `${startText} เป็นต้นไป`
    }
    return ''
  }

  constructor(
    public dialogRef: MatDialogRef<ModalDiscountCaratComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.dialogRef.close()
  }
}
