<!-- Search and Button -->
<div class="top-content-wrapper mb-3">
  <div class="top-content-left">
    <app-search-filter-user
      placeholder="ค้นหา"
      (onConfirm)="paginationUser.reset();getUserList();itemChecked.clear()"></app-search-filter-user>
  </div>

  <div class="top-content-right">
    @if (profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT], [ACTION_ACCESS.EXPORT]))
    {
    <button
      (click)="openModalDownloadFile('ดาวน์โหลดไฟล์รายชื่อ', { message: 'คุณต้องการดาวน์โหลดไฟล์รายชื่อที่เลือกใช่หรือไม่' })"
      matRipple
      class="btn btn-outline-secondary">
      <span class="icon material-symbols-outlined fill">download_2</span>
      ส่งออกไฟล์
    </button>
    }
    <!-- ~ -->
    @if (profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT], [ACTION_ACCESS.CREATE,
    ACTION_ACCESS.IMPORT])) {
    <button
      (click)="openModalUser('เพิ่มผู้ใช้งาน', 'add', { message: '' })"
      matRipple
      class="btn btn-primary">
      <span class="icon material-symbols-outlined fill">add_circle</span>
      เพิ่มผู้ใช้งาน
    </button>
    }
  </div>
</div>

<!-- Table -->
<div class="table-responsive table-main-tabs-wrapper">
  <table
    class="table table-fixed-column"
    [class.table-checkbox]="profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT],
  [ACTION_ACCESS.EXPORT])">
    <thead>
      <tr>
        @if (profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT],
        [ACTION_ACCESS.EXPORT])) {
        <th width="70">
          <app-checkbox-all-table
            [checkedValue]="itemChecked"
            [datas]="userList"
            fieldValue="id"></app-checkbox-all-table>
        </th>
        }
        <th width="90">รหัสผู้ใช้</th>
        <th width="140">ชื่อ</th>
        <th width="140">นามสกุล</th>
        <th width="145">ตำแหน่ง</th>
        <th width="200">อีเมล</th>
        <th width="150">เบอร์โทรศัพท์</th>
        <th width="120">สิทธิ์ผู้ใช้งาน</th>
        <th width="112">สถานะ</th>
        <th width="150">อัปเดตโดย</th>
        <th width="170">อัปเดตล่าสุด</th>
        <th width="120" class="fixed box-shadow-left">จัดการ</th>
      </tr>
    </thead>

    <tbody>
      @for (user of userList; track $index) {
      <tr>
        @if (profile.permissions.checkAccess([APP_PERMISSION.USER_MANAGEMENT],
        [ACTION_ACCESS.EXPORT])) {
        <td>
          <app-checkbox-table [checkedValue]="itemChecked" [value]="user.id"></app-checkbox-table>
        </td>
        }
        <td>{{ user.code }}</td>
        <td>{{ user.first_name }}</td>
        <td>{{ user.last_name }}</td>
        <td>{{ user.position }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.tel || '-' }}</td>
        <td>{{ user.role.name }}</td>
        <td>
          <app-status-user [status]="user.is_active ? 'active' : 'inactive'">
            {{ user.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
          </app-status-user>
        </td>
        <td>{{user.updated_by ? user.updated_by.full_name : ''}}</td>
        <td>{{ user.updated_at ? Moment(user.updated_at).format('DD-MM-YYYY, HH:mm') : '' }}</td>
        <td class="fixed box-shadow-left">
          <div class="d-flex justify-content-center align-items-center">
            @if (user.action.edit) {
            <button
              (click)="openModalUser('แก้ไขผู้ใช้งาน', 'edit', user)"
              matRipple
              matTooltip="แก้ไข"
              matTooltipPosition="above"
              class="btn btn-outline-secondary btn-icon">
              <span class="icon material-symbols-outlined fill">edit</span>
            </button>
            }
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>

  @if(!userList.length) {
  <div class="data-not-found-wrapper">ไม่พบผู้ใช้งาน กรุณาเพิ่มผู้ใช้งาน</div>
  }
</div>

<!-- Pagination -->
@if (userList.length) {
<div class="pagination-wrapper">
  <mat-paginator
    [pageIndex]="paginationUser.data.pageIndex"
    [length]="paginationUser.data.length"
    [pageSize]="paginationUser.data.pageSize"
    [pageSizeOptions]="paginationUser.data.pageSizeOptions"
    aria-label="Select page"
    (page)="paginationUser.setFromPaginator($event);getUserList()">
  </mat-paginator>
</div>
}
