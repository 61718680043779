import { Injectable } from '@angular/core'
import { __searchAndFitler } from './_mix'
import { FormControl, FormGroup } from '@angular/forms'
import { Moment, convertDateToApi } from '../../helpers'
import { CustomerService, RoleService } from '../../services'
import { Loading } from '../loading'
import { CustomSnackBar } from '../../components/snackbar/snackbar'

@Injectable({ providedIn: 'root' })
export class FilterTableCustomerOrder extends __searchAndFitler {
  readonly list = {
    banks: <any[]>[],
    statuses: <any[]>[],
    trackings: <any[]>[],
  }

  form = new FormGroup({
    bank_ids: new FormControl([]),
    status_ids: new FormControl([]),
    trackings: new FormControl([]),
    start_payment_date: new FormControl(''),
    end_payment_date: new FormControl(''),
    start_closed_date: new FormControl(''),
    end_closed_date: new FormControl(''),
  })

  get isFilter() {
    const data = this.getData()
    return data.filter && Object.keys(data.filter).length
  }

  get startEndDateFormatPayment() {
    return [
      this.form.controls['start_payment_date'].value,
      this.form.controls['end_payment_date'].value,
    ]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  get banksText() {
    const values = (this.form.controls.bank_ids.value || []) as any[]
    return this.list.banks
      .reduce((texts: string[], d: any) => {
        if (values.includes(d.id)) {
          texts.push(d.name)
        }
        return texts
      }, [])
      .join(', ')
  }

  get trackingsText() {
    const values = (this.form.controls.trackings.value || []) as string[]
    return values.join(', ')
  }

  get statussText() {
    const values = (this.form.controls.status_ids.value || []) as any[]
    return this.list.statuses
      .reduce((texts: string[], d: any) => {
        if (values.includes(d.id)) {
          texts.push(d.name)
        }
        return texts
      }, [])
      .join(', ')
  }

  get startEndDateFormatClosed() {
    return [
      this.form.controls['start_closed_date'].value,
      this.form.controls['end_closed_date'].value,
    ]
      .reduce((ds: any[], d: any) => {
        if (d) {
          ds.push(Moment(d).format('DD/MM/YYYY'))
        }
        return ds
      }, [])
      .join(' - ')
  }

  constructor(
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public customerService: CustomerService
  ) {
    const filter = {
      promotion_status_ids: [],
    }
    super('/customer-order', filter)

    this._initForm(this.form)
  }

  initData(customer_id: any) {
    this.loading.start()
    this.customerService.getCustomerOrderFilters(customer_id, {}).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.list.banks = res.data.banks
          this.list.statuses = res.data.statuses
          this.list.trackings = res.data.trackings.map((value: any) => ({ value }))
        } else {
          this.customSnackBar.fail()
        }
      }

      this.loading.stop()
    })
  }

  removeBankId() {
    this.form.controls.bank_ids.reset()
  }

  removeTrackingId() {
    this.form.controls.trackings.reset()
  }

  removeStatusId() {
    this.form.controls.status_ids.reset()
  }

  removeStartEndDatePayment() {
    this.form.controls['start_payment_date'].reset()
    this.form.controls['end_payment_date'].reset()
  }

  removeStartEndDateClosed() {
    this.form.controls['start_closed_date'].reset()
    this.form.controls['end_closed_date'].reset()
  }

  getData() {
    const filter: any = {}
    const data: any = {
      filter,
    }

    const value = this.form.getRawValue()
    if (value.bank_ids?.length) {
      filter.bank_ids = value.bank_ids
    }
    if (value.status_ids?.length) {
      filter.status_ids = value.status_ids
    }
    if (value.trackings?.length) {
      filter.trackings = value.trackings
    }

    if (value.start_payment_date) {
      filter.start_payment_date = convertDateToApi(value.start_payment_date)
    }
    if (value.end_payment_date) {
      filter.end_payment_date = convertDateToApi(value.end_payment_date, 'day')
    }

    if (value.start_closed_date) {
      filter.start_closed_date = convertDateToApi(value.start_closed_date)
    }
    if (value.end_closed_date) {
      filter.end_closed_date = convertDateToApi(value.end_closed_date, 'day')
    }

    if (Object.keys(filter).length) {
      data.filter = filter
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
