<div class="tabs-wrapper">
  <mat-tab-group
    animationDuration="0ms"
    [disableRipple]="true"
    [selectedIndex]="tab"
    (selectedIndexChange)="changeTab($event)"
  >
    <mat-tab label="ข้อมูลทั่วไป">
      <app-history-order-list></app-history-order-list>
    </mat-tab>

    <mat-tab label="ใบกำกับภาษี">
      <app-history-order-invoice-list></app-history-order-invoice-list>
    </mat-tab>

    <mat-tab label="รายการที่ลบ">
      <app-history-order-deleted-list></app-history-order-deleted-list>
    </mat-tab>
  </mat-tab-group>
</div>
