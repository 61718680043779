import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DatePickerComponent } from '../../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../../components/date-picker-range/date-picker-range'
import { FilterTableCustomer } from '../../../../../globals'
import { imports } from '../../../../../imports'

@Component({
  selector: 'app-modal-filter-customer',
  templateUrl: './modal-filter.html',
  styleUrls: ['./modal-filter.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
  ],
})
export class ModalFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTableCustomer: FilterTableCustomer
  ) {}

  ngOnInit(): void {
    this.filterTableCustomer.initData()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTableCustomer.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTableCustomer.confirm()
    this.dialogRef.close(true)
  }
}
