import { Component } from '@angular/core'
import { imports } from '../../../imports'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { Loading } from '../../../globals'
import { PublicService } from '../../../services'
import { ActivatedRoute } from '@angular/router'
import { decrypt } from '../../../helpers/crypto'
import { forkJoin } from 'rxjs'
import { setOrderForPrint } from '../../../helpers'
import { environment } from '../../../../environments/environment'
import { CoverSheetComponent } from '../cover-sheet/cover-sheet'

@Component({
  selector: 'app-cover-sheet-public',
  standalone: true,
  imports: [...imports, CoverSheetComponent],
  templateUrl: './cover-sheet-public.html',
  styleUrls: ['./cover-sheet-public.scss'],
})
export class CoverSheetPublicComponent {
  companyProfile: any
  orders: any[] = []
  addresss: any[] = []
  adminName = ''

  temps = new Array(30)

  constructor(
    public route: ActivatedRoute,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public publicService: PublicService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      try {
        const data = decrypt(params['token'].replaceAll(' ', '+'), environment.publicKey)
        this.companyProfile = data.companyProfile
        this.adminName = data.adminName
        this.initData(data.link_codes)
      } catch (e) {}
    })
  }

  initData(linkCodes: any[]) {
    const apis = [this.publicService.getCompanyProfile()]
    for (const linkCode of linkCodes) {
      apis.push(this.publicService.getOrderByLinkCode(linkCode))
    }

    if (apis.length) {
      this.loading.start()
      forkJoin(apis).subscribe((ress: any) => {
        const [resCompanyProfile, ...resOrders] = ress

        //--------------test
        // const testOrder = {
        //   data: {
        //     ...resOrders[0].data,
        //   },
        // }
        // testOrder.data.order_no = 'AAAA'
        // testOrder.data.delivery_address = [
        //   {
        //     ...testOrder.data.delivery_address[0],
        //     uuid: 'ccccccc',
        //   },
        // ]
        // resOrders.push(testOrder)
        //------------------

        if (!resCompanyProfile.is_error) {
          this.companyProfile = resCompanyProfile.data
        } else {
          this.customSnackBar.fail(resCompanyProfile.message)
        }

        const orders: any[] = []
        const addresss: any[] = []

        for (const res of resOrders) {
          if (!res.is_error) {
            setOrderForPrint(orders, addresss, res.data)
          } else {
            this.customSnackBar.fail(res.message)
          }
        }

        this.orders = orders
        this.addresss = addresss

        // console.log('orders', this.orders)
        // console.log('addresss', this.addresss)

        this.loading.stop()
      })
    }
  }
}
