<div class="mt-3">
  <div class="table-responsive">
    <table class="table table-fixed-column">
      <tbody>
        @for (item of dataCheckbox; track $index) {
        <tr class="role">
          <td class="role">{{item.name}}</td>
          <td class="role">
            @for (itemA of item.application_permissions; track $index) {
            <mat-checkbox
              class="example-margin"
              [(ngModel)]="itemA.permission.is_selected"
              [disabled]="!isEditAccess"
              (change)="onChange.emit()"
            >
              {{itemA.permission.name}}
            </mat-checkbox>
            }
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
