@if (imgUrl) {
<div class="upload-image-box-inner d-flex justify-content-start align-items-center">
  @if (imgUrl) {
  <button class="btn-close-img" matRipple (click)="isDelete()">
    <span class="icon icon-white material-symbols-outlined"> close </span>
  </button>
  }

  <label class="upload-image-inner {{preview == 'square' && 'square'}}">
    <img alt="Uploaded Image" class="upload-image" [src]="imgUrl" />
  </label>
</div>

} @else {
<input
  type="file"
  id="upload"
  [accept]="acceptFileTypes.toString()"
  class="input-upload"
  #fileUpload
  (change)="onChangeUpload(fileUpload)"
/>

<div class="drop-wrapper d-flex justify-content-start align-items-center">
  <label
    class="upload-file-wrapper d-flex flex-column justify-content-center align-items-center py-4 mt-2 mb-2"
    for="upload"
    (dragover)="dragOver($event)"
    (drop)="drop($event)"
  >
    <span class="icon-32 icon-blue material-symbols-outlined fill"> add_circle </span>

    <div class="upload-file-text mt-3 mb-2 px-1">เพิ่มรูปภาพ</div>
  </label>
</div>
}
