<span class="snackbar-success" matSnackBarLabel>
  <span class="d-flex align-items-center">
    <img class="me-2" src="/assets/images/icons/icon-success.svg" />
    {{ data }}
  </span>
  <span
    class="icon material-symbols-outlined cursor-pointer"
    matSnackBarAction
    (click)="snackBarRef.dismissWithAction()"
  >
    close
  </span>
</span>
