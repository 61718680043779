<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="box-qr-scanner d-flex justify-content-center align-items-center">
      <video #elVideo class="video-qr-scanner"></video>
    </div>
    <!-- <img src="/assets/images/icons/mockup-scan.png" /> -->
  </div>
</mat-dialog-content>
