import momentTimezone from 'moment-timezone'
import moment from 'moment'

declare module 'moment' {
  export interface Moment {
    formatThaiYear(formatString: string): string
  }
}

;(moment.fn as any).formatThaiYear = function (formatString: string) {
  const _self = this as moment.Moment
  const YYYY = (_self.year() + 543).toString()
  const YY = YYYY.slice(-2)
  return _self.format(formatString.replace('YYYY', YYYY).replace('YY', YY))
}

export const timezoneThai = 'Asia/Bangkok'
momentTimezone.tz.setDefault(timezoneThai)

export const Moment = momentTimezone

export const timeZoneOffset = Moment().format('Z')

// const formatDateToApi = 'YYYY-MM-DDTHH:mm:ssZ'
export const convertDateToApi = (
  d: string | moment.Moment | Date | null,
  endOf?: 'day' | 'month'
) => (d ? (endOf ? Moment(d).endOf(endOf).toISOString() : Moment(d).toISOString()) : null)

export const formatDate = (date: any) => Moment(date).format('DD/MM/YYYY')

export const formatTime = (date: any) => Moment(date).format('hh:mm A')

export const mergeDateAndTime = (date: any, time: any) => {
  date = Moment(date)
  if (time) {
    const [HH, mm, ss] = time.split(':')
    date.set('hours', parseInt(HH)).set('minutes', parseInt(mm)).set('seconds', parseInt(ss))
  }
  return date
}
