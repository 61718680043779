<div mat-dialog-title class="dialog-title">
  <div class="dialog-title-head">{{data.title}}</div>
  <button class="btn btn-transparent btn-icon" (click)="onClose()">
    <img src="../assets/images/icons/icon-close.svg" />
  </button>
</div>

<mat-dialog-content>
  <div class="modal-content">
    <div class="row">
      <div class="filter-item-wrapper col-12">
        <div class="filter-title">สถานะออเดอร์</div>

        <div class="filter-checkbox-wrapper checkbox-two-column">
          @for (item of filterTablePrroductSeller.list.status_ids; track $index) {
          <mat-checkbox
            class="filter-checkbox"
            [checked]="filterTablePrroductSeller.isStatusIdChecked(item.id)"
            (change)="filterTablePrroductSeller.statusIdChange(item.id)"
          >
            {{ item.text }}
          </mat-checkbox>
          }
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="footer-button-wrapper d-flex justify-content-end w-100">
    <button matRipple class="btn btn-outline-secondary btn-md" (click)="onReset()">ล้างค่า</button>
    <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">นำไปใช้</button>
  </div>
</mat-dialog-actions>
