import { Component, Inject, Injectable } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InputComponent } from '../input/input'
import { ValidateForm } from '../../helpers'
import { MatRipple } from '@angular/material/core'

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.html',
  styleUrls: ['./modal-delete.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    MatRipple,
  ],
})
export class ModalDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(this.data)
  }
}
