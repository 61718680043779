import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router'
import { SettingMenuComponent } from '../../../components/setting-menu/setting-menu'
import { imports } from '../../../imports'
import { SelectComponent } from '../../../components/select/select'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ACTION_ACCESS, APP_PERMISSION, Loading, Profile } from '../../../globals'
import {
  Pagination,
  formatPrice,
  Moment,
  formatPriceAutoDecimal,
  DownloadFileBlob,
  convertDateToApi,
} from '../../../helpers'
import { DatePickerRangeComponent } from '../../../components/date-picker-range/date-picker-range'
import { SearchComponent } from '../../../components/search/search'
import { ModalDownloadFileComponent } from '../../../components/modal-download-file/modal-download-file'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { ReportService } from '../../../services/report.service'
@Component({
  selector: 'app-report-customer-ranking',
  standalone: true,
  imports: [
    ...imports,
    RouterOutlet,
    SettingMenuComponent,
    SelectComponent,
    DatePickerRangeComponent,
    SearchComponent,
    ModalDownloadFileComponent,
  ],
  templateUrl: './customer-ranking.html',
  styleUrls: ['./customer-ranking.scss'],
})
export class ReportCustomerRankingComponent implements OnInit {
  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly Moment = Moment

  search = new FormControl('')
  top = new FormControl(10)
  dateStr = new FormControl('')
  dateEnd = new FormControl('')

  topList = [
    {
      value: 10,
      name: 'Top 10',
    },
    {
      value: 20,
      name: 'Top 20',
    },
    {
      value: 50,
      name: 'Top 50',
    },
    {
      value: 100,
      name: 'Top 100',
    },
  ]

  list: any = []

  constructor(
    public route: ActivatedRoute,
    public Router: Router,
    public dialog: MatDialog,
    public profile: Profile,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.getCustomerRanking()
  }

  getFilter() {
    const dataFilter: any = {
      filter: {},
    }

    if (this.dateStr.value && this.dateEnd.value) {
      dataFilter.filter.start_date = convertDateToApi(this.dateStr.value)
      dataFilter.filter.end_date = convertDateToApi(this.dateEnd.value, 'day')
    }

    if (this.search.value) {
      dataFilter.search = this.search.value
    }

    return dataFilter
  }

  getCustomerRanking() {
    const dataFilter = this.getFilter()

    const payload = {
      sort_by: 'asc',
      sort_name: 'id',
      page_size: this.top.value,
      ...dataFilter,
    }

    this.loading.start()
    this.reportService.getReportCustomersList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.list = res.data.records
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  openModalDownloadFile(title: string, detail: any) {
    const dialogRef = this.dialog.open(ModalDownloadFileComponent, {
      data: {
        title,
        detail,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.downloadFile()
      }
    })

    return dialogRef
  }

  downloadFile() {
    const dataFilter = this.getFilter()

    const payload = {
      page_size: this.top.value,
      ...dataFilter,
    }

    this.loading.start()
    this.reportService.exportReportCustomersList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          DownloadFileBlob(res.body, `report-customer.xlsx`)
          this.customSnackBar.success('ดาวน์โหลดข้อมูลสำเร็จ')
        } else {
          this.customSnackBar.fail('ดาวน์โหลดข้อมูลไม่สำเร็จ')
        }
      }
      this.loading.stop()
    })
  }
}
