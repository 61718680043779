import { Component, Inject, Injectable, Input } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTabsModule } from '@angular/material/tabs'
import { MatIconModule } from '@angular/material/icon'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { InputComponent } from '../../../../components/input/input'
import { MARKETPLACE_SHOP, ValidateForm } from '../../../../helpers'
import { MatRipple } from '@angular/material/core'
import { SpinnerCustomComponent } from '../../../../components/spinner/custom-spinner.component'
import { MaketplaceService } from '../../../../services'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'

@Component({
  selector: 'app-modal-connect-product',
  templateUrl: './modal-connect-product.html',
  styleUrls: ['./modal-connect-product.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    MatRipple,
    SpinnerCustomComponent,
  ],
})
export class ModalConnectProductComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalConnectProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public maketplaceService: MaketplaceService
  ) {}

  ngOnInit(): void {
    const api =
      this.data.shop == MARKETPLACE_SHOP.SHOPEE
        ? this.maketplaceService.connectShopeeProduct(this.data.item.id)
        : this.maketplaceService.connectLazadaProduct(this.data.item.id)
    api.subscribe(res => {
      setTimeout(() => {
        if (!res.is_error) {
          this.onSuccess()
        } else {
          this.customSnackBar.fail()
          this.onClose()
        }
      }, 1000)
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onSuccess(): void {
    this.dialogRef.close(true)
  }
}
