@if (chartOptions) {
  <div class="chart-wrapper">
    @if (average) {
      <div class="average-container {{average.theme}}">
        คะแนนเฉลี่ย <span class="value">{{average.value}}</span>
      </div>
    }
    <apx-chart
      [series]="chartOptions?.series"
      [chart]="chartOptions?.chart"
      [xaxis]="chartOptions?.xaxis"
      [yaxis]="chartOptions?.yaxis"
      [title]="chartOptions?.title"
      [tooltip]="chartOptions?.tooltip"
      [plotOptions]="chartOptions?.plotOptions"
      [dataLabels]="chartOptions?.dataLabels"
      [fill]="chartOptions?.fill"
      [legend]="chartOptions?.legend"
      [stroke]="chartOptions?.stroke"
    />
  
    @if (name) {
      <div class="text-center text-sm-normal color--Neutral-800--f">{{name}}</div>
    }
  </div>
}

