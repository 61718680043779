import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NavMenu {
  readonly open = new BehaviorSubject<Boolean>(false)

  constructor() {}

  toggle() {
    this.open.next(!this.open.getValue())
  }
}
