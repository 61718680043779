import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { setLocalStorage } from '../../helpers'

// declare const chrome: any
// console.log('chrome', chrome)

// window.addEventListener('storage', e => {
//   console.log('eeeeeeeeeeeeeeeeeeee', e)
//   if (location.pathname != '/temp') {
//     if (e.key == 'access_token') {
//       console.log('eeeeeeeeeeeeeeeeeeee1', e)
//       // location.href = '/'
//     }
//   }
// })

// window.addEventListener(
//   'message',
//   e => {
//     console.log('message', e)
//   },
//   false
// )

@Component({
  selector: 'app-temp',
  standalone: true,
  templateUrl: './temp.html',
})
export class TempComponent {
  constructor(private route: ActivatedRoute) {
    route.queryParams.subscribe(queryParams => {
      if (queryParams['access_token'] && queryParams['refresh_token']) {
        localStorage.clear()
        sessionStorage.clear()

        setLocalStorage('access_token', queryParams['access_token'])
        setLocalStorage('refresh_token', queryParams['refresh_token'])

        // const win = window.top as any
        window.postMessage('ttttttttttttttttt')
      }
    })
  }

  onPost() {
    window.postMessage('sssssssssssssssssssss')
  }

  onPost2() {
    window.postMessage('sssssssssssssssssssss', 'https://bnh.poc.socket9.com')
  }
}
