import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-status-user',
  standalone: true,
  templateUrl: './status-user.html',
  styleUrls: ['./status-user.scss'],
})
export class StatusUserComponent implements OnInit {
  @Input() status: 'active' | 'inactive' | 'expired' = 'inactive'
  @Input() fixed_width: boolean = true

  ngOnInit(): void {}
}
