import { Component, Inject } from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialog,
} from '@angular/material/dialog'
import { CustomSnackBar } from '../../../../components/snackbar/snackbar'
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePickerComponent } from '../../../../components/date-picker/date-picker'
import { DatePickerRangeComponent } from '../../../../components/date-picker-range/date-picker-range'
import { InputComponent } from '../../../../components/input/input'
import { SelectComponent } from '../../../../components/select/select'
import { TextareaComponent } from '../../../../components/text-area/text-area'
import { imports } from '../../../../imports'
import {
  Moment,
  Pagination,
  ValidateForm,
  convertDateToApi,
  formatPrice,
  formatPriceAutoDecimal,
  productImage,
  productKey,
  setErrorForm,
} from '../../../../helpers'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm'
import { SearchComponent } from '../../../../components/search/search'
import { CheckboxTableComponent } from '../../../../components/checkbox-table/checkbox-table.component'
import { MatPaginator } from '@angular/material/paginator'
import { Loading } from '../../../../globals'
import { ProductService } from '../../../../services'
import { InputNumberComponent } from '../../../../components/input-number/input-number'

@Component({
  selector: 'app-modal-product',
  templateUrl: './modal-product.html',
  styleUrls: ['./modal-product.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    DatePickerComponent,
    DatePickerRangeComponent,
    InputNumberComponent,
    SelectComponent,
    TextareaComponent,
    MatCheckbox,
    ModalConfirmComponent,
    SearchComponent,
    CheckboxTableComponent,
    MatPaginator,
  ],
})
export class ModalProductComponent {
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal

  search = new FormControl('')
  readonly paginationProduct = new Pagination({
    sortName: 'code',
    sortBy: 'asc',
  })
  rerender = false
  pageNumber = new FormControl(1)
  productAddList: any[] = []
  productEditList: any[] = []
  productEditListSearch: any
  productSelect = new Map<any, any>()

  imgDefault = '../../../../assets/images/icons/img-default-table.svg'

  get productList() {
    if (this.data.type == 'edit') {
      return this.productEditListSearch || this.productEditList
    }
    return this.productAddList
  }

  get total() {
    let amount = 0
    let quantity = 0
    let price = 0
    this.productSelect.forEach(data => {
      if (data.checked) {
        amount++
        quantity += data.quantity || 0
        price += (data.quantity || 0) * (data.priceControl.value || 0)
      }
    })
    return { amount, quantity, price }
  }

  constructor(
    public dialogRef: MatDialogRef<ModalProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public productService: ProductService
  ) {}

  ngOnInit(): void {
    this.productEditList = this.data.products.map((productOrder: any) => {
      // console.log('productOrder', productOrder)

      const key = this.setKey(
        productOrder.product_id,
        productOrder.product_detail_id,
        productOrder.index
      )

      const newProduct = {
        checked: true,
        key,
        quantity: productOrder.quantity.value,
        priceControl: new FormControl(productOrder.price.value || null),
        image: productImage(productOrder),
        product: {
          product_id: productOrder.product_id,
          product_detail_id: productOrder.product_detail_id,
          cost: productOrder.cost,
          product_code: productOrder.product_code,
          product_name: productOrder.product_name,
          color: productOrder.color,
          size: productOrder.size,
          files: productOrder.files,
          is_carat: productOrder.is_carat,
        },
        productOrder,
      }

      if (this.data.type == 'edit') {
        this.productSelect.set(key, newProduct)
      }

      return newProduct
    })

    if (this.data.type == 'edit') {
      // this.getProductListForEdit()
    } else {
      this.getProductList()
    }
  }

  setKey(product_id: any, product_details_id: any, index = '') {
    let id = `${product_id}-`
    if (product_details_id) {
      id += product_details_id
    }
    return `[${id}]${index || ''}`
  }

  onSearch() {
    if (this.data.type == 'edit') {
      const searchText = (this.search.value || '').toLowerCase()
      this.productEditListSearch = this.productEditList.filter(
        (p: any) =>
          !searchText ||
          p.product.product_code.toLowerCase().includes(searchText) ||
          p.product.product_name.toLowerCase().includes(searchText)
      )
    } else {
      this.paginationProduct.reset()
      this.getProductList()
      this.productEditListSearch = false
    }
  }

  onChangePage(reset = false) {
    if (this.pageNumber.value) {
      const page = this.pageNumber.value - 1
      if (this.paginationProduct.data.pageIndex != page) {
        this.paginationProduct.data.pageIndex = page
        this.getProductList()
      }
    } else if (reset) {
      this.pageNumber.setValue(this.paginationProduct.data.pageIndex + 1)
      this.rerender = true
      setTimeout(() => {
        this.rerender = false
      })
    }
  }

  // getProductListForEdit = () => {
  //   const payload = {
  //     page: 1,
  //     page_size: 999,
  //     filter: <any>{
  //       ids: this.productEditList.map((item: any) => item.product.product_id),
  //       is_actives: [true],
  //       is_stock: true,
  //     },
  //   }

  //   this.loading.start()
  //   this.productService.getProductList(payload).subscribe((res: any) => {
  //     if (!res.data.is_error) {
  //       res.data.records.forEach((product: any) => {
  //         if (product.details.length) {
  //           for (const product_detail of product.details) {
  //             const key = this.setKey(product.id, product_detail.id)
  //             this.updateProductEdit(key, product_detail.stock)
  //           }
  //         } else {
  //           const key = this.setKey(product.id, null)
  //           this.updateProductEdit(key, product.stock)
  //         }
  //       })
  //     }
  //     this.loading.stop()
  //   })
  // }

  // updateProductEdit(key: string, stock: number) {
  //   const productEdit = this.productEditList.find((productEdit: any) =>
  //     productEdit.key.includes(key)
  //   )
  //   if (productEdit) {
  //     if (stock > productEdit.stock) {
  //       productEdit.stock = stock
  //     }
  //   }
  // }

  getProductList() {
    const pagination = this.paginationProduct.get()
    const payload = {
      ...pagination,
      search: this.search.value || undefined,
      filter: {
        is_actives: [true],
        is_stock: true,
      },
    }

    // console.log(payload)
    // return

    this.loading.start()
    this.productService.getProductForOrderList(payload).subscribe((res: any) => {
      if (res) {
        if (!res.is_error) {
          this.productAddList = res.data.records.map((product: any) => {
            const key = this.setKey(product.id, product.product_detail_id)

            const newProduct = {
              checked: false,
              key,
              quantity: 1,
              priceControl: new FormControl(product.selling_price || null),
              stock: product.stock,
              image: productImage(product),
              product: {
                product_id: product.id,
                product_detail_id: product.product_detail_id,
                cost: product.cost_price,
                product_code: product.code,
                product_name: product.name,
                color: product.color,
                size: product.size,
                files: product.files,
                is_carat: product.is_carat,
              },
              productOrder: {
                delivery_address_id: null,
                delivery_address_uuid: null,
              },
            }

            //update stock
            const product_key = productKey(product.id, product.product_detail_id)

            const productStockEditSelf = this.data.productStockEdit.get(product_key)
            if (productStockEditSelf) {
              // console.log(newProduct.stock, productStockEditSelf)
              newProduct.stock += productStockEditSelf.total_quantity
            }

            const productStockSelf = this.data.productStock.get(product_key)
            if (productStockSelf) {
              // console.log(newProduct.stock, productStockSelf)
              newProduct.stock -= productStockSelf.total_quantity
              if (newProduct.stock < 0) {
                newProduct.stock = 0
              }
            }

            const productSelected = this.productSelect.get(newProduct.key)
            if (productSelected) {
              newProduct.checked = true
              newProduct.priceControl.setValue(productSelected.priceControl.value)
              newProduct.quantity = productSelected.quantity
              // newProduct.productOrder = productSelected.productOrder
            }

            // if (newProduct.checked) {
            //   this.productSelect.set(key, newProduct)
            // }

            return newProduct
          })
          this.paginationProduct.setFromResponse(res.data)
        } else {
          this.customSnackBar.fail()
        }
      }
      this.loading.stop()
    })
  }

  productChecked(item: any, checked: boolean) {
    const dataSelected = this.productSelect.get(item.key)
    if (dataSelected) {
      dataSelected.checked = checked
    } else {
      item.checked = true
      this.productSelect.set(item.key, item)
    }

    if (!checked) {
      item.quantity = 1
      dataSelected.quantity = 1
    }
  }

  addQuantity(item: any) {
    item.quantity += 1
    this.productChecked(item, true)
  }

  reduceQuantity(item: any) {
    item.quantity -= 1
    this.productChecked(item, item.quantity > 0)
  }

  removeProduct(item: any) {
    this.productEditList.splice(this.productEditList.indexOf(item), 1)
    this.productSelect.delete(item.key)
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    const datas: any[] = []
    this.productSelect.forEach(data => {
      if (data.checked) {
        const quantity = data.quantity
        const price = data.priceControl.value || 0

        const product = {
          ...data.productOrder,
          ...data.product,
          quantity,
          price,
          total_price: price * quantity,
        }

        if (product.delivery_address_uuid instanceof FormControl) {
          product.delivery_address_uuid = product.delivery_address_uuid.value
        } else {
          product.delivery_address_uuid = product.delivery_address_uuid
        }

        datas.push(product)
      }
    })

    this.dialogRef.close(datas)
  }
}
