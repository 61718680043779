<div class="search-wrapper">
  <mat-form-field appearance="outline" class="input">
    <button type="button" mat-icon-button matPrefix class="btn btn-transparent btn-icon ps-3">
      <span class="icon icon-grey material-symbols-outlined fill">search</span>
    </button>

    <input
      matInput
      [formControl]="formControl"
      placeholder="{{placeholder || ''}}"
      (input)="onInput.emit()"
      (keyup.enter)="onEnter.emit()"
    />

    <div matSuffix class="d-flex me-2">
      @if (formControl.value) {
      <button
        type="button"
        mat-icon-button
        class="btn btn-transparent btn-icon btn-icon-right"
        (click)="close()"
      >
        <span class="icon icon-dark-grey material-symbols-outlined fill">close</span>
      </button>
      }
      <button
        (click)="openModalScan('การสแกน')"
        type="button"
        mat-icon-button
        class="btn btn-transparent btn-icon btn-icon-right"
      >
        <span class="icon icon-dark material-symbols-outlined fill">qr_code_scanner</span>
      </button>
    </div>

    @if (control.invalid) {
    <mat-error>
      <ng-content select="[error]"></ng-content>
    </mat-error>
    }
  </mat-form-field>
</div>
