<div class="page-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ตั้งค่า</div>
    <div class="breadcrumb-separator">/</div>
    @if(isView) {
    <div class="breadcrumb-text">ข้อมูลการชำระเงิน</div>
    } @else{
    <div class="breadcrumb-text breadcrumb-link color--brand-blue--f" (click)="onClose()">
      ข้อมูลการชำระเงิน
    </div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">แก้ไขบัตรเครดิต/ เดบิต</div>
    }
  </div>

  <div class="title-wrapper">ข้อมูลการชำระเงิน</div>
  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      <mat-tab label="Payment" [disabled]="!isView">
        <div class="tabs-content-wrapper">
          <div class="top-content-wrapper mb-3 justify-content-end">
            @if (profile.permissions.checkAccess([APP_PERMISSION.BANK_COMPANY],
            [ACTION_ACCESS.CREATE])) {
            <button matRipple class="btn btn-primary" (click)="openModalBank('เพิ่มธนาคาร', 'add')">
              <span class="icon material-symbols-outlined fill">add_circle</span>
              เพิ่มธนาคาร
            </button>
            }
          </div>

          <!-- Table -->
          <div class="table-responsive table-main-tabs-wrapper">
            <table class="table table-fixed-column">
              <thead>
                <tr>
                  <th width="180">ธนาคาร</th>
                  <th width="200">ชื่อบัญชี</th>
                  <th width="180">ชื่อสาขา</th>
                  <th width="180">รหัสสาขา</th>
                  <!-- <th width="140">เลขที่บัญชี</th> -->
                  <!-- <th width="120">ประเภท</th> -->
                  <th width="120">สถานะ</th>
                  <th class="text-center" width="120">ธนาคารหลัก</th>
                  <th width="150">อัปเดตโดย</th>
                  <th width="170">อัปเดตล่าสุด</th>
                  <th width="120" class="fixed box-shadow-left">จัดการ</th>
                </tr>
              </thead>

              <tbody>
                @for (d of paymentChannelList; track $index) {
                <tr>
                  <td>
                    <div class="d-flex align-items-center gap-2">
                      <img
                        [alt]="d.bank.name"
                        src="/assets/images/logos/banks/{{ d.bank.code }}.png"
                        width="20" />{{ d.bank.name }}
                    </div>
                  </td>
                  <td>{{ d.account_name }}</td>
                  <td>{{ d.branch_name || '-' }}</td>
                  <td>{{ d.branch_code || '-' }}</td>
                  <!-- <td>{{d.account_number }}</td> -->
                  <!-- <td>{{ d.type.name }}</td> -->
                  <td>
                    <app-status-user [status]="d.is_active ? 'active' : 'inactive'">
                      {{ d.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
                    </app-status-user>
                  </td>
                  <td class="text-center">
                    <img
                      src="/assets/images/icons/icon-checkbox{{ d.is_primary ? '-checked' : '' }}.svg" />
                  </td>
                  <td>{{ d.updated_by ? d.updated_by.full_name : '' }}</td>
                  <td>{{ Moment(d.updated_at).format('DD-MM-YYYY, HH:mm') }}</td>
                  <td class="fixed box-shadow-left">
                    <div class="action-button-wrapper">
                      @if (d.action.edit) {
                      <button
                        matRipple
                        matTooltip="แก้ไข"
                        matTooltipPosition="above"
                        class="btn btn-outline-secondary btn-icon"
                        (click)="openModalBank('แก้ไขธนาคาร', 'edit', d)">
                        <span class="icon material-symbols-outlined fill">edit</span>
                      </button>
                      } @if (d.action.delete && !d.is_primary) {
                      <button
                        matRipple
                        matTooltip="ลบ"
                        matTooltipPosition="above"
                        class="btn btn-outline-danger btn-icon"
                        (click)="confirmDelete(d)">
                        <span class="icon material-symbols-outlined fill">delete</span>
                      </button>
                      }
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>

            @if(!paymentChannelList.length) {
            <div class="data-not-found-wrapper">ไม่มีข้อมูลธนาคาร</div>
            }
          </div>

          <!-- Pagination -->
          @if (paymentChannelList.length) {
          <div class="pagination-wrapper">
            <mat-paginator
              [pageIndex]="paginationPaymentChannel.data.pageIndex"
              [length]="paginationPaymentChannel.data.length"
              [pageSize]="paginationPaymentChannel.data.pageSize"
              [pageSizeOptions]="paginationPaymentChannel.data.pageSizeOptions"
              aria-label="Select page"
              (page)="paginationPaymentChannel.setFromPaginator($event);getPaymentChannelList()">
            </mat-paginator>
          </div>
          }
        </div>
      </mat-tab>
      <mat-tab label="บัตรเครดิต/ เดบิต">
        <div class="tabs-content-wrapper">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="title-blue" [style]="{ height: '40px' }">รายละเอียดบัตรเครดิต/ เดบิต</div>
            @if (isView && profile.permissions.checkAccess([APP_PERMISSION.BANK_COMPANY],
            [ACTION_ACCESS.UPDATE])) {
            <button matRipple class="btn btn-primary min-w-100px" (click)="editCreditDebit()">
              แก้ไขข้อมูล
            </button>
            }
          </div>

          <div class="container-fluid gx-0">
            <div class="row">
              @if(isView) {
              <div class="col-12 col-sm-4">
                <label class="form-label neutral-500">
                  สถานะเปิดรับบัตรเครดิต/ เดบิต จากลูกค้าทางลิงก์ / Website
                </label>
                <app-status-user
                  [status]="form.controls['is_debit_credit'].value ? 'active' : 'inactive'">
                  {{ form.controls['is_debit_credit'].value ? 'ใช้งาน' : 'ไม่ใช้งาน' }}
                </app-status-user>
              </div>

              <div class="col-12 col-sm-4">
                <label class="form-label neutral-500"> ค่าธรรมเนียมการให้บริการ (%) </label>
                <div class="view-data-text">{{ form.controls['fee_percent'].value }}</div>
              </div>
              } @if(!isView) {
              <div class="col-12 col-sm-4">
                <label class="label">
                  สถานะเปิดรับบัตรเครดิต/ เดบิต จากลูกค้าทางลิงก์ / Website
                </label>

                <div class="mt-2">
                  <mat-slide-toggle
                    hideIcon="true"
                    color="primary"
                    class="slide-toggle"
                    [formControl]="form.controls.is_debit_credit">
                    ใช้งาน
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="col-12 col-sm-4">
                <label class="label">
                  ค่าธรรมเนียมการให้บริการ (%)
                  <span class="text-danger">*</span>
                </label>
                <app-input-number
                  [control]="form.controls['fee_percent']"
                  [max]="100"
                  [useZero]="true">
                  @if (form.controls['fee_percent'].hasError('required')) {
                  <ng-container error>กรุณากรอกข้อมูล</ng-container>
                  } @else if (form.controls['fee_percent'].hasError('max')) {
                  <ng-container error>กรุณากรอกไม่เกิน 100</ng-container>
                  }
                </app-input-number>
              </div>
              }
            </div>
          </div>

          @if(!isView) {
          <div class="footer-button-wrapper d-flex justify-content-end w-100">
            <button matRipple class="btn btn-outline-secondary btn-md" (click)="onClose()">
              ยกเลิก
            </button>
            <button matRipple class="btn btn-primary btn-md" (click)="onConfirm()">บันทึก</button>
          </div>
          }
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
