<div class="header-wrapper">
  <div class="header-left">
    <div matRipple class="logo-wrapper">
      <img src="/assets/images/logos/logo.png" alt="Logo" class="logo-image" />
    </div>
    <div class="toggle-menu">
      <button matRipple (click)="NavMenu.toggle()" class="btn btn-transparent-outline">
        <span class="icon material-symbols-outlined"> menu </span>
      </button>
    </div>
    <div class="top-menu-wrapper">
      @if (profile.permissions.checkAccess([APP_PERMISSION.ORDER])) {
      <a
        matRipple
        class="top-menu-item"
        [class.active]="checkActive('/order')"
        [routerLink]="['/order']">
        <span class="icon icon-grey material-symbols-outlined fill">list_alt</span>
        <div class="top-menu-text">ออเดอร์</div>
      </a>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.PRODUCT])) {
      <a
        matRipple
        class="top-menu-item"
        [class.active]="checkActive('/product')"
        [routerLink]="['/product']">
        <span class="icon icon-grey material-symbols-outlined fill">shopping_basket</span>
        <div class="top-menu-text">สินค้า</div>
      </a>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.REPORT])) {
      <a
        matRipple
        class="top-menu-item"
        [class.active]="checkActive('/report')"
        [routerLink]="['/report']">
        <span class="icon icon-grey material-symbols-outlined fill">assessment</span>
        <div class="top-menu-text">รายงาน</div>
      </a>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER])) {
      <a
        matRipple
        class="top-menu-item"
        [class.active]="checkActive('/customer') || checkActive('/supplier')"
        [routerLink]="['/customer']"
        [queryParams]="{ tab: 0 }">
        <span class="icon icon-grey material-symbols-outlined fill">assignment_ind</span>
        <div class="top-menu-text">ผู้ติดต่อ</div>
      </a>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.EXPENSE])) {
      <a
        matRipple
        class="top-menu-item"
        [class.active]="checkActive('/record-expenses')"
        [routerLink]="['/record-expenses']">
        <span class="icon icon-grey material-symbols-outlined fill">paid</span>
        <div class="top-menu-text">ค่าใช้จ่าย</div>
      </a>
      }
      <!-- ~ -->
      @if (profile.permissions.checkAccess([APP_PERMISSION.HISTORY])) {
      <a
        matRipple
        class="top-menu-item"
        [class.active]="checkActive('/history')"
        [routerLink]="['/history']">
        <span class="icon icon-grey material-symbols-outlined fill">history</span>
        <div class="top-menu-text">ประวัติ</div>
      </a>
      }
    </div>
  </div>
  <div class="header-right">
    @if (pathDefaultSetting) {
    <div class="header-button-wrapper">
      <a
        matRipple
        class="setting-wrapper {{ checkActive('/setting') && 'active' }}"
        [routerLink]="[pathDefaultSetting.path]">
        <span class="icon icon-grey material-symbols-outlined fill">settings</span>
      </a>
    </div>
    }

    <div class="profile-wrapper cursor-pointer" [matMenuTriggerFor]="menu">
      <div class="profile-info">
        <div class="user-name">{{ profile.fullName }}</div>
        <div class="role-name">{{ profile.roleName }}</div>
      </div>

      <button class="btn btn-transparent btn-icon-sm">
        <span class="icon icon-dark material-symbols-rounded fill">arrow_drop_down</span>
      </button>
    </div>

    <mat-menu #menu="matMenu">
      <div matRipple class="mat-menu-item d-flex align-items-center" (click)="openLogout()">
        <span class="icon material-symbols-outlined"> logout </span>
        <span class="ps-2">ออกจากระบบ</span>
      </div>
    </mat-menu>
  </div>
</div>
