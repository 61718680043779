import { Component, Input } from '@angular/core'
import { imports } from '../../../imports'
import { MatDialog } from '@angular/material/dialog'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { SelectComponent } from '../../../components/select/select'
import { SearchComponent } from '../../../components/search/search'
import {
  ACTION_ACCESS,
  APP_PERMISSION,
  FilterTableProductCategory,
  Loading,
  Profile,
} from '../../../globals'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatMenuModule } from '@angular/material/menu'
import { ModalStatusConfirmComponent } from './modal-status-confirm/modal-status-confirm'
import { ModalErrorComponent } from '../../../components/modal-error/modal-error'
import { CompanyProfileService, OrderService } from '../../../services'
import { ActivatedRoute, Router } from '@angular/router'
import { decrypt } from '../../../helpers/crypto'
import { forkJoin } from 'rxjs'
import { PrintHtmlRenderComponent } from './print-html-render/print-html-render'
import {
  formatPrice,
  formatPriceAutoDecimal,
  pageSize,
  EnumPrintTypeValue,
  printType,
  thaiBath,
  Moment,
  ORDER_ID,
  getBarcodeBase64,
  setOrderForPrint,
  setLocalStorage,
  getLocalStorage,
  setSessionStorage,
  getSessionStorage,
  KEY_STARAGE_LIST_UPDATE,
  resetOrderList,
} from '../../../helpers'
import JsBarcode from 'jsbarcode'

@Component({
  selector: 'app-cover-sheet[companyProfile][orders][addresss][showButtonConfirm]',
  standalone: true,
  imports: [
    ...imports,
    SearchComponent,
    SelectComponent,
    MatPaginatorModule,
    MatMenuModule,
    PrintHtmlRenderComponent,
  ],
  templateUrl: './cover-sheet.html',
  styleUrls: ['./cover-sheet.scss'],
})
export class CoverSheetComponent {
  @Input() showPrint = true
  @Input() companyProfile: any
  @Input() orders: any[] = []
  @Input() addresss: any[] = []
  @Input() adminName = ''
  @Input() showButtonConfirm: boolean = true

  readonly APP_PERMISSION = APP_PERMISSION
  readonly ACTION_ACCESS = ACTION_ACCESS
  readonly formatPrice = formatPrice
  readonly formatPriceAutoDecimal = formatPriceAutoDecimal
  readonly thaiBath = thaiBath
  readonly Moment = Moment

  isAddress: boolean = false
  isReceipt: boolean = false
  isTaxInvoice: boolean = true
  isCopy: boolean = false

  form = new FormGroup({
    order_status: new FormControl(''),
  })

  temps = new Array(30)

  options = {
    [printType.address]: {
      '10cm': pageSize['10cm'],
      show: false,
    },
    [printType.receipt]: {
      A4: pageSize.A4,
      show: false,
    },
    [printType.tax]: {
      A4: pageSize.A4,
      show: false,
    },
  }

  get isButtonConfirm() {
    return (
      this.showButtonConfirm &&
      this.orders.some((order: any) =>
        [ORDER_ID.CREATE, ORDER_ID.VERIFY, ORDER_ID.PAID].includes(order.status_id)
      )
    )
  }

  constructor(
    public profile: Profile,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public customSnackBar: CustomSnackBar,
    public loading: Loading,
    public orderService: OrderService,
    public companyProfileService: CompanyProfileService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.isCopy = queryParams['is_copy'] == 'true'
    })

    this.route.params.subscribe(params => {
      try {
        this.options[params['view'] as EnumPrintTypeValue].show = true
      } catch (e) {}
    })

    if (this.options['tax'].show && !sessionStorage.getItem('printTax')) {
      sessionStorage.setItem('printTax', 'true')
      this.orders.forEach((order: any) => {
        this.orderService.printTax(order.id).subscribe(() => {})
      })
    }
  }

  paymentText(item: any) {
    return item.is_qrcode ? 'QR code / Prompt Pay' : 'Credit / Debit Card'
  }

  openModalStatusConfirm(): void {
    if (
      this.orders.some(
        (order: any) =>
          [ORDER_ID.CREATE, ORDER_ID.VERIFY, ORDER_ID.PAID].includes(order.status_id) &&
          !order.action.printed
      )
    ) {
      this.dialog.open(ModalErrorComponent, {
        data: {
          title: 'ไม่สามารถเปลี่ยนสถานะได้',
          detail: 'ไม่สามารถเปลี่ยนสถานะออเดอร์เป็นพิมพ์แล้ว เนื่องจากมีออเดอร์ยังไม่ชำระเงิน',
        },
      })
      return
    }

    const dialogRef = this.dialog.open(ModalStatusConfirmComponent, {
      data: true,
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const orders = this.orders.filter((order: any) => order.action.printed)
        let i = orders.length
        for (const order of orders) {
          this.orderService.printedOrder(order.id).subscribe((res: any) => {
            i--
            if (i == 0) {
              resetOrderList()
            }
            if (!res.is_error) {
              order.status_id = ORDER_ID.PRINTED
              order.status.id = ORDER_ID.PRINTED
              order.action.printed = false
              this.customSnackBar.success('เปลี่ยนสถานะเป็นพิมพ์แล้ว')
            } else {
              this.customSnackBar.failSave(res.message)
            }
          })
        }
      }
    })
  }

  onPrint() {
    window.print()
  }
}
