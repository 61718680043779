import { Injectable } from '@angular/core'
import { CustomSnackBar } from '../../components/snackbar/snackbar'
import { Loading } from '../loading'
import { __searchAndFitler } from './_mix'

@Injectable({ providedIn: 'root' })
export class FilterTableHistoryOrderPayment extends __searchAndFitler {
  constructor(public loading: Loading, public customSnackBar: CustomSnackBar) {
    const filter = {}
    super('/history/order-payment', filter)
  }

  initData() {}

  getData() {
    const data: any = {
      filter: {},
    }

    const searchValue = this.search.value
    if (searchValue) {
      data.search = searchValue
    }

    return data
  }
}
