<div class="page-main-content">
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-text breadcrumb-link">ผู้ติดต่อ</div>
    <div class="breadcrumb-separator">/</div>
    <div class="breadcrumb-text">{{ tab == 0 ? 'ข้อมูลลูกค้า' : 'ข้อมูลผู้ขาย'}}</div>
  </div>

  <div class="title-wrapper">{{ tab == 0 ? 'ข้อมูลลูกค้า' : 'ข้อมูลผู้ขาย'}}</div>

  <div class="tabs-wrapper">
    <mat-tab-group
      animationDuration="0ms"
      [disableRipple]="true"
      [selectedIndex]="tab"
      (selectedIndexChange)="changeTab($event)">
      @if (profile.permissions.checkAccess([APP_PERMISSION.CUSTOMER])) {
      <mat-tab label="ข้อมูลลูกค้า">
        <div class="tabs-content-wrapper">
          <app-tab-customer #tabCustomer></app-tab-customer>
        </div>
      </mat-tab>
      } @if ( profile.permissions.checkAccess([APP_PERMISSION.SUPPLIER])) {
      <mat-tab label="ข้อมูลผู้ขาย">
        <div class="tabs-content-wrapper">
          <app-tab-supplier #tabSupplier></app-tab-supplier>
        </div>
      </mat-tab>
      }
    </mat-tab-group>
  </div>
</div>
