<div class="page-main-sub-content bg-height">
  <div class="box-content py-3">
    <div class="page-content-inner">
      <div class="d-flex justify-content-center mb-2">
        <img alt="Logo" src="/assets/images/logos/logo-login.svg" />
      </div>
      <div class="text-center">
        <div>Brandname Hunter</div>
        <div class="title-sub">เลขที่ใบสั่งซื้อ {{ order ? order.order_no : '-' }}</div>
        <div>
          วันที่สั่งซื้อ {{ order ? Moment(order.created_at).format('DD-MM-YYYY, HH:mm') : '-' }}
        </div>

        <div class="mt-3">
          สถานะออเดอร์
          <span class="title-blue">
            @if (isCreate) { รอยืนยันที่อยู่จัดส่ง
            <!-- ~ -->
            } @else if (isWaitPaid) { รอชำระเงิน
            <!-- ~ -->
            } @else if (isVerify) { กำลังตรวจสอบการชำระเงิน
            <!-- ~ -->
            } @else if (isPreparing) { กำลังจัดเตรียมสินค้า
            <!-- ~ -->
            } @else if (isShipped) { จัดส่งสินค้าแล้ว
            <!-- ~ -->
            } @else { -
            <!-- ~ -->
            }
          </span>
        </div>
        <app-payment-status-log [stepCompleted]="stepCompleted"></app-payment-status-log>
      </div>
    </div>

    @if (order) {
    <!-- ข้อมูลลูกค้า -->
    @if (isCreate || isWaitPaid || isVerify || isPreparing || isShipped) {
    <div class="page-content-inner mt-3">
      <div class="d-flex align-items-center justify-content-between">
        <div class="title mb-2">ข้อมูลลูกค้า</div>
        <!-- @if (isWaitPaid && !isEditCustomer) {
        <button class="btn btn-outline-secondary btn-md" matRipple (click)="isEditCustomer = true">
          แก้ไข
        </button>
        } -->
      </div>

      @if (isCreate || isEditCustomer) {
      <div class="row">
        <div class="col-12 col-sm-6">
          <label
            class="form-label"
            [class.text-danger]="form.controls['name'].touched && form.controls['name'].invalid">
            ชื่อลูกค้า <span class="text-danger">*</span>
          </label>
          <app-input [control]="form.controls['name']" [disabled]="true"></app-input>
        </div>
        <div class="col-12 col-sm-6">
          <label
            class="form-label"
            [class.text-danger]="form.controls['telephone'].touched && form.controls['telephone'].invalid">
            เบอร์โทรศัพท์ <span class="text-danger">*</span>
          </label>
          <app-input [control]="form.controls['telephone']" [disabled]="true"></app-input>
        </div>
      </div>
      <div class="title mb-2">ที่อยู่จัดส่ง</div>
      @for (item of addresss.controls; track item; let i = $index;) { @if
      (formStatusCreate.controls['is_multi_delivery'].value || item.controls['is_main'].value ||
      addresss.controls.length < 2) {
      <div class="row">
        @if (formStatusCreate.controls['is_multi_delivery'].value) {
        <div
          class="col-6 col-sm-6 title-sub d-flex align-items-center"
          [style]="{ height: '40px' }">
          ที่อยู่ {{ i + 1}}
        </div>
        <div class="col-6 col-sm-6 d-flex justify-content-end">
          @if (addresss.controls.length > 1 && !item.controls['is_main'].value) {
          <button matRipple class="btn btn-outline-danger button-del" (click)="delAddress(item, i)">
            <span class="icon material-symbols-outlined fill">delete</span>
            ลบ
          </button>
          }
        </div>
        }
        <div class="col-12 col-sm-6">
          <label
            class="form-label"
            [class.text-danger]="item.controls['name'].touched && item.controls['name'].invalid">
            ชื่อผู้รับ
            <span class="text-danger">*</span>
          </label>
          <app-input [control]="item.controls['name']">
            @if (item.controls['name'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (item.controls['name'].hasError('maxlength')) {
            <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
            }
          </app-input>
        </div>
        <div class="col-12 col-sm-6">
          <label
            class="form-label"
            [class.text-danger]="item.controls['telephone'].touched && item.controls['telephone'].invalid">
            เบอร์ผู้รับ <span class="text-danger">*</span>
          </label>
          <app-input [control]="item.controls['telephone']">
            @if (item.controls['telephone'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            } @else if (item.controls['telephone'].hasError('pattern')) {
            <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
            }
          </app-input>
        </div>
        <div class="col-12 col-sm-12">
          <label
            class="form-label"
            [class.text-danger]="item.controls['address'].touched && item.controls['address'].invalid">
            ที่อยู่จัดส่ง
            <span class="text-danger">*</span>
          </label>
          <app-text-area [control]="item.controls['address']" remainingText="250" maxlength="250">
            @if (item.controls['address'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-text-area>
        </div>
        <!-- @if (formStatusCreate.controls['is_multi_delivery'].value) {
          <div class="col-12 col-sm-12">
            <label
              class="form-label"
              [class.text-danger]="item.controls['product'].touched && item.controls['product'].invalid"
            >
              สินค้าที่จัดส่ง
              <span class="text-danger">*</span>
            </label>
            <app-select
              [control]="item.controls['product']"
              [datas]="addressProductList(i)"
              fieldValue="key"
              placeholder="เลือก"
              [multiple]="true"
            >
              <ng-template #optionRef let-option> {{ option.item.name }} </ng-template>

              @if (item.controls['product'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              }
            </app-select>
          </div>
          } -->
        <div class="col-12 col-sm-12" [formGroup]="item">
          <mat-checkbox formControlName="is_main" (change)="setDefaultAddress(item)">
            ตั้งเป็นที่อยู่หลัก
          </mat-checkbox>
        </div>
      </div>
      @if (addresss.value.length > 1 && i+1 != addresss.value.length) {
      <div class="line-sub"></div>
      } } }

      <div class="row">
        @if (formStatusCreate.controls['is_multi_delivery'].value) {
        <div class="col-12 col-sm-12 py-3">
          <button class="btn btn-outline-secondary w-100" matRipple (click)="addAddress()">
            <span class="icon material-symbols-outlined fill">add_circle</span>
            เพิ่มที่อยู่ใหม่
          </button>
        </div>
        }
        <div class="col-12 col-sm-12">
          <mat-checkbox [formControl]="form.controls['is_policy']">
            ข้าพเจ้าได้อ่านและยอมรับ
          </mat-checkbox>
          <a
            href="/assets/นโยบายการคุ้มครองความเป็นส่วนตัว.pdf"
            target="_blank"
            class="color--brand-blue ms-1 font-14 text-link">
            นโยบายความเป็นส่วนตัว
          </a>
        </div>
      </div>
      } @else {
      <div class="row">
        <div class="col-12 col-sm-4">
          <div>ชื่อลูกค้า</div>
        </div>
        <div class="col-12 col-sm-8">
          <div class="d-flex align-items-center">
            {{ order.customer.name }}
            <!-- ~ -->
            @if (customer.tier) {
            <app-label-tier-color
              class="ms-3"
              [color]="customer.tier.color"
              class="ms-2 cursor-pointer"
              (click)="openMyTier()">
              <div class="d-flex align-items-center ps-1">
                {{ customer.tier.name }}
                <span class="icon material-symbols-outlined fill ps-1">chevron_right</span>
              </div>
            </app-label-tier-color>
            }
          </div>
        </div>

        <div class="col-12 col-sm-4">เบอร์โทรศัพท์</div>
        <div class="col-12 col-sm-8">{{ order.customer.telephone }}</div>
      </div>
      <div class="title mt-3 mb-2">{{ isShipped ? 'รายการพัสดุ' : 'ที่อยู่จัดส่ง' }}</div>
      @for (item of order.delivery_addresses; track item; let i = $index) {
      <!-- ~ -->
      @if ((!order.is_multi_delivery && item.is_main) || (order.is_multi_delivery && item.is_used))
      {
      <!-- ~ -->
      @if (i > 0) {
      <div class="line-sub"></div>
      }

      <div class="row">
        @if (isShipped) {
        <div class="col-12 col-sm-4 py-1">เลขพัสดุ</div>
        <div class="col-12 col-sm-8 py-1">
          <div class="d-flex flex-wrap justify-content-between gap-2">
            <span>{{item.tracking_no || '-'}}</span>

            @if (order.transport.id == TRANSPORT_ID.EMS) {
            <!-- <div class="d-flex justify-content-end mt-2"> -->
            <a
              [href]="'https://track.thailandpost.co.th/?trackNumber=' + item.tracking_no"
              target="_blank"
              class="link-button">
              <button class="btn btn-primary" matRipple>
                <span class="icon material-symbols-outlined fill">local_shipping</span>
                ตรวจสอบสถานะพัสดุ
              </button>
            </a>
            <!-- </div> -->
            }
          </div>
        </div>

        <div class="col-12 col-sm-4 py-1">วันที่ส่งของ</div>
        <div class="col-12 col-sm-8 py-1">
          {{ item.delivery_date ? Moment(item.delivery_date).format('DD-MM-YYYY, HH:mm') : '-' }}
        </div>
        }

        <div class="col-12 col-sm-4 py-1">ชื่อผู้รับ</div>
        <div class="col-12 col-sm-8 py-1">{{item.name || '-'}}</div>

        <div class="col-12 col-sm-4 py-1">เบอร์โทรศัพท์</div>
        <div class="col-12 col-sm-8 py-1">{{item.telephone || '-'}}</div>

        <div class="col-12 col-sm-4 py-1">ที่อยู่จัดส่ง</div>
        <div class="col-12 col-sm-8 py-1">{{item.address || '-'}}</div>

        <!-- <div class="col-12 col-sm-4 py-1">สินค้าที่ส่ง</div>
          <div class="col-12 col-sm-8 py-1">{{item.product || '-'}}</div> -->
      </div>
      }
      <!-- ~ -->
      }
      <!-- ~ -->
      }
    </div>
    }

    <!-- รายการสั่งซื้อ -->
    @if (isCreate || isWaitPaid || isVerify || isPreparing || isShipped) {
    <div class="page-content-inner mt-3">
      <div class="d-flex justify-content-between">
        <div class="title mb-2">รายการสั่งซื้อ</div>
        @if (!order.is_confirm_address) {
        <div class="mb-2">
          <mat-checkbox
            [formControl]="formStatusCreate.controls['is_multi_delivery']"
            (change)="resetProductAddress();productArrs.reset()">
            สินค้าจัดส่งหลายที่อยู่
          </mat-checkbox>
        </div>
        }
      </div>

      <div class="table-responsive-no-border">
        <table class="table table-fixed-column table-no-border">
          <thead>
            <tr>
              <th width="210">ชื่อสินค้า</th>
              <th width="90" class="text-center">จำนวน</th>
              <th width="140" class="text-end">ราคาต่อหน่วย (บาท)</th>
              <th width="100" class="text-end">รวม (บาท)</th>
            </tr>
          </thead>

          <tbody>
            @for (item of products; track item) {
            <tr [class.tr-br-bottom]="!isMultiDelivery">
              <td class="text-ellipsis">{{ item.product_name }}</td>
              <td class="text-center">{{ formatPriceAutoDecimal(item.quantity) }}</td>
              <td class="text-end">{{ formatPrice(item.price) }}</td>
              <td class="text-end">{{ formatPrice(item.total_price) }}</td>
            </tr>
            @if(isCreate && isMultiDelivery) {
            <tr [class.tr-br-bottom]="isMultiDelivery">
              <td class="text-ellipsis py-0">
                <app-select
                  [control]="item.addressControl"
                  [datas]="addresss.value"
                  fieldValue="uuid"
                  placeholder="เลือกที่อยู่จัดส่ง">
                  <ng-template #optionRef let-option>
                    <div class="d-flex color--neutral-950">
                      {{ option.name }} <span class="mx-1">|</span> {{ option.telephone}}
                    </div>
                    <div class="color--neutral-500 font-14">{{ option.address }}</div>
                  </ng-template>

                  @if (item.addressControl.hasError('required')) {
                  <ng-container error>กรุณาเลือกข้อมูล</ng-container>
                  }
                </app-select>
              </td>
              <td class="py-0"></td>
              <td class="py-0"></td>
              <td class="py-0"></td>
            </tr>
            } }
          </tbody>
        </table>
      </div>
    </div>
    }

    <!-- ข้อมูลใบกำกับภาษี -->
    <!-- @if (isCreate || isShipped) {  -->
    <!-- ~ -->
    @if (isCreate) {
    <div class="page-content-inner mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">ข้อมูลใบกำกับภาษี</div>
        <div [formGroup]="formTax">
          <mat-checkbox formControlName="is_tax" (change)="onCheckTax()">
            ออกใบกำกับภาษี
          </mat-checkbox>
        </div>
      </div>

      @if (formTax.controls['is_tax'].value) {
      <div class="mt-2">
        <label
          class="form-label"
          [class.text-danger]="formTax.controls['tax_name'].touched && formTax.controls['tax_name'].invalid">
          ชื่อ-นามสกุล / บริษัทผู้เสียภาษี <span class="text-danger">*</span>
        </label>

        <app-input [control]="formTax.controls['tax_name']">
          @if (formTax.controls['tax_name'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (formTax.controls['tax_name'].hasError('maxlength')) {
          <ng-container error>กรุณากรอกข้อมูลไม่เกิน 100 ตัวอักษร</ng-container>
          }
        </app-input>
      </div>

      <div>
        <label
          class="form-label"
          [class.text-danger]="formTax.controls['tax_id'].touched && formTax.controls['tax_id'].invalid">
          เลขประจำตัวผู้เสียภาษี <span class="text-danger">*</span>
        </label>

        <app-input [control]="formTax.controls['tax_id']">
          @if (formTax.controls['tax_id'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (formTax.controls['tax_id'].hasError('pattern')) {
          <ng-container error>Tax ID ไม่ถูกต้อง</ng-container>
          }
        </app-input>
      </div>

      <div>
        <div class="row">
          <div
            class="form-label d-flex align-items-center col-12 col-lg-6 mb-0"
            [class.text-danger]="formTax.controls['tax_address'].touched && formTax.controls['tax_address'].invalid">
            ที่อยู่ในการออกใบกำกับภาษี <span class="text-danger ms-1"> *</span>
          </div>
          <!-- <div class="col-12 col-lg-6 d-flex justify-content-sm-end" [formGroup]="formTax">
            <mat-checkbox formControlName="is_tax_same_address"
              >ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน</mat-checkbox
            >
          </div> -->
        </div>

        <app-text-area
          [control]="formTax.controls['tax_address']"
          remainingText="250"
          maxlength="250">
          @if (formTax.controls['tax_address'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-text-area>
      </div>

      <div>
        <label
          class="form-label"
          [class.text-danger]="formTax.controls['tax_telephone'].touched && formTax.controls['tax_telephone'].invalid">
          เบอร์โทรศัพท์ <span class="text-danger">*</span>
        </label>

        <app-input [control]="formTax.controls['tax_telephone']">
          @if (formTax.controls['tax_telephone'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          } @else if (formTax.controls['tax_telephone'].hasError('pattern')) {
          <ng-container error>เบอร์โทรศัพท์ไม่ถูกต้อง</ng-container>
          }
        </app-input>
      </div>
      }
    </div>
    } @else if (order.is_tax) {
    <div class="page-content-inner mt-3">
      <div class="title mb-2">ข้อมูลใบกำกับภาษี</div>
      <div class="row gx-2 gy-2">
        <div class="col-12 col-sm-4">ชื่อ-นามสกุล / บริษัทผู้เสียภาษี</div>
        <div class="col-12 col-sm-8">{{ order.tax_name }}</div>
        <div class="col-12 col-sm-4">เลขประจำตัวผู้เสียภาษี</div>
        <div class="col-12 col-sm-8">{{ order.tax_id }}</div>
        <div class="col-12 col-sm-4">ที่อยู่ในการออกใบกำกับภาษี</div>
        <div class="col-12 col-sm-8">{{ order.tax_address }}</div>
        <div class="col-12 col-sm-4">เบอร์โทรศัพท์</div>
        <div class="col-12 col-sm-8">{{ order.tax_telephone }}</div>
      </div>
    </div>
    }
    <!-- } -->

    <!-- บันทึก -->
    @if (isCreate) {
    <div class="d-flex justify-content-end mt-3">
      <button
        class="btn btn-primary btn-md"
        matRipple
        [disabled]="!form.controls['is_policy'].value"
        (click)="onUpdateOrder()">
        ยืนยันที่อยู่
      </button>
    </div>
    }

    <!-- คูปองส่วนลด -->
    @if (isWaitPaid || isVerify || isPreparing || isShipped) {
    <div class="page-content-inner mt-3">
      <div class="title mb-2">คูปองส่วนลด</div>

      @if (isWaitPaid) {
      <label class="form-label d-flex justify-content-between">
        <span>รหัสส่วนลด</span>

        <span
          class="color--brand-blue d-flex align-items-center cursor-pointer"
          (click)="discountCode()">
          <span class="icon-16 material-symbols-outlined fill me-1"> sell </span>
          ดูส่วนลดที่ใช้ได้
        </span>
      </label>

      <div class="d-flex w-100">
        <div class="w-100">
          <app-input-chips [control]="form.controls.discount_code" [max]="1">
            @if (form.controls.discount_code.hasError('incorrect')) {
            <ng-container error>รหัสส่วนลดไม่ถูกต้อง</ng-container>
            } @else if (form.controls.discount_code.hasError('formApi')) {
            <ng-container error>{{ form.controls.discount_code.errors?.['formApi']}}</ng-container>
            }
          </app-input-chips>
        </div>
        <button class="btn btn-primary ms-2" matRipple (click)="useDiscountCode()">ใช้</button>
      </div>
      } @else {
      <div>
        <div class="d-flex w-75">
          <span class="w-25">รหัสส่วนลด</span>
          <span>{{ payment.promotion_code }}</span>
        </div>
        <div class="d-flex w-75 mt-1">
          <span class="w-25">จำนวนส่วนลด</span>
          <span>{{ formatPrice(payment.discount_code_price) }} บาท</span>
        </div>
      </div>
      }
    </div>
    }

    <!-- เครดิตและกะรัตส่วนลด -->
    @if (isWaitPaid || isVerify || isPreparing || isShipped) {
    <div class="page-content-inner mt-3">
      <div class="title my-2">เครดิตและกะรัตส่วนลด</div>

      @if (isWaitPaid) {
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="box-carat">
            <div class="d-flex align-items-center box-inner-credit-carat">
              <div class="d-flex align-items-center w-75 mb-3">
                <img src="/assets/images/icons/icon-gift.svg" class="me-2" />
                <div>
                  <span class="font-14">เครดิต</span>
                  <div class="font-12 color--neutral-500">1 เครดิต = 1 บาท</div>
                </div>
              </div>
              <div class="input-number-fake">
                <app-input-number
                  [control]="formPayment.controls['credit']"
                  [min]="0"
                  [max]="maxCredit"
                  [useZero]="true"
                  [decimal]="0"
                  (onInput)="validateCreditAndCarat()">
                  @if (formPayment.controls['credit'].hasError('max')) {
                  <ng-container error>เครดิตต้องน้อยกว่า {{ maxCredit }}</ng-container>
                  }
                </app-input-number>
                <div class="btn-num-right">
                  <button matRipple (click)="setUseCredit(1)">
                    <img src="/assets/images/icons/icon-btn-number-up.svg" />
                  </button>
                  <button matRipple (click)="setUseCredit(-1)">
                    <img src="/assets/images/icons/icon-btn-number-down.svg" />
                  </button>
                </div>
              </div>
            </div>
            <div class="color--brand-blue pt-2 font-14">
              คุณมีเครดิตสะสม :
              <b class="ms-2"> {{ formatPriceAutoDecimal(customer.credit) }} เครดิต </b>
            </div>
            <div class="color--neutral-500 font-12">&nbsp;</div>
            <div class="line-sub"></div>
            <div class="d-flex justify-content-between">
              <span class="font-14">แลกใช้เครดิตเป็นส่วนลด</span>
              <b class="color--brand-blue">ลด {{ formatPrice(useCredit) }} บาท</b>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="box-carat">
            <div class="d-flex align-items-center box-inner-credit-carat">
              <div class="d-flex align-items-center w-75 mb-3">
                <img src="/assets/images/icons/icon-carat.svg" class="py-1 me-2" />
                <div>
                  <span class="font-14 d-flex align-items-center">
                    กะรัต
                    <span
                      class="icon material-symbols-outlined fill color--brand-blue ms-1 cursor-pointer"
                      matRipple
                      (click)="openDiscountCarat()"
                      >info</span
                    >
                  </span>
                  <div class="font-12 color--neutral-500">
                    {{ carat.carat }} กะรัต = {{ formatPriceAutoDecimal(carat.value_amount) }} บาท
                  </div>
                </div>
              </div>
              <div class="input-number-fake">
                <app-input-number
                  [control]="formPayment.controls['carat']"
                  [min]="0"
                  [max]="maxCaratCustomer"
                  [useZero]="true"
                  [decimal]="0"
                  [disabled]="!this.carat.isUseCarat"
                  (onInput)="validateCreditAndCarat()">
                  @if (formPayment.controls['carat'].hasError('max')) {
                  <ng-container error>กะรัตต้องน้อยกว่า {{ maxCredit }}</ng-container>
                  }
                </app-input-number>
                <div class="btn-num-right">
                  <button matRipple (click)="setUseCarat(1)" [disabled]="!this.carat.isUseCarat">
                    <img src="/assets/images/icons/icon-btn-number-up.svg" />
                  </button>
                  <button matRipple (click)="setUseCarat(-1)" [disabled]="!this.carat.isUseCarat">
                    <img src="/assets/images/icons/icon-btn-number-down.svg" />
                  </button>
                </div>
              </div>
            </div>
            <div class="color--brand-blue pt-2 font-14">
              คุณมีกะรัตสะสม :
              <b class="ms-2"> {{ formatPriceAutoDecimal(customer.carat) }} กะรัต </b>
            </div>
            <div class="color--neutral-500 font-12">
              สะสมครบ {{ nextCarat.carat }} กะรัตเพื่อรับส่วนลดเพิ่มเป็น {{
              formatPrice(nextCarat.discount_price) }} บาท
            </div>
            <div class="line-sub"></div>
            <div class="d-flex justify-content-between">
              <span class="font-14">แลกใช้กะรัตเป็นส่วนลด</span>
              <b class="color--brand-blue">ลด {{ formatPrice(nowCarat.discount) }} บาท</b>
            </div>
          </div>
        </div>
      </div>

      @if (creditAndCaratError) {
      <div class="message-error d-flex gap-2">
        <img src="assets/images/icons/icon-message-error.svg" />
        <span>ไม่สามารถใส่ส่วนลดมากกว่ายอดที่ต้องชำระได้</span>
      </div>
      }
      <!-- ~ -->
      } @else {
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 col-sm-2 text-end">
              <img src="/assets/images/icons/icon-gift.svg" />
            </div>
            <div class="col-12 col-sm-10">
              <div class="row gy-1">
                <div class="col-12 col-sm-12">
                  <div class="row">
                    <div class="col-6 col-sm-5">แลกใช้เครดิต</div>
                    <div class="col-6 col-sm-7">
                      {{ formatPriceAutoDecimal(payment.used_credit) }} เครดิต
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="row">
                    <div class="col-6 col-sm-5">จำนวนส่วนลด</div>
                    <div class="col-6 col-sm-7">
                      {{ formatPrice(payment.credit_code_price) }} บาท
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 col-sm-2 text-end">
              <img src="/assets/images/icons/icon-carat.svg" />
            </div>
            <div class="col-12 col-sm-10">
              <div class="row gy-1">
                <div class="col-12 col-sm-12">
                  <div class="row">
                    <div class="col-6 col-sm-5">แลกใช้กะรัต</div>
                    <div class="col-6 col-sm-7">
                      {{ formatPriceAutoDecimal(payment.used_carat) }} กะรัต
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="row">
                    <div class="col-6 col-sm-5">จำนวนส่วนลด</div>
                    <div class="col-6 col-sm-7">
                      {{ formatPrice(payment.carat_code_price) }} บาท
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    }

    <!-- สรุปการสั่งซื้อ -->
    @if (isWaitPaid || isVerify || isPreparing || isShipped) {
    <div class="page-content-inner mt-3">
      <div class="title my-2">สรุปการสั่งซื้อ</div>

      <div class="d-flex justify-content-between">
        <span>รวมราคาสินค้า</span>
        <span> {{ formatPrice(totalProductPrice(false)) }} </span>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <span>ค่าจัดส่ง</span>
        <span>{{ shippingCost ? formatPrice(shippingCost) : 'ฟรี' }}</span>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <span>ส่วนลดเพิ่มเติม</span>
        <span>{{ formatPrice(formatTotalDiscount(discount)) }}</span>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <span>รหัสส่วนลด</span>
        <span>{{ formatPrice(formatTotalDiscount(discountCodePrice)) }} </span>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <span>ส่วนลดเครดิต</span>
        <span>{{ formatPrice(formatTotalDiscount(creditCodePrice)) }} </span>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <span>ส่วนลดกะรัต</span>
        <span>{{ formatPrice(formatTotalDiscount(caratCodePrice )) }} </span>
      </div>
      <div class="line-sub"></div>
      <div class="d-flex justify-content-between color--brand-blue font-18">
        <b>ยอดรวมสุทธิ</b>
        <b class="blue">{{ formatPrice(sumTotal) }} </b>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <span>ได้รับกะรัต</span>
        <span>{{ formatPriceAutoDecimal(receivedCarat) }} กะรัต</span>
      </div>

      @if (isWaitPaid) {
      <div class="box-sell-carat">
        <div class="d-flex justify-content-between">
          <span class="d-flex align-items-center">
            <img src="/assets/images/icons/icon-carat.svg" width="18" class="me-2" />
            <span class="color--brand-blue-700">
              ซื้อเพิ่มอีก {{ formatPriceAutoDecimal(caratTotal.remain) }} บาท เพื่อรับอีก 1 กะรัต
            </span>
            <span class="color--neutral-500 ms-2">
              ({{ formatPriceAutoDecimal(carat.total_order_amount) }} บาท = 1 กะรัต)
            </span>
          </span>
          <span class="color--brand-blue-700">
            {{ formatPriceAutoDecimal(caratTotal.excess) }} / {{
            formatPriceAutoDecimal(carat.total_order_amount) }} บาท
          </span>
        </div>
        <div class="progress mt-2">
          <div
            class="progress-bar"
            role="progressbar"
            [style]="{width: caratTotal.percent + '%' }"
            [attr.aria-valuenow]="caratTotal.percent"
            aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
      </div>

      @if (sumTotal < 1) {
      <div class="line mt-3"></div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary btn-md" matRipple (click)="onPayment()">
          ยืนยันคำสั่งซื้อ
        </button>
      </div>
      }
      <!-- ~ -->
      }
      <!-- ~ -->
      @if (isVerify) {
      <div class="warning-error d-flex align-items-center mt-3">
        <img src="/assets/images/icons/icon-warning-error.svg" class="me-2" />
        @if (payment && payment.payment_amount > sumTotal) {
        <!-- ~ -->
        ชำระเกิน: {{ formatPrice(payment.payment_amount - sumTotal) }}
        <!-- ~ -->
        } @else if (payment && payment.payment_amount < sumTotal) {
        <!-- ~ -->
        ชำระขาด: {{ formatPrice(sumTotal - payment.payment_amount) }}
        <!-- ~ -->
        } @else if (payment && payment.payment_amount == 0) {
        <!-- ~ -->
        ยอดค้างชำระ: {{ formatPrice(sumTotal) }}
        <!-- ~ -->
        } บาท
      </div>
      }
    </div>
    }

    <!-- รายละเอียดการชำระเงิน -->
    @if (isCreate) {
    <div class="page-content-inner mt-3">
      <div class="title mb-2">รายละเอียดการชำระเงิน</div>
      <div class="data-not-found-wrapper">กรุณายืนยันที่อยู่จัดส่งก่อน</div>
    </div>
    }

    <!-- ช่องทางการชำระเงิน -->
    @if ((!isCreate) && sumTotal > 0) {
    <div class="page-content-inner mt-3">
      @if (payment) {
      <div class="title mb-2">รายละเอียดการชำระเงิน</div>
      @for (paymentDetail of payment.details; track $index) {
      <!-- ~ -->
      @if ($index > 0) {
      <div class="px-3">
        <div class="line mt-3"></div>
      </div>
      }
      <div class="row gx-2 gy-2">
        <div class="col-12 col-sm-4">ช่องทางการชำระเงิน</div>
        <div class="col-12 col-sm-8">
          @if (paymentDetail.payment_type == 1) { โอนเงินผ่านธนาคาร
          <!-- ~ -->
          @if (paymentDetail?.bank?.code != BANK_NONE.CODE) {
          <img
            src="/assets/images/logos/banks/{{ paymentDetail?.bank?.code }}.png"
            width="20"
            height="20"
            class="ms-1 me-1" />
          }
          <label class="ms-1">
            เลขที่บัญชี: {{ paymentDetail?.bank_account?.account_number }}
          </label>
          } @else if (paymentDetail.payment_type == 2) { เงินสด } @else if
          (paymentDetail.payment_type == 3) { Credit / Debit Card }
        </div>
        <div class="col-12 col-sm-4">ยอดชำระ</div>
        <div class="col-12 col-sm-8">{{ formatPrice(paymentDetail?.payment_amount) }} บาท</div>
        <div class="col-12 col-sm-4">วันเวลาที่ชำระ</div>
        <div class="col-12 col-sm-8">
          {{ Moment(paymentDetail.payment_date).format('DD-MM-YYYY, HH:mm') }}
        </div>
      </div>
      }
      <!-- ~ -->
      }
      <!-- ~ -->
      @if (isWaitPaid || isVerify) {
      <!-- ~ -->
      @for (paymentGroup of arrayPayments.controls; track $index) {
      <!-- ~ -->
      @if ($index > 0 || payment) {
      <div class="px-3">
        <div class="line mt-3"></div>
      </div>
      }

      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="title my-2">ช่องทางการชำระเงิน</div>

        @if ($index > 0 || (payment && payment.details.length)) {
        <button class="btn btn-outline-danger" matRipple (click)="delPayment($index)">
          <span class="icon material-symbols-outlined fill">delete</span>
          ลบ
        </button>
        }
      </div>

      <mat-radio-group
        [formControl]="paymentGroup.controls['payment']"
        class="d-flex align-items-center"
        (change)="resetPayment(paymentGroup)">
        <div
          class="box-radio {{ paymentGroup.controls['payment'].value == PAYMENT_TYPE.SLIP && 'active' }} me-2">
          <mat-radio-button [value]="1">โอนเงินผ่านธนาคาร</mat-radio-button>
        </div>
        @if (companyProfile.is_debit_credit) {
        <div
          class="box-radio {{ paymentGroup.controls['payment'].value == PAYMENT_TYPE.CREDIT_DEBIT && 'active' }}"
          [class.disabled]="disableCreditDebit && paymentGroup.controls['payment'].value != PAYMENT_TYPE.CREDIT_DEBIT">
          <mat-radio-button
            [value]="3"
            [disabled]="disableCreditDebit && paymentGroup.controls['payment'].value != PAYMENT_TYPE.CREDIT_DEBIT"
            >Credit / Debit Card</mat-radio-button
          >
        </div>
        }
      </mat-radio-group>

      @if (paymentGroup.controls['payment'].value == PAYMENT_TYPE.SLIP) {
      <div class="row mt-3">
        <div class="col-12 mb-3">กรุณาชำระเงินโดยการโอนเงินเข้าบัญชีธนาคาร</div>
        <div class="col-12">
          <div class="box-select-slip">
            <app-select
              [control]="paymentGroup.controls['bank_account_id']"
              [datas]="bankAccountList"
              fieldValue="id"
              placeholder="เลือก"
              type="select-bank">
              <ng-template #optionRef let-option>
                <div class="d-flex align-items-center">
                  @if (option.bank_id) {
                  <img
                    [alt]="option.bank.name"
                    src="/assets/images/logos/banks/{{ option.bank.code }}.png"
                    width="32" />
                  }
                  <div class="ms-2">
                    <b>{{ option.account_name }}</b>
                    <div>เลขที่บัญชี {{ option.account_number}}</div>
                  </div>
                </div>
              </ng-template>

              @if (paymentGroup.controls['bank_account_id'].hasError('required')) {
              <ng-container error>กรุณาเลือกข้อมูล</ng-container>
              }
            </app-select>
          </div>

          @if (bankAccount(paymentGroup)?.qr?.url) {
          <div class="box-slip mb-3">
            <div class="box-qrcode">
              <img [src]="bankAccount(paymentGroup).qr.url" />
            </div>

            <button
              class="btn btn-outline-secondary w-100 mt-3"
              matRipple
              (click)="downloadQr(paymentGroup)">
              บันทึก QR Code
            </button>
          </div>
          }
        </div>
      </div>

      <div class="title">หลักฐานการโอนเงิน</div>

      <div class="row mt-3 font-14">
        <div class="col-12 col-sm-12">
          <span
            [class.text-danger]="paymentGroup.controls['file_id'].touched && paymentGroup.controls['file_id'].invalid">
            หลักฐานการโอนเงิน <span class="text-danger">*</span>
          </span>
          <div class="my-2">
            <app-upload-image-profile
              [isPublic]="true"
              [type]="'edit'"
              [logoDefault]="''"
              (upload)="paymentGroup.controls['file_id'].setValue($event.id)"
              (onDelete)="paymentGroup.controls['file_id'].reset()"></app-upload-image-profile>
          </div>
        </div>
        <div class="col-12 col-sm-12">
          <label
            class="form-label"
            [class.text-danger]="paymentGroup.controls['bank_id'].touched && paymentGroup.controls['bank_id'].invalid">
            ธนาคารผู้โอน <span class="text-danger">*</span>
          </label>
          <app-select
            [control]="paymentGroup.controls['bank_id']"
            [datas]="bankList"
            fieldValue="id"
            placeholder="เลือก">
            <ng-template #optionRef let-option>
              <div class="d-flex align-items-center gap-2">
                @if (option.code != BANK_NONE.CODE) {
                <img
                  [alt]="option.name"
                  src="/assets/images/logos/banks/{{ option.code }}.png"
                  width="20" />
                } {{ option.name }}
              </div>
            </ng-template>

            @if (paymentGroup.controls['bank_id'].touched &&
            paymentGroup.controls['bank_id'].hasError('required')) {
            <ng-container error>กรุณาเลือกข้อมูล</ng-container>
            }
          </app-select>
        </div>

        <div class="col-12 col-sm-12">
          <label
            class="form-label"
            [class.text-danger]="paymentGroup.controls['payment_amount'].touched && paymentGroup.controls['payment_amount'].invalid">
            ยอดโอน <span class="text-danger">*</span>
          </label>
          <app-input-number
            [control]="paymentGroup.controls['payment_amount']"
            unit="บาท"
            placeholder="0.00"
            [min]="0"
            (onInput)="changePriceSlip()">
            @if (paymentGroup.controls['payment_amount'].touched &&
            paymentGroup.controls['payment_amount'].hasError('required')) {
            <ng-container error>กรุณากรอกข้อมูล</ng-container>
            }
          </app-input-number>
        </div>

        <div class="col-12 col-sm-6">
          <label class="form-label"> วันที่โอนเงิน </label>
          <app-date-picker [control]="paymentGroup.controls['date']">
            @if (paymentGroup.controls['date'].hasError('required')) {
            <ng-container error>กรุณาเลือกข้อมูล</ng-container>
            }
          </app-date-picker>
        </div>

        <div class="col-12 col-sm-6">
          <label class="form-label"> เวลาที่โอนเงิน </label>
          <app-time-picker [control]="paymentGroup.controls['time']">
            @if (paymentGroup.controls['time'].hasError('required')) {
            <ng-container error>กรุณาเลือกข้อมูล</ng-container>
            }
          </app-time-picker>
        </div>
      </div>
      } @else if (paymentGroup.controls['payment'].value == PAYMENT_TYPE.CREDIT_DEBIT) {
      <div [style]="{ 'margin-left': '-10px' }">
        <mat-radio-group
          [formControl]="paymentGroup.controls['is_full_amount']"
          class="d-flex align-items-center mt-2 gap-2"
          (change)="resetPayment(paymentGroup)">
          <mat-radio-button [value]="1">ชำระเต็มจำนวน</mat-radio-button>
          <mat-radio-button [value]="2">ระบุยอดเงิน</mat-radio-button>
        </mat-radio-group>
      </div>

      @if (paymentGroup.controls['is_full_amount'].value == 1) {
      <div class="text-remain-amount mt-2 mb-3">
        ยอดชำระ {{ formatPrice(paymentRemainAmount) }} บาท
      </div>
      } @else {
      <div class="mt-2">
        <label
          class="form-label"
          [class.text-danger]="paymentGroup.controls['payment_amount'].touched && paymentGroup.controls['payment_amount'].invalid">
          ยอดชำระ <span class="text-danger">*</span>
        </label>
        <app-input-number
          [control]="paymentGroup.controls['payment_amount']"
          unit="บาท"
          [min]="0"
          [max]="paymentRemainAmount"
          placeholder="0.00">
          @if (paymentGroup.controls['payment_amount'].touched &&
          paymentGroup.controls['payment_amount'].hasError('required')) {
          <ng-container error>กรุณากรอกข้อมูล</ng-container>
          }
        </app-input-number>
      </div>
      }

      <div class="mb-3">
        การชำระเงินด้วยบัตรเครดิตจะมีค่าธรรมเนียมบัตรเครดิต {{ companyProfile.is_debit_credit ?
        companyProfile.fee_percent + '%' : '' }} เป็นไปตามที่ธนาคารกำหนด
      </div>
      }
      <!-- ~ -->
      }

      <div class="px-3">
        <div class="line mt-3"></div>
      </div>
      <button class="btn btn-outline-primary-dark btn-md" matRipple (click)="addPayment()">
        <span class="icon material-symbols-outlined fill">add_circle</span>
        เพิ่มการชำระเงินหลายช่องทาง
      </button>

      @if (isWaitPaid) {
      <div class="px-3">
        <div class="line mt-3"></div>
      </div>

      <div class="color--orange-600 d-flex align-items-center font-14 mt-3">
        <span class="icon material-symbols-outlined fill me-1">info</span>
        กรุณาตรวจสอบรายการคำสั่งซื้อก่อนชำระเงิน บริษัทไม่มีนโยบายการเปลี่ยน / คืนสินค้าในทุกกรณี
      </div>
      }
      <!-- ~ -->
      @if (arrayPayments.value.length) {
      <div class="line mt-3"></div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary btn-md" matRipple (click)="onPayment()">ชำระเงิน</button>
      </div>
      }
      <!-- ~ -->
      }
    </div>
    }
    <!-- --- -->
    }

    <!-- not found -->
    @if (isNotFound) {
    <div class="d-flex flex-column align-items-center justify-content-center">
      <img
        src="/assets/images/icons/img-order-not-found.svg"
        alt="Order Not Found"
        class="img-order-not-found" />
      ไม่พบข้อมูล
    </div>
    }
  </div>
</div>
