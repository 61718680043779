import { Component, Inject } from '@angular/core'
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { CustomSnackBar } from '../../../../../../components/snackbar/snackbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule } from '@angular/forms'
import { FilterTableUser } from '../../../../../../globals'
import { imports } from '../../../../../../imports'

@Component({
  selector: 'app-modal-filter-user',
  templateUrl: './modal-filter.html',
  styleUrls: ['./modal-filter.scss'],
  standalone: true,
  imports: [
    ...imports,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
  ],
})
export class ModalFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public customSnackBar: CustomSnackBar,
    public filterTableUser: FilterTableUser
  ) {
    this.filterTableUser.initData()
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onReset(): void {
    this.filterTableUser.resetModalFilter()
  }

  onConfirm(): void {
    this.filterTableUser.confirm()
    this.dialogRef.close(true)
  }
}
