import { Component, EventEmitter, Injectable, Input, OnInit, Output, inject } from '@angular/core'
import { MatSuffix } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatDialog } from '@angular/material/dialog'
import { ReactiveFormsModule } from '@angular/forms'

import { ModalFilterComponent } from '../modal-filter/modal-filter'

import { FilterTableUser, Loading } from '../../../../../../globals'
import { RoleService } from '../../../../../../services'
import { CustomSnackBar } from '../../../../../../components/snackbar/snackbar'
import { InputSearchComponent } from '../../../../../../components/input-search/input-search'
import { imports } from '../../../../../../imports'

@Component({
  selector: 'app-search-filter-user',
  standalone: true,
  imports: [
    ...imports,
    MatInputModule,
    MatSelectModule,
    MatSuffix,
    ReactiveFormsModule,
    ModalFilterComponent,
    InputSearchComponent,
  ],
  templateUrl: './search-filter.html',
  styleUrls: ['./search-filter.scss'],
})
export class SearchFilterComponent {
  @Input() placeholder = ''

  @Output() onConfirm = new EventEmitter()

  constructor(
    public dialog: MatDialog,
    public roleService: RoleService,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public filterTableUser: FilterTableUser
  ) {
    this.filterTableUser.initData()
  }

  async openModalFilter() {
    const dialogRef = this.dialog.open(ModalFilterComponent, {
      data: {
        title: 'ตัวกรอง',
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onConfirm.emit()
      } else {
        this.filterTableUser.close()
      }
    })
  }

  afterFilter() {
    this.filterTableUser.confirm()
    this.onConfirm.emit()
  }
}
