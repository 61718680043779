import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Service } from './service'

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private service: Service) {}
  private pathPublic = '/v1/public'
  getCompanyProfile(payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/company-profile`, payload)
  }
  getBankAccounts(payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/bank-accounts`, payload)
  }
  getBanks(payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/banks`, payload)
  }
  getCustomerById(customerId: any, payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/customers/${customerId}`, payload)
  }
  getCarats(payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/carats`, payload)
  }
  getTiers(payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/tiers`, payload)
  }
  getPromotionByOrderId(orderId: any, payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/promotions/${orderId}`, payload)
  }
  validatePromotion(orderId: any, promotionId: any, payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublic}/promotions/${orderId}/${promotionId}`, payload)
  }

  private pathPublicOrder = this.pathPublic + '/orders'
  getOrderByLinkCode(link_code: string): Observable<any> {
    return this.service.get(`${this.pathPublicOrder}`, {
      link_code,
    })
  }
  getPaymentByOrderId(orderId: any, payload: any = {}): Observable<any> {
    return this.service.get(`${this.pathPublicOrder}/payments/${orderId}`, payload)
  }
  updateOrder(orderId: any, payload: any): Observable<any> {
    return this.service.put(`${this.pathPublicOrder}/${orderId}`, payload)
  }
  savePayment(payload: any): Observable<any> {
    return this.service.post(`${this.pathPublicOrder}/payments`, payload)
  }

  private pathPublicFile = this.pathPublic + '/files'
  upload(file: any): Observable<any> {
    return this.service.uploadFile(`${this.pathPublicFile}`, file)
  }
  getBlob(id: any, payload: any = {}): Observable<any> {
    return this.service.getBlob(`${this.pathPublicFile}/${id}/download`, payload)
  }
}
